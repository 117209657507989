import { Text, Flex, Stack, Box } from '@chakra-ui/react';
import { FormikProps, Form } from 'formik';
import { observer } from 'mobx-react';

import { FormDatePicker } from '../../../components/form/FormDatePicker';
import { FormInput } from '../../../components/form/FormInput';
import { getFormDuration, IWorkHourForm } from '../../../models/workHours/workHourFormUtils';
import { durationToMinutesDisplay } from '../../../utils/time/timeUtils';

export const ManualWorkHourForm = observer(
  ({
    form,
    minimumDate,
    maximumDate,
  }: {
    form: FormikProps<IWorkHourForm>;
    minimumDate?: Date;
    maximumDate?: Date;
  }) => {
    return (
      <Form>
        <Stack>
          <FormInput name="comment" label="תיאור" type="textarea" rows={2} size="sm" />
          <FormDatePicker
            size="sm"
            name="date"
            label="תאריך"
            isRequired
            minimumDate={minimumDate}
            maximumDate={maximumDate}
          />
          <Flex justifyContent="space-between" alignItems="center" mb="16px">
            <Flex gap="14px" alignItems="center">
              <Box w="80px">
                <FormInput name="startTime" textAlign="right" h="22px" type="time" label="התחלה" isRequired size="sm" />
              </Box>
              <Text fontSize="xs" mt="10px">
                -
              </Text>
              <Box w="80px">
                <FormInput name="endTime" type="time" textAlign="right" h="22px" label="סיום" isRequired size="sm" />
              </Box>
            </Flex>
            <Flex backgroundColor="success.0" borderRadius="8px" py="8px" px="12px" alignItems="center">
              <Text textStyle="large" color="base.1000">
                {durationToMinutesDisplay(getFormDuration(form.values))}
              </Text>
            </Flex>
          </Flex>
        </Stack>
      </Form>
    );
  },
);
