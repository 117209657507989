import { Text, Heading, Button, Stack } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useState } from 'react';

import { useCreateUserData } from '../../models/userData/useCreateUserData';
import {
  getUserDataInitialValues,
  IValidUserDataValues,
  validateUserDataValues,
} from '../../models/userData/userDataFormUtils';
import { logger } from '../../utils/logger';
import { AuthenticationLayout } from '../common/Layout/AuthenticationLayout';

import { OnboardingFirstStep } from './OnboardingFirstStep';
import { OnboardingSecondStep } from './OnboardingSecondStep';

export const Onboarding = ({}: {}) => {
  const { createUserData, isLoading } = useCreateUserData();
  const [isFinalStep, setIsFinalStep] = useState(false);

  const initialValues = getUserDataInitialValues();
  const handleSubmit = async (values: IValidUserDataValues) => {
    if (!isFinalStep) {
      setIsFinalStep(true);
      document.getElementById('logo')?.scrollIntoView();
      return;
    }

    try {
      await createUserData(values);
    } catch (err) {
      return logger.error(err);
    }
  };

  return (
    <AuthenticationLayout
      onBackClick={isFinalStep ? () => setIsFinalStep(false) : undefined}
      wizardStep={isFinalStep ? 4 : 3}
    >
      <Heading
        id={`onboarding_${isFinalStep ? 1 : 0}`}
        textStyle={{ base: 'h3', md: 'h2' }}
        mb={{ base: '24px', md: '47px' }}
      >
        {isFinalStep ? (
          <>
            עוד קצת ונכנסים
            <br />
            לחשבון
          </>
        ) : (
          <>
            מעולה, אפשר
            <br />
            להתחיל
          </>
        )}
      </Heading>
      <Text fontWeight="300" fontSize={{ base: 'lg', md: 'xl' }} mb="40px">
        כמה שאלות חשובות כדי שנוכל להתאים את החשבון לצרכים שלך ולספק לך המלצות. ניתן לעדכן אותם בהמשך מתוך אזור ההגדרות
      </Text>
      <Formik
        initialValues={initialValues}
        validate={(values) =>
          validateUserDataValues(values, isFinalStep ? 'onboardingSecondStep' : 'onboardingFirstStep')
        }
        onSubmit={(values) => handleSubmit(values as IValidUserDataValues)}
      >
        {(props) => (
          <Form
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          >
            <Stack gap="24px" mb={5}>
              {isFinalStep ? <OnboardingSecondStep /> : <OnboardingFirstStep />}
              <Button isLoading={isLoading} type="submit" w="100%" isDisabled={!props.isValid}>
                {isFinalStep ? 'קדימה לחשבון' : 'המשך'}
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </AuthenticationLayout>
  );
};
