import { useQuery } from '@tanstack/react-query';

import { getTasks } from './tasksApi';

export const TASKS_QUERY_KEY = 'tasks';

export function useTasks() {
  const { data: tasks, isLoading } = useQuery({
    queryKey: [TASKS_QUERY_KEY],
    queryFn: () => getTasks(),
  });

  return {
    tasks,
    isLoading,
  };
}
