import { Flex, Box, Divider } from '@chakra-ui/react';
import { observer } from 'mobx-react';

import { ProgressBar } from '../../../components/ProgressBar';
import { useVat } from '../../../hooks/useVat/useVat';
import { Project } from '../../../models/Project';
import { roundTo2 } from '../../../utils/number/numberUtils';
import { durationToMinutesDisplay, HOURS_TO_DURATION } from '../../../utils/time/timeUtils';
import { Stat } from '../../common/Stat';

import { VIEW_STATE } from './SingleProjectData';

export const HourlyData = observer(({ project, viewState }: { project: Project; viewState: VIEW_STATE }) => {
  const { getValueWithVat } = useVat();

  const getProgressStats = () => {
    if (viewState === 'HOURS') {
      return {
        current: `${durationToMinutesDisplay(project.accumulatedDuration)}`,
        total: project.totalValue ? `${durationToMinutesDisplay(project.remainingRevenueDuration)}` : 'ללא מסגרת',
        currentIcon: 'ש׳',
        totalIcon: project.totalValue ? 'ש׳' : '',
      };
    }
    return {
      current: `${roundTo2(getValueWithVat(project.accumulatedValue)).toLocaleString()}`,
      total: project.totalValue
        ? `${roundTo2(getValueWithVat(project.remainingRevenue)).toLocaleString()}`
        : 'ללא מסגרת',
      currentIcon: '₪',
      totalIcon: project.totalValue ? '₪' : '',
    };
  };

  const statsByState = {
    [VIEW_STATE.HOURS]: [
      {
        label: 'מסגרת שעות',
        value: project.totalDuration ? Math.floor(project.totalDuration / HOURS_TO_DURATION) : 'ללא',
      },
      {
        label: 'תוספות',
        value: project.totalAdditionsHours ? roundTo2(project.totalAdditionsHours) : '-',
      },
      {
        label: 'סה״כ שעות',
        value: project.totalRevenueDuration ? Math.floor(project.totalRevenueDuration / HOURS_TO_DURATION) : '-',
      },
      {
        label: 'עלות לשעה',
        value: `${getValueWithVat(project.rate).toLocaleString()}`,
        icon: '₪',
      },
    ],
    [VIEW_STATE.INCOME]: [
      {
        label: 'הכנסה צפויה',
        value: project.totalValue ? `${getValueWithVat(project.totalValue).toLocaleString()}` : '-',
        icon: project.totalValue ? '₪' : '',
      },
      {
        label: 'תוספות',
        value: project.totalAdditionsValue ? `${getValueWithVat(project.totalAdditionsValue).toLocaleString()}` : '-',
        icon: project.totalAdditionsValue ? '₪' : '',
      },
      {
        label: 'הכנסה סופית',
        value: project.totalRevenue ? `${getValueWithVat(project.totalRevenue).toLocaleString()}` : '-',
        icon: project.totalRevenue ? '₪' : '',
      },
      {
        label: 'תנאי תשלום',
        value: project.displayPaymentCycle,
      },
    ],
  };

  const getProgressValue = () => {
    if (!project.totalValue) {
      // ללא מסגרת - נציג מלא
      return 100;
    }
    return project.totalRevenue && project.accumulatedValue
      ? Math.max((project.accumulatedValue / project.totalRevenue) * 100, 5)
      : 0;
  };

  const advancePayment = project.payments.find((p) => p.isAdvance);
  const progressValue = getProgressValue();

  return (
    <>
      <Flex gap="56px">
        {statsByState[viewState].map((stat) => (
          <Stat key={stat.label} value={stat.value.toString()} label={stat.label} icon={Boolean(stat.icon)} />
        ))}
      </Flex>
      <Divider orientation="vertical" minHeight="50px" mx="48px" />
      <Box flex="1">
        <ProgressBar
          current={{ label: getProgressStats().current, icon: getProgressStats().currentIcon }}
          total={{ label: getProgressStats().total, icon: getProgressStats().totalIcon }}
          advancePercentage={advancePayment?.sum ? advancePayment?.sum / project.totalRevenue : undefined}
          value={progressValue}
        />
      </Box>
    </>
  );
});
