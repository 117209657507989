import { Route, State } from 'router5';

import { RootStore, IStore, InitializedStore } from '../models/RootStore';
import { AffiliateStats } from '../pages/affiliateStats/AffiliateStats';
import { Authentication } from '../pages/authentication/Authentication';
import { AuthenticationViewModel } from '../pages/authentication/AuthenticationViewModel';
import { CreateCustomer } from '../pages/customer/createCustomer/CreateCustomer';
import { CreateCustomerViewModel } from '../pages/customer/createCustomer/CreateCustomerViewModel';
import { Customers } from '../pages/customer/Customers';
import { CustomersViewModel } from '../pages/customer/CustomersViewModel';
import { SingleCustomer } from '../pages/customer/singleCustomer/SingleCustomer';
import { SingleCustomerViewModel } from '../pages/customer/singleCustomer/SingleCustomerViewModel';
import { Insights } from '../pages/insights/Insights';
import { InsightsViewModel } from '../pages/insights/InsightsViewModel';
import { Leads } from '../pages/leads/Leads';
import { LeadsViewModel } from '../pages/leads/LeadsViewModel';
import { Onboarding } from '../pages/onboarding/Onboarding';
import { OnboardingViewModel } from '../pages/onboarding/OnboardingViewModel';
import { CreateProject } from '../pages/projects/createProject/CreateProject';
import { CreateProjectViewModel } from '../pages/projects/createProject/CreateProjectViewModel';
import { Projects } from '../pages/projects/Projects';
import { ProjectsViewModel } from '../pages/projects/ProjectsViewModel';
import { SingleProject } from '../pages/projects/singleProject/SingleProject';
import { SingleProjectViewModel } from '../pages/projects/singleProject/SingleProjectViewModel';
import { Settings } from '../pages/settings/Settings';
import { SettingsViewModel } from '../pages/settings/SettingsViewModel';
import { TasksPage } from '../pages/task/TasksPage';
import { TasksViewModel } from '../pages/task/TasksViewModel';
import { WorkHours } from '../pages/workHour/WorkHours';
import { WorkHoursViewModel } from '../pages/workHour/WorkHoursViewModel';

import { RoutingStore } from './RoutingStore';

export type LinkData = Record<string, any>;

export interface IRoute extends Route {
  displayName: string;
  href: (...args: any[]) => string;
  link: (...args: any[]) => LinkData;
  component: (viewModel: IStore) => JSX.Element;
  viewModel: (store: RootStore, routingStore: RoutingStore, routeParams: any) => IStore;
  deactivate?: (store: RootStore, current?: any, next?: State) => void;
}

export type Routes = { [name: string]: IRoute };

export const ROUTES: Routes = {};

export const ProjectsRoute: IRoute = {
  name: 'projects',
  displayName: 'פרויקטים',
  path: '/projects',
  href: () => `/projects`,

  // Reverse routing 💪
  link: () => ({
    name: ProjectsRoute.name,
    params: {},
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as ProjectsViewModel;
    return <Projects viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    const initializedStore = store as InitializedStore;
    const viewModel = new ProjectsViewModel(
      initializedStore.projectsModel,
      initializedStore.customersModel,
      routingStore,
    );

    return viewModel;
  },
};
ROUTES[ProjectsRoute.name] = ProjectsRoute;

export const SingleProjectRoute: IRoute = {
  name: 'singleProject',
  displayName: 'פרוייקט',
  path: '/projects/:projectId',
  href: (projectId) => `/projects/${projectId}`,

  // Reverse routing 💪
  link: (projectId) => ({
    name: SingleProjectRoute.name,
    params: { projectId },
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as SingleProjectViewModel;
    return <SingleProject viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore, routeParams: any) => {
    const initializedStore = store as InitializedStore;
    const viewModel = new SingleProjectViewModel(
      initializedStore.projectsModel,
      initializedStore.customersModel,
      routingStore,
    );
    viewModel.activate(routeParams.projectId);
    return viewModel;
  },
};
ROUTES[SingleProjectRoute.name] = SingleProjectRoute;

export const CreateProjectRoute: IRoute = {
  name: 'createProject',
  displayName: 'פרוייקט',
  path: '/projects/new',
  href: () => `/projects/new`,

  // Reverse routing 💪
  link: () => ({
    name: CreateProjectRoute.name,
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as CreateProjectViewModel;
    return <CreateProject viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    const initializedStore = store as InitializedStore;
    const viewModel = new CreateProjectViewModel(
      initializedStore.projectsModel,
      initializedStore.customersModel,
      routingStore,
    );
    return viewModel;
  },
};
ROUTES[CreateProjectRoute.name] = CreateProjectRoute;

export const EditProjectRoute: IRoute = {
  name: 'editProject',
  displayName: 'פרוייקט',
  path: '/projects/edit/:projectId',
  href: (projectId) => `/projects/edit/${projectId}`,

  // Reverse routing 💪
  link: (projectId) => ({
    name: `/projects/edit/${projectId}`,
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as CreateProjectViewModel;
    return <CreateProject viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore, routeParams: any) => {
    const initializedStore = store as InitializedStore;
    const viewModel = new CreateProjectViewModel(
      initializedStore.projectsModel,
      initializedStore.customersModel,
      routingStore,
      routeParams.projectId,
    );
    return viewModel;
  },
};
ROUTES[EditProjectRoute.name] = EditProjectRoute;

export const LoginRoute: IRoute = {
  name: 'login',
  displayName: 'כניסה',
  path: '/login',
  href: () => '/login',

  // Reverse routing 💪
  link: () => ({
    name: LoginRoute.name,
    params: {},
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as AuthenticationViewModel;
    return <Authentication viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    return new AuthenticationViewModel(store.initialize, store.authenticationModel, routingStore);
  },
};
ROUTES[LoginRoute.name] = LoginRoute;

export const EmailVerifiedRoute: IRoute = {
  name: 'email-verified',
  displayName: 'מייל אושר',
  path: '/email-verified',
  href: () => '/email-verified',

  // Reverse routing 💪
  link: () => ({
    name: EmailVerifiedRoute.name,
    params: {},
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as AuthenticationViewModel;
    return <Authentication viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    return new AuthenticationViewModel(store.initialize, store.authenticationModel, routingStore);
  },
};
ROUTES[EmailVerifiedRoute.name] = EmailVerifiedRoute;

export const SignupRoute: IRoute = {
  name: 'signup',
  displayName: 'הרשמה',
  path: '/signup',
  href: () => '/signup',

  // Reverse routing 💪
  link: () => ({
    name: SignupRoute.name,
    params: {},
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as AuthenticationViewModel;
    return <Authentication viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    return new AuthenticationViewModel(store.initialize, store.authenticationModel, routingStore);
  },
};
ROUTES[SignupRoute.name] = SignupRoute;

export const OnboardingRoute: IRoute = {
  name: 'onboarding',
  displayName: 'הרשמה - פרטים אישיים',
  path: '/onboarding',
  href: () => '/onboarding',

  // Reverse routing 💪
  link: () => ({
    name: OnboardingRoute.name,
    params: {},
  }),

  component: () => {
    return <Onboarding />;
  },

  viewModel: () => {
    return new OnboardingViewModel();
  },
};
ROUTES[OnboardingRoute.name] = OnboardingRoute;

export const CustomersRoute: IRoute = {
  name: 'customers',
  displayName: 'לקוחות',
  path: '/customers',
  href: () => '/customers',

  // Reverse routing 💪
  link: () => ({
    name: CustomersRoute.name,
    params: {},
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as CustomersViewModel;
    return <Customers viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    const initializedStore = store as InitializedStore;

    return new CustomersViewModel(initializedStore.customersModel, initializedStore.projectsModel, routingStore);
  },
};
ROUTES[CustomersRoute.name] = CustomersRoute;

export const SingleCustomerRoute: IRoute = {
  name: 'singleCustomer',
  displayName: 'לקוח',
  path: '/customers/:customerId',
  href: (customerId) => `/customers/${customerId}`,

  // Reverse routing 💪
  link: (customerId) => ({
    name: SingleCustomerRoute.name,
    params: { customerId },
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as SingleCustomerViewModel;
    return <SingleCustomer viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore, routeParams: any) => {
    const initializedStore = store as InitializedStore;
    const viewModel = new SingleCustomerViewModel(
      initializedStore.projectsModel,
      initializedStore.customersModel,
      routingStore,
    );
    viewModel.activate(routeParams.customerId);
    return viewModel;
  },
};
ROUTES[SingleCustomerRoute.name] = SingleCustomerRoute;

export const CreateCustomerRoute: IRoute = {
  name: 'createCustomer',
  displayName: 'לקוח',
  path: '/customers/new',
  href: () => `/customers/new`,

  // Reverse routing 💪
  link: () => ({
    name: CreateCustomerRoute.name,
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as CreateCustomerViewModel;
    return <CreateCustomer viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    const initializedStore = store as InitializedStore;
    const viewModel = new CreateCustomerViewModel(initializedStore.customersModel, routingStore);
    return viewModel;
  },
};
ROUTES[CreateCustomerRoute.name] = CreateCustomerRoute;

export const EditCustomerRoute: IRoute = {
  name: 'editCustomer',
  displayName: 'לקוח',
  path: '/customers/edit/:customerId',
  href: (customerId) => `/customers/edit/${customerId}`,

  // Reverse routing 💪
  link: (customerId) => ({
    name: `/customers/edit/${customerId}`,
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as CreateCustomerViewModel;
    return <CreateCustomer viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore, routeParams: any) => {
    const initializedStore = store as InitializedStore;
    const viewModel = new CreateCustomerViewModel(
      initializedStore.customersModel,
      routingStore,
      routeParams.customerId,
    );
    return viewModel;
  },
};
ROUTES[EditCustomerRoute.name] = EditCustomerRoute;

export const WorkHoursRoute: IRoute = {
  name: 'work-hours',
  displayName: 'שעות',
  path: '/work-hours',
  href: () => '/work-hours',

  // Reverse routing 💪
  link: () => ({
    name: WorkHoursRoute.name,
    params: {},
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as WorkHoursViewModel;
    return <WorkHours viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    const initializedStore = store as InitializedStore;

    return new WorkHoursViewModel(initializedStore.projectsModel, initializedStore.customersModel, routingStore);
  },
};
ROUTES[WorkHoursRoute.name] = WorkHoursRoute;

export const SettingsRoute: IRoute = {
  name: 'settings',
  displayName: 'הגדרות',
  path: '/settings',
  href: () => '/settings',

  // Reverse routing 💪
  link: () => ({
    name: SettingsRoute.name,
    params: {},
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as SettingsViewModel;
    return <Settings viewModel={typedViewModel} />;
  },

  viewModel: (_, routingStore: RoutingStore) => {
    return new SettingsViewModel(routingStore);
  },
};
ROUTES[SettingsRoute.name] = SettingsRoute;

export const InsightsRoute: IRoute = {
  name: 'insights',
  displayName: 'תובנות',
  path: '/insights',
  href: () => '/insights',

  // Reverse routing 💪
  link: () => ({
    name: InsightsRoute.name,
    params: {},
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as InsightsViewModel;
    return <Insights viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    const initializedStore = store as InitializedStore;

    return new InsightsViewModel(initializedStore.projectsModel, routingStore);
  },
};
ROUTES[InsightsRoute.name] = InsightsRoute;

export const AffiliateStatsRoute: IRoute = {
  name: 'affiliate stats',
  displayName: 'נתוני אפיליאציה',
  path: '/affiliate-stats',
  href: () => '/affiliate-stats',

  // Reverse routing 💪
  link: () => ({
    name: AffiliateStatsRoute.name,
    params: {},
  }),

  component: () => {
    return <AffiliateStats />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    const initializedStore = store as InitializedStore;

    return new InsightsViewModel(initializedStore.projectsModel, routingStore);
  },
};
ROUTES[AffiliateStatsRoute.name] = AffiliateStatsRoute;

export const TasksRoute: IRoute = {
  name: 'tasks',
  displayName: 'משימות',
  path: '/tasks',
  href: () => '/tasks',

  // Reverse routing 💪
  link: () => ({
    name: TasksRoute.name,
    params: {},
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as TasksViewModel;
    return <TasksPage viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    const initializedStore = store as InitializedStore;

    return new TasksViewModel(initializedStore.projectsModel, routingStore);
  },
};
ROUTES[TasksRoute.name] = TasksRoute;

export const LeadsRoute: IRoute = {
  name: 'leads',
  displayName: 'לידים',
  path: '/leads',
  href: () => '/leads',

  // Reverse routing 💪
  link: () => ({
    name: LeadsRoute.name,
    params: {},
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as LeadsViewModel;
    return <Leads viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    const initializedStore = store as InitializedStore;

    return new LeadsViewModel(initializedStore.customersModel, initializedStore.projectsModel, routingStore);
  },
};
ROUTES[LeadsRoute.name] = LeadsRoute;

export const CreateLeadRoute: IRoute = {
  name: 'createLead',
  displayName: 'יצירת ליד',
  path: '/leads/new',
  href: () => `/leads/new`,

  // Reverse routing 💪
  link: () => ({
    name: CreateLeadRoute.name,
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as CreateCustomerViewModel;
    return <CreateCustomer viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore) => {
    const initializedStore = store as InitializedStore;
    const viewModel = new CreateCustomerViewModel(initializedStore.customersModel, routingStore, undefined, true);
    return viewModel;
  },
};
ROUTES[CreateLeadRoute.name] = CreateLeadRoute;

export const EditLeadRoute: IRoute = {
  name: 'editLead',
  displayName: 'עריכת ליד',
  path: '/leads/edit/:leadId',
  href: (leadId) => `/leads/edit/${leadId}`,

  // Reverse routing 💪
  link: (leadId) => ({
    name: `/leads/edit/${leadId}`,
  }),

  component: (viewModel) => {
    const typedViewModel = viewModel as CreateCustomerViewModel;
    return <CreateCustomer viewModel={typedViewModel} />;
  },

  viewModel: (store: RootStore, routingStore: RoutingStore, routeParams: any) => {
    const initializedStore = store as InitializedStore;
    const viewModel = new CreateCustomerViewModel(
      initializedStore.customersModel,
      routingStore,
      routeParams.leadId,
      true,
      routeParams.status,
    );
    return viewModel;
  },
};
ROUTES[EditLeadRoute.name] = EditLeadRoute;
