import { Box, Button, Heading, Flex, Image, Text } from '@chakra-ui/react';

import { Card } from '../../../components/Card';

export const EmptyState = ({
  title,
  body,
  secondaryText,
  imageSrc,
  onClick,
  buttonText = '+ הוספה',
}: {
  title: string;
  body: string;
  secondaryText?: string;
  imageSrc: string;
  onClick?: () => void;
  buttonText?: string;
}) => {
  return (
    <Box pt="130px" paddingInlineStart="82px" paddingInlineEnd="90px" h="550px" w="1300px">
      <Card overflow="hidden">
        <Flex alignItems="center">
          <Image src={imageSrc} width="340px" mt="60px" mb="30px" marginInlineStart="40px" />
          <Flex direction="column" marginInlineStart="60px" maxW="390px">
            <Heading textStyle="h4" mb="15px">
              {title}
            </Heading>
            <Text fontSize="lg" mb="15px">
              {body}
            </Text>
            {secondaryText && <Text fontSize="lg">{secondaryText}</Text>}
            {onClick && (
              <Button size="lg" onClick={onClick} alignSelf="flex-start">
                {buttonText}
              </Button>
            )}
          </Flex>
        </Flex>
        <Box position="absolute" left="-102px" bottom="-194px" w="350px" h="350px" borderRadius="50%" bg="#C4C0F6" />
      </Card>
    </Box>
  );
};
