import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleControl = defineStyle((props) => {
  const { colorScheme: c } = props;

  return {
    w: '100%',
    transitionProperty: 'box-shadow',
    transitionDuration: 'normal',
    border: '1px solid',
    borderRadius: 'md',
    borderColor: 'white',
    boxShadow: '0px 0px 0px 1px #DFE1E7, 0px 1px 2px 0px rgba(164, 172, 185, 0.40)',

    _hover: {
      boxShadow: '0px 0px 0px 1px #C1C7D0, 0px 1px 2px 0px rgba(102, 109, 128, 0.40)',
    },

    _checked: {
      bg: mode('base.800', `${c}.200`)(props),
      borderColor: mode('base.1000', `${c}.200`)(props),
      color: 'base.0',

      _hover: {
        bg: mode('base.800', `${c}.200`)(props),
        borderColor: mode('base.1000', `${c}.200`)(props),
      },

      _disabled: {
        bg: 'base.100',
        borderColor: 'base.0',
        // color: 'base.400',
      },
    },

    _indeterminate: {
      bg: mode('base.800', `${c}.200`)(props),
      borderColor: mode('base.1000', `${c}.200`)(props),
      color: mode('white', 'gray.900')(props),
    },

    _disabled: {
      bg: 'base.100',
      borderColor: 'base.0',
      color: 'base.400',
    },

    _focus: {
      boxShadow: '0px 0px 0px 1px #C1C7D0, 0px 1px 2px 0px rgba(102, 109, 128, 0.40)',
    },

    _active: {
      boxShadow:
        '0px 0px 0px 3px rgba(39, 12, 67, 0.50), 0px 0px 0px 2px #FFF, 0px 0px 0px 1px #270C43, 0px 1px 2px 0px rgba(56, 29, 83, 0.50)',
    },

    _invalid: {
      borderColor: mode('error.100', 'error.100')(props),
    },
  };
});

const baseStyleContainer = defineStyle({
  _disabled: { cursor: 'not-allowed' },
});

const baseStyleLabel = defineStyle({
  userSelect: 'none',
  color: 'base.1000',
  marginInlineStart: '12px',
  lineHeight: 0,
});

const baseStyleIcon = defineStyle({
  transitionProperty: 'transform',
  transitionDuration: 'normal',
});

const baseStyle = definePartsStyle((props) => ({
  icon: baseStyleIcon,
  container: baseStyleContainer,
  control: baseStyleControl(props),
  label: baseStyleLabel,
}));

const sizes = {
  sm: definePartsStyle({
    control: { w: '16px', h: '16px' },
    label: { fontSize: 'lg' },
    icon: { fontSize: '0.5rem' },
  }),
  md: definePartsStyle({
    control: { w: '24px', h: '24px' },
    label: { fontSize: 'lg' },
    icon: { fontSize: 'sm' },
  }),
};

export const Checkbox = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    colorScheme: 'base',
  },
});
