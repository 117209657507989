import { Flex, Text, Link } from '@chakra-ui/react';

import { PartyIcon } from './icons';

export const WatchVideoTeaser = () => {
  return (
    <Flex
      py="12px"
      px="16px"
      bg="white"
      height="56px"
      border="1px solid"
      borderColor="base.200"
      borderRadius="16px"
      alignItems="center"
      gap="12px"
    >
      <PartyIcon h="24px" w="24px" />
      <Text textStyle="large">
        חדשים כאן?{' '}
        <Link
          marginInlineStart="10px"
          marginInlineEnd="20px"
          target="_blank"
          href="https://www.youtube.com/@Peko-freelancers/videos"
        >
          צפו בסרטוני ההדרכה שלנו
        </Link>
      </Text>
    </Flex>
  );
};
