import { Box } from '@chakra-ui/react';

import { FormButtonTabs } from '../../components/form/FormButtonTabs';
import { FormInput } from '../../components/form/FormInput';
import { FormSelect } from '../../components/form/FormSelect';
import { industryOptions } from '../../models/userData/userDataFormUtils';
import { NUM_CO_WORKERS } from '../../models/userData/useUserData';

export const OnboardingFirstStep = () => {
  return (
    <>
      <FormInput isRequired name="companyName" label="מה שם העסק שלך?" />
      <FormSelect isRequired name="industry" label="באיזה תחום" data={industryOptions} />
      <Box mb="10px">
        <FormButtonTabs
          name="numberOfCoWorkers"
          label="איך את/ה עובד/ת?"
          options={[
            { label: 'לבד', value: NUM_CO_WORKERS.ALONE },
            { label: 'כחלק מצוות', value: NUM_CO_WORKERS.GROUP },
          ]}
        />
      </Box>
      <FormSelect
        isRequired
        name="numberOfYears"
        label="כמה שנים את/ה עצמאי/ת?"
        data={[
          { key: '0-1', value: '0-1' },
          { key: '2-4', value: '2-4' },
          { key: '5-7', value: '5-7' },
          { key: '8+', value: '8+' },
        ]}
      />
    </>
  );
};
