import { Bar } from 'react-chartjs-2';

export const options: any = {
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'y',
  scales: {
    yAxis: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    xAxis: {
      display: false,
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    datalabels: {
      color: '#270C43',
      anchor: 'center',
      align: 'center',
      textAlign: 'center',
      font: {
        family: 'Ploni',
        size: 16,
      },
      formatter: function (value: string) {
        const num = parseInt(value, 10);
        return `${num.toLocaleString()}%`;
      },
    },
    legend: {
      display: false,
    },
  },
};

const baseGraphData = {
  labels: [],
  datasets: [
    {
      label: 'Dataset 1',
      yAxisID: 'yAxis',
      xAxisID: 'xAxis',
      data: [],
      backgroundColor: '#D6D3FE',
      hoverBackgroundColor: '#D6D3FE',
      barThickness: 32,
      maxBarThickness: 32,
      borderRadius: 4,
    },
  ],
};

export function ServicesBarChart({ data }: { data: Array<{ label: string; data: number }>; includeGrid?: boolean }) {
  const graphData = {
    ...baseGraphData,
    labels: data.map((item) => item.label),
    datasets: [
      {
        ...baseGraphData.datasets[0],
        data: data.map((item) => item.data),
      },
    ],
  };

  return <Bar options={options} data={graphData} />;
}
