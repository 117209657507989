import { FormikErrors } from 'formik';
import Cookies from 'js-cookie';
import { action, computed, makeObservable, observable } from 'mobx';

import { AFFILIATE_LINK_COOKIE_NAME } from '../../models/affiliateLink/useAffiliateLink';
import { AuthenticationModel, ID_TOKEN } from '../../models/AuthenticationModel';
import { IStore } from '../../models/RootStore';
import { isPasswordValid } from '../../models/userData/userDataUtils';
import { LoginRoute, SignupRoute, InsightsRoute, EmailVerifiedRoute } from '../../router/routes';
import { RoutingStore } from '../../router/RoutingStore';
import { initWebAuth } from '../../utils/auth0';
import { logger } from '../../utils/logger';

export enum VIEW_TYPE {
  LOGIN,
  SIGNUP,
  CONFIRM_EMAIL,
  RESET_PASSWORD,
  EMAIL_VERIFIED,
}

export interface ISignupFormValues {
  name: string;
  email: string;
  password: string;
  approvedTermsOfService: boolean;
}

export interface ILoginFormValues {
  email: string;
  password: string;
}

export interface IResetPasswordFormValues {
  email: string;
}

const SOURCE_COOKIE_NAME = 'source';

export class AuthenticationViewModel implements IStore {
  private authenticationModel: AuthenticationModel;
  private routingStore: RoutingStore;

  _currentView: VIEW_TYPE;
  isLoading: boolean;

  onInitComplete: () => void;

  constructor(onInitComplete: () => void, authenticationModel: AuthenticationModel, routingStore: RoutingStore) {
    this.onInitComplete = onInitComplete;
    this.authenticationModel = authenticationModel;
    this.routingStore = routingStore;

    switch (this.routingStore.route.name) {
      case SignupRoute.name:
        this._currentView = VIEW_TYPE.SIGNUP;
        break;
      case EmailVerifiedRoute.name:
        this._currentView = VIEW_TYPE.EMAIL_VERIFIED;
        break;
      case LoginRoute.name:
      default:
        this._currentView = VIEW_TYPE.LOGIN;
        break;
    }
    this.isLoading = false;

    makeObservable(this, {
      _currentView: observable,
      currentView: computed,
      setCurrentView: action,
      isLoading: observable,
      email: computed,
      setLoading: action,
    });
  }

  activate() {}

  get currentView() {
    return this._currentView;
  }

  setCurrentView = (view: VIEW_TYPE) => {
    this._currentView = view;
    this.navigateToPage();
  };

  get email() {
    return this.authenticationModel.email;
  }

  onLogin = async (idToken: string) => {
    await this.authenticationModel.onLogin(idToken);
    this.onInitComplete();
    this.routingStore.navigate(InsightsRoute);
    this.setLoading(false);
  };

  onSignup = async (idToken: string) => {
    const affiliateLinkId = Cookies.get(AFFILIATE_LINK_COOKIE_NAME);
    const userSource = Cookies.get(SOURCE_COOKIE_NAME);

    // js-cookie parses non-existing cookies as the string 'null'
    await this.authenticationModel.onSignup(
      idToken,
      affiliateLinkId !== 'null' ? affiliateLinkId : undefined,
      userSource !== 'null' ? userSource : undefined,
    );
    this.setCurrentView(VIEW_TYPE.CONFIRM_EMAIL);

    if (affiliateLinkId && affiliateLinkId !== 'null') {
      // clear cookies if exist
      Cookies.set(AFFILIATE_LINK_COOKIE_NAME, 'null', { path: '/', domain: '.peko.co.il', expires: 0 });
    }
    if (userSource && userSource !== 'null') {
      // clear cookies if exist
      Cookies.set(SOURCE_COOKIE_NAME, 'null', { path: '/', domain: '.peko.co.il', expires: 0 });
    }
    this.setLoading(false);
  };

  onLogout = () => {
    this.authenticationModel.logout();
  };

  getUrlByView = () => {
    switch (this.currentView) {
      case VIEW_TYPE.LOGIN:
        return LoginRoute;
      case VIEW_TYPE.EMAIL_VERIFIED:
        return EmailVerifiedRoute;
      case VIEW_TYPE.SIGNUP:
        return SignupRoute;
      default:
        return null;
    }
  };

  navigateToPage = (stripHash: boolean = false) => {
    const link = this.getUrlByView();
    if (link) {
      this.routingStore.navigate(link, { replace: stripHash });
    }
  };

  parseHash = async (hash: string) => {
    this.setLoading(true);
    const webAuth = initWebAuth();
    if (hash.includes('emailStatus') && hash.includes('true')) {
      const idToken = window?.localStorage.getItem(ID_TOKEN);
      if (!idToken) {
        this.setCurrentView(VIEW_TYPE.EMAIL_VERIFIED);
        this.setLoading(false);
      }
      return;
    }

    webAuth.parseHash({ hash }, async (err, authResult) => {
      if (err) {
        logger.log('Failed to parse hash');
        return logger.error(err);
      }
      if (!authResult?.accessToken || !authResult.idToken || !authResult.idTokenPayload) {
        return logger.log('Bad auth response received');
      }

      this.authenticationModel.setUserValues(authResult.idToken);

      if (this.currentView === VIEW_TYPE.SIGNUP) {
        return await this.onSignup(authResult.idToken);
      }
      return await this.onLogin(authResult.idToken);
    });
  };

  validateLogin(values: ILoginFormValues): FormikErrors<ILoginFormValues> {
    const errors: FormikErrors<ILoginFormValues> = {};
    if (!values.email) {
      errors.email = 'אנחנו צריכים את זה';
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'צריך להקפיד על המבנה הזה: name@email.com';
    }
    if (!values.password) {
      errors.password = 'בלי זה אי אפשר להמשיך';
    }
    if (!isPasswordValid(values.password)) {
      errors.password = 'לפחות 8 תווים, תו מיוחד (לדוגמא !@#$%^&*), מספר, אות קטנה ואות גדולה';
    }
    return errors;
  }

  validateSignup(values: ISignupFormValues): FormikErrors<ISignupFormValues> {
    const errors: FormikErrors<ISignupFormValues> = {};
    // Name
    if (!values.name) {
      errors.name = 'מה השם המלא שלך? זה יותר נחמד ככה';
    }
    if (!values.name.includes(' ')) {
      errors.name = 'חסר רווח בין השם הפרטי למשפחה';
    }
    // Email
    if (!values.email) {
      errors.email = 'אנחנו צריכים את זה';
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'צריך להקפיד על המבנה הזה: name@email.com';
    }
    // Password
    if (!values.password) {
      errors.password = 'בלי זה אי אפשר להמשיך';
    }
    if (!isPasswordValid(values.password)) {
      errors.password = 'לפחות 8 תווים, תו מיוחד (לדוגמא !@#$%^&*), מספר, אות קטנה ואות גדולה';
    }
    // ToS
    if (!values.approvedTermsOfService) {
      errors.approvedTermsOfService = 'אי אפשר להמשיך בלי לאשר את התנאים';
    }
    return errors;
  }

  setLoading = (state: boolean) => {
    this.isLoading = state;
  };
}
