import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Heading } from '@chakra-ui/react';

type DialogSizes = 'small' | 'medium' | 'large';

interface DialogProps {
  title: string;
  onClose: () => void;
  size?: DialogSizes;
  backgroundColor?: string;
  children: React.ReactNode;
  id?: string;
}

function getSize(size: DialogSizes) {
  switch (size) {
    case 'small':
      return 'md';
    case 'medium':
      return 'xl';
    case 'large':
      return '2xl';
  }
}

function getContentWidth(size: DialogSizes) {
  switch (size) {
    case 'small':
      return '400px';
    case 'medium':
      return '500px';
    case 'large':
      return '750px';
  }
}

export const Dialog = ({ title, onClose, size = 'small', backgroundColor = '#FFFFFF', id, children }: DialogProps) => {
  return (
    <Modal isOpen={true} onClose={onClose} isCentered size={getSize(size)} id={id} returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent
        backgroundColor={backgroundColor}
        boxShadow="0px 0px 40px 5px rgba(39, 12, 67, 0.15)"
        borderRadius="10px"
        w={getContentWidth(size)}
      >
        <ModalHeader p="32px">
          <Heading color="base.1000" textStyle="h5" textAlign="center">
            {title}
          </Heading>
        </ModalHeader>
        <ModalCloseButton
          top="20px"
          left="20px"
          right={'initial'}
          borderRadius="50%"
          backgroundColor="base.100"
          color="base.1000"
          _hover={{
            backgroundColor: 'base.200',
          }}
          transition="background-color 0.2s"
          fontSize="10px"
          fontWeight="bold"
          h="28px"
          w="28px"
        />
        <ModalBody p="0">{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
