import { Box } from '@chakra-ui/react';

import { Loader } from '../../components/Loader/Loader';
import { Page } from '../../components/Page';
import { useOrderedTasks } from '../../models/tasks/useOrderedTasks';
import { EmptyState } from '../common/errors/EmptyState';

import { Tasks } from './Tasks';
import { TasksViewModel } from './TasksViewModel';

export const TasksPage = ({ viewModel }: { viewModel: TasksViewModel }) => {
  const { tasks, isLoading, onOrderChange } = useOrderedTasks();

  if (!isLoading && tasks.length === 0) {
    return (
      <Box position="relative">
        <EmptyState
          title="ניהול משימות"
          body="כאן יוצג פירוט המשימות שלך. אפשר ליצור משימות עם או בלי שיוך לפרויקט מסוים, להגדיר תאריכי יעד למעקב ועוד. מומלץ להוסיף משימה חדשה כדי לראות איך הכל עובד"
          imageSrc="/no-tasks.svg"
          buttonText="+ הוספת משימה"
          onClick={viewModel.createTask}
        />
      </Box>
    );
  }
  return (
    <Page title="משימות">
      {isLoading || !tasks ? (
        <Loader />
      ) : (
        <Tasks
          tasks={tasks}
          projects={viewModel.projects}
          editTask={viewModel.editTask}
          saveWorkHour={viewModel.saveWorkHour}
          onOrderChange={onOrderChange}
          onProjectClick={viewModel.onProjectClick}
        />
      )}
    </Page>
  );
};
