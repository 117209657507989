export const colors = {
  primary: {
    0: '#F1F0FF',
    25: '#D6D3FE',
    50: '#A19BF5',
    100: '#7B73EA',
    200: '#5B50F3',
    300: '#362EA8',
  },
  base: {
    0: '#FFFFFF',
    25: '#F7F8FA',
    50: '#F7F5FA',
    100: '#EEEFF5',
    200: '#D8DBED',
    300: '#C3C1DA',
    400: '#D2CAE2',
    500: '#9286AB',
    600: '#685888',
    700: '#413052',
    800: '#3D1E5C',
    900: '#270C43',
    1000: '#160825',
  },
  success: {
    0: '#DFFCF7',
    25: '#A8F8EA',
    50: '#74E9D4',
    100: '#2EDEBF',
    200: '#176F60',
    300: '#092C26',
  },
  error: {
    0: '#FFF0F3',
    25: '#FADBE1',
    50: '#ED8296',
    100: '#DF1C41',
    200: '#96132C',
    300: '#710E21',
  },
  accent: {
    0: '#FFE4FB',
    25: '#F8C0EF',
    50: '#EE87DD',
    100: '#E44ECC',
    200: '#D613B6',
    300: '#4E0943',
  },
  teal: {
    300: '#DDFAF5',
    500: '#42CEB3',
  },
  purple: {
    300: '#D6D3FE',
    500: '#7B73EA',
    600: '#635BD1',
  },
  brand: {
    600: '#270C43',
  },
};
