import { createIcon } from '@chakra-ui/icons';

export const PauseIcon = createIcon({
  displayName: 'PauseIcon',
  viewBox: '0 0 25 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M7.25 2C6.32174 2 5.4315 2.30729 4.77513 2.85427C4.11875 3.40125 3.75 4.14312 3.75 4.91667V19.0833C3.75 19.8569 4.11875 20.5987 4.77513 21.1457C5.4315 21.6927 6.32174 22 7.25 22C8.17826 22 9.0685 21.6927 9.72487 21.1457C10.3813 20.5987 10.75 19.8569 10.75 19.0833V4.91667C10.75 4.14312 10.3813 3.40125 9.72487 2.85427C9.0685 2.30729 8.17826 2 7.25 2ZM8.75 19.0833C8.75 19.4149 8.59196 19.7328 8.31066 19.9672C8.02936 20.2016 7.64782 20.3333 7.25 20.3333C6.85218 20.3333 6.47064 20.2016 6.18934 19.9672C5.90804 19.7328 5.75 19.4149 5.75 19.0833V4.91667C5.75 4.58515 5.90804 4.2672 6.18934 4.03278C6.47064 3.79836 6.85218 3.66667 7.25 3.66667C7.64782 3.66667 8.02936 3.79836 8.31066 4.03278C8.59196 4.2672 8.75 4.58515 8.75 4.91667V19.0833Z"
    />,
    <path
      key="1"
      fill="currentColor"
      d="M18.25 2C17.3217 2 16.4315 2.30729 15.7751 2.85427C15.1187 3.40125 14.75 4.14312 14.75 4.91667V19.0833C14.75 19.8569 15.1187 20.5987 15.7751 21.1457C16.4315 21.6927 17.3217 22 18.25 22C19.1783 22 20.0685 21.6927 20.7249 21.1457C21.3813 20.5987 21.75 19.8569 21.75 19.0833V4.91667C21.75 4.14312 21.3813 3.40125 20.7249 2.85427C20.0685 2.30729 19.1783 2 18.25 2ZM19.75 19.0833C19.75 19.4149 19.592 19.7328 19.3107 19.9672C19.0294 20.2016 18.6478 20.3333 18.25 20.3333C17.8522 20.3333 17.4706 20.2016 17.1893 19.9672C16.908 19.7328 16.75 19.4149 16.75 19.0833V4.91667C16.75 4.58515 16.908 4.2672 17.1893 4.03278C17.4706 3.79836 17.8522 3.66667 18.25 3.66667C18.6478 3.66667 19.0294 3.79836 19.3107 4.03278C19.592 4.2672 19.75 4.58515 19.75 4.91667V19.0833Z"
    />,
  ],
});
