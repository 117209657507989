import { createIcon } from "@chakra-ui/icons";

export const LinkedinIcon = createIcon({
  displayName: "LinkedinIcon",
  viewBox: "0 0 13 13",
  path: (
    <path
      key="0"
      fill="currentColor"
      d="M7.22221 13H4.33333V4.33333H7.22221V5.77778C7.52634 5.39086 7.91232 5.07604 8.35247 4.8559C8.79262 4.63577 9.27604 4.51576 9.76804 4.5045C10.6285 4.50928 11.4519 4.85508 12.0578 5.46609C12.6637 6.0771 13.0025 6.90343 13 7.76389V13H10.1111V8.125C10.0552 7.73469 9.86025 7.3777 9.56207 7.11972C9.26388 6.86175 8.88256 6.72015 8.48827 6.721C8.3118 6.72658 8.13828 6.76775 7.97811 6.84204C7.81794 6.91633 7.67444 7.02222 7.5562 7.15334C7.43796 7.28446 7.34743 7.43811 7.29004 7.60508C7.23265 7.77205 7.20958 7.94889 7.22221 8.125V13ZM2.88889 13H0V4.33333H2.88889V13ZM1.44444 2.88889C1.06135 2.88889 0.693953 2.73671 0.423067 2.46582C0.152182 2.19494 0 1.82753 0 1.44444C0 1.06135 0.152182 0.693954 0.423067 0.423068C0.693953 0.152182 1.06135 0 1.44444 0C1.82753 0 2.19493 0.152182 2.46582 0.423068C2.7367 0.693954 2.88889 1.06135 2.88889 1.44444C2.88889 1.82753 2.7367 2.19494 2.46582 2.46582C2.19493 2.73671 1.82753 2.88889 1.44444 2.88889Z"
    />
  ),
});
