import { endOfMonth, getMonth, getYear } from 'date-fns';
import { action, makeObservable, observable, computed, runInAction } from 'mobx';
import TagManager from 'react-gtm-module';

import { Client, IProjectApi, PROJECT_STATUS, PROJECT_TYPE } from '../requests/Client';
import { logger } from '../utils/logger';
import { decimalHoursToDuration, roundToNextMinute } from '../utils/time/timeUtils';

import { Addition, IAdditionValues } from './Addition';
import { Expense, IExpenseValues } from './Expense';
import { Payment } from './Payment';
import { IPaymentValues } from './PaymentUtils';
import { Project } from './Project';
import { IProjectValues } from './ProjectUtils';
import { WorkHour } from './WorkHour';
import { IWorkHourBase } from './workHours/workHourApi';
import { getWorkHoursDuration, IWorkHourValues } from './workHourUtils';

export class ProjectsModel {
  private client: Client;
  private abortController: AbortController;

  _projects: Array<Project>;
  _isLoading: boolean;

  constructor(client: Client) {
    this.client = client;

    this._projects = [];
    this._isLoading = true;
    this.abortController = new AbortController();

    this.init();
    this.setupListeners();

    makeObservable(this, {
      _projects: observable,
      projects: computed,
      createProject: action,
      loadProjects: action,
      deleteProject: action,

      _isLoading: observable,
      isLoading: computed,
      setIsLoading: action,
    });
  }

  private async init() {
    this.setIsLoading(true);
    await this.loadProjects();
    this.setIsLoading(false);
  }

  get projects() {
    return this._projects || [];
  }

  getProjectById = (id: string) => {
    return this.projects.find((p) => p._id === id);
  };

  getProjectByName = (name: string) => {
    return this.projects.find((p) => p.name === name);
  };

  /** Projects */
  async createProject(project: IProjectValues, importedWorkHours: IWorkHourBase[]) {
    if (project.type === PROJECT_TYPE.HOURLY && project.rate && project.totalHours) {
      project.totalValue = project.rate * project.totalHours;
    }

    if (!project.totalValue) {
      project.totalValue = 0;
    }

    const createdProject = await this.client.createProject(project);
    const payments = await Promise.all(
      project.payments.map((p) => this.client.createPayment(p as IPaymentValues, createdProject.id.toString())),
    );
    TagManager.dataLayer({
      dataLayer: {
        event: 'project_added',
      },
    });
    if (this._projects.length === 0) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'first_project_added',
        },
      });
    }
    runInAction(() => {
      this._projects.push(new Project({ ...createdProject, payments }));
      this.bulkCreateWorkHours(
        importedWorkHours.map((wh) => new WorkHour({ ...wh, id: 0, project_id: createdProject.id })),
        createdProject.id.toString(),
      );
    });
  }

  async updateProject(values: IProjectValues, project: Project) {
    if (values.type === PROJECT_TYPE.HOURLY) {
      return this.updateHourlyProject(values, project);
    }

    // FIXED project flow
    const remainingPaymentIds = values.payments.map((p) => p.id);
    const paymentsToDelete = project.payments.filter((p) => !remainingPaymentIds.includes(p._id));
    await Promise.all(paymentsToDelete.map((p) => this.client.deletePayment(p._id)));

    const paymentsToUpdate = values.payments.filter((p) => Boolean(p.id));
    await Promise.all(paymentsToUpdate.map((p) => this.client.updatePayment(p as IPaymentValues, p.id!)));

    const paymentsToCreate = values.payments.filter((p) => !p.id);
    await Promise.all(paymentsToCreate.map((p) => this.client.createPayment(p as IPaymentValues, project._id)));

    try {
      const [updatedProjectRes, workHours] = await Promise.all([
        this.client.updateProject(values, project._id, project.status),
        this.client.getWorkHours(),
      ]);
      const projectWorkHours = workHours
        .filter((wh) => Boolean(wh.end_time))
        .filter((wh) => wh.project_id.toString() === updatedProjectRes.id.toString())
        .map(
          (wh) =>
            new WorkHour({
              id: wh.id,
              start_time: wh.start_time,
              end_time: wh.end_time || null,
              comment: wh.comment,
              project_id: updatedProjectRes.id,
            }),
        );
      const updatedProject = new Project(updatedProjectRes, projectWorkHours);
      runInAction(() => {
        this._projects = this._projects.filter((p) => p._id !== project._id);
        this._projects.push(updatedProject);
      });
    } catch (err) {
      logger.error('updateProject - failed to update fixed project', {
        error: err,
        values,
      });
      throw err;
    }
  }

  async updateHourlyProject(values: IProjectValues, project: Project) {
    if (values.type === PROJECT_TYPE.HOURLY && values.rate && values.totalHours) {
      values.totalValue = values.rate * values.totalHours;
    }
    if (values.type === PROJECT_TYPE.HOURLY && !values.includeTotalHours) {
      values.totalValue = 0;
    }

    try {
      await Promise.all(project.payments.map((payment) => this.client.deletePayment(payment._id)));
    } catch (err) {
      logger.error('updateProject - failed to delete payments', {
        error: err,
        values,
      });
      throw err;
    }

    // create all payments
    await Promise.all(values.payments.map((p) => this.createPayment(p as IPaymentValues, project._id)));

    try {
      const [updatedProjectRes, workHours] = await Promise.all([
        this.client.updateProject(values, project._id, project.status),
        this.client.getWorkHours(),
      ]);
      const projectWorkHours = workHours
        .filter((wh) => Boolean(wh.end_time))
        .filter((wh) => wh.project_id.toString() === updatedProjectRes.id.toString())
        .map(
          (wh) =>
            new WorkHour({
              id: wh.id,
              start_time: wh.start_time,
              end_time: wh.end_time || null,
              comment: wh.comment,
              project_id: updatedProjectRes.id,
            }),
        );
      const updatedProject = new Project(updatedProjectRes, projectWorkHours);
      runInAction(() => {
        this._projects = this._projects.filter((p) => p._id !== project._id);
        this._projects.push(updatedProject);
        this.splitAdvanceWorkHour(updatedProject);
      });
    } catch (err) {
      logger.error('updateProject - failed to update hourly project', {
        error: err,
        values,
      });
      throw err;
    }
  }

  async updateProjectPayments(project: Project) {
    const advanceWorkHourIds = project.getAdvanceWorkHours().map((wh) => wh.id);
    const remainingWorkHours = project.workHours.filter((wh) => !advanceWorkHourIds.includes(wh.id));

    const paymentsToDelete = project.payments.filter((p) => !p.isAdvance);
    await Promise.all(paymentsToDelete.map((p) => this.client.deletePayment(p._id)));
    paymentsToDelete.forEach((payment) => project.removePayment(payment));

    const paymentDates = remainingWorkHours.reduce<{ [dateString: string]: Date }>((dateObj, wh) => {
      if (!dateObj[`${getYear(wh._startTime)}_${getMonth(wh._startTime)}`]) {
        dateObj[`${getYear(wh._startTime)}_${getMonth(wh._startTime)}`] = endOfMonth(wh._startTime);
      }
      return dateObj;
    }, {});

    await Promise.all(
      Object.values(paymentDates).map((date) => {
        const newPayment: IPaymentValues = {
          sum: null,
          comment: '',
          isAdvance: false,
          submitDate: date,
        };
        return this.createPayment(newPayment, project._id);
      }),
    );
  }

  async updateProjectStatus(projectId: string, status: PROJECT_STATUS) {
    const project = this.getProjectById(projectId);
    if (!project) {
      throw new Error('project does not exist');
    }

    try {
      await this.client.updateProjectStatus(project, status);
      project.setStatus(status);
    } catch (err) {
      logger.error(err);
    }
  }

  loadProjects = async () => {
    const projects = await this.client.getUserProjects();
    const workHours = await this.client.getWorkHours();

    runInAction(() => {
      this._projects =
        projects?.map((p: IProjectApi) => {
          const projectWorkHours = workHours
            .filter((wh) => Boolean(wh.end_time))
            .filter((wh) => wh.project_id.toString() === p.id.toString())
            .map(
              (wh) =>
                new WorkHour({
                  id: wh.id,
                  start_time: wh.start_time,
                  end_time: wh.end_time || null,
                  comment: wh.comment,
                  project_id: p.id,
                }),
            );
          return new Project(p, projectWorkHours);
        }) || [];
    });
  };

  deleteProject = async (project: Project) => {
    const paymentPromises = project.payments.map((payment: Payment) => this.client.deletePayment(payment._id));
    await Promise.all(paymentPromises);

    const workHourPromises = project.workHours.map(
      (workHour: WorkHour) => workHour._id && this.client.deleteWorkHour(workHour._id),
    );
    await Promise.all(workHourPromises);

    await this.client.deleteProject(project._id);

    runInAction(() => {
      this._projects = this._projects.filter((p) => p._id !== project._id);
    });
  };

  /** Payments */
  createPayment = async (payment: IPaymentValues, projectId: string) => {
    const paymentApi = await this.client.createPayment(payment, projectId);

    const project = this.getProjectById(projectId);
    project?.addPayment(new Payment(project.rate, project.paymentCycle, paymentApi));
  };

  /** Work Hours */
  createWorkHour = async (workHour: WorkHour, projectId: string) => {
    const project = this.getProjectById(projectId);
    if (!project) {
      logger.error('tried to create workHour without project');
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'work_hour_logged',
      },
    });
    const totalWorkHoursCount = this.projects.reduce((total, p) => {
      return (total += p.workHours.length);
    }, 0);
    if (totalWorkHoursCount === 2) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'third_work_hour_logged',
        },
      });
    }
    // WorkHour entity already created  - only update other values
    project.addWorkHour(workHour);
    await this.splitAdvanceWorkHour(project);
  };

  bulkCreateWorkHours = async (workHours: WorkHour[], projectId: string) => {
    const project = this.getProjectById(projectId);
    if (!project) {
      logger.error('tried to create workHour without project');
      return;
    }

    await Promise.all(
      workHours.map((workHour) => {
        return new Promise(async (resolve) => {
          const { id } = await this.client.createWorkHour(workHour, projectId);
          workHour.setId(id);

          project.addWorkHour(workHour);
          resolve(id);
        });
      }),
    );
    await this.splitAdvanceWorkHour(project);
  };

  updateWorkHour = async (workHour: WorkHour, updatedWorkHour: IWorkHourValues, project?: Project) => {
    if (!workHour.id) {
      return;
    }
    // Apply changes
    if (updatedWorkHour.comment) {
      workHour.setComment(updatedWorkHour.comment);
    }
    if (updatedWorkHour.startTime) {
      workHour.setStartTime(updatedWorkHour.startTime);
    }
    if (updatedWorkHour.endTime) {
      workHour.setEndTime(updatedWorkHour.endTime);
    }
    if (updatedWorkHour.date) {
      workHour.setDate(updatedWorkHour.date);
    }

    const wasProjectChanged = updatedWorkHour.projectName !== project?.name;

    if (wasProjectChanged) {
      const newProject = this.getProjectByName(updatedWorkHour.projectName);
      try {
        await this.client.createWorkHour(workHour, newProject?._id);
        if (newProject) {
          await this.createWorkHour(workHour, newProject._id);
        }
      } catch (e) {
        logger.error(e, { text: 'updateWrokHour - failed to create new work hour' });
        throw e;
      }
      try {
        await this.deleteWorkHour(workHour.id, project);
      } catch (e) {
        logger.error(e, { text: 'updateWrokHour - failed to delete previous work hour' });
        throw e;
      }
    } else {
      try {
        await this.client.updateWorkHour(workHour);
      } catch (e) {
        logger.error(e, { text: 'updateWrokHour - failed to update work hour' });
        throw e;
      }
      if (project) {
        await this.splitAdvanceWorkHour(project);
      }
    }
  };

  deleteWorkHour = async (workHourId: string, p?: Project) => {
    await this.client.deleteWorkHour(workHourId);

    if (p) {
      p.removeWorkHour(workHourId);
      await this.splitAdvanceWorkHour(p);
    }
  };

  splitAdvanceWorkHour = async (project: Project) => {
    if (project.type !== PROJECT_TYPE.HOURLY) {
      return;
    }
    const advancePayment = project.getAdvancePayment();
    if (!(advancePayment && advancePayment.sum)) {
      await this.updateProjectPayments(project);
      return;
    }

    const advanceWorkHours = project.getAdvanceWorkHours();

    const advanceDuration = decimalHoursToDuration(advancePayment.sum / project.rate);
    const workHoursDuration = getWorkHoursDuration(advanceWorkHours);

    if (workHoursDuration > advanceDuration) {
      const lastWorkHour = advanceWorkHours.pop();
      if (!lastWorkHour) {
        logger.error('failed to split workHours - couldnt find lastWorkHour');
        return;
      }

      const origLastWorkHourDuration = lastWorkHour.duration;
      const previousDuration = workHoursDuration - lastWorkHour.duration;
      const durationToCompleteAdvance = roundToNextMinute(advanceDuration - previousDuration);

      lastWorkHour.setEndTime(lastWorkHour.startTime);
      lastWorkHour.incrementTime({ seconds: durationToCompleteAdvance });

      try {
        await this.client.updateWorkHour(lastWorkHour);
      } catch (e) {
        logger.error(e, { text: 'splitAdvanceWorkHour - failed to update last work hour' });
        throw e;
      }

      if (!lastWorkHour._endTime) {
        return;
      }

      const remainderDuration = origLastWorkHourDuration - durationToCompleteAdvance;
      const newWorkHour = new WorkHour({
        id: 0,
        start_time: lastWorkHour._endTime.toISOString(),
        end_time: lastWorkHour._endTime.toISOString(),
        comment: lastWorkHour.comment || null,
        project_id: parseInt(project._id),
      });
      newWorkHour.incrementTime({
        seconds: remainderDuration,
      });
      try {
        const { _id } = await this.client.createWorkHour(newWorkHour, project._id);
        newWorkHour.setId(_id);
      } catch (e) {
        logger.error(e, { text: 'splitAdvanceWorkHour - failed to create new work hour' });
        throw e;
      }

      project.addWorkHour(newWorkHour);
    }
    await this.updateProjectPayments(project);
    return;
  };

  /** Project Additions */
  createAddition = async (data: IAdditionValues, project: Project) => {
    const additionApi = await this.client.createAddition(data, project._id);

    project.insertAddition(new Addition(additionApi, project.rate));
  };

  updateAddition = async (data: IAdditionValues, additionId: string, project: Project) => {
    const additionApi = await this.client.updateAddition(data, additionId);

    project.removeAdditionById(additionId);
    project.insertAddition(new Addition(additionApi, project.rate));
  };

  deleteAddition = async (additionId: string, project: Project) => {
    await this.client.deleteAddition(additionId);

    // if addition.payment
    // subtract value from payment

    project.removeAdditionById(additionId);
  };

  /** Project Expenses */
  createExpense = async (data: IExpenseValues, project: Project) => {
    const expenseApi = await this.client.createExpense(data, project._id);

    project.insertExpense(new Expense(expenseApi));
  };

  updateExpense = async (data: IExpenseValues, expenseId: string, project: Project) => {
    const expenseApi = await this.client.updateExpense(data, expenseId);

    project.removeExpenseById(expenseId);
    project.insertExpense(new Expense(expenseApi));
  };

  deleteExpense = async (expenseId: string, project: Project) => {
    await this.client.deleteExpense(expenseId);

    project.removeExpenseById(expenseId);
  };

  get isLoading() {
    return this._isLoading;
  }

  setIsLoading = (state: boolean) => {
    this._isLoading = state;
  };

  setupListeners = () => {
    window.addEventListener('focus', this.loadProjects, { signal: this.abortController.signal });
  };

  removeListeners = () => {
    this.abortController.abort();
  };
}
