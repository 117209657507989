import { Box, Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

export const SubscriptionFrame = ({ isLoading, url }: { isLoading: boolean; url?: string }) => {
  useEffect(() => {
    if (!isLoading && url) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'payment_iframe',
        },
      });
    }
  }, [isLoading, url]);

  if (isLoading || !url) {
    return <div>loading...</div>;
  }
  return (
    <Flex justifyContent="center">
      <Box w="400px" h="660px" data-testid="SubscriptionFrame" position="relative" top="0px">
        <iframe width="400px" height="650px" title="secure payment frame" src={url} />
      </Box>
    </Flex>
  );
};
