import { Box, Tag, Input, Text, Flex } from '@chakra-ui/react';
import { FieldArray, FormikErrors, FormikTouched } from 'formik';
import { useState, useRef, useEffect, RefObject } from 'react';

import { CrossIcon, ExclamationLightIcon } from '../../pages/common/icons';
import { StyledTooltip } from '../StyledTooltip';

import { RequiredIndicator } from './RequiredIndicator';

interface MultiValueInputProps {
  label: string;
  name: string;
  currentItems: string[];
  onItemAdd: (item: string) => void;
  onItemDelete: (index: number) => void;
  onBlur: () => void;
  helperText?: string;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  error?: string | string[] | FormikErrors<string> | FormikErrors<string>[] | undefined;
  isRequired?: boolean;
  tooltipText?: string;
  maintainHeight?: boolean;
  isDisabled?: boolean;
  size?: 'lg' | 'sm';
}

export const MultiValueInput = (props: MultiValueInputProps) => {
  const {
    label,
    name,
    currentItems,
    onItemAdd,
    onItemDelete,
    onBlur,
    error,
    helperText,
    touched,
    isRequired = false,
    tooltipText,
    maintainHeight = false,
    size = 'lg',
    isDisabled = false,
  } = props;

  const [inputText, setInputText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const onEnter = () => {
    if (inputText !== '' && inputText !== ' ' && !currentItems.includes(inputText)) {
      onItemAdd(inputText);
    }
    if (inputText === ' ') {
      setInputText('');
    }
  };

  useEffect(() => {
    setInputText('');
  }, [currentItems]);

  return (
    <Box position="relative">
      <Flex justifyContent="space-between" alignItems="center" mb="4px">
        <Flex>
          <Text textStyle="medium" color="base.600">
            {label}
          </Text>
          {isRequired && <RequiredIndicator />}
        </Flex>
        {tooltipText && (
          <StyledTooltip label={tooltipText}>
            <ExclamationLightIcon color="base.300" />
          </StyledTooltip>
        )}
      </Flex>
      <Box
        p={size === 'lg' ? '14px' : '8px'}
        color={inputText ? 'base.1000' : 'base.500'}
        textStyle="large"
        border={isDisabled ? 'none' : '1px solid'}
        borderColor={error && touched ? 'error.100 !important' : 'base.300'}
        borderRadius="12px"
        _hover={{
          borderColor: 'base.600',
        }}
        _focusWithin={{
          borderColor: 'base.1000',
        }}
        cursor={isDisabled ? 'not-allowed' : 'text'}
        bg={isDisabled ? 'base.100' : 'white'}
        onClick={() => inputRef.current?.focus()}
        display="flex"
        flexWrap="wrap"
        gap="8px"
        minH="45px"
      >
        {currentItems.map((item: any, index: number) => (
          <ItemChip key={`chip-item-${index}-${item}`} text={item} onDelete={() => onItemDelete(index)} />
        ))}
        <AutoResizeInput
          name={name}
          onBlur={() => {
            onBlur();
            onEnter();
          }}
          inputRef={inputRef}
          value={inputText}
          onChange={(value) => {
            if (value.charAt(value.length - 1) === ',') {
              onEnter();
            } else {
              setInputText(value);
            }
          }}
          onKeyDown={(value) => {
            if (value === 'Enter') {
              onEnter();
            } else if (value === 'Backspace' && inputText === '') {
              onItemDelete(currentItems.length - 1);
            }
          }}
        />
      </Box>
      {error && touched && (
        <Text textStyle="small" color="error.100" mt="8px">
          {error}
        </Text>
      )}
      {helperText && (!error || !touched) && (
        <Text textStyle="small" color="base.600" mt="8px">
          {helperText}
        </Text>
      )}
      {maintainHeight && !helperText && (!error || !touched) && (
        <Box textStyle="small" color="base.600" mt="8px" h="18px" />
      )}
    </Box>
  );
};

interface AutoResizeInputProps {
  name: string;
  onBlur: () => void;
  inputRef: RefObject<HTMLInputElement>;
  value: string;
  onChange: (key: string) => void;
  onKeyDown: (value: string) => void;
}
const AutoResizeInput = (props: AutoResizeInputProps) => {
  const { name, value, onChange, inputRef, onBlur, onKeyDown } = props;

  return (
    <Input
      maxW="450px"
      minW="50px"
      width="auto"
      name={name}
      id={name}
      onKeyDown={(event) => onKeyDown && onKeyDown(event.key)}
      onBlur={onBlur}
      ref={inputRef}
      onChange={(event) => onChange(event.target.value)}
      value={value}
      type="text"
      variant="unstyled"
    />
  );
};

const ItemChip = (props: { text: string; onDelete: () => void }) => {
  return (
    <Tag
      backgroundColor="base.100"
      color="base.1000"
      px="4px"
      py="2px"
      fontSize="md"
      justifyContent="center"
      alignItems="center"
    >
      {props.text}
      <CrossIcon w="12px" h="12px" marginInlineStart="4px" cursor="pointer" onClick={props.onDelete} />
    </Tag>
  );
};

interface FormikMultiValueInputProps {
  name: string;
  label: string;
  helperText?: string;
  isRequired?: boolean;
  tooltipText?: string;
}

export const FormMultiValueInput = ({
  name,
  label,
  helperText,
  isRequired,
  tooltipText,
}: FormikMultiValueInputProps) => {
  return (
    <FieldArray name={name}>
      {({ remove, push, form }) => {
        return (
          <MultiValueInput
            label={label}
            name={name}
            helperText={helperText}
            currentItems={form.values[name] || []}
            onItemDelete={remove}
            onItemAdd={push}
            onBlur={() => form.setFieldTouched(name, true)}
            error={form.errors[name] as string}
            touched={form.touched[name]}
            isRequired={Boolean(isRequired)}
            tooltipText={tooltipText}
          />
        );
      }}
    </FieldArray>
  );
};
