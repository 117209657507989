import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

import { Input } from './input';

const baseStyle = defineStyle({
  ...Input.baseStyle?.field,
  paddingY: '8px',
  minHeight: '80px',
  lineHeight: 'short',
  verticalAlign: 'top',
});

const variants = {
  outline: defineStyle((props) => Input.variants?.outline(props).field ?? {}),
  flushed: defineStyle((props) => Input.variants?.flushed(props).field ?? {}),
  filled: defineStyle((props) => Input.variants?.filled(props).field ?? {}),
  unstyled: defineStyle(Input.variants?.unstyled.field ?? {}),
};

const sizes = {
  xs: Input.sizes?.xs.field ?? {},
  sm: Input.sizes?.sm.field ?? {},
  md: Input.sizes?.md.field ?? {},
  lg: Input.sizes?.lg.field ?? {},
};

export const Textarea = defineStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'flushed',
  },
});
