import { fetcher } from '../../requests/fetcher';

import { IValidWorkHourValues } from './workHourFormUtils';

const baseUrl = import.meta.env.REACT_APP_API_URL;

export interface IWorkHourApi {
  id: number;
  start_time: string;
  end_time: string | null;
  comment: string | null;
  project_id?: number;
  task_id?: number;
}

export type IWorkHourBase = Omit<IWorkHourApi, 'id' | 'project_id'>;

export async function getWorkHours(): Promise<IWorkHourApi[]> {
  return fetcher(`${baseUrl}/work-hours`, '', 'GET');
}

export async function createWorkHour(workHour: IValidWorkHourValues) {
  const _workHour = {
    start_time: workHour.startTime.toISOString(),
    end_time: workHour.endTime?.toISOString(),
    comment: workHour.comment,
    task_id: workHour.task_id,
  };

  return fetcher(`${baseUrl}/work-hours`, JSON.stringify({ ..._workHour, project_id: workHour.projectId }));
}

export async function updateWorkHour(workHour: IValidWorkHourValues): Promise<IWorkHourApi> {
  const _workHour = {
    start_time: workHour.startTime.toISOString(),
    end_time: workHour.endTime?.toISOString(),
    comment: workHour.comment,
    id: workHour._id,
  };

  return fetcher(`${baseUrl}/work-hours`, JSON.stringify({ ..._workHour }), 'PATCH');
}
