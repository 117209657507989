import { createIcon } from '@chakra-ui/icons';

export const SettingsIcon = createIcon({
  displayName: 'SettingsIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M0.666667 3.16684H2.49067C2.63376 3.69333 2.94612 4.1581 3.37955 4.48946C3.81299 4.82082 4.34341 5.00035 4.889 5.00035C5.43459 5.00035 5.96501 4.82082 6.39845 4.48946C6.83188 4.1581 7.14424 3.69333 7.28733 3.16684H15.3333C15.5101 3.16684 15.6797 3.0966 15.8047 2.97158C15.9298 2.84655 16 2.67698 16 2.50017C16 2.32336 15.9298 2.15379 15.8047 2.02877C15.6797 1.90374 15.5101 1.83351 15.3333 1.83351H7.28733C7.14424 1.30702 6.83188 0.842243 6.39845 0.510885C5.96501 0.179527 5.43459 0 4.889 0C4.34341 0 3.81299 0.179527 3.37955 0.510885C2.94612 0.842243 2.63376 1.30702 2.49067 1.83351H0.666667C0.489856 1.83351 0.320286 1.90374 0.195262 2.02877C0.0702379 2.15379 0 2.32336 0 2.50017C0 2.67698 0.0702379 2.84655 0.195262 2.97158C0.320286 3.0966 0.489856 3.16684 0.666667 3.16684ZM4.88867 1.33351C5.11941 1.33351 5.34497 1.40193 5.53683 1.53012C5.72869 1.65832 5.87822 1.84053 5.96653 2.05371C6.05483 2.26689 6.07793 2.50147 6.03292 2.72778C5.9879 2.95409 5.87679 3.16197 5.71362 3.32513C5.55046 3.48829 5.34258 3.59941 5.11627 3.64442C4.88996 3.68944 4.65538 3.66633 4.4422 3.57803C4.22902 3.48973 4.04681 3.3402 3.91862 3.14834C3.79042 2.95648 3.722 2.73092 3.722 2.50017C3.72235 2.19086 3.84538 1.89432 4.0641 1.6756C4.28281 1.45689 4.57936 1.33386 4.88867 1.33351Z"
    />,
    <path
      key="1"
      fill="currentColor"
      d="M15.3333 7.33416H13.5093C13.3665 6.80755 13.0542 6.3426 12.6208 6.01111C12.1874 5.67961 11.657 5.5 11.1113 5.5C10.5657 5.5 10.0352 5.67961 9.60182 6.01111C9.16842 6.3426 8.85619 6.80755 8.71333 7.33416H0.666667C0.489856 7.33416 0.320286 7.40439 0.195262 7.52942C0.0702379 7.65444 0 7.82401 0 8.00082C0 8.17763 0.0702379 8.3472 0.195262 8.47223C0.320286 8.59725 0.489856 8.66749 0.666667 8.66749H8.71333C8.85619 9.1941 9.16842 9.65904 9.60182 9.99054C10.0352 10.322 10.5657 10.5016 11.1113 10.5016C11.657 10.5016 12.1874 10.322 12.6208 9.99054C13.0542 9.65904 13.3665 9.1941 13.5093 8.66749H15.3333C15.5101 8.66749 15.6797 8.59725 15.8047 8.47223C15.9298 8.3472 16 8.17763 16 8.00082C16 7.82401 15.9298 7.65444 15.8047 7.52942C15.6797 7.40439 15.5101 7.33416 15.3333 7.33416ZM11.1113 9.16749C10.8806 9.16749 10.655 9.09907 10.4632 8.97087C10.2713 8.84268 10.1218 8.66047 10.0335 8.44729C9.94517 8.23411 9.92207 7.99953 9.96708 7.77322C10.0121 7.54691 10.1232 7.33903 10.2864 7.17586C10.4495 7.0127 10.6574 6.90159 10.8837 6.85657C11.11 6.81156 11.3446 6.83466 11.5578 6.92296C11.771 7.01127 11.9532 7.1608 12.0814 7.35266C12.2096 7.54451 12.278 7.77008 12.278 8.00082C12.2776 8.31013 12.1546 8.60668 11.9359 8.82539C11.7172 9.04411 11.4206 9.16714 11.1113 9.16749Z"
    />,
    <path
      key="2"
      fill="currentColor"
      d="M15.3333 12.8335H7.28733C7.14424 12.307 6.83188 11.8422 6.39845 11.5109C5.96501 11.1795 5.43459 11 4.889 11C4.34341 11 3.81299 11.1795 3.37955 11.5109C2.94612 11.8422 2.63376 12.307 2.49067 12.8335H0.666667C0.489856 12.8335 0.320286 12.9037 0.195262 13.0288C0.0702379 13.1538 0 13.3234 0 13.5002C0 13.677 0.0702379 13.8466 0.195262 13.9716C0.320286 14.0966 0.489856 14.1668 0.666667 14.1668H2.49067C2.63376 14.6933 2.94612 15.1581 3.37955 15.4895C3.81299 15.8208 4.34341 16.0003 4.889 16.0003C5.43459 16.0003 5.96501 15.8208 6.39845 15.4895C6.83188 15.1581 7.14424 14.6933 7.28733 14.1668H15.3333C15.5101 14.1668 15.6797 14.0966 15.8047 13.9716C15.9298 13.8466 16 13.677 16 13.5002C16 13.3234 15.9298 13.1538 15.8047 13.0288C15.6797 12.9037 15.5101 12.8335 15.3333 12.8335ZM4.88867 14.6668C4.65792 14.6668 4.43236 14.5984 4.2405 14.4702C4.04864 14.342 3.89911 14.1598 3.81081 13.9466C3.72251 13.7335 3.6994 13.4989 3.74442 13.2726C3.78943 13.0463 3.90055 12.8384 4.06371 12.6752C4.22687 12.5121 4.43475 12.4009 4.66106 12.3559C4.88737 12.3109 5.12195 12.334 5.33513 12.4223C5.54831 12.5106 5.73052 12.6602 5.85871 12.852C5.98691 13.0439 6.05533 13.2694 6.05533 13.5002C6.0548 13.8094 5.93172 14.1059 5.71304 14.3245C5.49436 14.5432 5.19792 14.6663 4.88867 14.6668Z"
    />,
  ],
});
