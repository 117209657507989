import { Stack, Button, Text, Grid, Flex, Divider, Box } from '@chakra-ui/react';
import { getMonth, getYear, isAfter } from 'date-fns';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { Dialog } from '../../../components/Dialog';
import { StackedCards } from '../../../components/StackedCards';
import { useVat } from '../../../hooks/useVat/useVat';
import { Addition } from '../../../models/Addition';
import { Payment } from '../../../models/Payment';
import { roundTo2 } from '../../../utils/number/numberUtils';
import { MONTHS, durationToMinutesDisplay } from '../../../utils/time/timeUtils';
import { DataPoint } from '../../common/DataPoint';
import { EmptyBlurb } from '../../common/errors/EmptyBlurb';

interface IPaymentsByYear {
  [k: string]: Payment[];
}

interface IProjectPayments {
  payments: Array<Payment>;
  getPaymentTotalWorkHours(p: Payment): number | undefined;
  getPaymentSum(p: Payment): number | null;
  rate: number;
  isHourlyProject: boolean;
  navigateToHoursTab: () => void;
  navigateToAdditions: () => void;
  projectTotalValue: number;
  additions: Array<Addition>;
}

export const ProjectPayments = observer(
  ({
    payments,
    getPaymentTotalWorkHours,
    getPaymentSum,
    rate,
    isHourlyProject,
    navigateToHoursTab,
    navigateToAdditions,
    projectTotalValue,
    additions,
  }: IProjectPayments) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const paymentsByYear = payments.reduce<IPaymentsByYear>((acc, p) => {
      const paymentYear = getYear(p.paymentDate || p._submitDate);
      if (!acc[paymentYear]) {
        acc[paymentYear] = [];
      }
      acc[paymentYear].push(p);
      return acc;
    }, {});

    const years = Object.keys(paymentsByYear);

    if (years.length === 0) {
      return (
        <Flex justifyContent="center" alignItems="center" direction="column" textAlign="center" mt="50px">
          <EmptyBlurb title="אין עדיין צפי הכנסות" body="צפי הכנסות יופיע ברגע שידווחו שעות בפרויקט" />
        </Flex>
      );
    }

    return (
      <Box>
        <Box position="relative">
          <Button
            position="absolute"
            left="0"
            top="12px"
            onClick={() => setIsDialogOpen(true)}
            variant="link"
            fontSize="md"
            zIndex={1000}
          >
            בקרוב, חיבור לחשבונית שלכם!
          </Button>
        </Box>
        <Stack>
          {years
            .sort((a, b) => Number(b) - Number(a))
            .map((y) => (
              <YearlyProjectPayments
                key={y}
                year={y}
                payments={paymentsByYear[y]}
                getPaymentTotalWorkHours={getPaymentTotalWorkHours}
                getPaymentSum={getPaymentSum}
                rate={rate}
                isHourlyProject={isHourlyProject}
                navigateToHoursTab={navigateToHoursTab}
                projectTotalValue={projectTotalValue}
                additions={additions}
                navigateToAdditions={navigateToAdditions}
              />
            ))}
        </Stack>
        {isDialogOpen && (
          <Dialog title="חיבור לחשבונית שלכם!" onClose={() => setIsDialogOpen(false)}>
            <Text textAlign="center" my="25px" px="25px">
              בקרוב יהיה אפשר להוציא מכאן דרישת תשלום על כמות השעות שדווחת באמצעות חיבור ישיר למערכת החשבוניות שלכם. רק
              רצינו שתדעו שאנחנו על זה 😉
            </Text>
            <Flex justifyContent="center" py="16px" px="30px">
              <Button onClick={() => setIsDialogOpen(false)}>הבנתי, תודה</Button>
            </Flex>
          </Dialog>
        )}
      </Box>
    );
  },
);

interface IYearlyProjectPayments {
  year: string;
  payments: Payment[];
  getPaymentTotalWorkHours(p: Payment): number | undefined;
  getPaymentSum(p: Payment): number | null;
  rate: number;
  isHourlyProject: boolean;
  navigateToHoursTab: () => void;
  navigateToAdditions: () => void;
  projectTotalValue: number;
  additions: Array<Addition>;
}
const YearlyProjectPayments = observer(
  ({
    year,
    payments,
    getPaymentTotalWorkHours,
    getPaymentSum,
    rate,
    isHourlyProject,
    navigateToHoursTab,
    navigateToAdditions,
    projectTotalValue,
    additions,
  }: IYearlyProjectPayments) => {
    const { getValueWithVat } = useVat();

    const getTotalHours = (p: Payment) => {
      if (p.isAdvance && p.sum) {
        const numberOfHours = roundTo2(p.sum / rate);
        const advanceInPercentage = roundTo2((p.sum / projectTotalValue) * 100);
        return (
          <Flex gap={1} fontSize="lg">
            <Text>{advanceInPercentage}% תשלום מקדמה</Text>
            <Text marginInlineStart="5px">({numberOfHours} שעות ממסגרת הפרויקט)</Text>
          </Flex>
        );
      }
      const total = durationToMinutesDisplay(getPaymentTotalWorkHours(p) || 0);
      return (
        <Flex gap={1} fontSize="lg">
          <Button onClick={navigateToHoursTab} variant="link" paddingInlineEnd="3px">
            {total}
          </Button>
          <Text>שעות שדווחו </Text>
          {isAfter(p._submitDate, new Date()) ? <Text>עד כה</Text> : null}
          <Text>בחודש</Text>
          <Text fontWeight="normal">{MONTHS[getMonth(p._submitDate)]}</Text>
        </Flex>
      );
    };

    const getSum = (p: Payment, additionsSum?: number) => {
      if (!isHourlyProject) {
        return getValueWithVat(additionsSum ? additionsSum + (p.sum || 0) : p.sum || 0).toLocaleString();
      }
      return getValueWithVat(getPaymentSum(p) || 0).toLocaleString();
    };
    payments.sort((a, b) => b._submitDate.getTime() - a._submitDate.getTime());
    const items = payments.map((p) => {
      const paymentAdditions = additions.filter((a) => a.payment === p._id);
      const additionsSum = paymentAdditions.reduce((acc, a) => acc + a.value, 0);
      const additionsExists = paymentAdditions.length > 0;
      return (
        <Grid
          key={p._id}
          templateColumns={`80px 100px 130px 3px 1fr ${additionsExists ? '1fr' : ''}`}
          gap="45px"
          zIndex={1}
        >
          <DataPoint label="חודש" value={MONTHS[getMonth(p.paymentDate || p._submitDate)]} />
          <DataPoint label="סכום" value={`${getSum(p, additionsSum)} ₪ ${additionsExists ? '*' : ''}`} />
          <DataPoint label="מועד צפוי לתשלום" value={p.displayPaymentDate || ''} />
          <Divider h="100%" orientation="vertical" />
          {isHourlyProject ? (
            <DataPoint label="מבוסס על" component={getTotalHours(p)} />
          ) : (
            <>
              <DataPoint label="תאור" value={p.comment || '-'} />
              {additionsExists && (
                <Text alignSelf="end">
                  * התשלום בחודש זה כולל{' '}
                  <Box
                    as="span"
                    fontWeight="normal"
                    textDecoration="underline"
                    cursor="pointer"
                    _hover={{
                      color: 'primary.200',
                    }}
                    transition=".2s"
                    onClick={navigateToAdditions}
                  >
                    תוספות בסך {additionsSum.toLocaleString()} ₪
                  </Box>
                </Text>
              )}
            </>
          )}
        </Grid>
      );
    });

    return <StackedCards title={year} items={items} />;
  },
);
