import { Box, Text, Input, Divider, Spacer } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';

import { IconButton } from '../../../components/IconButton';
import { PausedWorkHour, useTimeTracking } from '../../../models/workHours/useTimeTracking';
import { IWorkHourApi } from '../../../models/workHours/workHourApi';
import { PauseIcon, StopIcon } from '../../common/icons';
import { PlayIcon } from '../../common/icons/PlayIcon';

import { TimeTrackerSuccess } from './TimeTrackerSuccess';
import { TrackerProjectAutocomplete } from './TrackerProjectAutocomplete';
import { WorkHourComment } from './WorkHourComment';

type IProject = {
  _id: string;
  name: string;
} | null;

export const AutoTimeTracker = observer(
  ({
    allProjects,
    selectedProject,
    setSelectedProject,
    isProjectSelected,
    saveWorkHour,
  }: {
    allProjects: { key: string; value: string }[];
    selectedProject: IProject;
    setSelectedProject: (projectId?: string) => void;
    isProjectSelected: boolean;
    saveWorkHour: (workHour: IWorkHourApi) => Promise<void>;
  }) => {
    const [comment, setComment] = useState<string>('');
    const {
      startTimer,
      completeWorkHour,
      runningDuration,
      currentWorkHour,
      isRecording,
      isPaused,
      handleCommentUpdate,
      success,
    } = useTimeTracking({
      saveWorkHour,
    });

    // test how to sync state for second tab with session storage
    useEffect(() => {
      if (isPaused) {
        setComment(currentWorkHour?.comment || '');
        setSelectedProject((currentWorkHour as PausedWorkHour)?.projectId);
        return;
      }
      if (!currentWorkHour && isProjectSelected) {
        setComment('');
        setSelectedProject(undefined);
      }
    }, [currentWorkHour, isPaused, allProjects]);

    const handleButtonClick = async (wasStopClicked: boolean = false) => {
      completeWorkHour(wasStopClicked);
      // give a look at why we display the play for a second before pause kicks in
      if (wasStopClicked) {
        setComment('');
        setSelectedProject(undefined);
      }
    };

    return (
      <>
        <Divider borderColor="#E6E9F2" height="24px" marginInline="15px" orientation="vertical" />
        {!isProjectSelected && !(isRecording || isPaused) && (
          <TrackerProjectAutocomplete onChange={setSelectedProject} data={allProjects} isAuto />
        )}
        {isProjectSelected && !(isRecording || isPaused) && (
          <Input
            placeholder="הוספת תיאור"
            fontSize="lg"
            value={comment}
            variant="unstyled"
            onChange={(e) => setComment(e.target.value)}
          />
        )}
        {(isRecording || isPaused) && (
          <>
            <WorkHourComment value={currentWorkHour?.comment || ''} onChange={handleCommentUpdate} />
            <Spacer />
            <Text textAlign="end" fontSize="lg" w="74px" fontWeight="normal" marginInlineEnd="16px">
              {runningDuration}
            </Text>
          </>
        )}
        {isRecording ? (
          <>
            <IconButton
              onClick={() => handleButtonClick()}
              icon={<PauseIcon />}
              aria-label="pause hour"
              variant="secondary"
              color="accent"
              marginInlineEnd="8px"
            />
            <IconButton icon={<StopIcon />} color="accent" onClick={() => handleButtonClick(true)} disabled={false} />
          </>
        ) : (
          <>
            <IconButton icon={<PlayIcon />} color="primary" onClick={() => startTimer(selectedProject?._id, comment)} />
            {isPaused && !isRecording && (
              <Box marginInlineStart="8px">
                <IconButton
                  icon={<StopIcon />}
                  color="accent"
                  onClick={() => handleButtonClick(true)}
                  disabled={false}
                />
              </Box>
            )}
          </>
        )}
        <TimeTrackerSuccess shouldDisplay={success} />
      </>
    );
  },
);
