import { Flex, Box, Text, Checkbox } from '@chakra-ui/react';
import { format, isAfter, isBefore } from 'date-fns';
import { useSessionStorage } from 'usehooks-ts';

import { SelectItem } from '../../components/form/Select';
import { IconButton } from '../../components/IconButton';
import { SquareIconButton } from '../../components/SquareIconButton';
import { StatusDropDown } from '../../components/StatusDropDown';
import { StyledTooltip } from '../../components/StyledTooltip';
import { Table } from '../../components/Table';
import { TableActions } from '../../components/TableActions';
import { useCustomToast } from '../../hooks/toast/useCustomToast';
import { Project } from '../../models/Project';
import { Task, TaskStatus } from '../../models/tasks/tasksApi';
import { getPriorityColor, getPriorityLabel } from '../../models/tasks/taskUtils';
import { useDeleteTask } from '../../models/tasks/useDeleteTask';
import { useUpdateTask } from '../../models/tasks/useUpdateTask';
import { useTimeTracking } from '../../models/workHours/useTimeTracking';
import { IWorkHourApi } from '../../models/workHours/workHourApi';
import { logger } from '../../utils/logger';
import { EmptyBlurb } from '../common/errors/EmptyBlurb';
import { FlagIcon, NoTasksIcon, PauseIcon, StopIcon } from '../common/icons';
import { PlayIcon } from '../common/icons/PlayIcon';

import { TaskFilters } from './TaskFilters';

const BASE_FILTERS = {
  selectedProjects: [],
  selectedPriorities: [],
  selectedStatuses: [],
  startTime: null,
  endTime: null,
  nameFilter: '',
};

type TaskFiltersType = {
  selectedProjects: Array<SelectItem>;
  selectedPriorities: Array<SelectItem>;
  selectedStatuses: Array<SelectItem>;
  startTime: Date | null;
  endTime: Date | null;
  nameFilter: string;
};

export const Tasks = ({
  tasks,
  projects,
  editTask,
  saveWorkHour,
  onOrderChange,
  onProjectClick,
  isMinimal = false,
  projectId,
}: {
  tasks: Task[];
  projects?: Project[];
  editTask(id: number): void;
  saveWorkHour: (workHour: IWorkHourApi) => Promise<void>;
  onOrderChange: (movedId: number, previousId: number) => Promise<void>;
  onProjectClick?: (projectId: string) => void;
  isMinimal?: boolean;
  projectId?: string;
}) => {
  const { triggerToast } = useCustomToast();

  const { updateTask } = useUpdateTask();
  const { deleteTask } = useDeleteTask();

  const { startTimer, completeWorkHour, runningDuration, currentWorkHour, isRecording } = useTimeTracking({
    saveWorkHour,
  });

  const [filters, setFilters] = useSessionStorage<TaskFiltersType>(`taskFilters_${projectId || 0}`, BASE_FILTERS);

  const handleDateSelect = (start: Date | null, end: Date | null) => {
    setFilters((prev) => ({ ...prev, startTime: start, endTime: end }));
  };

  const projectsColumn = {
    key: 'project',
    label: 'פרויקט',
    render: (t: Task) => (
      <Box maxW="130px">
        {t.project_id ? (
          <Text
            onClick={(e) => {
              e.preventDefault();
              onProjectClick?.(t.project_id!.toString());
            }}
            textDecoration="underline"
            cursor="pointer"
            _hover={{
              color: 'primary.200',
            }}
            transition=".2s"
            title={t.project_name!}
            noOfLines={1}
            display="block"
            whiteSpace="nowrap"
          >
            {t.project_name}
          </Text>
        ) : (
          <Text>ללא</Text>
        )}
      </Box>
    ),
  };

  const columns = [
    {
      key: 'title',
      label: 'משימה',
      render: (t: Task) => (
        <Flex alignItems="center" minWidth="300px" maxW="350px">
          <Checkbox
            size="sm"
            isChecked={t.is_complete}
            onChange={() =>
              updateTask({
                ...t,
                is_complete: !t.is_complete,
                status: !t.is_complete ? TaskStatus.DONE : TaskStatus.TODO,
              })
            }
            marginInlineEnd="18px"
          />
          <Text title={t.label} noOfLines={1} display="block" whiteSpace="nowrap">
            {t.label}
          </Text>
        </Flex>
      ),
    },
    {
      key: 'dueDate',
      label: 'תאריך יעד',
      render: (t: Task) => (
        <Text color={t.due_date && isBefore(t.due_date, new Date()) ? 'accent.100' : 'base.1000'}>
          {t.due_date ? format(t.due_date, 'dd.MM.yyyy') : 'ללא'}
        </Text>
      ),
    },
    {
      key: 'priority',
      label: 'חשיבות',
      render: (t: Task) => (
        <Flex alignItems="center" gap="4px">
          <FlagIcon fontSize="12px" color={getPriorityColor(t.priority)} />
          <Text>{getPriorityLabel(t.priority)}</Text>
        </Flex>
      ),
    },
    {
      key: 'status',
      label: 'סטטוס',
      render: (t: Task) => (
        <Box minW="135px" maxW="135px">
          <StatusDropDown value={t.status} onChange={(status) => updateTask({ ...t, status: status as TaskStatus })} />
        </Box>
      ),
      padding: '10px 24px !important',
    },
  ];

  const handleTaskDelete = async (t: Task) => {
    try {
      await deleteTask(t);
      triggerToast('success');
    } catch (e) {
      logger.error(e);
      triggerToast('error');
    }
  };

  const filteredTasks = tasks
    .filter((t: Task) => (filters.startTime && t.due_date ? isAfter(t.due_date, filters.startTime) : true))
    .filter((t: Task) => (filters.endTime && t.due_date ? isBefore(t.due_date, filters.endTime) : true))
    .filter((t: Task) =>
      filters.selectedPriorities.length ? filters.selectedPriorities.map((p) => p.value).includes(t.priority) : true,
    )
    .filter((t: Task) =>
      filters.selectedStatuses.length ? filters.selectedStatuses.map((p) => p.value).includes(t.status) : true,
    )
    .filter((t: Task) =>
      filters.selectedProjects.length
        ? filters.selectedProjects.map((p) => p.value).includes(t.project_id?.toString() || '')
        : true,
    )
    .filter((t: Task) => (filters.nameFilter ? t.label.includes(filters.nameFilter) : true));

  if (!isMinimal) {
    columns.splice(1, 0, projectsColumn);
  }

  const clearFilters = () => {
    setFilters(BASE_FILTERS);
  };

  return (
    <>
      <TaskFilters
        isMinimal={isMinimal}
        projects={projects}
        selectedProjects={filters.selectedProjects}
        setSelectedProjects={(projects) => setFilters((prev) => ({ ...prev, selectedProjects: projects }))}
        selectedPriorities={filters.selectedPriorities}
        setSelectedPriorities={(priorities) => setFilters((prev) => ({ ...prev, selectedPriorities: priorities }))}
        selectedStatuses={filters.selectedStatuses}
        setSelectedStatuses={(statuses) => setFilters((prev) => ({ ...prev, selectedStatuses: statuses }))}
        startTime={filters.startTime ? new Date(filters.startTime) : null}
        endTime={filters.endTime ? new Date(filters.endTime) : null}
        handleDateSelect={handleDateSelect}
        nameFilter={filters.nameFilter}
        setNameFilter={(name) => setFilters((prev) => ({ ...prev, nameFilter: name }))}
        clearFilters={clearFilters}
      />
      <Text mt={isMinimal ? '30px' : '60px'} mb={isMinimal ? '14px' : '24px'} textStyle="extraLarge">
        סה״כ {filteredTasks.length} משימות
      </Text>
      {filteredTasks.length > 0 ? (
        <Table
          columns={columns}
          data={filteredTasks}
          isDraggable
          onDragEnd={onOrderChange}
          actions={{
            render: (t: Task) =>
              currentWorkHour && currentWorkHour.task_id === t.id ? (
                <Flex alignItems="center" gap="8px">
                  <Text textAlign="end" fontSize="lg" w="74px" fontWeight="normal" marginInlineEnd="16px">
                    {runningDuration}
                  </Text>
                  {isRecording ? (
                    <IconButton
                      onClick={() => completeWorkHour()}
                      icon={<PauseIcon />}
                      aria-label="pause hour"
                      variant="secondary"
                      color="accent"
                    />
                  ) : (
                    <IconButton
                      icon={<PlayIcon />}
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        startTimer(t.project_id?.toString(), t.label, t.id);
                      }}
                    />
                  )}
                  <IconButton
                    icon={<StopIcon />}
                    color="accent"
                    onClick={() => completeWorkHour(true)}
                    disabled={false}
                  />
                </Flex>
              ) : (
                <TableActions onDelete={() => handleTaskDelete(t)} onEdit={() => editTask(t.id)}>
                  <StyledTooltip label={currentWorkHour ? '' : 'הפעלת טיימר'}>
                    <SquareIconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        startTimer(t.project_id?.toString(), t.label, t.id);
                      }}
                      variant="ghost"
                      isDisabled={Boolean(currentWorkHour)}
                      size="sm"
                      icon={<PlayIcon />}
                    />
                  </StyledTooltip>
                </TableActions>
              ),
            alwaysVisible: (t: Task) => Boolean(currentWorkHour?.task_id === t.id),
            width: '242px',
          }}
        />
      ) : (
        <Flex w="100%" justifyContent="center" mt="48px">
          <EmptyBlurb
            title="אין לנו תוצאות להציג"
            body="כדאי לשנות את החיפוש"
            icon={<NoTasksIcon fontSize="72px" mb="16px" />}
            button={{
              label: 'איפוס',
              onClick: clearFilters,
            }}
          />
        </Flex>
      )}
    </>
  );
};
