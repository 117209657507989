import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Text,
  HTMLChakraProps,
} from '@chakra-ui/react';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { DragIcon } from '../pages/common/icons';

interface IColumn extends HTMLChakraProps<'th'> {
  key: string;
  label: string;
  render: (rowData: any) => React.ReactNode;
}

interface TableProps {
  columns: IColumn[];
  data: any[];
  title?: string;
  onRowClick?: (data: any) => void;
  actions?: {
    width?: string;
    alwaysVisible?: (rowData: any) => boolean;
    render: (rowData: any) => React.ReactNode;
  };
  isDraggable?: boolean;
  onDragEnd?: (movedId: number, previousId: number) => void;
}

const StaticTableRow = ({ rowData, columns, actions, isDraggable }: any) => {
  return (
    <tr
      style={{
        boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      }}
    >
      {columns.map((c: any) => (
        <td
          key={`${rowData.id}_${c.key}`}
          style={{
            background: 'white',
            padding: '18px 24px',
            fontSize: '1.125rem',
            lineHeight: '1.375rem',
            fontVariantNumeric: 'normal',
          }}
        >
          {c.render(rowData)}
        </td>
      ))}
      {actions && <td style={{ background: 'white', width: actions.width || '132px' }}>&nbsp;</td>}
    </tr>
  );
};

const TableRow = ({ rowData, columns, actions, onRowClick, isDraggable }: any) => {
  const { attributes, listeners, transform, setNodeRef, transition, isDragging } = useSortable({
    id: rowData._id || rowData.id,
  });

  return (
    <Tr
      ref={setNodeRef}
      onClick={() => onRowClick?.(rowData)}
      height={isDragging ? '79px' : '58px'}
      sx={{
        '& td': {
          padding: '18px 24px',
          textStyle: 'large',
          lineHeight: '1',
          fontVariantNumeric: 'normal',
        },
        border: 'none',
        position: 'relative',
        transition: transition,
        transform: CSS.Transform.toString(transform),
        cursor: onRowClick ? 'pointer' : 'default',
        '+ tr': {
          borderTopColor: 'base.100',
          borderTop: '1px solid',
        },
        '&:hover': {
          bg: 'base.25',
        },
        '&:hover .actions': {
          display: 'block',
        },
        '&:hover .drag': {
          opacity: 1,
        },
      }}
    >
      {isDragging ? (
        <Td
          colSpan={actions ? columns.length + 1 : columns.length}
          backgroundColor="base.100"
          w="100%"
          padding="18px 24px"
        >
          &nbsp;
        </Td>
      ) : (
        columns.map((c: any, i: number) => (
          <Td key={`${rowData.id}_${c.key}`} fontSize="lg" p={c.padding}>
            {isDraggable && i === 0 ? (
              <Box position="relative">
                <DragIcon
                  position="absolute"
                  right="-24px"
                  top="50%"
                  transform="translateY(-50%)"
                  className="drag"
                  opacity="0"
                  color="base.400"
                  fontSize="24px"
                  cursor={isDragging ? 'grabbing' : 'grab'}
                  {...attributes}
                  {...listeners}
                />
                {c.render(rowData)}
              </Box>
            ) : (
              c.render(rowData)
            )}
          </Td>
        ))
      )}
      {actions && !isDragging && (
        <Td py="8px !important" textAlign="end">
          <Box className="actions" display={actions.alwaysVisible?.(rowData) ? 'block' : 'none'}>
            {actions.render(rowData)}
          </Box>
        </Td>
      )}
    </Tr>
  );
};

export const Table = observer(
  ({ columns, data, title, onRowClick, actions, isDraggable = false, onDragEnd }: TableProps) => {
    const [activeId, setActiveId] = useState<string | null>(null);

    const items = data?.map(({ id }) => id);

    const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));

    function handleDragStart(event: any) {
      setActiveId(event.active.id);
    }

    function handleDragEnd(event: any) {
      const { active, over } = event;
      setActiveId(null);

      if (active.id !== over.id) {
        onDragEnd?.(active.id, over.id);
      }
    }

    function handleDragCancel() {
      setActiveId(null);
    }

    const selectedRow = data.find((d) => d.id === activeId);

    return (
      <Box>
        {title && (
          <Text textStyle="doubleLarge" mb="20px">
            {title}
          </Text>
        )}
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
        >
          <Box border="1px solid" borderColor="#E6E9F2" borderRadius="16px" bg="white">
            <TableContainer>
              <ChakraTable variant="simple">
                <Thead>
                  <Tr
                    height="68px"
                    sx={{
                      '& th': {
                        color: 'base.600',
                        textStyle: 'medium',
                        lineHeight: '1',
                        fontWeight: 'normal',
                        padding: '24px',
                        fontFamily: 'Ploni',
                      },
                    }}
                  >
                    {columns.map(({ key, label, render, ...props }, index) => (
                      <Th key={key} {...props}>
                        {label}
                      </Th>
                    ))}
                    {actions && <Th w={actions.width || '132px'} />}
                  </Tr>
                </Thead>
                <Tbody>
                  <SortableContext items={items} strategy={verticalListSortingStrategy}>
                    {data.map((d) => (
                      <TableRow
                        key={d._id || d.id}
                        rowData={d}
                        columns={columns}
                        actions={actions}
                        onRowClick={onRowClick}
                        isDraggable={isDraggable}
                      />
                    ))}
                  </SortableContext>
                </Tbody>
              </ChakraTable>
            </TableContainer>
            <DragOverlay dropAnimation={null}>
              {activeId && (
                <table style={{ width: '100%' }}>
                  <tbody>
                    <StaticTableRow rowData={selectedRow} columns={columns} actions={actions} />
                  </tbody>
                </table>
              )}
            </DragOverlay>
          </Box>
        </DndContext>
      </Box>
    );
  },
);
