import { Auth0UserProfile } from 'auth0-js';
import { jwtDecode } from 'jwt-decode';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import TagManager from 'react-gtm-module';

import { UserClient } from '../requests/UserClient';
import { auth0RenewAuth } from '../utils/auth0';
import { logger } from '../utils/logger';

export const ID_TOKEN = 'idToken';
export const USER_ID = 'userId';

export class AuthenticationModel {
  private userClient: UserClient;

  idToken: string | undefined;
  email: string | undefined;
  username: string | undefined;

  constructor(userClient: UserClient) {
    this.userClient = userClient;

    this.idToken = undefined;
    this.email = undefined;
    this.username = undefined;

    const idToken = window?.localStorage.getItem(ID_TOKEN);

    if (idToken) {
      this.renewToken();
      this.setUserValues(idToken);
      this.initTag();
    }

    makeObservable(this, {
      email: observable,
      idToken: observable,
      username: observable,
      setUserValues: action,
      isAuthenticated: computed,
      logout: action,
    });
  }

  onSignup = async (idToken: string, affiliateLinkId?: string, source?: string) => {
    try {
      const id = await this.userClient.createUser(idToken, affiliateLinkId, source);

      runInAction(() => {
        this.setUserValues(idToken);
        this.initTag('signup');
      });
      return id;
    } catch (err) {
      logger.log('Failed signup');
      logger.error(err);
    }
  };

  onLogin = async (idToken: string) => {
    try {
      const id = await this.userClient.getUserByToken(idToken);
      localStorage.setItem(USER_ID, id);

      runInAction(() => {
        this.setUserValues(idToken);
        this.initTag('login');
      });
    } catch (err) {
      logger.log('Failed login');
      logger.error(err);
    }
  };

  get isAuthenticated() {
    return Boolean(this.idToken);
  }

  logout = () => {
    window.localStorage.removeItem(ID_TOKEN);
    this.idToken = undefined;
  };

  renewToken = async () => {
    const res = await auth0RenewAuth();
    if (!res.idToken) {
      return;
    }
    this.setUserValues(res.idToken);
  };

  setUserValues = (idToken: string) => {
    window.localStorage.setItem(ID_TOKEN, idToken);
    this.idToken = idToken;

    const decoded = jwtDecode<Auth0UserProfile>(idToken);
    this.email = decoded.email;
    this.username = decoded.name;
    this.setUserContext({
      email: decoded.email,
      username: decoded.name,
    });
  };

  initTag = (eventName: string = 'new_session') => {
    const id = localStorage.getItem(USER_ID);
    if (id) {
      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          userId: id,
          user_email: this.email,
          user_name: this.username,
        },
      });
    }
  };

  setUserContext = (user: { email?: string; username?: string; id?: string }) => {
    logger.setUserContext(user);
  };
}
