import { Box, Grid, Text } from '@chakra-ui/layout';

import { ServicesBarChart } from './ServicesBarChart';

export const TopServices = ({ topServices }: { topServices: Array<{ label: string; data: number }> }) => {
  const getChatHeight = () => {
    if (topServices.length === 1) {
      return '50px';
    }
    if (topServices.length === 2) {
      return '100px';
    }
    if (topServices.length === 3) {
      return '150px';
    }
    return '200px';
  };
  return (
    <>
      <Text fontSize="xl" mb="22px">
        התפלגות שירותים
      </Text>
      <Grid position="relative" templateColumns="1fr 1fr">
        <Grid
          position="absolute"
          top="0"
          right="0"
          left="8px"
          bottom="0"
          templateColumns="1fr"
          templateRows="36px 36px 36px 36px"
          rowGap="14px"
          alignItems="center"
          pt="7px"
        >
          {topServices.map((_, i) => (
            <Box key={i} w="100%" h="32px" bg="#F7F7FF" borderRadius="4px" />
          ))}
        </Grid>
        <Grid
          zIndex="10"
          templateColumns="1fr"
          templateRows="36px 36px 36px 36px"
          rowGap="14px"
          alignItems="center"
          pt="7px"
        >
          {topServices.map((s) => (
            <Text marginInlineStart="12px" fontSize="lg" color="base.900" key={s.label}>
              {s.label}
            </Text>
          ))}
        </Grid>
        <Box w="185px" h={getChatHeight()} zIndex="10">
          <ServicesBarChart data={topServices} />
        </Box>
      </Grid>
    </>
  );
};
