import { Box, Text, Input, Flex } from '@chakra-ui/react';
import { useCombobox } from 'downshift';
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';

import { SearchIcon } from '../../common/icons';

interface ISelectFormProps {
  data: Array<{ key: string; value: string }>;
  onChange: (id?: string) => void;
  isAuto?: boolean;
  clearValues?: boolean;
}

export const TrackerProjectAutocomplete = observer(({ isAuto, data, onChange, clearValues }: ISelectFormProps) => {
  const [items, setItems] = useState(data);
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getInputProps,
    inputValue,
    setInputValue,
    getItemProps,
    getComboboxProps,
  } = useCombobox({
    onInputValueChange({ inputValue: newValue }) {
      if (newValue) {
        setItems(data.filter((d) => newValue && d.key.toLocaleLowerCase().includes(newValue)));
      } else {
        setItems(data);
      }
    },
    items: items.map((d) => d.key),
    onSelectedItemChange: ({ selectedItem }) => onChange(items.find((i) => i.key === selectedItem)?.value),
  });

  useEffect(() => {
    if (data.length !== items.length) {
      setItems(data);
    }
  }, [data.length]);

  useEffect(() => {
    if (clearValues) {
      onChange(undefined);
      setInputValue('');
    }
  }, [clearValues]);

  return (
    <Box position="relative" w="100%" {...getComboboxProps()}>
      <Box
        type="button"
        {...getToggleButtonProps({})}
        w="100%"
        color="#685888"
        fontSize="lg"
        py="13px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        cursor="pointer"
      >
        <Input
          fontSize="lg"
          variant="unstyled"
          {...getInputProps()}
          color={Boolean(inputValue) ? 'base.1000' : 'base.500'}
          textStyle="large"
          placeholder={isAuto ? 'על מה עובדים?' : 'על מה עבדת?'}
        />
      </Box>
      <Box
        as="ul"
        {...getMenuProps()}
        position="absolute"
        backgroundColor="white"
        boxShadow="0px 1px 2px 0px rgba(29, 79, 129, 0.04), 0px 16px 32px -12px rgba(7, 32, 69, 0.25)"
        _focus={{
          // override globals
          boxShadow: '0px 1px 2px 0px rgba(29, 79, 129, 0.04), 0px 16px 32px -12px rgba(7, 32, 69, 0.25) !important',
        }}
        borderRadius="8px"
        border="1px solid"
        borderColor="base.200"
        backdropFilter="blur(5px)"
        listStyleType="none"
        maxH="200px"
        overflowY="scroll"
        display={isOpen ? 'block' : 'none'}
        zIndex="dropdown"
        _focusVisible={{ outline: 'none' }}
        width="348px"
        transform="translate(12px, 8px)"
      >
        {isOpen && (
          <Box p="12px" pb="0">
            {items.length > 0 ? (
              items.map((item: any, index) => (
                <li key={`${item}${index}`} {...getItemProps({ item, index: index })}>
                  <Box maxW="356px">
                    <Text
                      cursor="pointer"
                      p="8px"
                      textStyle="large"
                      paddingInlineEnd="12px"
                      borderRadius="6px"
                      color="base.1000"
                      bg={highlightedIndex === index ? 'base.25' : 'white'}
                      noOfLines={1}
                      display="block"
                      whiteSpace="nowrap"
                      title={item.key}
                    >
                      {item.key}
                    </Text>
                  </Box>
                </li>
              ))
            ) : (
              <Flex direction="column" alignItems="center" pt="19px" pb="26px">
                <Flex
                  h="52px"
                  w="52px"
                  borderRadius="50%"
                  bg="primary.0"
                  color="primary.100"
                  alignItems="center"
                  justifyContent="center"
                  mb="20px"
                >
                  <SearchIcon h="16px" w="16px" />
                </Flex>
                <Text textStyle="large" color="base.1000" mb="4px">
                  {data.length === 0 ? 'אין לך עדיין פרויקטים' : 'אין לך פרויקט עם השם הזה'}
                </Text>
                {data.length === 0 && (
                  <Text textStyle="medium" color="base.600">
                    מה שכן, אפשר גם לדווח שעות בלי פרויקט
                  </Text>
                )}
              </Flex>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
});
