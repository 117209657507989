import { ChevronRightIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { he } from 'date-fns/locale';
import { forwardRef } from 'react';
import ReactDatePicker, { registerLocale, CalendarContainer, CalendarContainerProps } from 'react-datepicker';

import { CalendarIcon } from '../../pages/common/icons';
import { IconButton } from '../IconButton';

import { StyledInput } from './StyledInput';

registerLocale('he', he);

export interface DatePickerProps {
  selectedDate: Date | null;
  onDateSelect(s: Date | null): void;
  minimumDate?: Date;
  maximumDate?: Date;
  label?: string;
  isRequired?: boolean;
  error?: string;
  touched?: boolean;
  maintainHeight?: boolean;
  size?: 'sm' | 'lg';
  setTouched?: () => void;
  isClearable?: boolean;
}

const DateInput = forwardRef(
  (
    {
      value,
      onClick,
      onChange,
      error,
      touched,
      isRequired,
      maintainHeight,
      label,
      setTouched,
      size,
      isClearable,
      ...rest
    }: any,
    ref: any,
  ) => {
    const handleChange = (e: any) => {
      // make sure that typed date is valid
      if (new Date(e.target.value).toString() === 'Invalid Date' || isNaN(new Date(e.target.value).getTime())) {
        return;
      }
      onChange(e);
    };

    return (
      <StyledInput
        name="date-picker"
        onClick={onClick}
        label={label}
        error={error}
        touched={touched}
        maintainHeight={maintainHeight}
        onChange={handleChange}
        isRequired={isRequired}
        ref={ref}
        size={size}
        value={value}
        {...rest}
        onBlur={setTouched}
        endIcon={<CalendarIcon marginInlineEnd={isClearable && value ? '18px' : ''} color="base.500" />}
      />
    );
  },
);

export const DatePicker = ({
  selectedDate,
  onDateSelect,
  minimumDate,
  maximumDate,
  label,
  error,
  touched,
  maintainHeight,
  isRequired,
  setTouched,
  size,
  isClearable,
}: DatePickerProps) => {
  return (
    <ReactDatePicker
      calendarContainer={(props: CalendarContainerProps) => <MyContainer {...props} />}
      minDate={minimumDate}
      maxDate={maximumDate}
      dateFormatCalendar="MMMM"
      fixedHeight
      isClearable={isClearable}
      popperModifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, -30],
          },
        },
      ]}
      renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth, date, changeYear }) => {
        return (
          <CustomHeader
            monthDate={monthDate}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            year={date.getFullYear()}
            changeYear={changeYear}
          />
        );
      }}
      selected={selectedDate}
      onChange={(date) => {
        onDateSelect(date);
      }}
      dateFormat="dd.MM.yyyy"
      locale="he"
      customInput={
        <DateInput
          error={error}
          touched={touched}
          maintainHeight={maintainHeight}
          label={label}
          isRequired={isRequired}
          setTouched={setTouched}
          size={size}
          isClearable={isClearable}
        />
      }
    />
  );
};

const MyContainer = ({ className, children }: CalendarContainerProps) => {
  return (
    <Flex
      boxShadow="0px 0px 15px rgba(39, 12, 67, 0.12)"
      bg="white"
      border="1px solid"
      borderColor="#e6e9f2"
      borderRadius="14px"
      minW="307px"
      minH="292px"
      py="24px"
      px="16px"
    >
      <CalendarContainer className={className}>{children}</CalendarContainer>
    </Flex>
  );
};

const CustomHeader = ({ monthDate, decreaseMonth, increaseMonth, year, changeYear }: any) => {
  const startYear = year - 50;
  const years = Array.from({ length: 100 }, (_, i) => i + startYear);

  return (
    <Flex justifyContent="space-between" alignItems="center" mb="12px">
      <IconButton
        size="sm"
        color="success"
        aria-label="Previous Month"
        onClick={(e) => {
          // prevent odd error where this is considered a submit
          e.preventDefault();
          decreaseMonth();
        }}
        icon={<ChevronRightIcon />}
      />
      <Flex
        gap="8px"
        alignItems="center"
        sx={{
          '#year-selector': {
            cursor: 'pointer',
            borderRadius: '4px',
          },
          '#year-selector:hover': {
            backgroundColor: 'base.100',
          },
        }}
      >
        <Text fontSize="xl">
          {monthDate.toLocaleString('he', {
            month: 'long',
          })}
        </Text>
        <select id="year-selector" value={year} onChange={(e) => changeYear(parseInt(e.target.value))}>
          {years.map((y: number) => (
            <option key={y} value={y}>
              {y}
            </option>
          ))}
        </select>
      </Flex>
      <IconButton
        size="sm"
        color="success"
        aria-label="Next Month"
        onClick={(e) => {
          // prevent odd error where this is considered a submit
          e.preventDefault();
          increaseMonth();
        }}
        icon={<ChevronLeftIcon />}
      />
    </Flex>
  );
};
