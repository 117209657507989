import { SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { MultiSelect } from '../../components/form/MultiSelect';
import { StyledInput } from '../../components/form/StyledInput';
import { Page } from '../../components/Page';
import { SquareIconButton } from '../../components/SquareIconButton';
import { StatusDropDown, StatusTypes } from '../../components/StatusDropDown';
import { StyledTooltip } from '../../components/StyledTooltip';
import { Table } from '../../components/Table';
import { TableActions } from '../../components/TableActions';
import { Customer, CustomerStatus, CustomerStatuses } from '../../models/Customer';
import { EmptyBlurb } from '../common/errors/EmptyBlurb';
import { EmptyState } from '../common/errors/EmptyState';
import { CustomerIcon, NoTasksIcon } from '../common/icons';
import { DeleteCustomerDialog } from '../customer/DeleteCustomerDialog';

import { LeadsViewModel } from './LeadsViewModel';

export const Leads = observer(({ viewModel }: { viewModel: LeadsViewModel }) => {
  const [leadToDelete, setLeadToDelete] = useState<Customer | null>(null);

  const columns = [
    {
      key: 'name',
      label: 'שם',
      render: (lead: Customer) => (
        <Box maxWidth="250px">
          <Text noOfLines={1} display="block" whiteSpace="nowrap" title={lead.name}>
            {lead.name}
          </Text>
        </Box>
      ),
    },
    {
      key: 'contact_name',
      label: 'איש קשר',
      render: (lead: Customer) => lead.contact_name,
    },
    {
      key: 'email',
      label: 'מייל',
      render: (lead: Customer) => lead.email,
    },
    {
      key: 'phone',
      label: 'טלפון',
      render: (lead: Customer) => lead.phone || '-',
    },
    {
      key: 'source',
      label: 'מקור',
      render: (lead: Customer) => lead.source || '-',
    },
    {
      key: 'status',
      label: 'שלב',
      render: (lead: Customer) => {
        return (
          <Box minW="180px" maxW="180px" onClick={(e) => e.stopPropagation()}>
            <StatusDropDown
              type={StatusTypes.CUSTOMER}
              value={lead.status || CustomerStatus.NEW_LEAD}
              onChange={(value) => viewModel.changeStatus(lead._id, value)}
            />
          </Box>
        );
      },
      padding: '8px 24px !important',
    },
  ];

  if (!viewModel.leadsExist) {
    return (
      <EmptyState
        title="ניהול לידים"
        body="כאן אפשר לנהל את השלבים השונים של הלידים שלך עד לרגע שבו הם הופכים ללקוחות. מומלץ ליצור ליד חדש כדי לראות איך זה עובד"
        onClick={viewModel.createLead}
        buttonText="+ יצירת ליד"
        imageSrc="/no-leads.svg"
      />
    );
  }

  return (
    <Page title="לידים">
      <Flex mb="16px" justifyContent="space-between">
        <Box minW="144px" maxW="144px">
          <MultiSelect
            placeholder="שלב"
            data={CustomerStatuses.filter((s) => s.value !== CustomerStatus.CLOSED)}
            selectedItems={viewModel.selectedStatuses}
            onChange={(items) => viewModel.setSelectedStatuses(items)}
            size="sm"
            removeSearch
            enableAll
          />
        </Box>
        <StyledInput
          name="search"
          w="314px"
          type="text"
          placeholder="חיפוש ליד"
          value={viewModel.filter}
          onChange={(e) => viewModel.setFilter(e.target.value)}
          endIcon={<SearchIcon />}
          size="sm"
        />
      </Flex>
      {viewModel.leads.length ? (
        <Table
          columns={columns}
          data={viewModel.leads}
          actions={{
            width: '176px',
            render: (lead: any) => (
              <TableActions onDelete={() => setLeadToDelete(lead)} onEdit={() => viewModel.editLead(lead._id)}>
                <StyledTooltip label="שמירת הליד כלקוח">
                  <SquareIconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      viewModel.changeStatus(lead._id, CustomerStatus.CLOSED);
                    }}
                    variant="ghost"
                    size="sm"
                    icon={<CustomerIcon />}
                  />
                </StyledTooltip>
              </TableActions>
            ),
          }}
        />
      ) : (
        <Flex justifyContent="center" alignItems="center" direction="column" textAlign="center" mt="95px">
          <EmptyBlurb
            title="אין לך לידים עם השם הזה"
            body="כדאי לשנות לטקסט אחר"
            icon={<NoTasksIcon fontSize="72px" mb="16px" />}
            button={{
              label: 'איפוס',
              onClick: () => viewModel.setFilter(''),
            }}
          />
        </Flex>
      )}
      {leadToDelete && (
        <DeleteCustomerDialog
          customer={leadToDelete}
          customerProjects={[]}
          onSubmit={() => viewModel.deleteLead(leadToDelete._id)}
          onClose={() => setLeadToDelete(null)}
          isLead
        />
      )}
    </Page>
  );
});
