import { format } from 'date-fns';

import { fetcher } from '../../requests/fetcher';

import { IValidUserDataValues } from './userDataFormUtils';

const baseUrl = import.meta.env.REACT_APP_API_URL;
export interface IUserDataApi {
  id: string;
  name: string;
  email: string;
  email_verified: boolean;
  company_name: string;
  industry: string;
  number_of_coworkers: string | null;
  number_of_years: string | null;
  business_type: string | null;
  base_rate: number;
  services: string[];
  yearly_goal: number;
  phone: string | null;
  birthdate: string | null;
  created_at: string;
  include_vat: boolean;
  updated_at: string;
  payment_state: 'PAYING' | 'PAYMENT_FAILED' | 'TRIAL' | 'BETA';
  payment_token?: string;
  payment_4_digits?: string;
  payplus_user_id?: string;
  recurring_transaction_id?: string;
  payment_start_date?: string;
  payment_next_date?: string;
}

export async function getUserData(): Promise<IUserDataApi> {
  return fetcher(`${baseUrl}/users/user-data`, '', 'GET');
}

export async function createUserData(userData: IValidUserDataValues) {
  const _userData = {
    company_name: userData.companyName,
    industry: userData.industry,
    number_of_coworkers: userData.numberOfCoWorkers,
    number_of_years: userData.numberOfYears,
    business_type: userData.businessType,
    base_rate: userData.baseRate,
    services: userData.services,
    yearly_goal: userData.yearlyGoal,
    phone: userData.phone,
    birthdate: userData.birthDate ? format(userData.birthDate, 'yyyy-MM-dd') : undefined,
    include_vat: false,
  };

  return fetcher(`${baseUrl}/users/user-data`, JSON.stringify(_userData));
}

export async function updateUserData(userData: IValidUserDataValues) {
  const _userData = {
    email: userData.email,
    company_name: userData.companyName,
    industry: userData.industry,
    number_of_coworkers: userData.numberOfCoWorkers,
    number_of_years: userData.numberOfYears,
    business_type: userData.businessType,
    base_rate: userData.baseRate,
    services: userData.services,
    yearly_goal: userData.yearlyGoal,
    phone: userData.phone,
    birthdate: userData.birthDate ? format(userData.birthDate, 'yyyy-MM-dd') : null,
    include_vat: userData.includeVat,
    payment_state: userData.paymentState,
  };

  return fetcher(`${baseUrl}/users/user-data`, JSON.stringify(_userData), 'PATCH');
}
