import { Flex, Grid, Button, Heading, Box } from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import { Card } from '../../components/Card';
import { FormBigNumberInput } from '../../components/form/FormBigNumberInput';
import { FormButtonTabs } from '../../components/form/FormButtonTabs';
import { FormInput } from '../../components/form/FormInput';
import { FormSelect } from '../../components/form/FormSelect';
import { FormMultiValueInput } from '../../components/form/MultiValueInput';
import {
  getUserDataInitialValues,
  industryOptions,
  IValidUserDataValues,
  validateUserDataValues,
} from '../../models/userData/userDataFormUtils';
import { BUSINESS_TYPE, IUserData } from '../../models/userData/useUserData';
import { ShekelIcon } from '../common/icons';

export const ProfessionalDataSettings = ({
  userData,
  onSubmit,
}: {
  userData: IUserData;
  onSubmit: (values: IValidUserDataValues) => Promise<void>;
}) => {
  return (
    <Card>
      <Heading textStyle="h6" mb="65px" mt="10px" marginInlineStart="8px">
        פרטים מקצועיים
      </Heading>
      <Formik
        initialValues={getUserDataInitialValues(userData)}
        validate={(values) => {
          return validateUserDataValues(values, 'settings');
        }}
        onSubmit={async (values) => {
          await onSubmit(values as IValidUserDataValues);
        }}
      >
        {(props) => (
          <Form
            onKeyPress={(e) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          >
            <Grid
              columnGap="160px"
              templateColumns="repeat(2, minmax(100px, 50%))"
              paddingInlineEnd="88px"
              rowGap="50px"
              marginInlineStart="8px"
            >
              <FormInput
                isRequired
                name="baseRate"
                label="תעריף שעתי"
                type="number"
                step="0.01"
                endIcon={<ShekelIcon color="base.500" w="14px" />}
                helperText="ישמש כברירת מחדל ביצירת פרויקט חדש"
              />
              <FormBigNumberInput name="yearlyGoal" label="יעד הכנסה שנתי" />
              <FormSelect isRequired name="industry" label="תחום עיסוק" data={industryOptions} />
              <Flex justifyContent="space-between" gap="16px">
                <FormButtonTabs
                  name="businessType"
                  label="סוג העסק"
                  options={[
                    { label: 'פטור', value: BUSINESS_TYPE.OSEK },
                    { label: 'מורשה', value: BUSINESS_TYPE.MURSHE },
                    { label: 'חברה', value: BUSINESS_TYPE.COMPANY },
                  ]}
                  alignment="column"
                />
                <FormButtonTabs
                  name="includeVat"
                  label="הצגת סכומים"
                  options={[
                    { label: 'כולל מע״מ', value: 'true' },
                    { label: 'ללא מע״מ', value: 'false' },
                  ]}
                  alignment="column"
                />
              </Flex>
              <Box gridColumn="1 / span 2">
                <FormMultiValueInput
                  name="services"
                  label="סוגי שירותים"
                  helperText="הסרת שירות לא תשפיע על פרויקטים קיימים"
                />
              </Box>
              <Button
                mb="30px"
                gridColumn="1 / span 2"
                justifySelf="end"
                isLoading={props.isSubmitting}
                type="submit"
                variant="secondary"
                isDisabled={!props.isValid}
              >
                עדכון
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Card>
  );
};
