import { Box, Flex, Text, Grid } from '@chakra-ui/react';
import { observer } from 'mobx-react';

import { FormBigNumberInput } from '../../../components/form/FormBigNumberInput';
import { FormDatePicker } from '../../../components/form/FormDatePicker';
import { FormInput } from '../../../components/form/FormInput';
import { SquareIconButton } from '../../../components/SquareIconButton';
import { Addition } from '../../../models/Addition';
import { DataPoint } from '../../common/DataPoint';
import { CrossIcon, ShekelIcon } from '../../common/icons';

export const PaymentBox = observer(
  ({
    index,
    onDismiss,
    projectStartDate,
    additions,
    paymentDate,
  }: {
    index: number;
    onDismiss?: () => void;
    projectStartDate?: Date;
    additions?: Addition[];
    paymentDate: string;
  }) => {
    return (
      <Box
        position="relative"
        bg="white"
        border="1px solid"
        borderColor="base.200"
        borderRadius="18px"
        _before={{
          content: "''",
          display: 'block',
          position: 'absolute',
          top: '-26px',
          right: '60px',
          height: '26px',
          width: '2px',
          backgroundColor: 'base.200',
        }}
        _after={{
          content: "''",
          display: 'block',
          position: 'absolute',
          bottom: '-62px',
          right: '60px',
          height: '62px',
          width: '2px',
          backgroundColor: 'base.200',
        }}
      >
        <Flex alignItems="center" position="absolute" top="-38px" right="55px">
          <Box
            height="12px"
            width="12px"
            border="2px solid"
            borderColor="base.200"
            borderRadius="full"
            bg="white"
            marginInlineEnd="18px"
          />
          <Text fontSize="lg">תשלום מס׳ {index + 1}</Text>
        </Flex>
        {onDismiss && (
          <SquareIconButton
            position="absolute"
            top="18px"
            left="16px"
            right={'initial'}
            onClick={(e) => {
              onDismiss();
            }}
            variant="ghost"
            size="sm"
            icon={<CrossIcon />}
          />
        )}
        <Grid
          columnGap="160px"
          rowGap="38px"
          templateColumns="1fr 1fr"
          p="55px"
          paddingBottom="25px"
          paddingInlineEnd="112px"
          alignItems="center"
        >
          <FormBigNumberInput
            name={`payments.${index}.sum`}
            helperText="ללא מע״מ"
            label="גובה התשלום"
            isRequired
            step="0.01"
            endIcon={<ShekelIcon w="14px" color="base.500" />}
          />
          <FormDatePicker
            name={`payments.${index}.submitDate`}
            label="מועד התשלום"
            minimumDate={projectStartDate}
            isRequired
          />
          <Box gridColumn="1 / -1">
            <FormInput
              name={`payments.${index}.comment`}
              label="תיאור"
              type="textarea"
              rows={3}
              helperText="לדוג’: מעמד חתימה על הסכם"
            />
          </Box>
        </Grid>
        {additions && additions.length > 0 && (
          <Flex
            borderTop="1px solid"
            borderColor="base.200"
            bg="base.25"
            px="48px"
            py="34px"
            direction="column"
            gap="18px"
          >
            <Text fontSize="lg">תוספות</Text>
            {additions.map((addition, i) => (
              <Grid
                key={i}
                columnGap="38px"
                templateColumns="80px 120px 1fr 100px 120px"
                p="20px"
                paddingInlineEnd="120px"
                alignItems="center"
                bg="white"
                border="1px solid"
                borderColor="#E6E9F2"
                borderRadius="18px"
              >
                <DataPoint label="אושר ע״י" value={addition.approvedBy} />
                <DataPoint label="תאריך" value={addition.displayDate} />
                <DataPoint label="תיאור" value={addition.comment || ''} />
                <DataPoint label="סכום" value={`${addition.value} ₪`} />
                <DataPoint label="משויך לתשלום" value={paymentDate} />
              </Grid>
            ))}
          </Flex>
        )}
      </Box>
    );
  },
);
