import { Box, Grid } from '@chakra-ui/react';

import { FormInput } from '../../components/form/FormInput';
import { FormSelect } from '../../components/form/FormSelect';

export const CustomerFormInner = ({
  prefix,
  isGrid = true,
  size = 'lg',
}: {
  prefix?: string;
  isGrid?: boolean;
  size?: 'sm' | 'lg';
}) => {
  const sources = [
    {
      key: 'אתר עסקי',
      value: 'אתר עסקי',
    },
    {
      key: 'גוגל',
      value: 'גוגל',
    },
    {
      key: 'פייסבוק',
      value: 'פייסבוק',
    },
    {
      key: 'אינסטגרם',
      value: 'אינסטגרם',
    },
    {
      key: 'הפניה',
      value: 'הפניה',
    },
    {
      key: 'אחר',
      value: 'אחר',
    },
  ];
  return (
    <Grid columnGap="160px" templateColumns={isGrid ? '1fr 1fr' : '1fr'} rowGap="16px">
      <FormInput size={size} isRequired name={prefix ? `${prefix}.name` : 'name'} label="שם מלא" />
      <FormInput size={size} name={prefix ? `${prefix}.phone` : 'phone'} type="tel" label="טלפון" />
      <FormInput size={size} name={prefix ? `${prefix}.email` : 'email'} type="email" label="מייל" />
      <FormSelect
        size={size}
        name={prefix ? `${prefix}.source` : 'source'}
        label="מקור"
        data={sources}
        helperText="מאיפה הגיע הלקוח"
      />
    </Grid>
  );
};
