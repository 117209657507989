import { Box, Button, Grid, Flex, Text } from '@chakra-ui/react';
import { add, format } from 'date-fns';
import TagManager from 'react-gtm-module';
import { useEffectOnce } from 'usehooks-ts';

export const SubscriptionExplanation = ({ onClick }: { onClick: () => void }) => {
  useEffectOnce(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'payment_info',
      },
    });
  });
  const now = new Date();
  const today = format(now, 'dd.MM.yyyy');
  const oneMonth = format(add(now, { months: 1 }), 'dd.MM.yyyy');
  return (
    <Box mt="26px" mx="38px" data-testid="SubscriptionExplanation">
      <Grid gridTemplateColumns="1fr 80px 65px" gridColumnGap="20px">
        <Text gridColumn="1 / span 2" paddingInlineStart="12px" textStyle="medium" color="base.600">
          מוצר
        </Text>
        <Text color="base.600">מחיר</Text>
        <Box gridColumn="1 / -1" borderBottom="1px solid" borderColor="base.200" mb="16px" />
        <Text gridColumn="1 / span 2" paddingInlineStart="12px" textStyle="large">
          מינוי ‘פרילנס מוביל’
          <br />
          <Box as="span" textStyle="small" color="base.600">
            לתקופה {today} - {oneMonth}
          </Box>
        </Text>
        <Text textStyle="large">29 ₪</Text>
        <Box gridColumn="1 / -1" h="60px" />
        <Text gridColumn="1 / span 2" textAlign="end" textStyle="large">
          מע”מ
        </Text>
        <Text textStyle="large">4.93 ₪</Text>
        <Box gridColumn="1 / -1" borderBottom="1px solid" borderColor="base.200" my="8px" />
        <Text gridColumn="1 / span 2" textAlign="end" textStyle="large">
          סה”כ לתשלום
        </Text>
        <Text textStyle="large">33.93 ₪</Text>
      </Grid>
      <Text mt="40px" color="base.600" textStyle="small">
        עם אישור התשלום, פרטי האשראי יישמרו בחשבון שלך לחיובים הבאים
      </Text>
      <Text color="base.600" textStyle="small">
        החיוב החודשי יתחדש באופן אוטומטי אך ניתן לבטל אותו מתוך אזור ההגדרות
      </Text>
      <Flex justifyContent="end" mt="36px" mb="30px">
        <Button w="194px" onClick={onClick} data-testid="SubscriptionExplanation.next">
          המשך לתשלום
        </Button>
      </Flex>
    </Box>
  );
};
