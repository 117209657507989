import { switchAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { calc, cssVar } from '@chakra-ui/theme-tools';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');
const $diff = cssVar('switch-track-diff');
const diffValue = calc.subtract($width, $height);
const $translateX = cssVar('switch-thumb-x');

const baseStyleTrack = defineStyle((props) => {
  return {
    borderRadius: 'full',
    p: '2px',
    width: '32px',
    height: '16px',
    transitionProperty: 'common',
    transitionDuration: 'fast',
    border: '1px solid',
    borderColor: 'transparent',
    bg: 'base.100',
    _focus: {
      boxShadow: 'none',
    },
    _checked: {
      bg: 'success.100',
    },
    _hover: {
      bg: 'base.50',
      _checked: {
        bg: 'success.200',
      },
    },
    _disabled: {
      cursor: 'not-allowed',
      bg: 'white',
      border: '1px solid',
      borderColor: 'base.200',
    },
  };
});

const baseStyleThumb = defineStyle({
  bg: 'white',
  filter: 'drop-shadow(0px 1px 2px rgba(164, 172, 185, 0.40))',
  transitionProperty: 'transform',
  transitionDuration: 'normal',
  borderRadius: 'inherit',
  width: '16px',
  height: '16px',
  transform: 'translateX(-15px)',
  _checked: {
    transform: 'translateX(-1px)',
    filter: 'drop-shadow(0px 1px 2px rgba(0, 92, 78, 0.40))',
  },
  _disabled: {
    bg: 'base.400',
    filter: 'none',
  },
});

const baseStyle = definePartsStyle((props) => ({
  container: {
    [$diff.variable]: diffValue,
    [$translateX.variable]: $diff.reference,
    _rtl: {
      [$translateX.variable]: calc($diff).negate().toString(),
    },
  },
  track: baseStyleTrack(props),
  thumb: baseStyleThumb,
}));

const sizes = {
  sm: definePartsStyle({
    container: {
      [$width.variable]: '1.375rem',
      [$height.variable]: '0.75rem',
    },
  }),
  md: definePartsStyle({
    container: {
      [$width.variable]: '1.875rem',
      [$height.variable]: '1rem',
    },
  }),
  lg: definePartsStyle({
    container: {
      [$width.variable]: '2.875rem',
      [$height.variable]: '1.5rem',
    },
  }),
};

export const Switch = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
  },
});
