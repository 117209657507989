import { Flex, Text, Button, Box } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { Dialog } from '../../components/Dialog';
import { Customer } from '../../models/Customer';
import { Project } from '../../models/Project';
import { WarningIcon } from '../common/icons/WarningIcon';

export const DeleteCustomerDialog = observer(
  ({
    customer,
    customerProjects,
    onClose,
    onSubmit,
    isLead = false,
  }: {
    customer: Customer;
    customerProjects: Project[];
    onClose: () => void;
    onSubmit: () => Promise<void>;
    isLead?: boolean;
  }) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
      setLoading(true);
      await onSubmit();
      onClose();
    };

    const canDelete = customerProjects.length === 0;
    return (
      <Dialog title={isLead ? 'מחיקת ליד' : 'מחיקת לקוח'} onClose={onClose}>
        {canDelete ? (
          <>
            <Flex marginBottom="26px" width="100%" flexDirection="column" alignItems="center">
              <Text textStyle="medium" color="base.600" mb="4px">
                האם למחוק את:
              </Text>
              <Text textStyle="medium" color="base.1000">
                '{customer.name}'?
              </Text>
            </Flex>
            <Flex
              position="relative"
              p="24px"
              pt="32px"
              mx="32px"
              background="base.25"
              borderRadius="12px"
              textAlign="center"
              justifyContent="center"
            >
              <WarningIcon
                color="error.100"
                position="absolute"
                top="-12px"
                left="50%"
                transform="translateX(-50%)"
                w="26px"
                h="26px"
              />
              <Text textStyle="medium" color="base.1000" maxW="230px">
                <Text as="span" textDecoration="underline">
                  חשוב:
                </Text>{' '}
                לא ניתן לשחזר את פרטי {isLead ? 'הליד' : 'הלקוח'} לאחר המחיקה.
              </Text>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center" p="32px">
              <Button size="sm" variant="secondary" alignSelf="start" onClick={onClose}>
                ביטול
              </Button>
              <Button size="sm" isLoading={loading} colorScheme="purple" onClick={handleSubmit}>
                מחיקה
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <Flex
              position="relative"
              p="24px"
              pt="32px"
              mx="32px"
              background="base.25"
              borderRadius="12px"
              textAlign="center"
              gap="6px"
              mb="16px"
            >
              <WarningIcon
                color="error.100"
                position="absolute"
                top="-12px"
                left="50%"
                transform="translateX(-50%)"
                w="26px"
                h="26px"
              />
              <Flex width="100%" flexDirection="column" alignItems="center" gap="6px">
                <Text textStyle="medium" color="base.1000">
                  לא ניתן למחוק את הלקוח
                </Text>
                <Text textStyle="extraLarge" color="base.600">
                  '{customer.name}'
                </Text>
                <Text textStyle="medium" color="base.1000" mb="10px">
                  מכיוון שהוא משויך לפרויקט/ים הבא/ים:
                </Text>
                <Box maxH="130px" overflow="auto" w="100%">
                  {customerProjects.map((p) => (
                    <Box
                      bg="base.100"
                      borderRadius="10px"
                      p="8px"
                      paddingInlineStart="12px"
                      mb="5px"
                      marginInlineEnd="8px"
                    >
                      <Text textStyle="small" color="base.1000" textAlign="start">
                        {p.name}
                      </Text>
                    </Box>
                  ))}
                </Box>
              </Flex>
            </Flex>
            <Text textStyle="medium" color="base.1000" textAlign="center" mx="53px">
              מחיקת הלקוח תתאפשר רק לאחר מחיקת הפרויקטים המשויכים אליו
            </Text>
            <Flex justifyContent="center" alignItems="center" p="32px">
              <Button size="sm" isLoading={loading} onClick={onClose}>
                הבנתי, תודה
              </Button>
            </Flex>
          </>
        )}
      </Dialog>
    );
  },
);
