import { CloseIcon } from '@chakra-ui/icons';
import {
  Flex,
  Box,
  Button,
  Text,
  Popover,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
  PopoverAnchor,
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';

import { ButtonTabs } from '../../../components/ButtonTabs';
import { NFI_KEY, useNfi } from '../../../hooks/nfi/useNfi';
import { useWorkHours } from '../../../models/workHours/useWorkHours';
import { StarIcon } from '../../common/icons';

import { AutoTimeTracker } from './AutoTimeTracker';
import { CreateWorkHourViewModel, REPORTING_TYPE } from './CreateWorkHourViewModel';
import { ManualTimeTracker } from './ManualTimeTracker';

export const TimeTracker = observer(({ viewModel }: { viewModel?: CreateWorkHourViewModel }) => {
  const { partialWorkHour } = useWorkHours();
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  const initialFocusRef = useRef(null);

  const { showNfi, setNfi } = useNfi(NFI_KEY.REPORT_WORK_HOUR_WITHOUT_PROJECT);

  useEffect(() => {
    if (partialWorkHour && partialWorkHour._id !== selectedProject?._id) {
      viewModel?.setSelectedProject(partialWorkHour.project._id);
    }
    if (partialWorkHour && timerType !== REPORTING_TYPE.AUTOMATIC) {
      // set tracker to automatic if partial wh exists
      viewModel?.handleToggleState();
    }
  }, [partialWorkHour?._id]);

  if (!viewModel) {
    return null;
  }

  const { timerType, selectedProject } = viewModel;

  const handleToggleState = () => {
    if (timerType === REPORTING_TYPE.AUTOMATIC && selectedProject && partialWorkHour) {
      return;
    }
    viewModel.handleToggleState();
  };

  const reportingTypeItems = [
    {
      label: 'אוטומטי',
      id: REPORTING_TYPE.AUTOMATIC,
    },
    {
      label: 'ידני',
      id: REPORTING_TYPE.MANUAL,
    },
  ];

  const isRecordingActive = Boolean(partialWorkHour);

  return (
    <Popover isOpen={showNfi} initialFocusRef={initialFocusRef} placement="bottom" offset={[0, 12]} arrowSize={14}>
      <PopoverAnchor>
        <Flex
          align="center"
          border="1px solid"
          borderColor="#DEE0EF"
          borderRadius="14px"
          backgroundColor="white"
          py="4px"
          paddingInlineStart={
            (viewModel.isProjectSelected || isRecordingActive) && timerType === REPORTING_TYPE.AUTOMATIC
              ? '16px'
              : '10px'
          }
          paddingInlineEnd="7px"
          marginInlineStart="27px"
          height="56px"
          width="550px"
          style={{ position: 'relative' }}
        >
          {(viewModel.isProjectSelected || isRecordingActive) && timerType === REPORTING_TYPE.AUTOMATIC ? (
            <Button
              position="relative"
              backgroundColor="base.100"
              _hover={{
                backgroundColor: `${isRecordingActive ? 'base.100' : 'base.200'}`,
              }}
              minW="137px"
              h="30px"
              borderRadius="8px"
              fontSize="lg"
              px="0"
              color="base.1000"
              cursor="pointer"
              onClick={handleToggleState}
              fontWeight="normal"
              onMouseEnter={() => setIsButtonHovered(true)}
              onMouseLeave={() => setIsButtonHovered(false)}
            >
              <Text maxW="90px" noOfLines={1} display="block" whiteSpace="nowrap" title={selectedProject?.name}>
                {selectedProject?.name || 'ללא פרויקט'}
              </Text>
              {viewModel.isProjectSelected && isButtonHovered && !isRecordingActive && (
                <CloseIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    viewModel.setSelectedProject(undefined);
                  }}
                  cursor="pointer"
                  position="absolute"
                  bg="transparent"
                  color="base.900"
                  h="18px"
                  w="18px"
                  p="3px"
                  borderRadius="50%"
                  left="8px"
                />
              )}
            </Button>
          ) : (
            <Box minW="170px">
              <ButtonTabs items={reportingTypeItems} selectedId={timerType} handleClick={handleToggleState} />
            </Box>
          )}
          {timerType === REPORTING_TYPE.AUTOMATIC ? (
            <AutoTimeTracker
              allProjects={viewModel.allProjects}
              selectedProject={selectedProject}
              isProjectSelected={viewModel.isProjectSelected}
              setSelectedProject={viewModel.setSelectedProject}
              saveWorkHour={viewModel.saveWorkHour}
            />
          ) : (
            <ManualTimeTracker viewModel={viewModel} />
          )}
        </Flex>
      </PopoverAnchor>
      <Portal>
        <PopoverContent
          border="none"
          w="326px"
          p="10px"
          borderRadius="16px"
          bg="primary.300"
          boxShadow="0px 4px 12px 0px rgba(22, 8, 37, 0.20)"
        >
          <PopoverArrow bg="primary.300" />
          <PopoverBody p="0" color="primary.0">
            <Box p="34px 16px 16px" position="relative">
              <StarIcon
                color="accent.100"
                w="20px"
                h="20px"
                position="absolute"
                top="14px"
                left="17px"
                transform="rotate(70deg);"
              />
              <StarIcon
                color="accent.0"
                w="8px"
                h="8px"
                position="absolute"
                top="33px"
                left="37px"
                transform="rotate(70deg);"
              />
              <Text mb="8px" textStyle="large">
                חדש!
              </Text>
              <Text textStyle="doubleLarge" mb="8px">
                מעקב שעות ללא פרויקט
              </Text>
              <Text color="primary.50" mb="30px" textStyle="large">
                לפעמים חשוב להשקיע זמן בדברים אחרים שתורמים לעסק אבל לא קשורים לפרויקט מסוים. מעכשיו, גם אותם אפשר
                למדוד!
              </Text>
              <Flex direction="row-reverse">
                <Button
                  variant="secondary"
                  color="white"
                  borderColor="primary.25"
                  _hover={{
                    color: 'base.800',
                    background: 'primary.0',
                  }}
                  size="sm"
                  onClick={() => setNfi()}
                >
                  מעולה, תודה
                </Button>
              </Flex>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
});
