import { Flex, Box, Divider, Spacer, Switch, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { DateRangePicker } from '../../../components/DateRangePicker';
import { MultiSelect } from '../../../components/form/MultiSelect';
import { SelectItem } from '../../../components/form/Select';
import { SquareIconButton } from '../../../components/SquareIconButton';
import { StyledTooltip } from '../../../components/StyledTooltip';
import { DownloadIcon } from '../../common/icons';
import { WorkHoursReportDialog } from '../WorkHoursReportDialog';
import { EnrichedWorkHour } from '../WorkHoursViewModel';

interface IWorkHourFiltersProps {
  allProjects: Array<SelectItem>;
  selectedProjects: Array<SelectItem>;
  onProjectSelectionChange(p: SelectItem[]): void;
  allCustomers: Array<SelectItem>;
  selectedCustomers: Array<SelectItem>;
  onCustomerSelectionChange(c: SelectItem[]): void;
  selectedStartTime: Date | null;
  selectedEndTime: Date | null;
  onDateSelect(s: Date | null, e: Date | null): void;
  isGraphOpen: boolean;
  toggleGraph: () => void;
  currentWorkHours: EnrichedWorkHour[];
}

export const WorkHourFilters = observer(
  ({
    allProjects,
    selectedProjects,
    onProjectSelectionChange,
    allCustomers,
    selectedCustomers,
    onCustomerSelectionChange,
    selectedStartTime,
    selectedEndTime,
    onDateSelect,
    isGraphOpen,
    toggleGraph,
    currentWorkHours,
  }: IWorkHourFiltersProps) => {
    const [isDownloadReportOpen, setIsDownloadReportOpen] = useState(false);
    return (
      <>
        <Flex
          alignItems="center"
          background="white"
          border="1px solid"
          borderColor="base.200"
          borderRadius="18px"
          p="20px"
          gap={5}
          id="ignore-me"
        >
          <Box>
            <DateRangePicker
              selectedStartTime={selectedStartTime}
              selectedEndTime={selectedEndTime}
              onDateSelect={onDateSelect}
              size="sm"
            />
          </Box>
          <Divider orientation="vertical" borderColor="base.200" height="50px" />
          <Box minW="230px" width="230px">
            <MultiSelect
              placeholder="פרויקטים"
              data={allProjects}
              selectedItems={selectedProjects}
              onChange={(items) => onProjectSelectionChange(items as any)}
              enableAll
              menuWidth="300px"
              size="sm"
            />
          </Box>
          <Box minW="230px" width="230px">
            <MultiSelect
              placeholder="לקוחות"
              data={allCustomers}
              selectedItems={selectedCustomers}
              onChange={(items) => onCustomerSelectionChange(items as any)}
              enableAll
              menuWidth="300px"
              size="sm"
            />
          </Box>
          <Spacer />
          <Flex w="105px" alignItems="center" gap="10px" marginInlineEnd="16px">
            <Switch id="graph" isChecked={isGraphOpen} onChange={() => toggleGraph()} />
            <Text as="label" textStyle="large" margin="0" htmlFor="graph">
              הצג גרף
            </Text>
          </Flex>
          <StyledTooltip label="הורדת דוח שעות">
            <SquareIconButton
              aria-label="download"
              variant="ghost"
              size="sm"
              onClick={() => setIsDownloadReportOpen(true)}
              icon={<DownloadIcon />}
            />
          </StyledTooltip>
        </Flex>
        {isDownloadReportOpen && (
          <WorkHoursReportDialog currentWorkHours={currentWorkHours} onDismiss={() => setIsDownloadReportOpen(false)} />
        )}
      </>
    );
  },
);
