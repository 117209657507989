import { Icon, IconProps } from '@chakra-ui/react';

export const TasksIcon = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 25 24">
      <g clipPath="url(#clip0_7416_15352)">
        <path
          d="M19.4167 0H5.41675C4.09115 0.00158786 2.82031 0.528882 1.88297 1.46622C0.94563 2.40356 0.418336 3.67441 0.416748 5L0.416748 19C0.418336 20.3256 0.94563 21.5964 1.88297 22.5338C2.82031 23.4711 4.09115 23.9984 5.41675 24H19.4167C20.7423 23.9984 22.0132 23.4711 22.9505 22.5338C23.8879 21.5964 24.4152 20.3256 24.4167 19V5C24.4152 3.67441 23.8879 2.40356 22.9505 1.46622C22.0132 0.528882 20.7423 0.00158786 19.4167 0ZM22.4167 19C22.4167 19.7956 22.1007 20.5587 21.5381 21.1213C20.9755 21.6839 20.2124 22 19.4167 22H5.41675C4.6211 22 3.85804 21.6839 3.29543 21.1213C2.73282 20.5587 2.41675 19.7956 2.41675 19V5C2.41675 4.20435 2.73282 3.44129 3.29543 2.87868C3.85804 2.31607 4.6211 2 5.41675 2H19.4167C20.2124 2 20.9755 2.31607 21.5381 2.87868C22.1007 3.44129 22.4167 4.20435 22.4167 5V19Z"
          fill="currentColor"
        />
        <path
          d="M10.3515 14.3829L7.32019 11.3515C7.17514 11.2065 6.97843 11.1251 6.77333 11.1251C6.56823 11.1251 6.37152 11.2065 6.22647 11.3515C6.08146 11.4966 6 11.6933 6 11.8984C6 12.1035 6.08146 12.3002 6.22647 12.4452L9.2578 15.4766C9.40146 15.6203 9.57203 15.7343 9.75976 15.8121C9.94749 15.8899 10.1487 15.9299 10.3519 15.9299C10.5551 15.9299 10.7563 15.8899 10.9441 15.8121C11.1318 15.7343 11.3024 15.6203 11.446 15.4766L18.6024 8.32019C18.7474 8.17514 18.8289 7.97843 18.8289 7.77333C18.8289 7.56823 18.7474 7.37152 18.6024 7.22647C18.4573 7.08146 18.2606 7 18.0555 7C17.8504 7 17.6537 7.08146 17.5087 7.22647L10.3515 14.3829Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_7416_15352">
          <rect width="24" height="24" fill="white" transform="translate(0.416748)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
