import { Icon, IconProps } from '@chakra-ui/react';

export const SearchIcon = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <g id="General Icon/ search" clipPath="url(#clip0_7712_236)">
        <path
          id="Vector"
          d="M15.8048 14.8619L11.8254 10.8825C12.9098 9.55625 13.443 7.86392 13.3147 6.15556C13.1863 4.44721 12.4062 2.85354 11.1358 1.7042C9.8654 0.554862 8.20183 -0.0622172 6.48919 -0.019396C4.77656 0.0234252 3.1459 0.72287 1.93451 1.93426C0.723114 3.14566 0.0236694 4.77632 -0.0191518 6.48895C-0.061973 8.20158 0.555106 9.86516 1.70445 11.1356C2.85379 12.406 4.44745 13.1861 6.15581 13.3144C7.86416 13.4428 9.5565 12.9096 10.8828 11.8252L14.8621 15.8045C14.9878 15.926 15.1562 15.9932 15.331 15.9916C15.5058 15.9901 15.673 15.92 15.7966 15.7964C15.9203 15.6728 15.9904 15.5056 15.9919 15.3308C15.9934 15.156 15.9262 14.9876 15.8048 14.8619ZM6.66677 11.9999C5.61193 11.9999 4.58079 11.6871 3.70372 11.101C2.82666 10.515 2.14308 9.68204 1.73941 8.7075C1.33574 7.73296 1.23012 6.66061 1.43591 5.62604C1.6417 4.59147 2.14965 3.64117 2.89553 2.89529C3.64141 2.1494 4.59172 1.64145 5.62628 1.43567C6.66085 1.22988 7.7332 1.3355 8.70774 1.73916C9.68228 2.14283 10.5152 2.82642 11.1013 3.70348C11.6873 4.58054 12.0001 5.61169 12.0001 6.66652C11.9985 8.08052 11.4361 9.43615 10.4362 10.436C9.4364 11.4359 8.08077 11.9983 6.66677 11.9999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_7712_236">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
