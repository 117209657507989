import { action, computed, makeObservable } from 'mobx';

import { Customer, CustomerStatus, CustomerStatuses } from '../../../models/Customer';
import { CustomerModel } from '../../../models/CustomerModel';
import { ICustomerValues } from '../../../models/customers/customerUtils';
import { IStore } from '../../../models/RootStore';
import { RoutingStore } from '../../../router/RoutingStore';

import { CustomersRoute, LeadsRoute, SingleCustomerRoute } from './../../../router/routes';

export class CreateCustomerViewModel implements IStore {
  private customersModel: CustomerModel;
  private routingStore: RoutingStore;

  baseCustomer: Customer | undefined;
  isDirty = false;
  isLead: boolean;
  initialStatus: CustomerStatus;

  constructor(
    customersModel: CustomerModel,
    routingStore: RoutingStore,
    customerId?: string,
    isLead = false,
    _initialStatus?: CustomerStatus,
  ) {
    this.customersModel = customersModel;
    this.routingStore = routingStore;

    const customer = customerId ? this.customersModel.getCustomerById(customerId) : undefined;
    this.baseCustomer = customer;

    this.isDirty = false;
    this.isLead = isLead;
    this.initialStatus = _initialStatus || (isLead ? CustomerStatus.NEW_LEAD : CustomerStatus.CLOSED);

    makeObservable(this, {
      createCustomer: action,
      setIsDirty: action,
      isEditMode: computed,
    });
  }

  activate() {}

  createCustomer = async (values: ICustomerValues) => {
    await this.customersModel.createCustomer(values);
    this.postCreateOrUpdate();
  };

  updateCustomer = async (values: ICustomerValues) => {
    await this.customersModel.updateCustomer(values, this.baseCustomer!._id);
    this.postCreateOrUpdate();
  };

  postCreateOrUpdate = () => {
    this.setIsDirty(false);

    if (this.isLead) {
      if (this.initialStatus === CustomerStatus.CLOSED) {
        this.routingStore.navigate(CustomersRoute);
      } else {
        this.routingStore.navigate(LeadsRoute);
      }
    } else {
      const nextRoute = this.isEditMode
        ? {
            ...SingleCustomerRoute,
            params: { customerId: this.baseCustomer?._id },
          }
        : CustomersRoute;
      this.routingStore.navigate(nextRoute);
    }
  };

  onCancel = () => {
    window.history.go(-1);
  };

  get isEditMode() {
    return Boolean(this.baseCustomer);
  }

  setIsDirty = (state: boolean) => {
    this.isDirty = state;
  };

  get existingCustomerNames() {
    return this.customersModel.customers.map((c) => c.name);
  }
}
