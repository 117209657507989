import { Flex, Link } from '@chakra-ui/react';

import { FacebookIcon, YoutubeIcon } from '../icons';
import { LinkedinIcon } from '../icons';

export const Footer = () => {
  return (
    <Flex
      w="100%"
      padding="24px 0"
      borderTop="1px solid #E6E9F2"
      justifyContent="center"
      alignItems="center"
      gap="30px"
    >
      <Link fontSize="sm" target="_blank" href="https://peko.co.il/privacy/">
        מדיניות פרטיות
      </Link>
      <Link fontSize="sm" target="_blank" href="https://peko.co.il/terms/">
        תנאי שימוש
      </Link>
      <Link target="_blank" href="https://www.youtube.com/@Peko-freelancers">
        <YoutubeIcon />
      </Link>
      <Link target="_blank" href="https://www.facebook.com/pekofreelancers">
        <FacebookIcon />
      </Link>
    </Flex>
  );
};
