import { Box, Collapse } from '@chakra-ui/react';
import { format, getMonth } from 'date-fns';
import { Bar } from 'react-chartjs-2';

import { IWorkHour } from '../../../models/workHours/useWorkHours';
import { getWorkHoursDuration2 } from '../../../models/workHourUtils';
import {
  MONTHS,
  decimalHoursToMinutesDisplay,
  getDaysInRange,
  getMonthsInRange,
  secondsToDecimalHours,
} from '../../../utils/time/timeUtils';
import { EnrichedWorkHour } from '../WorkHoursViewModel';

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    bar: {
      borderRadius: 4,
      maxBarThickness: 10,
      barThickness: 10,
    },
  },
  scales: {
    yAxis: {
      ticks: {
        color: '#160825',
        padding: 12,
        stepSize: 2,
        font: {
          family: 'Ploni',
          size: 16,
        },
      },
      border: {
        display: false,
      },
      grid: {
        color: '#D8DBED',
        drawBorder: false,
        drawTicks: false,
      },
      min: 0,
      suggestedMax: 10,
    },
    xAxis: {
      ticks: {
        color: '#160825',
        font: {
          family: 'Ploni',
          size: 14,
        },
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    tooltip: {
      backgroundColor: '#270C43',
      padding: 12,
      rtl: true,
      callbacks: {
        label: function (ctx: any) {
          if (ctx.parsed.y !== null) {
            return `סה״כ ${decimalHoursToMinutesDisplay(ctx.parsed.y)}`;
          }
          return ctx.dataset.label;
        },
      },
      titleFont: {
        family: 'Ploni',
        size: 16,
      },
      bodyFont: {
        family: 'Ploni',
        size: 16,
      },
    },
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
      labels: {
        font: {
          size: 13,
          family: '"Ploni",Helvetica,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        },
      },
    },
  },
};

export const WorkHoursBarChart = ({
  isOpen,
  startTime,
  endTime,
  workHours,
}: {
  isOpen: boolean;
  startTime: Date | null;
  endTime: Date | null;
  workHours: EnrichedWorkHour[];
}) => {
  const colorize = () => {
    return (ctx: any) => {
      const now = new Date();
      const today = format(now, 'd.M');
      const thisMonth = MONTHS[now.getMonth()];
      return data[ctx.index] && (data[ctx.index].label === today || data[ctx.index].label === thisMonth)
        ? '#2EDEBF'
        : '#A8F8EA';
    };
  };

  const baseGraphData = {
    labels: [],
    datasets: [
      {
        label: 'Dataset 1',
        yAxisID: 'yAxis',
        xAxisID: 'xAxis',
        data: [],
        backgroundColor: colorize(),
        hoverBackgroundColor: colorize(),
        barThickness: 20,
        maxBarThickness: 20,
      },
    ],
  };

  const calculateGraphData = () => {
    if (startTime && endTime) {
      const daysInRange = getDaysInRange(new Date(startTime), new Date(endTime));
      if (daysInRange.length > 31) {
        const monthsInRange = getMonthsInRange(new Date(startTime), new Date(endTime));

        const filteredWorkHoursByMonth = workHours.reduce<{ [s: string]: IWorkHour[] }>((acc, curr) => {
          const month = getMonth(new Date(curr.startTime));
          if (!acc[month]) {
            acc[month] = [];
          }
          acc[month].push(curr);
          return acc;
        }, {});

        // max display is 12 months
        return monthsInRange.slice(0, 12).map((date) => {
          return {
            label: MONTHS[date.getMonth()],
            data: getWorkHoursDuration2(filteredWorkHoursByMonth?.[getMonth(date)]?.map((wh) => wh)),
          };
        });
      }

      const filteredWorkHoursByDate = workHours.reduce<{ [s: string]: IWorkHour[] }>((acc, curr) => {
        const date = format(curr.startTime, 'yyyy-MM-dd');
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(curr);
        return acc;
      }, {});

      return daysInRange.slice(0, 31).map((date) => {
        return {
          label: format(date, 'd.M'),
          data: getWorkHoursDuration2(filteredWorkHoursByDate?.[format(date, 'yyyy-MM-dd')]?.map((wh) => wh)),
        };
      });
    }
    return [];
  };

  const data = calculateGraphData();
  const graphData = {
    ...baseGraphData,
    labels: data.map((item) => item.label),
    datasets: [
      {
        ...baseGraphData.datasets[0],
        data: data.map((item) => {
          const decimalHours = secondsToDecimalHours(item.data);
          if (!decimalHours || decimalHours === 0) {
            return decimalHours;
          }
          return decimalHours > 0.5 ? decimalHours : 0.2;
        }),
      },
    ],
  };

  return (
    <Collapse in={isOpen} animateOpacity>
      <Box my="30px" border="1px solid" borderColor="#E6E9F2" borderRadius="18px" bg="white" h="330px" p="30px">
        <Bar options={options} data={graphData} />
      </Box>
    </Collapse>
  );
};
