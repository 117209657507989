import { Box, Button, Flex, Grid, Heading, Stack, Text } from '@chakra-ui/react';
import { Form, Formik, Field, FieldAttributes } from 'formik';
import isEqual from 'lodash/isEqual';
import { useState } from 'react';

import { FormDatePicker } from '../../../components/form/FormDatePicker';
import { FormInput } from '../../../components/form/FormInput';
import { FormSelect } from '../../../components/form/FormSelect';
import { StatusDropDown, StatusTypes } from '../../../components/StatusDropDown';
import { CustomerStatus } from '../../../models/Customer';
import { getInitialCustomerValues, validateCustomerValues } from '../../../models/customers/customerUtils';
import { industryOptions } from '../../../models/userData/userDataFormUtils';
import { ShekelIcon } from '../../common/icons';
import { paymentCycles } from '../../projects/createProject/CreateHourlyContractForm';

import { CreateCustomerViewModel } from './CreateCustomerViewModel';

const sources = [
  {
    key: 'אתר עסקי',
    value: 'אתר עסקי',
  },
  {
    key: 'גוגל',
    value: 'גוגל',
  },
  {
    key: 'פייסבוק',
    value: 'פייסבוק',
  },
  {
    key: 'אינסטגרם',
    value: 'אינסטגרם',
  },
  {
    key: 'הפניה',
    value: 'הפניה',
  },
  {
    key: 'אחר',
    value: 'אחר',
  },
];

export const CreateCustomer = ({ viewModel }: { viewModel: CreateCustomerViewModel }) => {
  const [error, setError] = useState('');

  const initialValues = getInitialCustomerValues(viewModel.baseCustomer, viewModel.isLead, viewModel.initialStatus);

  const markAsDitry = (values: any) => {
    viewModel.setIsDirty(!isEqual(values, initialValues));
  };

  const getTitle = () => {
    const entityName = viewModel.isLead ? 'ליד' : 'לקוח';

    if (viewModel.isLead && viewModel.initialStatus === CustomerStatus.CLOSED) {
      return 'שמירת הליד כלקוח';
    }

    return viewModel.baseCustomer ? `עריכת ${entityName}` : `יצירת ${entityName}`;
  };

  const title = getTitle();

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        markAsDitry(values);
        validateCustomerValues(values, viewModel.existingCustomerNames, viewModel.baseCustomer?.name);
      }}
      onSubmit={async (values) => {
        try {
          (await viewModel.baseCustomer) ? viewModel.updateCustomer(values) : viewModel.createCustomer(values);
        } catch (_) {
          setError('ארעה שגיאה');
        }
      }}
    >
      {(props) => (
        <Form>
          <Flex justifyContent="space-between">
            <Heading textStyle="h3" mb="20px">
              {title}
            </Heading>
            {viewModel.isLead && (
              <Field name="status">
                {({ field, form }: FieldAttributes<any>) => {
                  return (
                    <Box w="150px">
                      <StatusDropDown
                        type={StatusTypes.CUSTOMER}
                        value={field.value}
                        onChange={(status) => form.setFieldValue(field.name, status)}
                      />
                    </Box>
                  );
                }}
              </Field>
            )}
          </Flex>

          <Grid
            p="55px"
            paddingBottom="20px"
            paddingInlineEnd="112px"
            bg="white"
            border="1px solid"
            borderColor="base.200"
            borderRadius="18px"
            mb="38px"
            templateColumns="repeat(2, minmax(100px, 50%))"
            columnGap="160px"
          >
            <Text textStyle="extraLarge" mb="36px" gridColumn="1 / -1">
              פרטי איש קשר
            </Text>
            <FormInput isRequired name="contact_name" label="שם מלא" />
            <FormInput name="contact_role" label="תפקיד" />
            <FormInput name="email" label="מייל" />
            <FormInput name="phone" label="טלפון" type="tel" />
            <FormDatePicker name="contact_birth_date" label="תאריך לידה" />
            <FormSelect name="source" label="מקור" data={sources} />
          </Grid>
          <Grid
            p="55px"
            paddingBottom="20px"
            paddingInlineEnd="112px"
            bg="white"
            border="1px solid"
            borderColor="base.200"
            borderRadius="18px"
            mb="38px"
            templateColumns="repeat(2, minmax(100px, 50%))"
            columnGap="160px"
          >
            <Text textStyle="extraLarge" mb="36px" gridColumn="1 / -1">
              פרטי ארגון / חברה
            </Text>
            <FormInput isRequired name="name" label="שם החברה" />
            <FormInput name="vat_number" label="מספר עוסק / חברה" />
            <FormInput
              name="rate"
              type="number"
              label="עלות לשעה"
              step="0.01"
              endIcon={<ShekelIcon color="base.500" w="14px" />}
            />
            <FormSelect name="payment_cycle" label="תנאי תשלום" data={paymentCycles} />
            <FormSelect name="industry" label="תחום" data={industryOptions} />
            <FormInput name="address" label="כתובת" />
          </Grid>
          <Flex
            p="55px"
            paddingBottom="20px"
            paddingInlineEnd="112px"
            bg="white"
            border="1px solid"
            borderColor="base.200"
            borderRadius="18px"
            mb="38px"
            rowGap="36px"
            direction="column"
          >
            <Text textStyle="extraLarge">הערות מיוחדות</Text>
            <FormInput
              name="comment"
              w="100%"
              type="textarea"
              rows={4}
              placeholder="כאן אפשר להוסיף כל דבר שחשוב לזכור בהתנהלות מול החברה / איש הקשר"
            />
          </Flex>
          <Flex justifyContent="space-between" alignItems="center" py="16px">
            <Button onClick={viewModel.onCancel} variant="secondary">
              ביטול
            </Button>
            <Stack>
              <Button type="submit" isDisabled={!props.isValid} isLoading={props.isSubmitting}>
                {viewModel.isEditMode ? 'שמירה' : viewModel.isLead ? 'יצירת ליד' : 'יצירת לקוח'}
              </Button>
              {error && <Text color="red">{error}</Text>}
            </Stack>
          </Flex>
        </Form>
      )}
    </Formik>
  );
};
