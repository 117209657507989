import { Box, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { StyledInput } from '../../../components/form/StyledInput';

import { TimeTrackerPopover } from './TimeTrackerPopover';

export const WorkHourComment = observer(({ value, onChange }: { value: string; onChange: (s: string) => void }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tempComment, setTempComment] = useState(value);

  return (
    <TimeTrackerPopover
      isOpen={isOpen}
      offset="-123px, 9px"
      minWidth="330px"
      Trigger={
        <Text
          onClick={() => setIsOpen(true)}
          fontSize="lg"
          cursor="pointer"
          noOfLines={1}
          display="block"
          whiteSpace="nowrap"
          title={value || 'הוספת תיאור'}
          w="145px"
          marginInlineEnd="5px"
          textDecoration={value ? 'none' : 'underline'}
          _hover={{
            textDecoration: 'underline',
            color: 'primary.200',
          }}
          transition=".2s"
        >
          {value || 'הוספת תיאור'}
        </Text>
      }
      Body={
        <Box mb="30px">
          <StyledInput
            name="comment"
            id="comment"
            type="textarea"
            label="תיאור"
            value={tempComment}
            onChange={(e) => setTempComment(e.target.value)}
          />
        </Box>
      }
      onSave={() => {
        onChange(tempComment);
        setIsOpen(false);
      }}
      onCancel={() => setIsOpen(false)}
      saveDisabled={!tempComment}
    />
  );
});
