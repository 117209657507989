import { createIcon } from '@chakra-ui/icons';

export const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 25 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M19.1946 11.1111H12.9724V4.88889C12.9724 4.65314 12.8787 4.42705 12.712 4.26035C12.5453 4.09365 12.3192 4 12.0835 4C11.8477 4 11.6217 4.09365 11.455 4.26035C11.2883 4.42705 11.1946 4.65314 11.1946 4.88889V11.1111H4.97238C4.73664 11.1111 4.51054 11.2048 4.34385 11.3715C4.17715 11.5382 4.0835 11.7643 4.0835 12C4.0835 12.2357 4.17715 12.4618 4.34385 12.6285C4.51054 12.7952 4.73664 12.8889 4.97238 12.8889H11.1946V19.1111C11.1946 19.3469 11.2883 19.573 11.455 19.7397C11.6217 19.9064 11.8477 20 12.0835 20C12.3192 20 12.5453 19.9064 12.712 19.7397C12.8787 19.573 12.9724 19.3469 12.9724 19.1111V12.8889H19.1946C19.4304 12.8889 19.6564 12.7952 19.8231 12.6285C19.9898 12.4618 20.0835 12.2357 20.0835 12C20.0835 11.7643 19.9898 11.5382 19.8231 11.3715C19.6564 11.2048 19.4304 11.1111 19.1946 11.1111Z"
    />,
  ],
});
