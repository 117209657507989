import { Box, Button, Heading, Flex, Image, Text, Spacer } from '@chakra-ui/react';

import { PaymentState } from '../../../models/userData/useUserData';

import { HorizontalBullets } from './HorizontalBullets';

export const SubscriptionEndedContent = ({
  paymentState,
  username,
  onClick,
}: {
  paymentState: PaymentState;
  username: string;
  onClick: () => void;
}) => {
  const getTitle = () => {
    switch (paymentState) {
      case 'TRIAL':
        return 'תקופת הניסיון שלך הסתיימה';
      case 'PAYMENT_FAILED':
        return 'אנחנו עדיין לא מצליחים לחייב את האשראי שלך';
      case 'CANCELLED':
        return 'אין לך גישה יותר כי בחרת לבטל את המינוי';
      case 'BETA':
      case 'PAYING':
        // shouldn't get here
        return '';
    }
  };

  const getBody = () => {
    switch (paymentState) {
      case 'TRIAL':
        return 'כדי להשתמש בכל האפשרויות שעוזרות לך להיות פרילנס מוביל, צריך פשוט לרכוש מינוי';
      case 'PAYMENT_FAILED':
        return 'כדי להמשיך להשתמש בכל הכלים שעוזרים לך להיות פרילנס מוביל, צריך פשוט לעדכן את פרטי הכרטיס';
      case 'CANCELLED':
        return 'בגלל שעזבת לא מזמן, שמרנו לך את כל המידע כך שאפשר פשוט להמשיך איפה שהפסקת';
      case 'BETA':
      case 'PAYING':
        // shouldn't get here
        return '';
    }
  };

  const getButtonLabel = () => {
    switch (paymentState) {
      case 'PAYMENT_FAILED':
        return 'לעדכון';
      case 'TRIAL':
      case 'CANCELLED':
        return 'לרכישה';
      case 'BETA':
      case 'PAYING':
        // shouldn't get here
        return '';
    }
  };

  const title = getTitle();
  const body = getBody();
  const buttonLabel = getButtonLabel();
  return (
    <>
      <Image src={paymentState === 'PAYMENT_FAILED' ? '/alert.svg' : '/favicon.svg'} width="53px" mb="28px" mt="13px" />
      <Box px="25px">
        <Heading textStyle="h3" mb="18px" textAlign="center" lineHeight="1.2">
          הי {username},<br />
          {title}
        </Heading>
        <Text fontSize="lg" textAlign="center" maxW="378px" mx="auto" mb="50px">
          {body}
        </Text>
      </Box>
      <Spacer />
      <Flex
        w="100%"
        h="84px"
        borderTop="1px solid #E6E9F2"
        paddingInlineStart="40px"
        paddingInlineEnd="27px"
        justifyContent="space-between"
        alignItems="center"
      >
        <HorizontalBullets labels={['עלות המינוי  29 ₪', 'אפשר לבטל בכל שלב']} />
        <Button w="150px" onClick={onClick}>
          {buttonLabel}
        </Button>
      </Flex>
    </>
  );
};
