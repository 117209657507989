import { Spacer, Box, Text, Flex, Button } from '@chakra-ui/react';
import { useState } from 'react';

import { WorkHour } from '../../../models/WorkHour';
import { IWorkHourBase } from '../../../models/workHours/workHourApi';
import { getWorkHoursDuration } from '../../../models/workHourUtils';
import { durationToMinutesDisplay } from '../../../utils/time/timeUtils';
import { CrossIcon, SuccessIcon, WorkHourIcon } from '../../common/icons';

import { UploadWorkHoursModal } from './UploadWorkHoursModal';

export const UploadWorkHoursStrip = ({
  setImportedWorkHours,
}: {
  setImportedWorkHours: (wh: IWorkHourBase[]) => void;
}) => {
  const [isUploadWorkHoursOpen, setIsUploadWorkHoursOpen] = useState(false);
  const [fileData, setFileData] = useState<{ totalTime: number; rows: number } | null>(null);
  const [importedFile, setImportedFile] = useState('');

  const handleSuccess = (fileName: string, workHours: IWorkHourBase[]) => {
    setImportedFile(fileName);
    setImportedWorkHours(workHours);
    setFileData({
      rows: workHours.length,
      totalTime: getWorkHoursDuration(workHours.map((wh) => new WorkHour({ ...wh, id: 0, project_id: 0 }))),
    });
  };

  const getSuccessString = () => {
    if (!fileData) {
      return '';
    }

    return `זיהינו ${fileData.rows} רשומות עם סה”כ של ${durationToMinutesDisplay(fileData.totalTime)} שעות`;
  };

  return (
    <>
      <Flex
        h="150px"
        paddingInlineStart="55px"
        paddingInlineEnd="112px"
        alignItems="center"
        bg="white"
        border="1px solid"
        borderColor="#E6E9F2"
        borderRadius="18px"
        mb="38px"
      >
        {importedFile ? (
          <>
            <Flex h="100%" w="100%" alignItems="center">
              <SuccessIcon w="36px" h="28px" color="accent.100" />
              <Flex direction="column" marginInlineStart="21px">
                <Text fontSize="xl" color="base.900">
                  שמרנו את קובץ השעות שלך בהצלחה!
                </Text>
                <Text fontSize="md" color="#685888">
                  השעות יוצגו בתוך פרטי הפרויקט ברגע שתהליך הוספת הפרויקט יושלם.
                </Text>
              </Flex>
              <Spacer />
              <Flex alignSelf="end" mb="44px" alignItems="center" gap="30px">
                <Flex direction="column" gap="14px" fontSize="md" color="#685888">
                  <Text>{getSuccessString()}</Text>
                  <Box dir="ltr" maxW="260px">
                    <Text noOfLines={1} wordBreak="break-all">
                      {importedFile}
                    </Text>
                  </Box>
                </Flex>
                <Button
                  onClick={() => {
                    setImportedWorkHours([]);
                    setImportedFile('');
                  }}
                  variant="secondary"
                  w="140px"
                  leftIcon={<CrossIcon />}
                  alignSelf="end"
                >
                  הסרת הקובץ
                </Button>
              </Flex>
            </Flex>
          </>
        ) : (
          <>
            <WorkHourIcon color="#8978A7" h="32px" w="33px" marginInlineEnd="25px" />
            <Box>
              <Text fontSize="xl" color="base.900">
                דיווחת כבר שעות על הפרויקט במקום אחר? אפשר לייבא אותן ולהמשיך מאיפה שהפסקת!
              </Text>
              <Text fontSize="md" color="#685888" mb="24px">
                דיווח שעות בתוך Peko בשילוב תעריף שעתי, יאפשר לך לקבל תמונת מצב ברורה על צפי ההכנסות והרווחיות בפרויקט.
              </Text>
              <Text fontSize="xs" color="#685888">
                * ניתן לבצע ייבוא שעות ממערכת אחרת רק בשלב הוספת הפרויקט
              </Text>
            </Box>
            <Spacer />
            <Button onClick={() => setIsUploadWorkHoursOpen(true)} variant="secondary" alignSelf="end" mb="20px">
              ייבוא שעות
            </Button>
          </>
        )}
      </Flex>
      {isUploadWorkHoursOpen && (
        <UploadWorkHoursModal onDismiss={() => setIsUploadWorkHoursOpen(false)} onSuccess={handleSuccess} />
      )}
    </>
  );
};
