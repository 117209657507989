import { createIcon } from '@chakra-ui/icons';

export const EditIcon = createIcon({
  displayName: 'EditIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M15.2353 0.765333C14.7821 0.312798 14.1678 0.0586243 13.5273 0.0586243C12.8869 0.0586243 12.2726 0.312798 11.8193 0.765333L0.976677 11.608C0.666178 11.9168 0.419985 12.284 0.252342 12.6885C0.0846994 13.093 -0.00106532 13.5268 9.98748e-06 13.9647V15.3333C9.98748e-06 15.5101 0.0702479 15.6797 0.195272 15.8047C0.320296 15.9298 0.489866 16 0.666677 16H2.03534C2.47319 16.0012 2.90692 15.9156 3.31145 15.7481C3.71597 15.5805 4.08325 15.3344 4.39201 15.024L15.2353 4.18067C15.6877 3.72746 15.9417 3.11331 15.9417 2.473C15.9417 1.83269 15.6877 1.21854 15.2353 0.765333ZM3.44934 14.0813C3.07335 14.4548 2.56532 14.6651 2.03534 14.6667H1.33334V13.9647C1.33267 13.7019 1.38411 13.4417 1.4847 13.199C1.58529 12.9563 1.73302 12.7359 1.91934 12.5507L10.148 4.322L11.6813 5.85533L3.44934 14.0813ZM14.292 3.238L12.6213 4.90933L11.088 3.37933L12.7593 1.708C12.86 1.60754 12.9795 1.52789 13.111 1.47361C13.2424 1.41932 13.3833 1.39146 13.5255 1.39161C13.6678 1.39177 13.8086 1.41994 13.9399 1.47451C14.0712 1.52908 14.1905 1.60899 14.291 1.70967C14.3915 1.81035 14.4711 1.92983 14.5254 2.06129C14.5797 2.19275 14.6076 2.33362 14.6074 2.47584C14.6072 2.61807 14.5791 2.75888 14.5245 2.89022C14.4699 3.02156 14.39 3.14087 14.2893 3.24133L14.292 3.238Z"
    />,
  ],
});
