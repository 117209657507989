import { Box, Flex } from '@chakra-ui/layout';
import { Progress } from '@chakra-ui/progress';

import { StyledTooltip } from './StyledTooltip';

interface ProgressValue {
  label: string;
  icon: string;
}

interface ProgressBarProps {
  current: ProgressValue;
  total: ProgressValue;
  value: number;
  advancePercentage?: number;
}

export const ProgressBar = ({ current, total, value, advancePercentage }: ProgressBarProps) => {
  return (
    <Box>
      <Flex justifyContent="space-between" fontSize="xl" mb="8px">
        <StyledTooltip label="בוצע">
          <Box as="span" textStyle="doubleLarge">
            {current.label}{' '}
            <Box as="span" fontSize="md">
              {current.icon}
            </Box>
          </Box>
        </StyledTooltip>
        <StyledTooltip label="נותר">
          <Box as="span" textStyle="doubleLarge">
            {total.label}{' '}
            <Box as="span" fontSize="md">
              {total.icon}
            </Box>
          </Box>
        </StyledTooltip>
      </Flex>
      <Box
        position="relative"
        _hover={{
          '& .advanceIndicator': {
            display: 'block',
          },
        }}
      >
        <Progress
          colorScheme="gray"
          sx={{
            '& *': {
              bg: value > 100 ? 'accent.100 !important' : '',
            },
            '& > *': {
              borderTopLeftRadius: value >= 100 ? 'inherit' : '0 !important',
              borderBottomLeftRadius: value >= 100 ? 'inherit' : '0 !important',
            },
          }}
          height="16px"
          borderRadius="2xl"
          value={value}
        />
        {Boolean(advancePercentage) && (
          <StyledTooltip placement="bottom" label="סיום מקדמה - מכאן מתחילים לחייב">
            <Box
              position="absolute"
              height="8px"
              w="8px"
              borderRadius="50%"
              backgroundColor="accent.100"
              bottom="4px"
              right={`calc(${advancePercentage! * 100}% - 4px)`}
            />
          </StyledTooltip>
        )}
      </Box>
    </Box>
  );
};
