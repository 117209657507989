import { CheckIcon } from '@chakra-ui/icons';
import { Box, Heading, Text, Button, Flex } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { StyledInput } from '../../../components/form/StyledInput';
import { USER_DATA_QUERY_KEY } from '../../../models/userData/useUserData';
import { fetcher } from '../../../requests/fetcher';

export const ConfirmEmail = ({
  email,
  goToLogin,
  onOtpVerified,
}: {
  email: string;
  goToLogin: () => void;
  onOtpVerified: () => void;
}) => {
  const [isResending, setIsResending] = useState(false);
  const [wasEmailSent, setWasEmailSent] = useState(false);
  const [error, setError] = useState(false);
  const queryClient = useQueryClient();
  const [otp, setOtp] = useState('');

  useEffect(() => {
    if (error) {
      setError(false);
    }
    if (otp.length === 6) {
      checkOtp(otp);
    }
  }, [otp]);

  const checkOtp = async (code: string) => {
    try {
      await fetcher(`${import.meta.env.REACT_APP_API_URL}/otp/verify`, JSON.stringify({ code }));
      queryClient.invalidateQueries({
        queryKey: [USER_DATA_QUERY_KEY],
      });
      onOtpVerified();
    } catch (e) {
      setError(true);
    }
  };

  const handleOnChange = (value: string) => {
    setOtp(value.slice(0, 6));
  };

  const handleEmailResend = async () => {
    setIsResending(true);
    await fetcher(`${import.meta.env.REACT_APP_API_URL}/otp/new`, JSON.stringify({}));
    setWasEmailSent(true);
    setIsResending(false);
  };

  return (
    <>
      <Heading id="confirm_email" textStyle={{ base: 'h3', md: 'h2' }} mb="64px">
        יש לך מייל מאיתנו
      </Heading>
      <Text fontSize={{ base: 'lg', md: 'xl' }} mb="48px" fontWeight="300">
        כדי לאמת את הכתובת ולהמשיך ביצירת
        <br />
        החשבון, צריך להזין את הקוד ששלחנו לך
        <br />
        למייל: {email}
        <br />
      </Text>
      <StyledInput type="number" onChange={(e) => handleOnChange(e.target.value)} value={otp} name="otp" size="sm" />
      {error ? (
        <Text color="error.100" textStyle="medium" mt="8px">
          קוד שגוי
        </Text>
      ) : (
        <Box h="28px" />
      )}
      <Box borderRadius="10px" bg="rgba(251, 197, 242, 0.30)" py="20px" px="40px" mt="24px" mb="32px">
        <Text fontWeight="bold" fontSize="lg">
          לא קיבלת את המייל?
        </Text>
        <Text fontWeight="300" fontSize="lg" mb="18px">
          כדאי לבדוק את תיקיית הספאם.
        </Text>
        {wasEmailSent ? (
          <Flex alignItems="center">
            <CheckIcon fontSize="xs" color="accent.200" marginInlineEnd="8px" />
            <Text fontWeight="normal" fontSize="lg" color="accent.200">
              שלחנו לך מייל נוסף
            </Text>
          </Flex>
        ) : (
          <Button variant="link" isLoading={isResending} onClick={handleEmailResend}>
            לא קיבלתי, שלחו לי שוב
          </Button>
        )}
      </Box>
      <Button variant="link" onClick={goToLogin}>
        כבר הצטרפת? נכנסים לחשבון מכאן
      </Button>
    </>
  );
};

const CustomInput = (props: any) => {
  return (
    <StyledInput h={{ base: '36px', md: '58px' }} minW="30px" flex="1" fontSize="2xl" value={props.value} {...props} />
  );
};
