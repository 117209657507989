import { Box } from '@chakra-ui/layout';
import { Field, FieldAttributes, getIn } from 'formik';
import { useEffect, useState } from 'react';

import { ButtonTabs } from '../../../components/ButtonTabs';
import { FormBigNumberInput } from '../../../components/form/FormBigNumberInput';
import { FormDatePicker } from '../../../components/form/FormDatePicker';
import { StyledInput } from '../../../components/form/StyledInput';
import { roundTo2 } from '../../../utils/number/numberUtils';

import { PAYMENT_TYPE } from './CreateProjectViewModel';

export const AdvancePayment = ({
  totalValue,
  index = 0,
  projectStartDate,
}: {
  totalValue?: number;
  index?: number;
  projectStartDate?: Date;
}) => {
  const [paymentType, setPaymentType] = useState<PAYMENT_TYPE>(PAYMENT_TYPE.EXACT);

  useEffect(() => {
    if (!totalValue) {
      setPaymentType(PAYMENT_TYPE.EXACT);
    }
  }, [totalValue]);

  const paymentTypeItems = [
    {
      label: '%',
      id: PAYMENT_TYPE.PERCENTAGE,
    },
    {
      label: 'ש״ח',
      id: PAYMENT_TYPE.EXACT,
    },
  ];
  const getSum = (percent = 0) => {
    if (percent === 0) {
      return 0;
    }

    if (totalValue) {
      return (totalValue * percent) / 100;
    }
  };

  const getPercentage = (sum = 0) => {
    if (!totalValue) {
      return 0;
    }
    return roundTo2((sum / totalValue) * 100);
  };

  return (
    <>
      <Box>
        {paymentType === PAYMENT_TYPE.EXACT ? (
          <FormBigNumberInput
            label="גובה המקדמה"
            endIcon={
              <ButtonTabs
                items={paymentTypeItems}
                selectedId={paymentType}
                handleClick={(type: PAYMENT_TYPE) => setPaymentType(type)}
                disabled={!totalValue}
                size="sm"
              />
            }
            isRequired
            name={`payments.${index}.sum`}
          />
        ) : (
          !!totalValue && (
            <Field name={`payments.${index}.sum`}>
              {({ field, form }: FieldAttributes<any>) => {
                const shouldShowCustomError =
                  getPercentage(Number(field.value)) > 100 || getPercentage(Number(field.value)) < 1;
                return (
                  <StyledInput
                    {...field}
                    label="גובה המקדמה"
                    value={getPercentage(Number(field.value))}
                    min={1}
                    max={100}
                    onChange={(e) => {
                      form.setFieldValue(field.name, getSum(Number(e.target.value)));
                    }}
                    type="number"
                    name={field.name}
                    error={shouldShowCustomError ? 'יש להכניס ערך תקין בין 1 ל-100' : getIn(form.errors, field.name)}
                    touched={getIn(form.touched, field.name)}
                    endIcon={
                      <ButtonTabs
                        size="sm"
                        items={paymentTypeItems}
                        selectedId={paymentType}
                        handleClick={(type: PAYMENT_TYPE) => setPaymentType(type)}
                        disabled={!totalValue}
                      />
                    }
                    isRequired
                    maintainHeight
                  />
                );
              }}
            </Field>
          )
        )}
      </Box>
      <FormDatePicker
        name={`payments.${index}.submitDate`}
        label="מועד תשלום המקדמה"
        minimumDate={projectStartDate}
        isRequired
      />
    </>
  );
};
