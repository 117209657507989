import { Box, Divider, Flex, Text, Stack, Heading, Button } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Field, FieldArray, FieldAttributes, getIn } from 'formik';
import { observer } from 'mobx-react';
import { useState, Fragment } from 'react';

import { Dialog } from '../../../components/Dialog';
import { FormBigNumberInput } from '../../../components/form/FormBigNumberInput';
import { Addition } from '../../../models/Addition';
import { getPaymentInitialValues } from '../../../models/PaymentUtils';
import { getRemainingValue, IProjectValues } from '../../../models/ProjectUtils';
import { IWorkHourBase } from '../../../models/workHours/workHourApi';
import { ExclamationLightIcon, ShekelIcon, WarningIcon } from '../../common/icons';

import { PaymentBox } from './PaymentBox';
import { UploadWorkHoursStrip } from './UploadWorkHoursStrip';

export const CreateFixedContractForm = observer(
  ({
    values,
    additions,
    isEditMode,
    setImportedWorkHours,
  }: {
    values: Partial<IProjectValues>;
    additions?: Array<Addition>;
    isEditMode: boolean;
    setImportedWorkHours: (wh: IWorkHourBase[]) => void;
  }) => {
    const remainingValue = getRemainingValue(values);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getPaymentDismiss = (onRemove: () => void, additions?: Addition[]) => {
      if (additions && additions.length > 0) {
        return () => setIsModalOpen(true);
      }
      return onRemove;
    };

    return (
      <>
        <Flex
          alignItems="center"
          justifyContent="center"
          mb="60px"
          bg="#DDFAF5"
          w="470px"
          marginInline="auto"
          px="38px"
          py="20px"
          borderRadius="10px"
          border="1px solid"
          borderColor="#E6E9F2"
        >
          <Text fontSize="lg">חוזה פיקס</Text>
          <Divider borderColor="base.900" minHeight="15px" mx="12px" orientation="vertical" />
          <Text fontSize="lg">הלקוח ישלם ע"פ מועדי התשלום שיוגדרו</Text>
        </Flex>
        {!isEditMode && <UploadWorkHoursStrip setImportedWorkHours={setImportedWorkHours} />}
        <Flex
          h="186px"
          paddingInlineStart="55px"
          paddingInlineEnd="65px"
          alignItems="center"
          bg="white"
          border="1px solid"
          borderColor="#E6E9F2"
          borderRadius="18px"
          mb="56px"
          gap="160px"
        >
          <FormBigNumberInput
            isRequired
            name="totalValue"
            label="עלות כוללת"
            helperText="ללא מע״מ"
            w="290px"
            step="0.01"
            endIcon={<ShekelIcon w="14px" color="base.500" />}
          />
          <Box>
            <Text fontSize="xl" color="base.900" mb="14px">
              מעקב שעות מומלץ גם בפרויקט מסוג{' '}
              <Box as="span" fontWeight="700">
                ‘פיקס’
              </Box>
            </Text>
            <Text textStyle="medium" color="base.600">
              ברגע שתתבצע מדידה של שעות בפרויקט מסוים, נחשב עבורך את כמות השעות המקסימלית שכדאי להשקיע ביחס לתעריף השעתי
              הרגיל שלך כדי לוודא שהפרויקט יהיה רווחי. הלקוח כמובן לא יחויב ע”פ המדידה הזו.
            </Text>
          </Box>
        </Flex>
        <Heading textStyle="h4" mb="77px">
          תכנון מועדי תשלום
        </Heading>
        <Stack direction="column" gap="70px" position="relative">
          <FieldArray name="payments">
            {({ push, remove }) => (
              <>
                {values.payments &&
                  values.payments.length &&
                  values.payments.map((p, i) => {
                    const paymentAdditions = additions?.filter((a) => a.payment === p.id);
                    return (
                      <Fragment key={i}>
                        <PaymentBox
                          key={i}
                          index={i}
                          onDismiss={i > 0 ? getPaymentDismiss(() => remove(i), paymentAdditions) : undefined}
                          projectStartDate={values.startDate!}
                          additions={paymentAdditions}
                          paymentDate={
                            values?.payments?.[i].submitDate ? format(values.payments[i].submitDate!, 'dd.MM.yyyy') : ''
                          }
                        />
                      </Fragment>
                    );
                  })}
                <Flex
                  bg="white"
                  p="32px 55px"
                  paddingInlineEnd="112px"
                  border="1px solid"
                  borderColor="base.200"
                  borderRadius="18px"
                  w="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  mt="-10px"
                >
                  <Text fontSize="lg">{`יתרה לתשלום מהעלות הכוללת ₪${remainingValue?.toLocaleString() || 0}`}</Text>
                  <Button variant="secondary" w="170px" onClick={() => push(getPaymentInitialValues())}>
                    + הוספת תשלום
                  </Button>
                </Flex>
                <Box position="absolute" left="200" bottom="-108px">
                  <Field name="paymentsTotal">
                    {({ field, form }: FieldAttributes<any>) => {
                      const error = getIn(form.errors, field.name);

                      return error ? (
                        <Text color="error.100" mt="8px" textStyle="small">
                          <ExclamationLightIcon marginInlineEnd="12px" mb="2px" />
                          {error}
                        </Text>
                      ) : null;
                    }}
                  </Field>
                </Box>
              </>
            )}
          </FieldArray>
        </Stack>
        <AdditionRemoveModal isOpen={isModalOpen} onDismiss={() => setIsModalOpen(false)} />
      </>
    );
  },
);

const AdditionRemoveModal = ({ isOpen, onDismiss }: { isOpen: boolean; onDismiss: () => void }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Dialog title="מחיקת תשלום" onClose={onDismiss}>
      <Flex
        position="relative"
        p="16px"
        mx="53px"
        mt="30px"
        marginBottom="40px"
        background="#FBC5F24D"
        borderRadius="5px"
        textAlign="center"
      >
        <WarningIcon
          color="accent.100"
          position="absolute"
          top="-12px"
          left="50%"
          transform="translateX(-50%)"
          w="24px"
          h="24px"
        />
        <Text color="base.900" fontSize="md">
          לא ניתן למחוק את התשלום כל עוד משויכת אליו תוספת
        </Text>
      </Flex>
      <Text fontSize="md" textAlign="center" mx="53px" mb="48px">
        יש לשייך את התוספת לתשלום אחר או למחוק את התוספת לפני מחיקת התשלום
      </Text>
      <Flex justifyContent="center" alignItems="center" paddingBottom="16px" px="30px">
        <Button size="lg" w="151px" onClick={onDismiss}>
          הבנתי, תודה
        </Button>
      </Flex>
    </Dialog>
  );
};
