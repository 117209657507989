import { Grid, Box, Text, Flex, Switch, Divider } from '@chakra-ui/react';
import { Field, FieldArray, FieldAttributes } from 'formik';
import { observer } from 'mobx-react';

import { FormInput } from '../../../components/form/FormInput';
import { FormSelect } from '../../../components/form/FormSelect';
import { getAdvancePaymentValues } from '../../../models/PaymentUtils';
import { PaymentCycleEnum } from '../../../models/Project';
import { IProjectValues } from '../../../models/ProjectUtils';
import { IWorkHourBase } from '../../../models/workHours/workHourApi';
import { ShekelIcon } from '../../common/icons';

import { AdvancePayment } from './AdvancePayment';
import { UploadWorkHoursStrip } from './UploadWorkHoursStrip';

export const paymentCycles = [
  {
    key: 'מיידי',
    value: PaymentCycleEnum.IMMEDIATE.toString(),
  },
  {
    key: 'שוטף',
    value: PaymentCycleEnum.SHOTEF.toString(),
  },
  {
    key: 'שוטף 10',
    value: PaymentCycleEnum.SHOTEF10.toString(),
  },
  {
    key: 'שוטף 15',
    value: PaymentCycleEnum.SHOTEF15.toString(),
  },
  {
    key: 'שוטף 30',
    value: PaymentCycleEnum.SHOTEF30.toString(),
  },
  {
    key: 'שוטף 45',
    value: PaymentCycleEnum.SHOTEF45.toString(),
  },
  {
    key: 'שוטף 60',
    value: PaymentCycleEnum.SHOTEF60.toString(),
  },
];

export const CreateHourlyContractForm = observer(
  ({
    values,
    isEditMode,
    setImportedWorkHours,
  }: {
    values: Partial<IProjectValues>;
    isEditMode: boolean;
    setImportedWorkHours: (wh: IWorkHourBase[]) => void;
  }) => {
    const getTotalValue = () => {
      if (values?.includeTotalHours && values?.totalHours && values?.rate) {
        return values.totalHours * values.rate;
      }
      return 0;
    };

    const total = getTotalValue();

    return (
      <>
        <Flex
          alignItems="center"
          justifyContent="center"
          mb="60px"
          bg="#DDFAF5"
          w="470px"
          marginInline="auto"
          px="38px"
          py="20px"
          borderRadius="10px"
          border="1px solid"
          borderColor="#E6E9F2"
        >
          <Text fontSize="lg">חוזה שעתי</Text>
          <Divider borderColor="base.900" minHeight="15px" mx="12px" orientation="vertical" />
          <Text fontSize="lg">הלקוח ישלם ע"פ קצב התקדמות השעות</Text>
        </Flex>
        {!isEditMode && <UploadWorkHoursStrip setImportedWorkHours={setImportedWorkHours} />}
        <Grid
          columnGap="60px"
          templateColumns="1fr 1fr"
          p="55px"
          paddingBottom="25px"
          paddingInlineEnd="112px"
          bg="white"
          border="1px solid"
          borderColor="#E6E9F2"
          borderRadius="18px"
          mb="38px"
        >
          <Box mb="36px">
            <FormInput
              name="rate"
              type="number"
              label="תעריף שעתי"
              helperText="ללא מע״מ"
              tooltipText="מבוסס על התעריף הרגיל שלך אבל ניתן גם לשינוי בכל פרויקט"
              isRequired
              step="0.01"
              endIcon={<ShekelIcon color="base.500" w="14px" />}
            />
          </Box>
          <FormSelect isRequired name="paymentCycle" label="תנאי תשלום" data={paymentCycles} />
          <Box mb={values.includeAdvance ? '30px' : '16px'}>
            <FormInput
              tooltipText="כדי שנוכל לשקף לך את קצב ההתקדמות ולהתריע כשיש חריגה"
              name="totalHours"
              label="מסגרת שעות"
              type="number"
              helperText={total > 0 ? `סה״כ הכנסה צפויה: ${total.toLocaleString()} ₪` : ''}
            />
          </Box>
          <FieldArray name="payments">
            {({ push, pop }) => (
              <>
                <Box>
                  <Flex alignItems="center" gap="16px" h="102px">
                    <Field name="includeAdvance">
                      {({ field, form }: FieldAttributes<any>) => {
                        return (
                          <>
                            <Switch
                              mb="5px"
                              isChecked={field.value}
                              onChange={() => {
                                if (field.value) {
                                  pop();
                                } else {
                                  push(getAdvancePaymentValues());
                                }
                                form.setFieldValue(field.name, !field.value);
                              }}
                            />
                            <Text textStyle="large" color="base.600" mb="6px">
                              בפרויקט זה מוגדרת מקדמה לתשלום
                            </Text>
                          </>
                        );
                      }}
                    </Field>
                  </Flex>
                </Box>
                {values.includeAdvance && values?.payments && values.payments.length > 0 && (
                  <AdvancePayment totalValue={getTotalValue()} projectStartDate={values.startDate!} />
                )}
              </>
            )}
          </FieldArray>
        </Grid>
      </>
    );
  },
);
