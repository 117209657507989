import { Flex } from '@chakra-ui/react';

import { PencilIcon, TrashIcon } from '../pages/common/icons';

import { SquareIconButton } from './SquareIconButton';
import { StyledTooltip } from './StyledTooltip';

export const TableActions = ({
  onEdit,
  onDelete,
  children,
}: {
  onEdit(): void;
  onDelete(): void;
  children?: React.ReactNode;
}) => {
  return (
    <Flex gap="8px" w="100%" justifyContent="end">
      {children}
      <StyledTooltip label="עריכה">
        <SquareIconButton
          onClick={(e) => {
            e.stopPropagation();
            onEdit();
          }}
          variant="ghost"
          size="sm"
          icon={<PencilIcon />}
        />
      </StyledTooltip>

      <StyledTooltip label="מחיקה">
        <SquareIconButton
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
          variant="dangerGhost"
          size="sm"
          icon={<TrashIcon />}
        />
      </StyledTooltip>
    </Flex>
  );
};
