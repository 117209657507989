import { useMutation, useQueryClient } from '@tanstack/react-query';

import { logger } from '../../utils/logger';

import { updateUserData } from './userDataApi';
import { IValidUserDataValues } from './userDataFormUtils';
import { USER_DATA_QUERY_KEY } from './useUserData';

export function useUpdateUserData(onSuccess?: () => void, onError?: () => void) {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation<void, unknown, IValidUserDataValues, unknown>({
    mutationFn: async (userData) => {
      await updateUserData(userData);
      return;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USER_DATA_QUERY_KEY],
      });
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (e) => {
      logger.error('Failed to update userData', e);
      if (onError) {
        onError();
      }
    },
  });

  return { updateUserData: mutateAsync };
}
