import { Box, Text } from '@chakra-ui/layout';

import { GoalBarChart } from './GoalBarChart';

export const GoalCard = ({ totalIncomeInYear, yearlyGoal }: { totalIncomeInYear: number; yearlyGoal?: number }) => {
  const currentYear = new Date();
  return (
    <>
      <Text fontSize="xl">הכנסות {currentYear.getFullYear()}</Text>
      <Box h="300px" mt="10px">
        <GoalBarChart
          data={[
            { label: 'סטטוס נוכחי', data: totalIncomeInYear },
            { label: 'יעד שנתי', data: yearlyGoal || 0 },
          ]}
        />
      </Box>
    </>
  );
};
