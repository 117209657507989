import format from 'date-fns/format';
import { makeObservable, observable, computed } from 'mobx';

import { IExpense } from '../requests/Client';

export interface IExpenseValues {
  date: Date;
  name?: string;
  value?: number;
  comment?: string;
}

export class Expense {
  _id: string;

  _name: string;
  _date: Date;
  _value: number;
  _comment?: string;

  constructor(expense: IExpense) {
    this._id = expense.id.toString() || '';
    this._name = expense.name || '';
    this._date = new Date(expense.date);
    this._value = expense.value;
    this._comment = expense.comment;

    makeObservable(this, {
      _id: observable,
      id: computed,
      _name: observable,
      name: computed,
      _date: observable,
      date: computed,
      displayDate: computed,
      _comment: observable,
      comment: computed,
      _value: observable,
      value: computed,
    });
  }

  get id() {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get date(): string {
    return format(this._date, 'yyyy-MM-dd');
  }

  get displayDate(): string {
    return format(this._date, 'dd.MM.yyyy');
  }

  get comment() {
    return this._comment;
  }

  get value() {
    return this._value;
  }
}
