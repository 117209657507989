import { format, add, sub, set, isBefore } from 'date-fns';

import { getDuration } from '../../utils/time/timeUtils';

import { IWorkHour } from './useWorkHours';

const SECONDS_IN_DAY = 24 * 60 * 60;

export interface IWorkHourForm {
  startTime: string;
  date: Date;
  endTime?: string; // HH:mm
  comment?: string; // HH:mm
}

export type IWorkHourValues = Partial<IWorkHour>;
export type IValidWorkHourValues = IWorkHourValues & {
  startTime: Date;
  projectId?: string;
};

export function getInitialFormData(): IWorkHourForm {
  const now = set(new Date(), {
    seconds: 0,
    milliseconds: 0,
  });

  return {
    startTime: format(now, 'HH:mm'),
    endTime: format(now, 'HH:mm'),
    date: now,
    comment: undefined,
  };
}

export function roundTime(workHour: IValidWorkHourValues) {
  if (!workHour.endTime) {
    return workHour;
  }
  // round seconds
  workHour.startTime = set(workHour.startTime, {
    seconds: 0,
    milliseconds: 0,
  });
  workHour.endTime = set(workHour.endTime, {
    seconds: 0,
    milliseconds: 0,
  });

  if (isBefore(workHour.endTime, workHour.startTime)) {
    workHour.endTime = add(workHour.endTime, {
      days: 1,
    });
  }
  const duration = getDuration(workHour.startTime, workHour.endTime);

  // set minimum duration
  if (duration < 60) {
    workHour.endTime = add(workHour.endTime, {
      minutes: 1,
    });
  }

  if (duration >= SECONDS_IN_DAY) {
    workHour.endTime = sub(workHour.endTime, {
      days: 1,
    });
  }

  return workHour;
}

export function getFormDuration(workHour: IWorkHourForm): number {
  if (!workHour.startTime || !workHour.endTime || workHour.startTime === workHour.endTime) {
    return 0;
  }

  const startTime = minutesDisplayToDate(workHour.startTime || '', workHour.date);
  const endTime = minutesDisplayToDate(workHour.endTime || '', workHour.date);

  const rounded = roundTime({
    ...workHour,
    startTime: new Date(startTime),
    endTime: new Date(endTime),
    projectId: undefined,
  });
  return getDuration(rounded.startTime, rounded.endTime!);
}

export function minutesDisplayToDate(minutesString: string, date = new Date()) {
  const [endHour = 0, endMin = 0] = minutesString.split(':');
  return set(date, {
    hours: Number(endHour),
    minutes: Number(endMin),
    seconds: 0,
    milliseconds: 0,
  });
}
