import { extendBaseTheme } from '@chakra-ui/react';

import { colors } from './colors';
import { components } from './components';
import { fonts, fontSizes, textStyles } from './fonts';

const theme = extendBaseTheme({
  styles: {
    global: {
      body: {
        bg: 'base.50',
        color: 'brand.600',
      },
    },
  },
  fonts,
  fontSizes,
  textStyles,
  colors,
  components,
});

export default theme;
