import { IUserDataApi } from './userDataApi';
import { BUSINESS_TYPE, IUserData, NUM_CO_WORKERS } from './useUserData';

export function apiToUserData(data: IUserDataApi): IUserData {
  return {
    id: data.id,

    username: data.name,
    email: data.email,
    baseRate: data.base_rate,
    services: data.services,
    emailVerified: data.email_verified,
    companyName: data.company_name,
    industry: data.industry,
    numberOfCoWorkers: data.number_of_coworkers as NUM_CO_WORKERS,
    numberOfYears: data.number_of_years,
    businessType: data.business_type as BUSINESS_TYPE,
    yearlyGoal: data.yearly_goal,
    phone: data.phone,
    birthDate: data.birthdate ? new Date(data.birthdate) : null,
    createdAt: new Date(data.created_at),
    includeVat: data.include_vat,
    paymentState: data.payment_state,
    payment4Digits: data.payment_4_digits,
    payplusUserId: data.payplus_user_id,
    recurringTransactionId: data.recurring_transaction_id,
    paymentStartDate: data.payment_start_date ? new Date(data.payment_start_date) : undefined,
    paymentNextDate: data.payment_next_date ? new Date(data.payment_next_date) : undefined,
  };
}
