import { getMonth, getYear } from 'date-fns';
import { computed, makeObservable, observable } from 'mobx';

import { CustomerModel } from '../../../models/CustomerModel';
import { Payment } from '../../../models/Payment';
import { Project } from '../../../models/Project';
import { ProjectsModel } from '../../../models/ProjectsModel';
import { IStore } from '../../../models/RootStore';
import { getWorkHoursDuration, getWorkHoursSum } from '../../../models/workHourUtils';
import { PROJECT_TYPE } from '../../../requests/Client';
import { CreateProjectRoute } from '../../../router/routes';
import { RoutingStore } from '../../../router/RoutingStore';
import { MONTHS } from '../../../utils/time/timeUtils';

import { EditCustomerRoute } from './../../../router/routes';

export interface PaymentSummaryByYearByMonth {
  [year: string]: {
    [month: string]: {
      total: number;
      displayPaymentCycle: string;
      payments: Array<{
        projectName: string;
        totalValue: number;
        service: string;
        projectType: string;
        workHourDuration?: number;
        additionValue: number; // TODO
      }>;
    };
  };
}
export class SingleCustomerViewModel implements IStore {
  projectsModel: ProjectsModel;
  customersModel: CustomerModel;
  routingStore: RoutingStore;

  selectedCustomerId: string | null = null;

  constructor(projectsModel: ProjectsModel, customersModel: CustomerModel, routingStore: RoutingStore) {
    this.projectsModel = projectsModel;
    this.customersModel = customersModel;
    this.routingStore = routingStore;

    makeObservable(this, {
      projectsModel: observable,

      selectedCustomerId: observable,
      customer: computed,

      customerProjects: computed,
      customerTotalProjects: computed,
      totalIncome: computed,
    });
  }

  activate(customerId: string) {
    this.selectedCustomerId = customerId;
  }

  get customer() {
    return this.selectedCustomerId
      ? this.customersModel.customers.find((c) => c._id === this.selectedCustomerId)
      : null;
  }

  get customerProjects() {
    return this.projectsModel.projects.filter((p) => p.customer === this.selectedCustomerId);
  }

  get customerTotalProjects() {
    return this.customerProjects.length;
  }

  get totalWorkHours() {
    return this.customerProjects.reduce((acc, project) => {
      return acc + getWorkHoursDuration(project.workHours);
    }, 0);
  }

  get projectsByYear() {
    return this.customerProjects.reduce<{ [year: string]: Project[] }>((acc, project) => {
      const year = getYear(project._startDate);
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(project);
      return acc;
    }, {});
  }

  get paymentsByYear() {
    return this.customerProjects.reduce<PaymentSummaryByYearByMonth>((acc, project) => {
      project.payments.forEach((payment: Payment) => {
        const year = getYear(payment._submitDate);
        if (!acc[year]) {
          acc[year] = {};
        }
        const month = MONTHS[getMonth(payment._submitDate)];
        if (!acc[year][month]) {
          acc[year][month] = {
            total: 0,
            displayPaymentCycle: payment.displayPaymentDate || '', // TODO?
            payments: [],
          };
        }
        const total = this.getPaymentSum(payment, project) || 0;
        acc[year][month] = {
          total: (acc[year][month].total || 0) + total,
          displayPaymentCycle: payment.displayPaymentDate || '', // TODO?
          payments: [
            ...(acc[year][month].payments || []),
            {
              projectName: project.name,
              totalValue: total,
              service: project.service,
              projectType: project.displayType,
              workHourDuration:
                project.type === PROJECT_TYPE.HOURLY
                  ? getWorkHoursDuration(project.getPaymentWorkHours(payment))
                  : undefined,
              additionValue: project._additions.reduce(
                (acc, a) => (a.payment === payment._id ? acc + a.value : acc),
                0,
              ),
            },
          ],
        };
      });
      return acc;
    }, {});
  }

  get totalIncome() {
    return this.customerProjects.reduce((acc, project) => acc + project.totalValue, 0);
  }

  getPaymentSum = (p: Payment, project: Project) => {
    if (project.type !== PROJECT_TYPE.HOURLY) {
      const addition = project._additions.find((a) => a.payment === p._id);
      return addition ? addition.value + (p.sum || 0) : p.sum || 0;
    }

    if (p.isAdvance) {
      return p.sum;
    }

    const workHours = project?.getPaymentWorkHours(p);
    if (!workHours || !project) {
      return 0;
    }

    const sum = getWorkHoursSum(workHours, project?.rate);
    return sum || 0;
  };

  createProject = () => {
    this.routingStore.navigate(CreateProjectRoute);
  };

  editCustomer = () => {
    this.routingStore.navigate({
      ...EditCustomerRoute,
      params: { customerId: this.selectedCustomerId },
    });
  };
}
