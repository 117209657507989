import { Flex } from '@chakra-ui/react';

import { ExclamationIcon } from '../../pages/common/icons';

export const Toast = ({ state, text }: { state: 'success' | 'error'; text: string }) => {
  const color = state === 'success' ? 'white' : 'base.900';
  const bg = state === 'success' ? 'base.900' : '#F0C4E9';

  return (
    <Flex
      color={color}
      bg={bg}
      fontSize="sm"
      alignItems="center"
      borderRadius="10px"
      w="240px"
      h="45px"
      paddingInline="14px"
    >
      {text}
      {state === 'error' && <ExclamationIcon fontSize="md" color="accent.100" marginInlineStart="auto" mt="-1px" />}
    </Flex>
  );
};
