import { format } from 'date-fns';

import { IAdditionValues } from '../models/Addition';
import { ICustomerValues } from '../models/customers/customerUtils';
import { IExpenseValues } from '../models/Expense';
import { Project } from '../models/Project';
import { IProjectValues } from '../models/ProjectUtils';
import { WorkHour } from '../models/WorkHour';

import { IPaymentValues } from './../models/PaymentUtils';
import { fetcher } from './fetcher';

export enum PROJECT_STATUS {
  ACTIVE = 'ACTIVE',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  PAUSED = 'PAUSED',
}

export enum PROJECT_TYPE {
  HOURLY = 'HOURLY',
  FIXED = 'FIXED',
  PRO_BONO = 'PRO_BONO',
}

export interface IProjectApi {
  id: number;
  name: string;
  start_date: string;
  end_date: string | null;
  service: string;
  status: string; // PROJECT_STATUS;
  type: string; // PROJECT_TYPE;
  rate: string | null;
  total_value: number;
  hour_tracking: boolean;
  payment_cycle: number;
  customer_id: string;
  payments: Array<{
    id: number;
    sum?: number;
    is_advance: boolean;
    comment: string | null;
    submit_date: string; // data-time
  }> | null;
  additions: Array<IAddition> | null;
  expenses: Array<IExpense> | null;
}

export interface ICustomerApi {
  id: string;
  name: string;
  phone?: string;
  email?: string;
  source?: string;
  created_at: string; // date-time
  status?: string;
  rate?: number;
  payment_cycle?: number; // enum
  vat_number?: string;
  industry?: string;
  address?: string;
  contact_name?: string;
  contact_role?: string;
  contact_birth_date?: string; // date
  is_active: boolean;
  comment?: string;
}

export interface IPaymentApi {
  id: number;
  sum?: number | null;
  comment?: string | null;
  is_advance: boolean;
  submit_date: string;
}

export interface IWorkHourResponse {
  id: number;
  start_time: string;
  end_time: string | null;
  comment: string | null;
  project_id: number;
}

export interface ITaskApi {
  id: string;
  label: string;
  is_complete: boolean;
}

export interface IAddition {
  id: number;
  value: number;
  date: string;
  approved_by?: string;
  comment?: string;
  payment_id?: number;
}

export interface IExpense {
  id: number;
  value: number;
  name: string;
  date: string;
  comment?: string;
}

const baseUrl = import.meta.env.REACT_APP_API_URL;
export class Client {
  constructor() {}

  /** Projects */
  async getUserProjects(): Promise<IProjectApi[]> {
    return fetcher(`${baseUrl}/projects`, '', 'GET');
  }

  async createProject(project: IProjectValues): Promise<IProjectApi> {
    const _project = {
      name: project.name,
      start_date: format(project.startDate, 'yyyy-MM-dd'),
      end_date: project.endDate ? format(project.endDate, 'yyyy-MM-dd') : undefined,
      service: project.service,
      status: PROJECT_STATUS.ACTIVE,
      type: project.type,
      rate: project.rate,
      total_value: project.totalValue,
      hour_tracking: true,
      payment_cycle: project.paymentCycle,
      customer_id: project.customerId,
    };

    return fetcher(`${baseUrl}/projects`, JSON.stringify({ ..._project }));
  }

  updateProject = async (project: IProjectValues, projectId: string, projectStatus: PROJECT_STATUS) => {
    const _project = {
      id: projectId,
      name: project.name,
      start_date: format(project.startDate, 'yyyy-MM-dd'),
      end_date: project.endDate ? format(project.endDate, 'yyyy-MM-dd') : undefined,
      service: project.service,
      status: projectStatus,
      type: project.type,
      rate: project.rate,
      total_value: project.totalValue,
      hour_tracking: true,
      payment_cycle: project.paymentCycle,
      customer_id: project.customerId,
    };

    return fetcher(`${baseUrl}/projects`, JSON.stringify({ ..._project }), 'PATCH');
  };

  updateProjectStatus = async (project: Project, status: PROJECT_STATUS) => {
    const _project = {
      id: project._id,
      name: project.name,
      start_date: format(project._startDate, 'yyyy-MM-dd'),
      end_date: project._endDate ? format(project._endDate, 'yyyy-MM-dd') : undefined,
      service: project.service,
      status,
      type: project.type,
      rate: project.rate,
      total_value: project.totalValue,
      hour_tracking: project.hourTracking,
      payment_cycle: project.paymentCycle,
      customer_id: project.customer,
    };

    return fetcher(`${baseUrl}/projects`, JSON.stringify({ ..._project }), 'PATCH');
  };

  deleteProject = async (projectId: string) => {
    return fetcher(
      `${baseUrl}/projects`,
      JSON.stringify({
        id: projectId,
      }),
      'DELETE',
    );
  };

  /** Customers */
  async getUserCustomers(): Promise<ICustomerApi[]> {
    return fetcher(`${baseUrl}/customers`, '', 'GET');
  }

  async createCustomer(customer: ICustomerValues): Promise<ICustomerApi> {
    return fetcher(
      `${baseUrl}/customers`,
      JSON.stringify({
        ...customer,
        contact_birth_date: customer.contact_birth_date ? format(customer.contact_birth_date, 'yyyy-MM-dd') : undefined,
      }),
    );
  }

  updateCustomer = async (customer: ICustomerValues, customerId: string) => {
    return fetcher(
      `${baseUrl}/customers`,
      JSON.stringify({
        ...customer,
        id: customerId,
        contact_birth_date: customer.contact_birth_date ? format(customer.contact_birth_date, 'yyyy-MM-dd') : undefined,
      }),
      'PATCH',
    );
  };

  deleteCustomer = async (customerId: string) => {
    return fetcher(`${baseUrl}/customers`, JSON.stringify({ id: customerId }), 'DELETE');
  };

  /** Payments */
  createPayment = (payment: IPaymentValues, projectId: string) => {
    return fetcher(
      `${baseUrl}/payments`,
      JSON.stringify({
        sum: payment.sum,
        comment: payment.comment,
        is_advance: payment.isAdvance,
        submit_date: format(payment.submitDate, 'yyyy-MM-dd'),
        project_id: projectId,
      }),
    );
  };

  updatePayment = (data: IPaymentValues, paymentId: string) => {
    return fetcher(
      `${baseUrl}/payments`,
      JSON.stringify({
        sum: data.sum,
        comment: data.comment,
        is_advance: data.isAdvance,
        submit_date: format(data.submitDate, 'yyyy-MM-dd'),
        id: paymentId,
      }),
      'PATCH',
    );
  };

  deletePayment = async (paymentId: string) => {
    return fetcher(
      `${baseUrl}/payments`,
      JSON.stringify({
        id: paymentId,
      }),
      'DELETE',
    );
  };

  /** WorkHours */
  async getWorkHours(): Promise<IWorkHourResponse[]> {
    return fetcher(`${baseUrl}/work-hours`, '', 'GET');
  }

  async createWorkHour(workHour: WorkHour, projectId?: string) {
    const _workHour = {
      start_time: workHour._startTime.toISOString(),
      end_time: workHour._endTime?.toISOString(),
      comment: workHour._comment,
    };

    return fetcher(`${baseUrl}/work-hours`, JSON.stringify({ ..._workHour, project_id: projectId }));
  }

  updateWorkHour = async (workHour: WorkHour) => {
    const _workHour = {
      start_time: workHour._startTime.toISOString(),
      end_time: workHour._endTime?.toISOString(),
      comment: workHour.comment,
      id: workHour._id,
    };

    return fetcher(`${baseUrl}/work-hours`, JSON.stringify(_workHour), 'PATCH');
  };

  deleteWorkHour = async (workHourId: string) => {
    return fetcher(`${baseUrl}/work-hours`, JSON.stringify({ id: workHourId }), 'DELETE');
  };

  /** Additions */
  async createAddition(addition: IAdditionValues, projectId: string): Promise<IAddition> {
    return fetcher(
      `${baseUrl}/additions`,
      JSON.stringify({
        date: format(addition.date, 'yyyy-MM-dd'),
        value: addition.value,
        comment: addition.comment,
        approved_by: addition.approvedBy,
        payment_id: addition.payment,
        project_id: projectId,
      }),
    );
  }

  async updateAddition(addition: IAdditionValues, additionId: string): Promise<IAddition> {
    return fetcher(
      `${baseUrl}/additions`,
      JSON.stringify({
        date: format(addition.date, 'yyyy-MM-dd'),
        value: addition.value,
        comment: addition.comment,
        approved_by: addition.approvedBy,
        payment_id: addition.payment,
        id: additionId,
      }),
      'PATCH',
    );
  }

  async deleteAddition(additionId: string): Promise<string> {
    return fetcher(
      `${baseUrl}/additions`,
      JSON.stringify({
        id: additionId,
      }),
      'DELETE',
    );
  }

  /** Expenses */
  async createExpense(expense: IExpenseValues, projectId: string): Promise<IExpense> {
    return fetcher(
      `${baseUrl}/expenses`,
      JSON.stringify({
        date: format(expense.date, 'yyyy-MM-dd'),
        value: expense.value,
        comment: expense.comment,
        name: expense.name,
        project_id: projectId,
      }),
    );
  }

  async updateExpense(expense: IExpenseValues, expenseId: string): Promise<IExpense> {
    return fetcher(
      `${baseUrl}/expenses`,
      JSON.stringify({
        date: format(expense.date, 'yyyy-MM-dd'),
        value: expense.value,
        comment: expense.comment,
        name: expense.name,
        id: expenseId,
      }),
      'PATCH',
    );
  }

  async deleteExpense(expenseId: string): Promise<string> {
    return fetcher(
      `${baseUrl}/expenses`,
      JSON.stringify({
        id: expenseId,
      }),
      'DELETE',
    );
  }
}
