import { runInAction } from 'mobx';
import createRouter, { Plugin, PluginFactory, Router, State } from 'router5';
import browserPlugin from 'router5-plugin-browser';

import { RootStore } from '../models/RootStore';

import { Routes, InsightsRoute, LoginRoute } from './routes';
import { RoutingStore } from './RoutingStore';

function makeMobxRouterPlugin(routes: Routes, store: RootStore, routingStore: RoutingStore): PluginFactory {
  function mobxRouterPlugin(): Plugin {
    return {
      onTransitionSuccess(nextState?: State, prevState?: State) {
        const prevParams = (prevState || ({} as any)).params || {};
        const nextParams = nextState && nextState.params ? nextState.params : {};
        const prevRoute = routes[(prevState || ({} as any)).name];
        const nextRoute = getNextRoute(routes, store.isInitialized, nextState?.name);

        if (prevRoute != null && prevRoute.deactivate != null) {
          prevRoute.deactivate(store, prevParams, nextState);
        }

        runInAction(() => {
          routingStore.setCurrentViewModel(nextRoute.viewModel(store, routingStore, nextParams));
          routingStore.route = nextRoute;
        });
      },
    };
  }
  return mobxRouterPlugin as any as PluginFactory;
}

export function makeMobxRouter(routes: Routes, store: RootStore, routingStore: RoutingStore): Router {
  const router = createRouter(Object.values(routes));
  router.usePlugin(browserPlugin(), makeMobxRouterPlugin(routes, store, routingStore));
  return router;
}

function getNextRoute(routes: Routes, isStoreInitialized: boolean, routeName?: string) {
  if (routeName) {
    return routes[routeName];
  } else if (!isStoreInitialized) {
    return routes[LoginRoute.name];
  }
  // return default page
  return routes[InsightsRoute.name];
}
