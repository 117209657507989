import { Box, Flex, Text } from '@chakra-ui/react';
import { useState } from 'react';

import { HelpDialog } from '../../../components/Dialogs/HelpDialog';
import { IUserData } from '../../../models/userData/useUserData';
import { SubscriptionDialog } from '../subscriptionDialog/SubscriptionDialog';

import { SubscriptionEndedContent } from './SubscriptionEndedContent';

export const SubscriptionEnded = ({ userData }: { userData: IUserData }) => {
  const { email, username, paymentState } = userData;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);

  return (
    <Box
      position="relative"
      h="100vh"
      data-testid="subscription-ended"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box width="550px" borderRadius="10px" background="#FFF" boxShadow="0px 0px 40px 5px rgba(39, 12, 67, 0.15)">
        <Flex direction="column" alignItems="center">
          {isModalOpen ? (
            <SubscriptionDialog onDismiss={() => setIsModalOpen(false)} isDialog={false} />
          ) : (
            <>
              <SubscriptionEndedContent
                paymentState={paymentState}
                username={username}
                onClick={() => setIsModalOpen(true)}
              />
              <Box
                position="absolute"
                bottom="80px"
                left="50%"
                transform="translateX(-50%)"
                fontSize="md"
                textAlign="center"
                opacity="0.7"
              >
                <Text>יש לך שאלות? משהו מונע ממך לשדרג?</Text>
                <Text cursor="pointer" textDecoration="underline" onClick={() => setHelpOpen(true)}>
                  הצוות שלנו ישמח לעזור
                </Text>
              </Box>
            </>
          )}
        </Flex>
      </Box>
      {helpOpen && <HelpDialog userName={username} userEmail={email} onDismiss={() => setHelpOpen(false)} />}
    </Box>
  );
};
