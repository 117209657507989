import { makeObservable, observable, action, computed } from 'mobx';

import { Project } from '../../../models/Project';
import { ProjectsModel } from '../../../models/ProjectsModel';
import { IStore } from '../../../models/RootStore';
import { WorkHour } from '../../../models/WorkHour';
import { IWorkHourApi } from '../../../models/workHours/workHourApi';
import { getMinimumWorkHourDate, getMaximumWorkHourDate } from '../../../models/workHourUtils';
import { PROJECT_STATUS } from '../../../requests/Client';

export enum REPORTING_TYPE {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
}

export class CreateWorkHourViewModel implements IStore {
  projectsModel: ProjectsModel;

  selectedProject: Project | null;
  isProjectSelected: boolean;

  timerType: REPORTING_TYPE;

  constructor(projectsModel: ProjectsModel) {
    this.projectsModel = projectsModel;

    this.timerType = REPORTING_TYPE.AUTOMATIC;
    this.selectedProject = null;
    this.isProjectSelected = false;

    makeObservable(this, {
      projectsModel: observable,
      timerType: observable,

      saveWorkHour: action,
      handleToggleState: action,
      selectedProject: observable,
      setSelectedProject: action,
      isProjectSelected: observable,

      allProjects: computed,

      minimumDate: computed,
      maximumDate: computed,
    });
  }

  activate() {}

  get allProjects() {
    const projects = this.projectsModel.projects
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((p) => p.status === PROJECT_STATUS.ACTIVE)
      .map((p) => ({
        key: p.name,
        value: p._id,
      }));

    return [{ key: 'ללא פרויקט', value: '' }, ...projects];
  }

  setSelectedProject = (projectId?: string) => {
    this.selectedProject = projectId ? this.projectsModel.getProjectById(projectId) || null : null;
    // handle case of selecting "no project"
    this.isProjectSelected = Boolean(projectId || projectId === '');
  };

  saveWorkHour = async (newWorkHour: IWorkHourApi) => {
    if (!newWorkHour.end_time) {
      // return early if saving a partial work hour
      return;
    }

    const workHour = new WorkHour(newWorkHour);

    if (newWorkHour.project_id) {
      await this.projectsModel.createWorkHour(workHour, newWorkHour.project_id.toString());
    }
  };

  get minimumDate() {
    return this.selectedProject ? getMinimumWorkHourDate(this.selectedProject) : undefined;
  }

  get maximumDate() {
    return this.selectedProject ? getMaximumWorkHourDate(this.selectedProject) : undefined;
  }

  handleToggleState = () => {
    this.timerType = this.timerType === REPORTING_TYPE.AUTOMATIC ? REPORTING_TYPE.MANUAL : REPORTING_TYPE.AUTOMATIC;
  };
}
