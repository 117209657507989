import { Icon, IconProps } from '@chakra-ui/react';

export const ExpenseIcon = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 25 24">
      <g clipPath="url(#clip0_7641_16776)">
        <path
          d="M20.0835 15.9998C20.0835 16.8278 19.4115 17.4998 18.5835 17.4998C17.7555 17.4998 17.0835 16.8278 17.0835 15.9998C17.0835 15.1718 17.7555 14.4998 18.5835 14.4998C19.4115 14.4998 20.0835 15.1718 20.0835 15.9998ZM23.0835 7.99984C22.5305 7.99984 22.0835 8.44784 22.0835 8.99984V20.9998C22.0835 21.5508 21.6355 21.9998 21.0835 21.9998H5.0835C3.4295 21.9998 2.0835 20.6538 2.0835 18.9998V8.99984V8.99484C2.9375 9.63484 3.9865 9.99984 5.0825 9.99984H15.0825C15.6355 9.99984 16.0825 9.55184 16.0825 8.99984C16.0825 8.44784 15.6355 7.99984 15.0825 7.99984H5.0835C4.2275 7.99984 3.4305 7.61884 2.8665 6.99584C3.4155 6.38884 4.2015 5.99984 5.0835 5.99984H12.0835C12.6365 5.99984 13.0835 5.55184 13.0835 4.99984C13.0835 4.44784 12.6365 3.99984 12.0835 3.99984H5.0835C2.0835 3.99984 0.0834961 6.49984 0.0834961 8.99984V18.9998C0.0834961 21.7568 2.3265 23.9998 5.0835 23.9998H21.0835C22.7375 23.9998 24.0835 22.6538 24.0835 20.9998V8.99984C24.0835 8.44784 23.6365 7.99984 23.0835 7.99984ZM16.7865 4.21084L18.0835 2.92984V8.99984C18.0835 9.55184 18.5305 9.99984 19.0835 9.99984C19.6365 9.99984 20.0835 9.55184 20.0835 8.99984V2.94784L21.3865 4.21584C21.5805 4.40484 21.8315 4.49984 22.0835 4.49984C22.3445 4.49984 22.6045 4.39884 22.8005 4.19784C23.1855 3.80184 23.1775 3.16884 22.7805 2.78384L20.5535 0.61584C19.7325 -0.20316 18.4015 -0.20216 17.5835 0.61184L15.3795 2.78884C14.9865 3.17684 14.9835 3.80984 15.3705 4.20284C15.7605 4.59684 16.3915 4.59884 16.7855 4.21184L16.7865 4.21084Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_7641_16776">
          <rect width="24" height="24" fill="white" transform="translate(0.0834961)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
