import {
  add,
  endOfDay,
  format,
  isSameWeek,
  isSameYear,
  isWithinInterval,
  startOfDay,
  sub,
  isSameMonth,
} from 'date-fns';
import groupBy from 'lodash/groupBy';
import isUndefined from 'lodash/isUndefined';
import { observable, action, computed } from 'mobx';
import { makeObservable } from 'mobx';

import { ProjectsModel } from '../../models/ProjectsModel';
import { IStore } from '../../models/RootStore';
import { getWorkHoursDuration, getWorkHoursSum } from '../../models/workHourUtils';
import { PROJECT_STATUS } from '../../requests/Client';
import { RoutingStore } from '../../router/RoutingStore';

import { WorkHour } from './../../models/WorkHour';
import { PROJECT_TYPE } from './../../requests/Client';
import { durationToMinutesDisplay } from './../../utils/time/timeUtils';

export enum WORK_HOUR_PRESETS {
  YESTERDAY = 'YESTERDAY',
  TODAY = 'TODAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

interface WorkHourWithProject {
  workHour: WorkHour;
  projectName: string;
}

export class InsightsViewModel implements IStore {
  private projectsModel: ProjectsModel;
  routingStore: RoutingStore;

  selectedWorkHourPreset: WORK_HOUR_PRESETS;

  constructor(projectsModel: ProjectsModel, routingStore: RoutingStore) {
    this.projectsModel = projectsModel;
    this.routingStore = routingStore;

    this.selectedWorkHourPreset = WORK_HOUR_PRESETS.TODAY;

    makeObservable(this, {
      selectedWorkHourPreset: observable,
      setSelectedWorkHourPreset: action,

      workHoursProjectBreakdown: computed,
      workHourDonutData: computed,
    });
  }

  activate() {}

  get isLoading() {
    return this.projectsModel.isLoading;
  }

  get isEmpty() {
    return !this.projectsModel.projects.length;
  }

  get allProjects() {
    return this.projectsModel.projects.filter((p) => p.status !== PROJECT_STATUS.CANCELLED);
  }
  get allWorkHours() {
    return this.allProjects.reduce<WorkHourWithProject[]>(
      (all, project) => all.concat(project.workHours.map((wh) => ({ workHour: wh, projectName: project.name }))),
      [],
    );
  }

  get totalIncomeInYear() {
    const now = new Date();
    return this.allProjects.reduce((totalIncome, project) => {
      totalIncome += project.payments.reduce((total, payment) => {
        if (!payment.paymentDate || !isSameYear(payment.paymentDate, now)) {
          return total;
        }
        if (project.type === PROJECT_TYPE.FIXED) {
          total += payment.sum || 0;
        } else {
          const totalWorkHours = project.getPaymentWorkHours(payment);
          total += totalWorkHours ? getWorkHoursSum(totalWorkHours, project.rate) : 0;
        }
        return total;
      }, 0);
      return totalIncome;
    }, 0);
  }

  get expectedIncomePerMonth() {
    const now = new Date();
    const data: { [k: string]: number } = {
      [format(sub(now, { months: 6 }), 'MM/yy')]: 0,
      [format(sub(now, { months: 5 }), 'MM/yy')]: 0,
      [format(sub(now, { months: 4 }), 'MM/yy')]: 0,
      [format(sub(now, { months: 3 }), 'MM/yy')]: 0,
      [format(sub(now, { months: 2 }), 'MM/yy')]: 0,
      [format(sub(now, { months: 1 }), 'MM/yy')]: 0,
      [format(now, 'MM/yy')]: 0,
      [format(add(now, { months: 1 }), 'MM/yy')]: 0,
      [format(add(now, { months: 2 }), 'MM/yy')]: 0,
      [format(add(now, { months: 3 }), 'MM/yy')]: 0,
      [format(add(now, { months: 4 }), 'MM/yy')]: 0,
      [format(add(now, { months: 5 }), 'MM/yy')]: 0,
    };

    this.allProjects.forEach((project) => {
      project.payments.reduce((acc, payment) => {
        const key = format(payment.paymentDate!, 'MM/yy');
        if (isUndefined(acc[key])) {
          return acc;
        }

        if (project.type === PROJECT_TYPE.FIXED) {
          acc[key] += payment.sum || 0;
        } else {
          const totalWorkHours = project.getPaymentWorkHours(payment);
          acc[key] += totalWorkHours ? getWorkHoursSum(totalWorkHours, project.rate) : 0;
        }
        return acc;
      }, data);
    });
    return Object.entries(data).map(([month, value]) => ({ label: month, data: value }));
  }

  get projectsPerExpectedIncomePerMonth() {
    const now = new Date();
    const data: { [k: string]: string[] } = {
      [format(sub(now, { months: 6 }), 'MM/yy')]: [],
      [format(sub(now, { months: 5 }), 'MM/yy')]: [],
      [format(sub(now, { months: 4 }), 'MM/yy')]: [],
      [format(sub(now, { months: 3 }), 'MM/yy')]: [],
      [format(sub(now, { months: 2 }), 'MM/yy')]: [],
      [format(sub(now, { months: 1 }), 'MM/yy')]: [],
      [format(now, 'MM/yy')]: [],
      [format(add(now, { months: 1 }), 'MM/yy')]: [],
      [format(add(now, { months: 2 }), 'MM/yy')]: [],
      [format(add(now, { months: 3 }), 'MM/yy')]: [],
      [format(add(now, { months: 4 }), 'MM/yy')]: [],
      [format(add(now, { months: 5 }), 'MM/yy')]: [],
    };

    this.allProjects.forEach((project) => {
      project.payments.reduce((acc, payment) => {
        const key = format(payment.paymentDate!, 'MM/yy');
        if (isUndefined(acc[key])) {
          return acc;
        }

        acc[key].push(project.name);
        return acc;
      }, data);
    });
    for (let key in data) {
      data[key] = Array.from(new Set(data[key]));
    }
    return data;
  }

  get projectsStatusSummary() {
    return this.projectsModel.projects.reduce(
      (acc, p) => {
        acc[p.status]++;
        return acc;
      },
      {
        [PROJECT_STATUS.ACTIVE]: 0,
        [PROJECT_STATUS.CANCELLED]: 0,
        [PROJECT_STATUS.COMPLETE]: 0,
        [PROJECT_STATUS.PAUSED]: 0,
      },
    );
  }

  get topServices() {
    const services = groupBy(this.allProjects, (p) => p.service);

    const sortedService = Object.keys(services)
      .reduce<{ service: string; data: number }[]>((acc, service) => {
        acc.push({
          service,
          data: services[service].length,
        });
        return acc;
      }, [])
      .sort((a, b) => b.data - a.data);

    const totalProjects = this.allProjects.length;

    return sortedService
      .reduce<{ service: string; data: number }[]>((acc, service) => {
        if (acc.length < 3) {
          acc.push(service);
        } else if (acc.length === 3) {
          acc.push({
            service: 'אחר',
            data: service.data,
          });
        } else {
          acc[3].data += service.data;
        }
        return acc;
      }, [])
      .map((s) => ({
        label: s.service,
        data: Math.round((s.data / totalProjects) * 100),
      }));
  }

  get totalHoursPerPreset(): { [key in WORK_HOUR_PRESETS]: string } {
    return {
      [WORK_HOUR_PRESETS.YESTERDAY]: durationToMinutesDisplay(
        getWorkHoursDuration(this.getWorkHoursByPreset(WORK_HOUR_PRESETS.YESTERDAY).map((wh) => wh.workHour)),
      ),
      [WORK_HOUR_PRESETS.TODAY]: durationToMinutesDisplay(
        getWorkHoursDuration(this.getWorkHoursByPreset(WORK_HOUR_PRESETS.TODAY).map((wh) => wh.workHour)),
      ),
      [WORK_HOUR_PRESETS.WEEK]: durationToMinutesDisplay(
        getWorkHoursDuration(this.getWorkHoursByPreset(WORK_HOUR_PRESETS.WEEK).map((wh) => wh.workHour)),
      ),
      [WORK_HOUR_PRESETS.MONTH]: durationToMinutesDisplay(
        getWorkHoursDuration(this.getWorkHoursByPreset(WORK_HOUR_PRESETS.MONTH).map((wh) => wh.workHour)),
      ),
    };
  }

  get workHoursProjectBreakdown() {
    const workHours = this.getWorkHoursByPreset(this.selectedWorkHourPreset);

    const projects = groupBy(workHours, (wh) => wh.projectName);

    return Object.keys(projects).reduce<{ projectName: string; totalDuration: number }[]>((acc, projectName) => {
      acc.push({
        projectName,
        totalDuration: getWorkHoursDuration(projects[projectName].map((wh) => wh.workHour)),
      });
      return acc;
    }, []);
  }

  get workHourDonutData() {
    const sorted = this.workHoursProjectBreakdown.slice().sort((a, b) => b.totalDuration - a.totalDuration);
    return sorted.reduce<{ label: string; data: number }[]>((acc, current) => {
      if (acc.length < 6) {
        acc.push({
          label: current.projectName,
          data: current.totalDuration,
        });
      } else if (acc.length === 6) {
        acc.push({
          label: 'אחר',
          data: current.totalDuration,
        });
      } else {
        acc[6].data += current.totalDuration;
      }

      return acc;
    }, []);
  }

  setSelectedWorkHourPreset = (preset: WORK_HOUR_PRESETS) => {
    this.selectedWorkHourPreset = preset;
  };

  getWorkHoursByPreset = (preset: WORK_HOUR_PRESETS) => {
    const today = startOfDay(new Date());

    return this.allWorkHours.filter((wh) => {
      const startTime = wh.workHour._startTime;
      switch (preset) {
        case WORK_HOUR_PRESETS.TODAY:
          return isWithinInterval(startTime, {
            start: today,
            end: endOfDay(today),
          });
        case WORK_HOUR_PRESETS.YESTERDAY:
          return isWithinInterval(startTime, {
            start: sub(today, { days: 1 }),
            end: endOfDay(sub(today, { days: 1 })),
          });
        case WORK_HOUR_PRESETS.WEEK:
          return isSameWeek(startTime, today);
        case WORK_HOUR_PRESETS.MONTH:
          return isSameMonth(startTime, today);
        default:
          return false;
      }
    });
  };

  openHelpDialog = () => {
    this.routingStore.setHelpDialogOpen(true);
  };
}
