import { createIcon } from '@chakra-ui/icons';

export const ExclamationLightIcon = createIcon({
  displayName: 'ExclamationLightIcon',
  viewBox: '0 0 15 15',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M8.0767 6.73076C8.0767 6.41215 7.81839 6.15384 7.49977 6.15384C7.18116 6.15384 6.92285 6.41215 6.92285 6.73076V11.3461C6.92285 11.6648 7.18116 11.9231 7.49977 11.9231C7.81839 11.9231 8.0767 11.6648 8.0767 11.3461V6.73076Z"
    />,
    <path
      key="1"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9335 0.095177C10.0549 -7.59959e-06 8.94754 -7.55584e-06 7.53477 1.36471e-07H7.46523C6.05246 -7.55584e-06 4.94508 -7.59959e-06 4.06652 0.095177C3.16995 0.192315 2.44351 0.394092 1.82326 0.844731C1.44776 1.11755 1.11755 1.44776 0.844731 1.82326C0.394092 2.44351 0.192315 3.16995 0.095177 4.06652C-7.59959e-06 4.94508 -7.55584e-06 6.05246 1.36471e-07 7.46523V7.53477C-7.55584e-06 8.94754 -7.59959e-06 10.0549 0.095177 10.9335C0.192315 11.8301 0.394092 12.5565 0.844731 13.1768C1.11755 13.5522 1.44776 13.8825 1.82326 14.1552C2.44351 14.6059 3.16995 14.8077 4.06652 14.9048C4.94507 15 6.05246 15 7.46515 15H7.53485C8.94754 15 10.0549 15 10.9335 14.9048C11.8301 14.8077 12.5565 14.6059 13.1768 14.1552C13.5522 13.8825 13.8825 13.5522 14.1552 13.1768C14.6059 12.5565 14.8077 11.8301 14.9048 10.9335C15 10.0549 15 8.94754 15 7.53485V7.46515C15 6.05246 15 4.94507 14.9048 4.06652C14.8077 3.16995 14.6059 2.44351 14.1552 1.82326C13.8825 1.44776 13.5522 1.11755 13.1768 0.844731C12.5565 0.394092 11.8301 0.192315 10.9335 0.095177ZM2.50147 1.77822C2.89225 1.4943 3.39241 1.32881 4.1908 1.24231C4.99999 1.15464 6.04492 1.15385 7.5 1.15385C8.95508 1.15385 10 1.15464 10.8092 1.24231C11.6076 1.32881 12.1078 1.4943 12.4985 1.77822C12.7761 1.97986 13.0202 2.22393 13.2218 2.50147C13.5057 2.89225 13.6712 3.39241 13.7577 4.1908C13.8454 4.99999 13.8462 6.04492 13.8462 7.5C13.8462 8.95508 13.8454 10 13.7577 10.8092C13.6712 11.6076 13.5057 12.1078 13.2218 12.4985C13.0202 12.7761 12.7761 13.0202 12.4985 13.2218C12.1078 13.5057 11.6076 13.6712 10.8092 13.7577C10 13.8454 8.95508 13.8462 7.5 13.8462C6.04492 13.8462 4.99999 13.8454 4.1908 13.7577C3.39241 13.6712 2.89225 13.5057 2.50147 13.2218C2.22393 13.0202 1.97986 12.7761 1.77822 12.4985C1.4943 12.1078 1.32881 11.6076 1.24231 10.8092C1.15464 10 1.15385 8.95508 1.15385 7.5C1.15385 6.04492 1.15464 4.99999 1.24231 4.1908C1.32881 3.39241 1.4943 2.89225 1.77822 2.50147C1.97986 2.22393 2.22393 1.97986 2.50147 1.77822Z"
    />,
    <path
      key="2"
      fill="currentColor"
      d="M8.26942 4.42307C8.26942 4.8479 7.92503 5.1923 7.50019 5.1923C7.07534 5.1923 6.73096 4.8479 6.73096 4.42307C6.73096 3.99824 7.07534 3.65384 7.50019 3.65384C7.92503 3.65384 8.26942 3.99824 8.26942 4.42307Z"
    />,
  ],
});
