import { Flex } from '@chakra-ui/react';
import { observer } from 'mobx-react';

import { Loader } from '../../../components/Loader/Loader';
import { useOrderedTasks } from '../../../models/tasks/useOrderedTasks';
import { IWorkHourApi } from '../../../models/workHours/workHourApi';
import { EmptyBlurb } from '../../common/errors/EmptyBlurb';
import { NoTasksIcon } from '../../common/icons';
import { Tasks } from '../../task/Tasks';

export const ProjectMainTab = observer(
  ({
    projectId,
    editTask,
    saveWorkHour,
    createTask,
  }: {
    projectId: string;
    editTask(id: number): void;
    saveWorkHour: (workHour: IWorkHourApi) => Promise<void>;
    createTask: () => void;
  }) => {
    const { tasks, isLoading, onOrderChange } = useOrderedTasks(projectId);

    if (isLoading) {
      return <Loader />;
    }

    if (tasks.length === 0) {
      return (
        <Flex w="100%" justifyContent="center" mt="48px">
          <EmptyBlurb
            title="עדיין אין לך משימות"
            body="משימות יכולות לעזור בניהול השוטף של הפרויקט"
            icon={<NoTasksIcon fontSize="72px" mb="16px" />}
            button={{
              label: 'יצירת משימה חדשה',
              onClick: createTask,
            }}
          />
        </Flex>
      );
    }

    return (
      <Tasks
        tasks={tasks || []}
        editTask={editTask}
        isMinimal
        saveWorkHour={saveWorkHour}
        onOrderChange={onOrderChange}
        projectId={projectId}
      />
    );
  },
);
