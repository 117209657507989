import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { format, sub } from 'date-fns';
import { useState } from 'react';

import { DateRangePicker } from '../../components/DateRangePicker';
import { Page } from '../../components/Page';
import { Table } from '../../components/Table';
import {
  IAffiliateStatsApi,
  UserStatsApi,
  UserStats,
  getAffiliateStats,
  getUserPurchases,
  getUserStats,
} from '../../models/affiliateLink/affiliateLinkApi';
import { USER_ID } from '../../models/AuthenticationModel';

const stagingAdminIds = ['8d4222f4-8449-4a05-9faa-f1a3a7e1ab09', 'f775b652-b098-405b-b104-c7435a32aeb2'];
const prodAdminIds = ['4bdb9405-c774-4473-8b7e-aeb6d2e93b47', 'b95a48ff-54cb-4663-bc0d-c22cd7d6a7b1'];
export function isAdmin(id: string) {
  const adminIds = [...stagingAdminIds, ...prodAdminIds];
  return adminIds.includes(id);
}

export const AffiliateStats = () => {
  const now = new Date();
  const [[startTime, endTime], setDateRange] = useState<Array<string | null>>([
    sub(now, { months: 1 }).toISOString(),
    now.toISOString(),
  ]);
  const { data: affiliateData, isLoading: isAffiliateLoading } = useQuery({
    queryKey: ['AFFILIATE_STATS'],
    queryFn: getAffiliateStats,
  });

  const { data: userData, isLoading: isUsersLoading } = useQuery<UserStatsApi[]>({
    queryKey: ['USER_STATS', startTime, endTime],
    queryFn: () => getUserStats(startTime, endTime),
    enabled: Boolean(startTime && endTime),
  });

  const { data: purchaseData, isLoading: isPurchaseLoading } = useQuery<UserStatsApi[]>({
    queryKey: ['PURCHASE_STATS', startTime, endTime],
    queryFn: () => getUserPurchases(startTime, endTime),
    enabled: Boolean(startTime && endTime),
  });

  const handleDateChange = (start: Date | null, end: Date | null) => {
    setDateRange([start?.toISOString() || null, end?.toISOString() || null]);
  };

  const userId = window.localStorage.getItem(USER_ID);
  if (!isAdmin(userId!)) {
    window.location.href = '/';
  }

  const isLoading = isAffiliateLoading || isUsersLoading || isPurchaseLoading;

  if (isLoading) {
    return <div>loading...</div>;
  }

  const groupedUserData: Array<UserStats & { id: string }> = (userData || []).map((d) => {
    const data: UserStats & { id: string } = { ...d, purchases: 0, id: d.source };
    const purchase = (purchaseData || []).find((p) => p.source === d.source);
    if (purchase) {
      data.purchases = purchase.count;
    }
    return data;
  });

  const affiliateColumns = [
    {
      key: 'name',
      label: 'שותף',
      render: (stat: IAffiliateStatsApi) => (
        <Flex direction="column" maxW="250px">
          <Text mb="8px">{stat.affiliate_user_name || 'לא משתמש רשום'}</Text>
          <Text textStyle="medium" color="base.600">
            {stat.affiliate_email || 'אין מייל במערכת'} -{' '}
            {stat.affiliate_created_at ? format(new Date(stat.affiliate_created_at), 'dd.MM.yyyy') : 'אין תאריך'}
          </Text>
        </Flex>
      ),
    },
    {
      key: 'description',
      label: 'תיאור',
      render: (stat: IAffiliateStatsApi) => (
        <Box minW="250px">
          <Text>{stat.description}</Text>
        </Box>
      ),
    },
    {
      key: 'additional_months',
      label: 'חודשים נוספים',
      render: (stat: IAffiliateStatsApi) => <Text>{stat.additional_months}</Text>,
    },
    {
      key: 'is_active',
      label: 'פעיל',
      render: (stat: IAffiliateStatsApi) => <Text>{stat.is_active ? 'כן' : 'לא'}</Text>,
    },
    {
      key: 'link',
      label: 'קישור',
      render: (stat: IAffiliateStatsApi) => <a href={`https://peko.co.il?afid=${stat.id}`}>לינק</a>,
    },
    {
      key: 'total_sign_ups',
      label: 'סה״כ נרשמים',
      render: (stat: IAffiliateStatsApi) => <Text>{stat.users_signed_up}</Text>,
    },
    {
      key: 'total_paid',
      label: 'סה״כ משלמים',
      render: (stat: IAffiliateStatsApi) => <Text>{stat.users_paid}</Text>,
    },
  ];

  const userColumns = [
    {
      key: 'source',
      label: 'מקור',
      render: (stat: UserStats) => <Text>{stat.source || 'לא משתמש רשום'}</Text>,
    },
    {
      key: 'count',
      label: 'כמות',
      render: (stat: UserStats) => <Text>{stat.count}</Text>,
    },
    {
      key: 'purchases',
      label: 'רכישות',
      render: (stat: UserStats) => <Text>{stat.purchases}</Text>,
    },
  ];

  return (
    <Page title="נתוני שותפים">
      <Table columns={affiliateColumns} data={affiliateData || []} />
      <Heading textStyle="h3" mt="32px" mb="16px">
        נתוני משתמשים
      </Heading>
      <Box mb="16px">
        <DateRangePicker
          selectedStartTime={startTime ? new Date(startTime) : null}
          selectedEndTime={endTime ? new Date(endTime) : null}
          onDateSelect={handleDateChange}
        />
      </Box>
      <Table columns={userColumns} data={groupedUserData} />
    </Page>
  );
};
