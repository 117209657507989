import { Button, ButtonProps, SystemStyleObject } from '@chakra-ui/react';
import { forwardRef } from 'react';

interface SquareButtonProps extends ButtonProps {
  variant?: 'primary' | 'secondary' | 'danger' | 'ghost' | 'dangerGhost';
  size?: 'sm' | 'lg';
  icon: React.ReactElement;
}

export const SquareIconButton = forwardRef(
  ({ variant = 'primary', size = 'lg', icon, ...rest }: SquareButtonProps, ref) => {
    const styleOverride: { base?: SystemStyleObject; hover?: SystemStyleObject; active?: SystemStyleObject } = {};
    if (variant === 'ghost') {
      styleOverride.hover = {
        background: 'base.200',
        borderColor: 'transparent',
      };
      styleOverride.active = {
        background: 'base.300',
        borderColor: 'transparent',
      };
    } else if (variant === 'dangerGhost') {
      styleOverride.base = {
        color: 'error.100',
      };
      styleOverride.hover = {
        background: 'error.0',
        borderColor: 'transparent',
      };
      styleOverride.active = {
        background: 'error.25',
        borderColor: 'transparent',
      };
    }

    return (
      <Button
        ref={ref}
        variant={variant}
        _hover={styleOverride.hover}
        _active={styleOverride.active}
        sx={styleOverride.base}
        h={size === 'lg' ? '46px' : '38px'}
        fontSize="20px"
        lineHeight="1"
        p={size === 'lg' ? '12px' : '8px'}
        size={size}
        {...rest}
      >
        {icon}
      </Button>
    );
  },
);
