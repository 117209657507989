import { ChakraBaseProvider } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import { App } from './App';
import { RootStore } from './models/RootStore';
import { ErrorBoundary } from './pages/common/errors/ErrorBoundary';
import { Client } from './requests/Client';
import { UserClient } from './requests/UserClient';
import { ROUTES } from './router/routes';
import { RoutingStore } from './router/RoutingStore';
import theme from './theme/theme';

import './styles.css';

const client = Client;

const userClient = new UserClient();
const store = new RootStore(userClient, client);
const routingStore = new RoutingStore(store, ROUTES);

const MatritsaFont = () => (
  <Global
    styles={`
      :root {
        font-size: 16px;
      }
      /** import Matritsa */
      @font-face {
        font-family: 'Matritsa';
        font-style: bold;
        font-weight: 700;
        src: url('/matritsa/Matritsa-Bold.woff2') format('woff2'), url('/matritsa/Matritsa-Bold.woff') format('woff'), url('/matritsa/Matritsa-Bold') format('eot');
      }
      /** import Ploni */
      @font-face {
        font-family: 'Ploni';
        font-style: regular;
        font-weight: 400;
        src: url('/ploni/ploni-regular-aaa.woff2') format('woff2'), url('/ploni/ploni-regular-aaa.woff') format('woff'), url('/ploni/ploni-regular-aaa') format('eot');
      }
      `}
  />
);

if (import.meta.env.REACT_APP_ENV !== 'dev') {
  Sentry.init({
    dsn: 'https://7a0d6c7397fb4b5191b6cfa5a7ba316e@o4504088029954048.ingest.sentry.io/4504088033492992',
  });
  const tagManagerArgs = {
    gtmId: 'GTM-PLLVJNQ',
  };

  TagManager.initialize(tagManagerArgs);
}

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraBaseProvider theme={theme}>
        <MatritsaFont />
        <ErrorBoundary>
          <App store={store} routingStore={routingStore} />
        </ErrorBoundary>
      </ChakraBaseProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
