import { Box, Flex } from '@chakra-ui/layout';

export const Wizard = ({ step }: { step: number }) => {
  const totalSteps = new Array(4).fill(null);
  return (
    <Flex
      alignItems="center"
      gap="30px"
      position="relative"
      _before={{
        content: "''",
        display: 'block',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        height: '1px',
        width: '100%',
        backgroundColor: 'base.100',
      }}
    >
      {totalSteps.map((s, i) => (
        <Box
          key={i}
          h="12px"
          w="12px"
          borderRadius="50%"
          border="1px solid"
          borderColor="base.900"
          bg={i < step ? 'base.900' : 'white'}
          zIndex="1"
        />
      ))}
    </Flex>
  );
};
