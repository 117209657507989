import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const Card = ({
  overflow = 'visible',
  testid,
  children,
  id = '',
}: {
  overflow?: 'hidden' | 'visible';
  testid?: string;
  children: ReactNode;
  id?: string;
}) => {
  return (
    <Box
      border="1px solid"
      borderColor="base.200"
      borderRadius="16px"
      py="24px"
      px="32px"
      bg="white"
      overflow={overflow}
      position="relative"
      id={id}
      data-testid={testid || ''}
    >
      {children}
    </Box>
  );
};
