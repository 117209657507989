import { Button } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import { Flex, Heading, Text } from '@chakra-ui/layout';

export const BlockMobileUsage = ({ onLogout }: { onLogout(): void }) => {
  return (
    <Flex direction="column" alignItems="center" py="32px" px="68px" textAlign="center">
      <Image src="/logo-dark.png" width="110px" mb="110px" />
      <Image src="/no-mobile.svg" width="110px" mb="30px" />
      <Heading textStyle="h3" mb="30px">
        מכאן, צריך
        <br />
        כבר מחשב
      </Heading>
      <Text textStyle="large" mb="110px">
        בשלב זה, המערכת שלנו לא מותאמת לשימוש במכשירים ניידים ולכן צריך להמשיך את השימוש במחשב
      </Text>
      <Button variant="link" onClick={onLogout}>
        התנתקות מהחשבון
      </Button>
    </Flex>
  );
};
