import { useMutation, useQueryClient } from '@tanstack/react-query';

import { logger } from '../../utils/logger';

import { Task, deleteTask } from './tasksApi';
import { TASKS_QUERY_KEY } from './useTasks';

export function useDeleteTask() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (task: Task) => deleteTask(task.id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TASKS_QUERY_KEY],
      });
    },
    onError: (e) => {
      logger.error('Failed to create userData', e);
    },
  });

  return {
    deleteTask: mutateAsync,
  };
}
