export function roundTo2(value: number) {
  // round to 2 decimal points
  // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
  return Math.round((value + Number.EPSILON) * 100) / 100;
}

export function removeMoreThenTwoDecimals(value: number) {
  const [integer, decimal] = value.toString().split('.');
  if (decimal && decimal.length > 2) {
    return parseFloat(`${integer}.${decimal.slice(0, 2)}`);
  }
  return value;
}

export function limitToTwoDecimals(value: string) {
  if (!value.includes('.')) {
    const maybeNumber = parseInt(value);
    return isNaN(maybeNumber) ? value : maybeNumber;
  }
  // Remove any non-numeric characters except the dot
  let result = value.replace(/[^0-9.]/g, '');

  const numericValue = parseFloat(result);
  if (!isNaN(numericValue)) {
    const formattedValue = removeMoreThenTwoDecimals(numericValue);
    if (value.endsWith('.')) {
      return `${formattedValue}.`;
    }
    if (value.endsWith('.0')) {
      return `${formattedValue}.0`;
    }
    return formattedValue;
  }

  return undefined;
}

export function formatToCompactNotation(num: number): string {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2).replace(/\.?0+$/, '') + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(2).replace(/\.?0+$/, '') + 'K';
  } else {
    return num.toString();
  }
}
