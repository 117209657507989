import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { format, isWithinInterval } from 'date-fns';
import flattenDeep from 'lodash/flattenDeep';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { ButtonTabs } from '../../components/ButtonTabs';
import { TIME_PRESETS, TIME_PRESETS_NAMES } from '../../components/DateRangePicker';
import { SelectItem } from '../../components/form/Select';
import { Loader } from '../../components/Loader/Loader';
import { useCustomToast } from '../../hooks/toast/useCustomToast';
import { useWorkHours, WORK_HOURS_QUERY_KEY } from '../../models/workHours/useWorkHours';
import { getWorkHoursDuration2 } from '../../models/workHourUtils';
import { durationToMinutesDisplay } from '../../utils/time/timeUtils';
import { EmptyBlurb } from '../common/errors/EmptyBlurb';
import { EmptyState } from '../common/errors/EmptyState';
import { BinocularsIcon } from '../common/icons/BinocularsIcon';

import { SummaryPill } from './SummaryPill';
import { AggregatedWorkHours } from './workHoursPage/AggregatedWorkHours';
import { DetailedWorkHours } from './workHoursPage/DetailedWorkHours';
import { WorkHourFilters } from './workHoursPage/WorkHourFilters';
import { WorkHoursBarChart } from './workHoursPage/WorkHoursBarChart';
import { EnrichedWorkHour, WorkHoursViewModel } from './WorkHoursViewModel';

type WorkHoursFiltersType = {
  selectedProjects: Array<SelectItem>;
  selectedCustomers: Array<SelectItem>;
  startTime: Date | null;
  endTime: Date | null;
};

const today = new Date();
const defaultPreset = TIME_PRESETS_NAMES.THIS_MONTH;
const BASE_FILTERS: WorkHoursFiltersType = {
  selectedProjects: [],
  selectedCustomers: [],
  startTime: TIME_PRESETS[defaultPreset].start(today),
  endTime: TIME_PRESETS[defaultPreset].end(today),
};

const WORK_HOURS_FILTERS_KEY = 'workHoursFilters';

enum AggregationType {
  ALL = 'all',
  PROJECT = 'project',
}

const AggregationDisplayType = [
  {
    label: 'תצוגה מפורטת',
    id: AggregationType.ALL,
  },
  {
    label: 'תצוגה מסכמת',
    id: AggregationType.PROJECT,
  },
];

export const WorkHours = observer(({ viewModel }: { viewModel: WorkHoursViewModel }) => {
  const { workHours, isLoading } = useWorkHours();
  const queryClient = useQueryClient();

  const enrichedWorkHours: EnrichedWorkHour[] = (workHours || []).map((wh) => {
    const project = viewModel.projectById(wh.project?._id);
    return {
      ...wh,
      projectName: project?.name || 'ללא',
      projectId: wh.project._id,
      customerName: project ? viewModel.getCustomerNameByProjectName(project.name) : undefined,
    };
  });

  const { triggerToast } = useCustomToast();
  const [isGraphOpen, setIsGraphOpen] = useState(false);
  const [aggregation, setAggregation] = useState<AggregationType>(AggregationType.ALL);
  const [filters, setFilters] = useSessionStorage<WorkHoursFiltersType>(WORK_HOURS_FILTERS_KEY, BASE_FILTERS);

  const handleDateSelect = (start: Date | null, end: Date | null) => {
    setFilters((prev) => ({ ...prev, startTime: start, endTime: end }));
  };

  const refetchWorkHours = () => {
    queryClient.invalidateQueries({
      queryKey: [WORK_HOURS_QUERY_KEY],
    });
  };

  useEffect(() => {
    if (viewModel.deleteWorkHourState) {
      triggerToast(viewModel.deleteWorkHourState);
      viewModel.setDeleteWorkHourState(null);
      refetchWorkHours();
    }
  }, [viewModel.deleteWorkHourState]);

  if (isLoading) {
    return <Loader />;
  }

  if (workHours.length === 0) {
    return (
      <Box position="relative">
        <EmptyState
          title="מעקב שעות"
          body="מרכז ניהול הזמן שלך. כאן אפשר לראות את כל השעות שעבדת על פי תקופה ובחיתוך לפי פרויקט או לקוח."
          secondaryText="מתחילים במדידה של זמן באמצעות הפעלת הטיימר בראש המסך"
          imageSrc="/no-work-hours.svg"
        />
        <Image src="/no-work-hours-arrow.svg" position="absolute" top="55px" right="155px" />
      </Box>
    );
  }

  const filteredWorkHours = enrichedWorkHours
    .filter((wh) => {
      if (filters.startTime && filters.endTime) {
        return isWithinInterval(wh.startTime, {
          start: filters.startTime,
          end: filters.endTime,
        });
      }
      return true;
    })
    .filter((wh) => {
      // filter by selected projects
      if (filters.selectedProjects.length) {
        return filters.selectedProjects.find((p) => (p.value ? p.value === wh.project._id : p.key === wh.projectName));
      }
      return true;
    })
    .filter((wh) => {
      // filter by selected customers
      if (filters.selectedCustomers.length) {
        return filters.selectedCustomers.find((c) => c.key === wh.customerName);
      }
      return true;
    });

  return (
    <Box id="export">
      <Flex justifyContent="space-between" mb="16px" position="relative">
        <Heading textStyle="h3">שעות</Heading>
        {filters.startTime && filters.endTime && (
          <Text>
            הופק באמצעות Peko.co.il | טווח הדו״ח - {format(filters.endTime, 'dd.MM.yyyy')} -{' '}
            {format(filters.startTime, 'dd.MM.yyyy')}
          </Text>
        )}
        <Box id="ignore-me" position="absolute" left="0" height="22px" width="485px" bgColor="#f8f5fa" />
      </Flex>
      <WorkHourFilters
        allProjects={viewModel.allProjects}
        allCustomers={viewModel.allCustomers}
        selectedProjects={filters.selectedProjects}
        selectedCustomers={filters.selectedCustomers}
        onProjectSelectionChange={(projects) => setFilters((prev) => ({ ...prev, selectedProjects: projects }))}
        onCustomerSelectionChange={(customers) => setFilters((prev) => ({ ...prev, selectedCustomers: customers }))}
        selectedStartTime={filters.startTime ? new Date(filters.startTime) : null}
        selectedEndTime={filters.endTime ? new Date(filters.endTime) : null}
        onDateSelect={handleDateSelect}
        isGraphOpen={isGraphOpen}
        toggleGraph={() => setIsGraphOpen((prev) => !prev)}
        currentWorkHours={flattenDeep(Object.values(filteredWorkHours).map((v) => Object.values(v)))}
      />
      <Box id="ignore-me">
        <WorkHoursBarChart
          startTime={filters.startTime}
          endTime={filters.endTime}
          workHours={filteredWorkHours}
          isOpen={isGraphOpen}
        />
      </Box>
      {filteredWorkHours.length ? (
        <Box>
          <Flex justifyContent="space-between" my="30px">
            <ButtonTabs
              items={AggregationDisplayType}
              selectedId={aggregation}
              handleClick={(id) => setAggregation(id as AggregationType)}
              size="lg"
            />
            <SummaryPill label="סה״כ כללי" value={durationToMinutesDisplay(getWorkHoursDuration2(filteredWorkHours))} />
          </Flex>
          {aggregation === AggregationType.ALL ? (
            <DetailedWorkHours
              workHours={filteredWorkHours}
              editWorkHour={viewModel.editWorkHour}
              deleteWorkHour={viewModel.deleteWorkHour}
              getProjectById={viewModel.projectById}
            />
          ) : (
            <AggregatedWorkHours
              workHours={filteredWorkHours}
              editWorkHour={viewModel.editWorkHour}
              deleteWorkHour={viewModel.deleteWorkHour}
            />
          )}
        </Box>
      ) : (
        <Flex justifyContent="center" alignItems="center" direction="column" textAlign="center" mt="95px">
          <EmptyBlurb
            title="אין לנו תוצאות להציג"
            body="כדאי לשנות את הפילטרים או את טווח הזמן"
            icon={<BinocularsIcon color="#42CEB3" fontSize="60px" mb="15px" />}
          />
        </Flex>
      )}
    </Box>
  );
});
