import { AddIcon } from '@chakra-ui/icons';
import { Text, Stack, Box, Flex, Grid } from '@chakra-ui/react';
import { useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { SquareIconButton } from '../../../components/SquareIconButton';
import { TableActions } from '../../../components/TableActions';
import { useVat } from '../../../hooks/useVat/useVat';
import { Expense, IExpenseValues } from '../../../models/Expense';
import { DataPoint } from '../../common/DataPoint';
import { EmptyBlurb } from '../../common/errors/EmptyBlurb';
import { NoTasksIcon } from '../../common/icons';

import { CreateExpense } from './CreateProjectExpense';

export const EXPENSE_DIALOG = 'isExpenseDialogOpen';

export const ProjectExpenses = ({
  expensesByYear,
  createExpense,
  updateExpense,
  deleteExpense,
}: {
  expensesByYear: { [year: string]: Expense[] } | null;
  createExpense: (data: IExpenseValues) => void;
  updateExpense: (data: IExpenseValues, id: string) => void;
  deleteExpense: (id: string) => void;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useSessionStorage(EXPENSE_DIALOG, false);
  const [editExpense, setEditExpense] = useState<Expense | null>(null);
  const { getValueWithVat } = useVat();

  const years = expensesByYear ? Object.keys(expensesByYear) : null;

  return (
    <Box>
      <Flex direction="row-reverse" alignItems="center">
        <SquareIconButton
          onClick={() => setIsDialogOpen((prevState) => !prevState)}
          aria-label="create expense"
          variant="ghost"
          icon={<AddIcon />}
        />
      </Flex>
      <Stack>
        {!years?.length && (
          <Flex justifyContent="center" alignItems="center" direction="column" textAlign="center" mt="50px">
            <EmptyBlurb
              title="עוד לא הוגדרו הוצאות "
              body="הוצאה היא עלות נוספת שלא בהכרח הגיעה מצד הלקוח אבל הכרחית להשלמת הפרויקט"
              icon={<NoTasksIcon fontSize="72px" mb="16px" />}
              button={{
                label: 'יצירת הוצאה חדשה',
                onClick: () => setIsDialogOpen(true),
              }}
            />
          </Flex>
        )}
        {years &&
          years.map((year) => (
            <Box
              position="relative"
              _before={{
                content: "''",
                display: 'block',
                position: 'absolute',
                right: '28px',
                borderLeft: '2px solid',
                borderColor: 'base.200',
                height: 'calc(100% - 30px)',
                top: '32px',
                width: '1px',
                zIndex: '0',
              }}
            >
              <Flex my={3} alignItems="center">
                <Box
                  marginInlineStart="24px"
                  height="12px"
                  width="12px"
                  border="2px solid"
                  borderColor="base.200"
                  borderRadius="full"
                />
                <Text marginInlineStart={5} fontSize="xl">
                  {year}
                </Text>
              </Flex>
              <Stack gap={5}>
                {expensesByYear &&
                  expensesByYear[year].map((expense) => (
                    <Grid
                      key={expense._id}
                      templateColumns="100px 280px 1fr  120px 84px"
                      border="1px solid"
                      borderColor="base.200"
                      borderRadius="2xl"
                      py={5}
                      px={6}
                      bg="white"
                      gap={10}
                      zIndex={1}
                      sx={{
                        '&:hover .menu': {
                          display: 'block',
                        },
                      }}
                    >
                      <DataPoint label="תאריך" value={expense.displayDate} />
                      <DataPoint label="עבור" value={expense.name} />
                      <Box maxW="500px">
                        <DataPoint label="תיאור ההוצאה" value={expense.comment || ''} />
                      </Box>
                      <DataPoint label="סכום" value={`${getValueWithVat(expense.value).toLocaleString()} ₪`} />
                      <Box className="menu" display="none" alignSelf="center">
                        <TableActions
                          onDelete={() => deleteExpense(expense.id)}
                          onEdit={() => {
                            setEditExpense(expense);
                            setIsDialogOpen(true);
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
              </Stack>
            </Box>
          ))}
      </Stack>
      {isDialogOpen && (
        <CreateExpense
          initialValues={editExpense}
          onSubmit={(data) => {
            if (editExpense) {
              updateExpense(data, editExpense.id);
            } else {
              createExpense(data);
            }
          }}
          onDismiss={() => {
            if (editExpense) {
              setEditExpense(null);
            }
            setIsDialogOpen(false);
          }}
        />
      )}
    </Box>
  );
};
