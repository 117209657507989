import { createIcon } from '@chakra-ui/icons';

export const FailedCreditCardIcon = createIcon({
  displayName: 'FailedCreditCardIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M3.33333 13.3359C2.23067 13.3359 1.33333 12.4386 1.33333 11.3359V6.66927H6.66667C7.03533 6.66927 7.33333 6.3706 7.33333 6.0026C7.33333 5.6346 7.03533 5.33594 6.66667 5.33594H1.33333V4.66927C1.33333 3.5666 2.23067 2.66927 3.33333 2.66927H12.6667C13.7475 2.66927 14.6311 3.53143 14.6656 4.60403C14.6663 4.62569 14.6667 7.98078 14.6667 8.0026C14.6667 8.3706 14.9647 8.66927 15.3333 8.66927C15.702 8.66927 16 8.3706 16 8.0026C16 7.98081 15.9998 4.62573 15.9994 4.60403C15.9645 2.79602 14.4829 1.33594 12.6667 1.33594H11.9327H10.552H5.448H4.06733H3.33333C1.49533 1.33594 0 2.83127 0 4.66927V11.3359C0 13.1739 1.49533 14.6693 3.33333 14.6693C3.702 14.6693 4 14.3706 4 14.0026C4 13.6346 3.702 13.3359 3.33333 13.3359Z"
    />,
    <path
      key="1"
      fill="currentColor"
      d="M14.6655 14.6636H12.6669C12.2982 14.6636 12.0002 14.365 12.0002 13.997C12.0002 13.629 12.2982 13.3303 12.6669 13.3303H14.6655L10.6715 6.66031L6.6642 13.337L8.66687 13.3303C9.03554 13.3303 9.33354 13.629 9.33354 13.997C9.33354 14.365 9.03554 14.6636 8.66687 14.6636H6.66754C6.1842 14.6636 5.7522 14.4136 5.51154 13.9943C5.27087 13.5756 5.2722 13.0763 5.51554 12.6583L9.51754 5.97498C9.7562 5.56698 10.1869 5.32031 10.6662 5.32031C11.1455 5.32031 11.5755 5.56765 11.8182 5.98165L15.8129 12.6523C16.0609 13.0763 16.0622 13.5756 15.8215 13.995C15.5809 14.4143 15.1482 14.6636 14.6655 14.6636ZM11.3335 11.997V9.99698C11.3335 9.62898 11.0355 9.33031 10.6669 9.33031C10.2982 9.33031 10.0002 9.62898 10.0002 9.99698V11.997C10.0002 12.365 10.2982 12.6636 10.6669 12.6636C11.0355 12.6636 11.3335 12.365 11.3335 11.997ZM10.6669 13.3303C10.2989 13.3303 10.0002 13.629 10.0002 13.997C10.0002 14.365 10.2989 14.6636 10.6669 14.6636C11.0349 14.6636 11.3335 14.365 11.3335 13.997C11.3335 13.629 11.0349 13.3303 10.6669 13.3303Z"
    />,
    <path
      key="2"
      fill="currentColor"
      d="M3.66699 12C4.21928 12 4.66699 11.5523 4.66699 11C4.66699 10.4477 4.21928 10 3.66699 10C3.11471 10 2.66699 10.4477 2.66699 11C2.66699 11.5523 3.11471 12 3.66699 12Z"
    />,
  ],
});
