import { createIcon } from "@chakra-ui/icons";

export const InvoiceIcon = createIcon({
  displayName: "InvoiceIcon",
  viewBox: "0 0 13 16",
  path: [
    <path
      key="0"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.05126 3.55761e-05C7.22672 -4.63647e-05 7.39188 -0.000123499 7.54834 0.00147674L7.54834 1.47866C7.54832 2.21587 7.5483 2.83061 7.61537 3.31863C7.68616 3.83374 7.84188 4.29881 8.22388 4.67252C8.60589 5.04624 9.08127 5.19859 9.6078 5.26784C10.1066 5.33345 10.735 5.33343 11.4886 5.33341L12.9985 5.33341C13.0001 5.48647 13 5.64804 13 5.81967L12.9999 10.5066C12.9999 11.6287 13 12.5332 12.9022 13.2445C12.8007 13.9831 12.5836 14.6049 12.0787 15.0988C11.5739 15.5927 10.9383 15.8051 10.1833 15.9044C9.45622 16 8.53169 16 7.38469 16H5.61524C4.46823 16 3.54371 16 2.81658 15.9044C2.06166 15.8051 1.42602 15.5927 0.921192 15.0988C0.416362 14.6049 0.199227 13.9831 0.0977297 13.2445C-2.99569e-05 12.5332 -1.63892e-05 11.6287 4.3991e-07 10.5066V5.49348C-1.63892e-05 4.37137 -2.99507e-05 3.4669 0.0977297 2.75555C0.199227 2.017 0.416362 1.39516 0.921192 0.901278C1.42602 0.4074 2.06166 0.194976 2.81658 0.0956806C3.54371 4.15397e-05 4.46823 5.51874e-05 5.61524 7.2109e-05L7.05126 3.55761e-05ZM7.36411 7.15467C7.11845 6.91435 6.72018 6.91435 6.47452 7.15467C6.22887 7.39499 6.22887 7.78463 6.47452 8.02495L7.49747 9.0257H3.98385C3.63645 9.0257 3.35482 9.30122 3.35482 9.64108C3.35482 9.98095 3.63645 10.2565 3.98385 10.2565H7.49747L6.47452 11.2572C6.22887 11.4975 6.22887 11.8872 6.47452 12.1275C6.72018 12.3678 7.11845 12.3678 7.36411 12.1275L9.46087 10.0762C9.70652 9.8359 9.70652 9.44626 9.46087 9.20594L7.36411 7.15467Z"
    />,
    <path
      key="1"
      fill="currentColor"
      d="M12.7605 3.73961C12.8105 3.85767 12.8501 3.97802 12.8814 4.10264H11.5322C10.7237 4.10264 10.1806 4.10134 9.77543 4.04805C9.3884 3.99715 9.22276 3.90917 9.11347 3.80224C9.00417 3.69532 8.91424 3.53327 8.8622 3.15463C8.80774 2.7583 8.8064 2.22697 8.8064 1.43599V0.11604C8.93378 0.14668 9.0568 0.18539 9.17746 0.234288C9.75732 0.469264 10.2054 0.908067 10.8502 1.53948L11.4264 2.10313C12.0718 2.73394 12.5203 3.17233 12.7605 3.73961Z"
    />,
  ],
});
