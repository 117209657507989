import { Flex, Text, Box, Button, Spacer } from '@chakra-ui/react';

import { Card } from '../../components/Card';
import { DateRangePicker } from '../../components/DateRangePicker';
import { MultiSelect } from '../../components/form/MultiSelect';
import { SelectItem } from '../../components/form/Select';
import { StyledInput } from '../../components/form/StyledInput';
import { Project } from '../../models/Project';
import { PriorityOptions, statusOptions } from '../../models/tasks/taskUtils';
import { SearchIcon } from '../common/icons';

interface TaskFilterProps {
  projects?: Project[];
  selectedProjects: Array<SelectItem>;
  setSelectedProjects(items: Array<SelectItem>): void;
  startTime: Date | null;
  endTime: Date | null;
  handleDateSelect(start: Date | null, end: Date | null): void;
  isMinimal: boolean;
  selectedPriorities: Array<SelectItem>;
  setSelectedPriorities(items: Array<SelectItem>): void;
  selectedStatuses: Array<SelectItem>;
  setSelectedStatuses(items: Array<SelectItem>): void;
  nameFilter: string;
  setNameFilter(e: string): void;
  clearFilters(): void;
}

export const TaskFilters = ({
  isMinimal,
  projects,
  selectedProjects,
  setSelectedProjects,
  selectedPriorities,
  setSelectedPriorities,
  selectedStatuses,
  setSelectedStatuses,
  startTime,
  endTime,
  handleDateSelect,
  nameFilter,
  setNameFilter,
  clearFilters,
}: TaskFilterProps) => {
  return isMinimal ? (
    <>
      <Text color="base.600" fontStyle="extraLarge" mb="15px">
        סינון לפי
      </Text>
      <Flex gap="20px">
        <Box>
          <DateRangePicker
            selectedStartTime={startTime}
            selectedEndTime={endTime}
            onDateSelect={handleDateSelect}
            allowEmpty
            size="sm"
          />
        </Box>
        <Box minW="144px" maxW="144px">
          <MultiSelect
            placeholder="חשיבות"
            data={PriorityOptions}
            selectedItems={selectedPriorities}
            onChange={(items) => setSelectedPriorities(items)}
            size="sm"
            removeSearch
          />
        </Box>
        <Box minW="144px" maxW="144px">
          <MultiSelect
            placeholder="סטטוס"
            data={statusOptions}
            selectedItems={selectedStatuses}
            onChange={(items) => setSelectedStatuses(items)}
            size="sm"
            removeSearch
          />
        </Box>
        <Button variant="link" onClick={clearFilters}>
          איפוס
        </Button>
        <Spacer />
        <StyledInput
          name="search"
          minW="340px"
          placeholder="חיפוש משימה"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
          endIcon={<SearchIcon />}
          size="sm"
        />
      </Flex>
    </>
  ) : (
    <Card>
      <Text fontStyle="extraLarge" mb="22px">
        סינון לפי
      </Text>
      <Flex gap="20px">
        <Box>
          <DateRangePicker
            selectedStartTime={startTime}
            selectedEndTime={endTime}
            onDateSelect={handleDateSelect}
            allowEmpty
            size="sm"
          />
        </Box>
        {projects && projects.length > 0 && (
          <Box minW="190px">
            <MultiSelect
              placeholder="פרויקט"
              data={projects.map((p) => ({ key: p.name, value: p._id }))}
              selectedItems={selectedProjects}
              onChange={(items) => setSelectedProjects(items)}
              enableAll
              menuWidth="300px"
              size="sm"
            />
          </Box>
        )}
        <Box minW="160px">
          <MultiSelect
            placeholder="חשיבות"
            data={PriorityOptions}
            selectedItems={selectedPriorities}
            onChange={(items) => setSelectedPriorities(items)}
            removeSearch
            size="sm"
          />
        </Box>
        <Box minW="160px">
          <MultiSelect
            placeholder="סטטוס"
            data={statusOptions}
            selectedItems={selectedStatuses}
            onChange={(items) => setSelectedStatuses(items)}
            removeSearch
            size="sm"
          />
        </Box>
        <Button variant="link" onClick={clearFilters}>
          איפוס
        </Button>
        <Spacer />
        <StyledInput
          name="search"
          minW="280px"
          placeholder="חיפוש משימה"
          value={nameFilter}
          onChange={(e) => setNameFilter(e.target.value)}
          endIcon={<SearchIcon />}
          size="sm"
        />
      </Flex>
    </Card>
  );
};
