import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { fetcher } from '../../requests/fetcher';
import { logger } from '../../utils/logger';

import { getUserData, IUserDataApi } from './userDataApi';
import { apiToUserData } from './userDataMapper';

export enum NUM_CO_WORKERS {
  ALONE = 'ALONE',
  GROUP = 'GROUP',
}

export enum BUSINESS_TYPE {
  OSEK = 'OSEK',
  MURSHE = 'MURSHE',
  COMPANY = 'COMPANY',
}

export type PaymentState = 'PAYING' | 'PAYMENT_FAILED' | 'TRIAL' | 'BETA' | 'CANCELLED';

export interface IUserData {
  id: string;

  email: string;
  username: string;
  emailVerified: boolean;
  baseRate: number;
  services: string[];
  companyName: string;
  industry: string;
  numberOfCoWorkers: NUM_CO_WORKERS | null;
  numberOfYears: string | null; // one of - 0-1 | 2-4 | 5-7 | 8+
  businessType: BUSINESS_TYPE | null;
  yearlyGoal: number;
  phone: string | null;
  birthDate: Date | null;
  createdAt: Date;
  includeVat: boolean;
  paymentState: PaymentState;
  paymentToken?: string;
  payment4Digits?: string;
  payplusUserId?: string;
  recurringTransactionId?: string;
  paymentStartDate?: Date;
  paymentNextDate?: Date;
}

export const USER_DATA_QUERY_KEY = 'userData';

export function useUserData(enabled = true, poll = false) {
  const { isLoading, error, data } = useQuery<IUserDataApi>({
    queryKey: [USER_DATA_QUERY_KEY],
    queryFn: getUserData,
    enabled,
    refetchInterval: (query) => {
      if (poll && query.state.data?.payment_state === 'TRIAL') {
        return 1000;
      }
      return false;
    },
  });

  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation({
    mutationFn: async () => {
      return fetcher(
        '/.netlify/functions/cancel-subscription',
        JSON.stringify({
          email: userData?.email,
          recurringUid: userData?.recurringTransactionId,
        }),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USER_DATA_QUERY_KEY],
      });
    },
    onError: (e) => {
      logger.error('Failed to cancel subscription userData', e);
    },
  });
  const userData = data && apiToUserData(data);

  return {
    isLoading,
    isEmpty: !userData?.services,
    error,
    userData,
    cancelSubscription: mutateAsync,
  };
}
