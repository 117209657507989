import { SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { StyledInput } from '../../components/form/StyledInput';
import { Page } from '../../components/Page';
import { Table } from '../../components/Table';
import { TableActions } from '../../components/TableActions';
import { useVat } from '../../hooks/useVat/useVat';
import { Customer } from '../../models/Customer';
import { EmptyBlurb } from '../common/errors/EmptyBlurb';
import { EmptyState } from '../common/errors/EmptyState';
import { NoTasksIcon } from '../common/icons';

import { CustomersViewModel } from './CustomersViewModel';
import { DeleteCustomerDialog } from './DeleteCustomerDialog';

export const Customers = observer(({ viewModel }: { viewModel: CustomersViewModel }) => {
  const [customerToDelete, setCustomerToDelete] = useState<Customer | null>(null);
  const { getValueWithVat } = useVat();

  const columns = [
    {
      key: 'name',
      label: 'שם',
      render: (customer: Customer) => (
        <Box maxWidth="250px">
          <Text noOfLines={1} display="block" whiteSpace="nowrap" title={customer.name}>
            {customer.name}
          </Text>
        </Box>
      ),
    },
    {
      key: 'email',
      label: 'מייל',
      render: (customer: Customer) => customer.email,
    },
    {
      key: 'phone',
      label: 'טלפון',
      render: (customer: Customer) => customer.phone || '-',
    },
    {
      key: 'projects',
      label: 'פרויקטים',
      render: (customer: any) => viewModel.customerTotalProjects(customer._id),
    },
    {
      key: 'income',
      label: 'סה״כ הכנסות',
      render: (customer: Customer) =>
        `${getValueWithVat(viewModel.customerTotalValue(customer._id)).toLocaleString()} ₪`,
    },
  ];

  if (!viewModel.customersExist) {
    return (
      <EmptyState
        title="ניהול לקוחות"
        body="כאן יוצג מידע על הלקוחות שמאחורי הפרויקטים שלך על פי מגוון מדדים שימושיים. מומלץ להוסיף לקוח חדש כדי לראות איך הכל עובד"
        onClick={viewModel.createCustomer}
        imageSrc="/no-customers.svg"
        buttonText="+ יצירת לקוח"
      />
    );
  }

  return (
    <Page title="לקוחות">
      <Flex mb="16px" direction="row-reverse">
        <StyledInput
          name="search"
          w="314px"
          type="text"
          placeholder="חיפוש לקוח"
          value={viewModel.filter}
          onChange={(e) => viewModel.setFilter(e.target.value)}
          endIcon={<SearchIcon />}
          size="sm"
        />
      </Flex>
      {viewModel.customers.length ? (
        <Table
          columns={columns}
          data={viewModel.customers}
          onRowClick={(c) => viewModel.onCustomerClick(c._id)}
          actions={{
            render: (customer: any) => (
              <TableActions
                onDelete={() => setCustomerToDelete(customer)}
                onEdit={() => viewModel.editCustomer(customer._id)}
              />
            ),
          }}
        />
      ) : (
        <Flex justifyContent="center" alignItems="center" direction="column" textAlign="center" mt="95px">
          <EmptyBlurb
            title="אין לך לקוח עם השם הזה"
            body="כדאי לשנות לטקסט אחר"
            icon={<NoTasksIcon fontSize="72px" mb="16px" />}
            button={{
              label: 'איפוס',
              onClick: () => viewModel.setFilter(''),
            }}
          />
        </Flex>
      )}
      {customerToDelete && (
        <DeleteCustomerDialog
          customer={customerToDelete}
          customerProjects={viewModel.customerProjects(customerToDelete._id)}
          onSubmit={() => viewModel.deleteCustomer(customerToDelete._id)}
          onClose={() => setCustomerToDelete(null)}
        />
      )}
    </Page>
  );
});
