import { ButtonProps, chakra } from '@chakra-ui/react';

interface IIconButtonProps extends ButtonProps {
  variant?: 'primary' | 'secondary';
  color?: 'base' | 'accent' | 'success' | 'primary';
  size?: 'sm' | 'lg';
  icon: React.ReactElement;
}

export const IconButton = ({ variant = 'primary', color = 'base', size = 'lg', icon, ...rest }: IIconButtonProps) => {
  const sizes = {
    sm: {
      h: '28px',
      w: '28px',
      p: '6px',
      fontSize: 'md',
    },
    lg: {
      h: '40px',
      w: '40px',
      p: '12px',
      fontSize: 'md',
    },
  };
  const colors = {
    base: {
      bg: 'base.800',
      hover: 'base.900',
      active: 'base.1000',
      secondaryBg: 'base.100',
      disabled: 'base.400',
    },
    accent: {
      bg: 'accent.100',
      hover: 'accent.200',
      active: 'accent.300',
      secondaryBg: 'accent.0',
      disabled: 'accent.25',
    },
    success: {
      bg: 'success.100',
      hover: 'success.200',
      active: 'success.300',
      secondaryBg: 'success.0',
      disabled: 'success.25',
    },
    primary: {
      bg: 'primary.100',
      hover: 'primary.200',
      active: 'primary.300',
      secondaryBg: 'primary.0',
      disabled: 'primary.25',
    },
  };
  const variants = {
    primary: {
      color: 'base.0',
      bg: colors[color].bg,
      _hover: {
        bg: colors[color].hover,
        _disabled: {
          bg: colors[color].disabled,
        },
      },
      _active: {
        bg: colors[color].active,
      },
      _disabled: {
        bg: colors[color].disabled,
        cursor: 'not-allowed',
      },
    },
    secondary: {
      color: colors[color].bg,
      bg: 'transparent',
      border: '1px solid',
      borderColor: colors[color].bg,
      _hover: {
        bg: colors[color].secondaryBg,
        _disabled: {
          bg: 'transparent',
          borderColor: colors[color].disabled,
          color: colors[color].disabled,
        },
      },
      _active: {
        bg: colors[color].secondaryBg,
        border: 'none',
      },
      _disabled: {
        bg: 'transparent',
        borderColor: colors[color].disabled,
        color: colors[color].disabled,
        cursor: 'not-allowed',
      },
    },
  };

  return (
    <chakra.button
      borderRadius="50%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...variants[variant]}
      {...sizes[size]}
      {...rest}
    >
      {icon}
    </chakra.button>
  );
};
