import { Box, Flex, Heading } from '@chakra-ui/layout';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useEventListener } from 'usehooks-ts';

import { Dialog } from '../../../components/Dialog';
import { USER_DATA_QUERY_KEY, useUserData } from '../../../models/userData/useUserData';
import { fetcher } from '../../../requests/fetcher';
import { logger } from '../../../utils/logger';
import { SubscriptionExplanation } from '../SubscriptionExplanation';
import { SubscriptionFailure } from '../SubscriptionFailure';
import { SubscriptionFrame } from '../SubscriptionFrame';
import { SubscriptionSuccess } from '../SubscriptionSuccess';

export type CURRENT_VIEW = 'explanation' | 'iframe' | 'success' | 'failure';

const GET_PAYMENT_LINK = 'GET_PAYMENT_LINK';

export const SubscriptionDialog = ({ onDismiss, isDialog = true }: { onDismiss: () => void; isDialog?: boolean }) => {
  const { userData } = useUserData();
  const queryClient = useQueryClient();

  const [url, setUrl] = useState<string | undefined>();

  const { data, isLoading, error } = useQuery({
    queryKey: [GET_PAYMENT_LINK],

    queryFn: () =>
      fetcher(
        '/.netlify/functions/generate-payment-link',
        JSON.stringify({
          email: userData?.email,
          name: userData?.username,
        }),
      ),

    enabled: Boolean(userData),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (data) {
      setUrl(JSON.parse(data).url);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      logger.error(new Error('Failed to generate payment link'), { email: userData?.email });
    }
  }, [error]);

  const [currentView, setCurrentView] = useState<CURRENT_VIEW>('explanation');

  const onPaymentComplete = (e: MessageEvent<'success' | 'failure'>) => {
    if (e.origin !== window.location.origin || !['success', 'failure'].includes(e.data)) {
      logger.error('unknown post-message received');
      return;
    }
    queryClient.invalidateQueries({
      queryKey: [GET_PAYMENT_LINK],
    });
    setCurrentView(e.data);
  };
  useEventListener('message', onPaymentComplete);

  const onSuccessDismiss = () => {
    queryClient.invalidateQueries({
      queryKey: [USER_DATA_QUERY_KEY],
    });
    onDismiss();
  };

  const getBackgroundColor = () => {
    if (currentView === 'success') {
      return '#f3f2fd';
    }
    if (currentView === 'failure') {
      return '#f8ddf7';
    }
    return '#fff';
  };

  const getTitle = () => {
    return currentView === 'iframe' || currentView === 'explanation' ? 'פרטי המנוי' : '';
  };

  const getViewComponent = () => {
    if (currentView === 'explanation') {
      return <SubscriptionExplanation onClick={() => setCurrentView('iframe')} />;
    }
    if (currentView === 'success') {
      return <SubscriptionSuccess name={userData?.username!} email={userData?.email!} onDismiss={onSuccessDismiss} />;
    }
    if (currentView === 'failure') {
      return <SubscriptionFailure onRetry={() => setCurrentView('iframe')} />;
    }

    return url ? <SubscriptionFrame url={url} isLoading={isLoading} /> : null;
  };

  const bgColor = getBackgroundColor();
  const title = getTitle();
  return (
    <>
      {isDialog ? (
        <Dialog size="medium" title={title} onClose={onDismiss} backgroundColor={bgColor}>
          {getViewComponent()}
        </Dialog>
      ) : (
        <Box w="100%" backgroundColor={bgColor}>
          <Flex justifyContent="center" mt="42px">
            <Heading textStyle="h3">{title}</Heading>
          </Flex>
          {getViewComponent()}
        </Box>
      )}
    </>
  );
};

/**
 * TODO:
 * Flows:
 * - on create:
 * - - set to trial
 * - - set next payment by closed list (30 / 60)
 * - on trial end:
 * - - banner
 * - - block screen
 * - on payment sucess
 * - - remove banner
 * - - unblock
 * - - update stats in settings page
 * - on payment cancel
 * - - block ui
 * - on payment failure
 * - - reset next payment?
 * - - display banner / lock
 * - - extend
 * - on payment update
 * - - dialog display
 * - - update stats in settings page
 */
