import { useMutation, useQueryClient } from '@tanstack/react-query';

import { logger } from '../../utils/logger';

import { WORK_HOURS_QUERY_KEY } from './useWorkHours';
import { updateWorkHour, IWorkHourApi } from './workHourApi';
import { roundTime, IValidWorkHourValues } from './workHourFormUtils';

export function useUpdateWorkHour() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation<
    IWorkHourApi,
    unknown,
    IValidWorkHourValues,
    { previousWorkHours: IWorkHourApi[] }
  >({
    mutationFn: async (workHour: IValidWorkHourValues) => {
      return updateWorkHour(workHour.endTime ? roundTime(workHour) : workHour);
    },
    onMutate: async (_newWorkHour) => {
      await queryClient.cancelQueries({
        queryKey: [WORK_HOURS_QUERY_KEY],
      });

      const previousWorkHours = queryClient.getQueryData<IWorkHourApi[]>([WORK_HOURS_QUERY_KEY]) || [];

      const newWorkHour = roundTime(_newWorkHour);
      const wh: IWorkHourApi = {
        id: parseInt(newWorkHour._id || '0'),
        start_time: newWorkHour.startTime.toISOString(),
        end_time: newWorkHour.endTime ? newWorkHour.endTime.toISOString() : null,
        comment: newWorkHour.comment || null,
        project_id: newWorkHour.projectId ? parseInt(newWorkHour.projectId) : undefined,
      };

      queryClient.setQueryData<IWorkHourApi[]>([WORK_HOURS_QUERY_KEY], (old) => {
        return (old || []).map((_wh) => (_wh.id.toString() === newWorkHour._id ? wh : _wh));
      });

      return { previousWorkHours };
    },
    onError: (e, _, context) => {
      if (context?.previousWorkHours) {
        queryClient.setQueryData([WORK_HOURS_QUERY_KEY], context.previousWorkHours);
      }
      logger.error('Failed to update work hour', e);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [WORK_HOURS_QUERY_KEY],
      });
    },
  });

  return { updateWorkHour: mutateAsync };
}
