import { Box, Text } from '@chakra-ui/react';

import { Table } from '../../../components/Table';
import { TableActions } from '../../../components/TableActions';
import { durationToMinutesDisplay, getDuration } from '../../../utils/time/timeUtils';
import { EnrichedWorkHour } from '../WorkHoursViewModel';

interface AggregatedWorkHour {
  id: string;
  projectName: string;
  customerName: string;
  duration: number;
}

export const AggregatedWorkHours = ({
  workHours,
  deleteWorkHour,
  editWorkHour,
}: {
  workHours: EnrichedWorkHour[];
  deleteWorkHour: (wh: EnrichedWorkHour) => void;
  editWorkHour: (wh: EnrichedWorkHour) => void;
}) => {
  const aggregation = workHours.reduce<{ [projectName: string]: AggregatedWorkHour }>((acc, wh) => {
    if (!acc[wh.projectName]) {
      acc[wh.projectName] = {
        id: wh.projectName,
        projectName: wh.projectName,
        customerName: wh.customerName || '',
        duration: 0,
      };
    }
    acc[wh.projectName].duration += getDuration(wh.startTime, wh.endTime);
    return acc;
  }, {});
  const totalDuration = Object.values(aggregation).reduce((acc, wh) => acc + wh.duration, 0);
  const columns = [
    {
      key: 'projectName',
      label: 'פרויקט',
      render: (wh: AggregatedWorkHour) => (
        <Box maxWidth="150px">
          <Text noOfLines={1} display="block" whiteSpace="nowrap" title={wh.projectName}>
            {wh.projectName}
          </Text>
        </Box>
      ),
      w: '200px',
    },
    {
      key: 'customer',
      label: 'לקוח',
      render: (wh: AggregatedWorkHour) => (
        <Box maxWidth="140px">
          <Text noOfLines={1} display="block" whiteSpace="nowrap" title={wh.customerName}>
            {wh.customerName}
          </Text>
        </Box>
      ),
      w: '170px',
    },
    {
      key: 'percent',
      label: 'אחוז',
      render: (wh: AggregatedWorkHour) => (
        <Box maxWidth="140px">
          <Text>{wh.duration ? ((wh.duration / totalDuration) * 100).toFixed(2) : 0}%</Text>
        </Box>
      ),
      w: '170px',
    },
    {
      key: 'duration',
      label: 'סה״כ',
      render: (wh: AggregatedWorkHour) => (
        <Box maxWidth="140px">
          <Text>{durationToMinutesDisplay(wh.duration)}</Text>
        </Box>
      ),
      w: '170px',
    },
  ];

  return <Table columns={columns} data={Object.values(aggregation)} title="סיכום השעות לפי פרויקט" />;
};
