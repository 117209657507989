import { Doughnut } from 'react-chartjs-2';

import { durationToMinutesDisplay } from '../../../utils/time/timeUtils';

export const options: any = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      backgroundColor: '#270C43',
      padding: 12,
      rtl: true,
      displayColors: false,
      callbacks: {
        label: function (ctx: any) {
          if (ctx.parsed.y !== null) {
            return `${ctx.label} - סה״כ ${durationToMinutesDisplay(ctx.parsed)}`;
          }
          return ctx.dataset.label;
        },
      },
    },
    datalabels: {
      display: false,
    },
    legend: {
      position: 'left',
      align: 'center',
      rtl: true,
      maxWidth: 120,
      onClick: () => {},
      labels: {
        usePointStyle: true,
        boxWidth: 10,
        boxHeight: 12,
        padding: 16,
        pointStyle: 'circle',
        generateLabels: (chart: any) => {
          return chart.data.labels.map((label: string, i: number) => ({
            datasetIndex: i,
            text: label.length > 13 ? ` ${label.slice(0, 12)}...` : ` ${label}                  `,
            fillStyle: chart.data.datasets[0].backgroundColor[i],
            strokeStyle: chart.data.datasets[0].backgroundColor[i],
            hidden: chart.getDatasetMeta(0).hidden,
          }));
        },
        font: {
          size: 14,
          family: 'Ploni',
        },
      },
    },
  },
};

const baseGraphData = {
  labels: [],
  datasets: [
    {
      label: 'Dataset 1',
      data: [],
      backgroundColor: ['#270C43', '#685888', '#D6D3FE', '#9A93EF', '#F3B2E9', '#42CEB3', '#94DDCF'],
      hoverBackgroundColor: ['#270C43', '#685888', '#D6D3FE', '#9A93EF', '#F3B2E9', '#42CEB3', '#94DDCF'],
    },
  ],
};

export function WorkHourDonutChart({ data }: { data: Array<{ label: string; data: number }>; includeGrid?: boolean }) {
  const graphData = {
    ...baseGraphData,
    labels: data.map((item) => item.label),
    datasets: [
      {
        ...baseGraphData.datasets[0],
        data: data.map((item) => item.data),
      },
    ],
  };

  return <Doughnut width="220px" height="220px" data={graphData} options={options} />;
}
