import format from 'date-fns/format';
import { makeObservable, observable, computed } from 'mobx';

import { IAddition } from '../requests/Client';
import { roundTo2 } from '../utils/number/numberUtils';

export interface IAdditionValues {
  date: Date;
  value?: number;
  comment?: string;
  approvedBy?: string;
  payment?: string; // id
}

export class Addition {
  _id: string;

  _date: Date;
  _value: number;
  _comment?: string;
  _approvedBy?: string;
  _payment?: string;

  rate: number;

  constructor(addition: IAddition, rate: number) {
    this._id = addition.id.toString() || '';
    this._date = new Date(addition.date);
    this._value = addition.value;
    this._comment = addition.comment;
    this._approvedBy = addition.approved_by;
    this._payment = addition.payment_id?.toString();

    this.rate = rate;

    makeObservable(this, {
      _id: observable,
      id: computed,
      _date: observable,
      date: computed,
      displayDate: computed,
      _comment: observable,
      comment: computed,
      _value: observable,
      value: computed,
      _approvedBy: observable,
      approvedBy: computed,
      _payment: observable,
      payment: computed,

      valueInHours: computed,
    });
  }

  get id() {
    return this._id;
  }

  get date(): string {
    return format(this._date, 'yyyy-MM-dd');
  }

  get displayDate(): string {
    return format(this._date, 'dd.MM.yyyy');
  }

  get comment() {
    return this._comment;
  }

  get approvedBy() {
    return this._approvedBy;
  }

  get payment() {
    return this._payment;
  }

  get value() {
    return this._value;
  }

  get valueInHours() {
    return roundTo2(this._value / this.rate);
  }
}
