import { radioAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

import { Checkbox } from './checkbox';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleControl = defineStyle((props) => {
  const control = Checkbox.baseStyle?.(props).control || {};

  return {
    ...control,
    borderRadius: 'full',
    borderColor: 'base.900',
    _checked: {
      // @ts-ignore
      ...control['_checked'],
      borderColor: 'base.900',
      bg: 'currentColor',
      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        w: '50%',
        h: '50%',
        borderRadius: '50%',
        bg: 'primary.100',
      },
    },
  };
});

const baseStyle = definePartsStyle((props) => ({
  label: Checkbox.baseStyle?.(props).label,
  container: Checkbox.baseStyle?.(props).container,
  control: baseStyleControl(props),
}));

const sizes = {
  md: definePartsStyle({
    control: { w: 4, h: 4 },
    label: { fontSize: 'md' },
  }),
  lg: definePartsStyle({
    control: { w: 5, h: 5 },
    label: { fontSize: 'lg' },
  }),
  sm: definePartsStyle({
    control: { width: 3, height: 3 },
    label: { fontSize: 'sm' },
  }),
};

export const Radio = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'md',
    colorScheme: 'blue',
  },
});
