import { IStore } from "../../models/RootStore";
import { RoutingStore } from "../../router/RoutingStore";

export class SettingsViewModel implements IStore {
  routingStore: RoutingStore;

  constructor(routingStore: RoutingStore) {
    this.routingStore = routingStore;
  }

  activate() {}

  openSubscriptionDialog = () => {
    this.routingStore.setIsSubscriptionDialogOpen(true);
  };
}
