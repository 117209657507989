import { Stack, Flex, Box, Text, Badge, Button } from '@chakra-ui/react';
import { getMonth } from 'date-fns';
import { flatten } from 'lodash';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { LabeledSeparator } from '../../../components/LabeledSeparator';
import { Table } from '../../../components/Table';
import { TableActions } from '../../../components/TableActions';
import { Project } from '../../../models/Project';
import { WorkHour } from '../../../models/WorkHour';
import { isAdvanceWorkHour } from '../../../models/workHourUtils';
import { MONTHS, durationToMinutesDisplay } from '../../../utils/time/timeUtils';
import { SummaryPill } from '../../workHour/SummaryPill';
import { WorkHoursReportDialog } from '../../workHour/WorkHoursReportDialog';
import { EnrichedWorkHour } from '../../workHour/WorkHoursViewModel';

export const ProjectWorkHours = observer(
  ({
    year,
    project,
    workHours,
    onDelete,
    onEdit,
    customerName,
  }: {
    year: string;
    workHours: WorkHour[];
    project: Project | undefined | null;
    onDelete: (wh: WorkHour) => void;
    onEdit: (wh: WorkHour) => void;
    customerName: string;
  }) => {
    const [isDownloadReportOpen, setIsDownloadReportOpen] = useState(false);

    const monthlyWorkHours = workHours.reduce<{
      [s: string]: { workHours: WorkHour[]; total: number };
    }>((acc, curr) => {
      const month = getMonth(curr._startTime);
      if (!acc[month.toString()]) {
        acc[month.toString()] = {
          workHours: [],
          total: 0,
        };
      }
      acc[month.toString()].workHours.push(curr);
      acc[month.toString()].total += curr.duration;
      return acc;
    }, {});

    const columns = [
      {
        key: 'displayDate',
        label: 'תאריך',
        render: (wh: any) => <Box w="10px">{wh.displayDate}</Box>,
        w: '150px',
      },
      {
        key: 'comment',
        label: 'תאור',
        render: (wh: any) => (
          <Box maxWidth="450px">
            <Text title={wh.comment || ''} noOfLines={1} display="block" whiteSpace="nowrap">
              {wh.comment || ''}
            </Text>
          </Box>
        ),
      },
      {
        key: '',
        label: '',
        render: (wh: any) =>
          project && isAdvanceWorkHour(wh._id, project) ? (
            <Badge color="brand.600" bg="purple.300">
              על חשבון מקדמה
            </Badge>
          ) : (
            <Box w="125px" />
          ),
        w: '125px',
      },
      {
        key: 'range',
        label: 'טווח',
        render: (wh: any) => `${wh.startTime}-${wh.endTime}`,
        w: '125px',
      },
      {
        key: 'total',
        label: 'סה״כ',
        render: (wh: any) => `${durationToMinutesDisplay(wh.duration)}`,
        w: '125px',
      },
    ];

    const exportWorkHours = flatten(
      Object.values(monthlyWorkHours).map(({ workHours }) =>
        workHours.map((wh) => ({
          _id: wh._id,
          startTime: wh._startTime,
          endTime: wh._endTime,
          date: wh._startTime,
          comment: wh.comment,
          projectName: project?.name,
          projectId: project?._id,
          customerName,
          project: {
            _id: project?._id,
          },
        })),
      ),
    );

    return (
      <>
        <Flex id="ignore-me" direction="row-reverse">
          <Button variant="secondary" size="sm" onClick={() => setIsDownloadReportOpen(true)}>
            הורדת דוח שעות עדכני
          </Button>
        </Flex>
        <Box mb="32px" px="180px">
          <LabeledSeparator label={year} />
        </Box>

        {Object.entries(monthlyWorkHours)
          .sort((a, b) => Number(b[0]) - Number(a[0]))
          .map(([month, { workHours, total }]) => (
            <Stack key={month} mb="50px" gap="10px">
              <Table
                title={MONTHS[Number(month)]}
                columns={columns}
                data={workHours.sort((a, b) => b._startTime.getTime() - a._startTime.getTime())}
                actions={{
                  render: (wh: any) => <TableActions onDelete={() => onDelete(wh)} onEdit={() => onEdit(wh)} />,
                }}
              />
              <Flex direction="row-reverse">
                <SummaryPill value={durationToMinutesDisplay(total)} label="סה״כ" />
              </Flex>
            </Stack>
          ))}
        {isDownloadReportOpen && (
          <WorkHoursReportDialog
            currentWorkHours={exportWorkHours as EnrichedWorkHour[]}
            onDismiss={() => setIsDownloadReportOpen(false)}
          />
        )}
      </>
    );
  },
);
