import { Box, Button, Stack, Text, Flex } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { set, isBefore, add } from 'date-fns';
import { Formik, FormikErrors, Form } from 'formik';
import { useState } from 'react';

import { Dialog } from '../../../components/Dialog';
import { FormAutocomplete } from '../../../components/form/FormAutocomplete';
import { FormDatePicker } from '../../../components/form/FormDatePicker';
import { FormInput } from '../../../components/form/FormInput';
import { WORK_HOURS_QUERY_KEY } from '../../../models/workHours/useWorkHours';
import { getWorkHourInitialValues, IWorkHourValues } from '../../../models/workHourUtils';
import { durationToMinutesDisplay, getDuration } from '../../../utils/time/timeUtils';

import { EditWorkHourViewModel } from './EditWorkHourViewModel';

export const EditWorkHour = ({ viewModel }: { viewModel: EditWorkHourViewModel }) => {
  const [error, setError] = useState('');
  const queryClient = useQueryClient();

  const refetchWorkHours = () => {
    queryClient.invalidateQueries({
      queryKey: [WORK_HOURS_QUERY_KEY],
    });
  };

  const initialValues = getWorkHourInitialValues(viewModel.workHour, viewModel.project?.name);

  const getDurationString = (start: string | undefined, end: string | undefined, date: Date) => {
    if (!start || !end) {
      return '00:00';
    }
    const [startHours, startMin] = start.split(':');
    const [endHours, endMin] = end.split(':');

    const startDate = set(date, {
      hours: Number(startHours),
      minutes: Number(startMin),
    });

    let endDate = set(date, {
      hours: Number(endHours),
      minutes: Number(endMin),
    });

    if (isBefore(endDate, startDate)) {
      endDate = add(endDate, {
        days: 1,
      });
    }

    return durationToMinutesDisplay(getDuration(startDate, endDate));
  };

  return (
    <Dialog title="עריכת דיווח" onClose={viewModel.onClose}>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: FormikErrors<IWorkHourValues> = {};
          if (!values.startTime) {
            errors.startTime = 'אנחנו צריכים את זה';
          }
          if (!values.endTime) {
            errors.endTime = 'בלי זה אי אפשר להמשיך';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          try {
            await viewModel.updateWorkHour(values as IWorkHourValues);
            refetchWorkHours();
            viewModel.onClose();
          } catch (_) {
            setError('ארעה שגיאה');
          }
        }}
      >
        {(props) => (
          <Form>
            <Stack gap="16px" mb="16px" px="32px">
              <FormAutocomplete
                size="sm"
                name="projectName"
                label="פרויקט"
                data={viewModel.allProjects}
                helperText={viewModel.getCustomerNameByProjectName(props.values.projectName || '')}
                menuWidth="300px"
              />
              <FormInput size="sm" name="comment" label="תיאור" type="textarea" rows={2} />
              <FormDatePicker
                size="sm"
                name="date"
                label="תאריך"
                minimumDate={viewModel.getMinimumDate(props.values)}
                maximumDate={viewModel.getMaximumDate(props.values)}
              />
              <Flex justifyContent="space-between" alignItems="center">
                <Flex alignItems="center" minWidth="204px">
                  <Box w="116px" marginInlineEnd="12px">
                    <FormInput size="sm" textAlign="end" name="startTime" type="time" label="התחלה" isRequired />
                  </Box>
                  <Text fontSize="xs" mt="10px" marginInlineEnd="12px">
                    -
                  </Text>
                  <Box w="116px" marginInlineEnd="24px">
                    <FormInput size="sm" name="endTime" textAlign="end" type="time" label="סיום" isRequired />
                  </Box>
                </Flex>
                <Flex backgroundColor="success.0" borderRadius="8px" py="8px" px="12px" alignItems="center">
                  <Text textStyle="large" color="base.1000">
                    {getDurationString(props.values.startTime, props.values.endTime, props.values.date || new Date())}
                  </Text>
                </Flex>
              </Flex>
            </Stack>
            <Flex justifyContent="space-between" alignItems="center" pb="32px" px="32px">
              <Button variant="secondary" size="sm" alignSelf="start" onClick={viewModel.onClose}>
                ביטול
              </Button>
              <Stack>
                <Button isLoading={props.isSubmitting} size="sm" type="submit" isDisabled={!props.isValid}>
                  שמירה
                </Button>
                {error && <Text color="red">{error}</Text>}
              </Stack>
            </Flex>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
