import { Button, Heading, Flex, Image, Text } from '@chakra-ui/react';

export const ErrorState = () => {
  return (
    <Flex direction="column" alignItems="center" pt="135px" m="0 auto" maxW="650px">
      <Image src="/error.svg" width="360px" mb="50px" />
      <Heading textStyle="h3" mb="30px">
        סליחה, משהו השתבש לנו...
      </Heading>
      <Text fontSize="lg" mb="30px" textAlign="center" maxW="350px">
        הצוות הטכני שלנו כבר קיבל על זה התראה. אפשר לרענן את העמוד והכל יחזור לעצמו
      </Text>
      <Button w="150px" onClick={() => window.location.reload()}>
        או ללחוץ כאן
      </Button>
    </Flex>
  );
};
