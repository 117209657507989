import { defineStyle, defineStyleConfig, SystemStyleObject } from '@chakra-ui/styled-system';

const baseStyle = defineStyle({
  transitionProperty: 'common',
  transitionDuration: 'fast',
  transitionTimingFunction: 'ease-out',
  cursor: 'pointer',
  textDecoration: 'underline',
  outline: 'none',
  color: 'base.1000',
  _hover: {
    color: 'primary.200',
  },
  _active: {
    color: 'primary.300',
  },
});

const sizes: Record<string, SystemStyleObject> = {
  lg: defineStyle({
    textStyle: 'large',
  }),
  sm: defineStyle({
    textStyle: 'small',
  }),
};

const defaultProps = {
  size: 'lg',
};

export const Link = defineStyleConfig({
  baseStyle,
  sizes,
  defaultProps,
});
