import { createIcon } from '@chakra-ui/icons';

export const LogoutIcon = createIcon({
  displayName: 'LogoutIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M15.2195 6.11689L12.6335 3.53089C12.5078 3.40945 12.3394 3.34226 12.1646 3.34378C11.9898 3.34529 11.8226 3.41541 11.699 3.53901C11.5754 3.66262 11.5052 3.82983 11.5037 4.00462C11.5022 4.17942 11.5694 4.34782 11.6908 4.47356L14.2768 7.05956C14.3537 7.13791 14.4208 7.2252 14.4768 7.31956C14.4668 7.31956 14.4588 7.31422 14.4488 7.31422L3.99284 7.33556C3.81603 7.33556 3.64646 7.4058 3.52143 7.53082C3.39641 7.65584 3.32617 7.82541 3.32617 8.00222C3.32617 8.17904 3.39641 8.3486 3.52143 8.47363C3.64646 8.59865 3.81603 8.66889 3.99284 8.66889L14.4448 8.64756C14.4635 8.64756 14.4788 8.63822 14.4968 8.63689C14.4377 8.74966 14.3627 8.85338 14.2742 8.94489L11.6882 11.5309C11.6245 11.5924 11.5737 11.666 11.5388 11.7473C11.5038 11.8286 11.4854 11.9161 11.4847 12.0046C11.4839 12.0931 11.5008 12.1809 11.5343 12.2629C11.5678 12.3448 11.6173 12.4192 11.6799 12.4818C11.7425 12.5444 11.8169 12.5939 11.8989 12.6274C11.9808 12.661 12.0686 12.6778 12.1571 12.6771C12.2456 12.6763 12.3331 12.6579 12.4144 12.623C12.4958 12.588 12.5693 12.5372 12.6308 12.4736L15.2168 9.88756C15.7168 9.38748 15.9976 8.70933 15.9976 8.00222C15.9976 7.29512 15.7168 6.61697 15.2168 6.11689H15.2195Z"
    />,
    <path
      key="1"
      fill="currentColor"
      d="M4.66667 14.6667H3.33333C2.8029 14.6667 2.29419 14.456 1.91912 14.0809C1.54405 13.7058 1.33333 13.1971 1.33333 12.6667V3.33333C1.33333 2.8029 1.54405 2.29419 1.91912 1.91912C2.29419 1.54405 2.8029 1.33333 3.33333 1.33333H4.66667C4.84348 1.33333 5.01305 1.2631 5.13807 1.13807C5.2631 1.01305 5.33333 0.843478 5.33333 0.666667C5.33333 0.489856 5.2631 0.320286 5.13807 0.195262C5.01305 0.0702379 4.84348 0 4.66667 0L3.33333 0C2.4496 0.00105857 1.60237 0.352588 0.97748 0.97748C0.352588 1.60237 0.00105857 2.4496 0 3.33333L0 12.6667C0.00105857 13.5504 0.352588 14.3976 0.97748 15.0225C1.60237 15.6474 2.4496 15.9989 3.33333 16H4.66667C4.84348 16 5.01305 15.9298 5.13807 15.8047C5.2631 15.6797 5.33333 15.5101 5.33333 15.3333C5.33333 15.1565 5.2631 14.987 5.13807 14.8619C5.01305 14.7369 4.84348 14.6667 4.66667 14.6667Z"
    />,
  ],
});
