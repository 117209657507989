import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { useUserData } from '../../models/userData/useUserData';
import { fetcher } from '../../requests/fetcher';

export enum NFI_KEY {
  REPORT_WORK_HOUR_WITHOUT_PROJECT = 'REPORT_WORK_HOUR_WITHOUT_PROJECT',
}

const featureAddedAt = {
  [NFI_KEY.REPORT_WORK_HOUR_WITHOUT_PROJECT]: '2024-06-24',
};

const NPI_API_KEY = 'getNfiState';

const baseUrl = import.meta.env.REACT_APP_API_URL;

export const useNfi = (key: NFI_KEY) => {
  const queryClient = useQueryClient();

  const { userData } = useUserData();

  const { data, isLoading } = useQuery({
    queryKey: [NPI_API_KEY, key],
    queryFn: async () => {
      return fetcher(`${baseUrl}/nfi?key=${key}`, '', 'GET');
    },
  });

  const { mutate } = useMutation({
    mutationKey: [NPI_API_KEY],
    mutationFn: async () => {
      return fetcher(`${baseUrl}/nfi?key=${key}`, '{}', 'POST');
    },
    onMutate: async () => {
      queryClient.setQueryData([NPI_API_KEY, key], { seen: true });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [NPI_API_KEY, key] });
    },
  });

  const userCreatedAfterFeature = (userData?.createdAt.getTime() || 0) > new Date(featureAddedAt[key]).getTime();

  return { showNfi: !userCreatedAfterFeature && !data?.seen && !isLoading, setNfi: mutate };
};
