import { createIcon } from '@chakra-ui/icons';

export const CrossIcon = createIcon({
  displayName: 'CrossIcon',
  viewBox: '0 0 25 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M18.2501 6.00006C18.0625 5.81259 17.8082 5.70728 17.5431 5.70728C17.2779 5.70728 17.0236 5.81259 16.8361 6.00006L12.2501 10.5861L7.66406 6.00006C7.47653 5.81259 7.22223 5.70728 6.95706 5.70728C6.6919 5.70728 6.43759 5.81259 6.25006 6.00006C6.06259 6.18759 5.95728 6.4419 5.95728 6.70706C5.95728 6.97223 6.06259 7.22653 6.25006 7.41406L10.8361 12.0001L6.25006 16.5861C6.06259 16.7736 5.95728 17.0279 5.95728 17.2931C5.95728 17.5582 6.06259 17.8125 6.25006 18.0001C6.43759 18.1875 6.6919 18.2928 6.95706 18.2928C7.22223 18.2928 7.47653 18.1875 7.66406 18.0001L12.2501 13.4141L16.8361 18.0001C17.0236 18.1875 17.2779 18.2928 17.5431 18.2928C17.8082 18.2928 18.0625 18.1875 18.2501 18.0001C18.4375 17.8125 18.5428 17.5582 18.5428 17.2931C18.5428 17.0279 18.4375 16.7736 18.2501 16.5861L13.6641 12.0001L18.2501 7.41406C18.4375 7.22653 18.5428 6.97223 18.5428 6.70706C18.5428 6.4419 18.4375 6.18759 18.2501 6.00006Z"
    />,
  ],
});
