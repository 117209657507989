import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { logger } from '../../utils/logger';

import { createUserData } from './userDataApi';
import { IValidUserDataValues } from './userDataFormUtils';
import { USER_DATA_QUERY_KEY } from './useUserData';

export function useCreateUserData(onSuccess?: () => void, onError?: () => void) {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: async (userData: IValidUserDataValues) => {
      return createUserData(userData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [USER_DATA_QUERY_KEY],
      });
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (e) => {
      logger.error('Failed to create userData', e);
      if (onError) {
        onError();
      }
    },
  });

  const mutate = async (variables: IValidUserDataValues) => {
    setIsLoading(true);
    await mutateAsync(variables);
    setIsLoading(false);
  };

  return { createUserData: mutate, isLoading };
}
