import { Box, Divider, Flex, Text } from '@chakra-ui/react';

export const LabeledSeparator = ({ label }: { label: string }) => {
  return (
    <Flex alignItems="center" gap="24px">
      <Divider flex="1" color="base.200" />
      <Box px="18px" py="2px" bg="base.800" color="base.0" borderRadius="8px">
        <Text textStyle="extraLarge">{label}</Text>
      </Box>
      <Divider flex="1" color="base.200" />
    </Flex>
  );
};
