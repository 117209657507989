import { Icon, IconProps } from '@chakra-ui/react';

export const DragIcon = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 20 20">
      <g id="fi_8912970" clipPath="url(#clip0_7712_6029)">
        <g id="Clip path group">
          <mask id="mask0_7712_6029" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
            <g id="clip0_11_1464">
              <path id="Vector" d="M0 0H20V20H0V0Z" fill="white" />
            </g>
          </mask>
          <g mask="url(#mask0_7712_6029)">
            <g id="Group">
              <path
                id="Vector_2"
                d="M9.16671 15.0002C9.16671 15.9168 8.41671 16.6668 7.50004 16.6668C6.58337 16.6668 5.83337 15.9168 5.83337 15.0002C5.83337 14.0835 6.58337 13.3335 7.50004 13.3335C8.41671 13.3335 9.16671 14.0835 9.16671 15.0002ZM7.50004 8.3335C6.58337 8.3335 5.83337 9.0835 5.83337 10.0002C5.83337 10.9168 6.58337 11.6668 7.50004 11.6668C8.41671 11.6668 9.16671 10.9168 9.16671 10.0002C9.16671 9.0835 8.41671 8.3335 7.50004 8.3335ZM7.50004 3.3335C6.58337 3.3335 5.83337 4.0835 5.83337 5.00016C5.83337 5.91683 6.58337 6.66683 7.50004 6.66683C8.41671 6.66683 9.16671 5.91683 9.16671 5.00016C9.16671 4.0835 8.41671 3.3335 7.50004 3.3335ZM12.5 6.66683C13.4167 6.66683 14.1667 5.91683 14.1667 5.00016C14.1667 4.0835 13.4167 3.3335 12.5 3.3335C11.5834 3.3335 10.8334 4.0835 10.8334 5.00016C10.8334 5.91683 11.5834 6.66683 12.5 6.66683ZM12.5 8.3335C11.5834 8.3335 10.8334 9.0835 10.8334 10.0002C10.8334 10.9168 11.5834 11.6668 12.5 11.6668C13.4167 11.6668 14.1667 10.9168 14.1667 10.0002C14.1667 9.0835 13.4167 8.3335 12.5 8.3335ZM12.5 13.3335C11.5834 13.3335 10.8334 14.0835 10.8334 15.0002C10.8334 15.9168 11.5834 16.6668 12.5 16.6668C13.4167 16.6668 14.1667 15.9168 14.1667 15.0002C14.1667 14.0835 13.4167 13.3335 12.5 13.3335Z"
                fill="currentColor"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7712_6029">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
