import { Box, Button } from '@chakra-ui/react';
import { observer } from 'mobx-react';

interface IButtonTabsProps {
  items: Array<{ label: string; id: string }>;
  selectedId: string;
  handleClick(id: string): void;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

// TODO - fix for new button styles
// implemented only for medium size
export const ButtonTabs = observer(
  ({ items, selectedId, handleClick, disabled = false, size = 'md' }: IButtonTabsProps) => {
    const buttonStyles = {
      sm: {
        h: '22px',
        px: '4px',
        py: '2px',
        fontSize: 'sm',
        borderRadius: '4px',
        // fullHeight: '24px',
      },
      md: {
        h: '28px',
        px: '8px',
        py: '5px',
        fontSize: 'md',
        borderRadius: '6px',
        // fullHeight: '36px',
      },
      lg: {
        h: '42px',
        px: '12px',
        py: '11px',
        fontSize: 'md',
        borderRadius: '8px',
        // fullHeight: '48px',
      },
    };

    return (
      <Box
        borderRadius={buttonStyles[size].borderRadius}
        bg="base.100"
        // h={buttonStyles[size].fullHeight}
        display="grid"
        gridTemplateColumns={`repeat(${items.length}, 1fr)`}
        gridColumnGap={size === 'sm' ? '2px' : '4px'}
        alignItems="center"
        justifyContent="space-between"
        p={size === 'sm' ? '2px' : '4px'}
      >
        {items.map((item) => (
          <Button
            key={item.id}
            onClick={() => handleClick(item.id)}
            color={item.id === selectedId ? 'base.1000' : 'base.600'}
            border="1px solid"
            borderColor={item.id === selectedId ? 'base.200' : 'transparent'}
            background={item.id === selectedId ? 'white' : 'none'}
            pointerEvents={item.id === selectedId ? 'none' : 'all'}
            _hover={{
              background: item.id === selectedId ? 'white' : 'base.200',
            }}
            fontWeight="normal"
            isDisabled={disabled && item.id !== selectedId}
            {...buttonStyles[size]}
          >
            {item.label}
          </Button>
        ))}
      </Box>
    );
  },
);
