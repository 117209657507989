import { createIcon } from '@chakra-ui/icons';

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 25 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M20.5745 5.24522L9.05963 16.7592C8.98221 16.8369 8.89021 16.8986 8.7889 16.9407C8.68759 16.9828 8.57897 17.0044 8.46926 17.0044C8.35956 17.0044 8.25094 16.9828 8.14963 16.9407C8.04832 16.8986 7.95632 16.8369 7.8789 16.7592L3.42595 12.3021C3.34853 12.2244 3.25653 12.1627 3.15522 12.1206C3.05391 12.0786 2.94529 12.0569 2.83559 12.0569C2.72588 12.0569 2.61726 12.0786 2.51595 12.1206C2.41464 12.1627 2.32264 12.2244 2.24522 12.3021C2.1675 12.3795 2.10582 12.4715 2.06374 12.5728C2.02166 12.6742 2 12.7828 2 12.8925C2 13.0022 2.02166 13.1108 2.06374 13.2121C2.10582 13.3134 2.1675 13.4054 2.24522 13.4828L6.69983 17.9366C7.16975 18.4057 7.80657 18.6691 8.47051 18.6691C9.13446 18.6691 9.77128 18.4057 10.2412 17.9366L21.7552 6.42512C21.8328 6.34772 21.8944 6.25576 21.9364 6.15453C21.9784 6.0533 22 5.94477 22 5.83517C22 5.72557 21.9784 5.61704 21.9364 5.51581C21.8944 5.41457 21.8328 5.32262 21.7552 5.24522C21.6778 5.1675 21.5858 5.10582 21.4845 5.06374C21.3832 5.02166 21.2745 5 21.1648 5C21.0551 5 20.9465 5.02166 20.8452 5.06374C20.7439 5.10582 20.6519 5.1675 20.5745 5.24522Z"
    />,
  ],
});
