import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Text, Flex, Divider, Button } from '@chakra-ui/react';
import { useCombobox } from 'downshift';
import { ReactNode, useEffect, useState } from 'react';

import { SearchIcon } from '../../pages/common/icons';
import { DropListItem } from '../DropListItem';

import { RequiredIndicator } from './RequiredIndicator';
import { SelectItem, SelectProps } from './Select';
import { StyledInput } from './StyledInput';

export interface AutocompleteProps extends SelectProps {
  action?: {
    label: string;
    onClick: () => void;
    icon?: ReactNode;
  };
  filter?: ReactNode;
  menuWidth?: string;
}

export const Autocomplete = ({
  label,
  data,
  helperText,
  isRequired,
  onChange,
  touched,
  error,
  maintainHeight = false,
  selectedItem: initialSelectedItem,
  setTouched,
  action,
  filter,
  size = 'sm',
  placeholder = 'בחירה',
  menuWidth,
}: AutocompleteProps) => {
  const [items, setItems] = useState(data);
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    getComboboxProps,
    getLabelProps,
    reset,
  } = useCombobox<SelectItem>({
    // @ts-ignore
    initialSelectedItem: items.find((i) => i.value == initialSelectedItem),
    onInputValueChange({ inputValue }) {
      if (inputValue) {
        setItems(data.filter((d) => inputValue && d.key.toLocaleLowerCase().includes(inputValue)));
      } else {
        setItems(data);
      }
    },
    items,
    itemToString: (selectedItem) => selectedItem?.key || '',
    onSelectedItemChange: ({ selectedItem }) => {
      const selected = items.find((i) => i.value === selectedItem?.value);
      if (selected && onChange) {
        onChange(selected);
      }
    },
    onIsOpenChange: (changes) => {
      if (!changes.isOpen) {
        setTouched?.();
      }
    },
  });

  useEffect(() => {
    if (data.length !== items.length) {
      setItems(data);
    }
  }, [data.length]);

  const getMenuHeight = () => {
    let height = 330;
    if (action) {
      height += 70;
    }

    if (filter) {
      height += 50;
    }

    return height;
  };

  return (
    <Box position="relative" {...getComboboxProps()}>
      {label && (
        <Text textStyle="medium" color="base.600" mb="4px" {...getLabelProps()}>
          {label}
          {isRequired && <RequiredIndicator />}
        </Text>
      )}
      <Box
        type="button"
        {...getToggleButtonProps()}
        border="1px solid"
        borderColor={touched && error ? 'error.100' : isOpen ? 'base.1000' : 'base.300'}
        borderRadius={size === 'sm' ? '8px' : '12px'}
        w="100%"
        minH={size === 'sm' ? '38px' : '50px'}
        bg="white"
        color={selectedItem ? 'base.1000' : 'base.600'}
        textStyle="large"
        p={size === 'sm' ? '8px' : '14px'}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        cursor="pointer"
        tabIndex="0"
        _hover={{
          borderColor: touched && error ? 'error.100' : isOpen ? 'base.1000' : 'base.600',
        }}
        _focus={{
          borderColor: touched && error ? 'error.100' : 'base.1000',
        }}
        _active={{
          borderColor: touched && error ? 'error.100' : 'base.1000',
        }}
      >
        <Flex gap="4px" alignItems="center" maxW="90%">
          {!selectedItem?.key && placeholder}
          {selectedItem?.icon && selectedItem?.icon}
          {selectedItem?.key ? (
            <Text noOfLines={1} display="block" whiteSpace="nowrap" title={selectedItem.key}>
              {selectedItem.key}
            </Text>
          ) : (
            <Box />
          )}
        </Flex>
        <ChevronDownIcon
          color="base.900"
          w="16px"
          h="16px"
          marginInlineEnd="0px"
          transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}
        />
      </Box>
      <Box
        as="ul"
        {...getMenuProps()}
        position="absolute"
        top={size === 'lg' ? '84px' : '72px'}
        backgroundColor="white"
        boxShadow="0px 4px 20px 0px rgba(92, 109, 145, 0.08)"
        _focus={{
          // override globals
          boxShadow: '0px 4px 20px 0px rgba(92, 109, 145, 0.08) !important',
        }}
        borderRadius={size === 'sm' ? '8px' : '12px'}
        border="1px solid"
        borderColor="base.300"
        listStyleType="none"
        maxH={`${getMenuHeight()}px`}
        minWidth="100%"
        width={menuWidth}
        overflowY="auto"
        display={isOpen ? 'block' : 'none'}
        zIndex="dropdown"
        p="12px"
        _focusVisible={{ outline: 'none' }}
        overflow="hidden"
      >
        <StyledInput size="sm" {...getInputProps()} endIcon={<SearchIcon />} />
        {action && (
          <Button
            variant="ghost"
            mt="8px"
            w="100%"
            size="sm"
            onClick={() => {
              action.onClick();
              reset();
            }}
          >
            {action.label}
          </Button>
        )}
        <Divider color="base.200" my="12px" />
        <Box overflowY="auto" maxH={`${getMenuHeight() - 120}px`}>
          {items.length > 0 ? (
            items.map((item: any, index) => (
              <Box as="li" key={`${item}${index}`} {...getItemProps({ item, index })}>
                <DropListItem
                  label={
                    <Flex gap="4px" alignItems="center">
                      {item.icon && item.icon}
                      <Text
                        maxW={`calc(${menuWidth} - 84px)`}
                        color="base.1000"
                        noOfLines={1}
                        display="block"
                        whiteSpace="nowrap"
                        title={item.key}
                      >
                        {item.key}
                      </Text>
                    </Flex>
                  }
                  size={size}
                  isSelected={selectedItem?.value === item.value}
                />
              </Box>
            ))
          ) : (
            <Flex direction="column" alignItems="center" py="38px" px="18px">
              <Flex
                h="48px"
                w="48px"
                borderRadius="50%"
                bg="primary.0"
                color="primary.100"
                alignItems="center"
                justifyContent="center"
                mb="20px"
              >
                <SearchIcon />
              </Flex>
              <Text textStyle="large">אין תוצאות חיפוש</Text>
              <Text textStyle="small" color="base.500">
                בוא ננסה מילת מפתח אחרת
              </Text>
            </Flex>
          )}
        </Box>
        {filter && (
          <Box>
            <Divider color="base.200" my="12px" />
            {filter}
          </Box>
        )}
      </Box>
      {error && touched && (
        <Text textStyle="small" color="error.100" mt="8px">
          {error}
        </Text>
      )}
      {helperText && (!error || !touched) && (
        <Text textStyle="small" color="base.600" mt="8px">
          {helperText}
        </Text>
      )}
      {maintainHeight && !helperText && (!error || !touched) && (
        <Box textStyle="small" color="base.600" mt="8px" h="18px" />
      )}
    </Box>
  );
};
