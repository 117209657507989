import { Box, Flex, Spacer, Text, Divider } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { SquareIconButton } from '../../../components/SquareIconButton';
import { PROJECT_STATUS, PROJECT_TYPE } from '../../../requests/Client';
import { EditIcon } from '../../common/icons/EditIcon';
import { ProjectStatus } from '../../common/ProjectStatus';

export const SingleProjectHeader = observer(
  ({
    projectType,
    serviceType,
    status,
    setStatus,
    editProject,
    startDate,
    customerName,
    customerSource,
  }: {
    projectType: PROJECT_TYPE;
    serviceType: string;
    status: PROJECT_STATUS;
    setStatus: (s: PROJECT_STATUS) => void;
    editProject: () => void;
    startDate: string;
    customerName: string;
    customerSource?: string;
  }) => {
    const [loading, setLoading] = useState(false);

    const handleChange = async (status: PROJECT_STATUS) => {
      setLoading(true);
      await setStatus(status);
      setLoading(false);
    };
    return (
      <Flex alignItems="center" mb="10px" gap="20px" color="base.500" id="ignore-me">
        <Text fontSize="lg">{startDate}</Text>
        <Divider minH="29px" orientation="vertical" />
        <Text fontSize="lg">{serviceType}</Text>
        <Divider minH="29px" orientation="vertical" />
        <Text fontSize="lg">{projectType === PROJECT_TYPE.HOURLY ? 'שעתי' : 'פיקס'}</Text>
        <Spacer />
        <SquareIconButton icon={<EditIcon />} variant="ghost" size="sm" onClick={editProject} />
        <Divider minH="29px" orientation="vertical" />
        <Text fontSize="lg">{customerName}</Text>
        {customerSource && customerSource !== 'undefined' && (
          <>
            <Divider minH="29px" orientation="vertical" />
            <Text fontSize="lg">{customerSource}</Text>
          </>
        )}
        <Box w="100px">
          <ProjectStatus status={status} onChange={handleChange} loading={loading} />
        </Box>
      </Flex>
    );
  },
);
