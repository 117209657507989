import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/styled-system';

const $bg = cssVar('tabs-bg');

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleRoot = defineStyle((props) => {
  const { orientation } = props;
  return {
    display: orientation === 'vertical' ? 'flex' : 'block',
  };
});

const baseStyleTab = defineStyle((props) => {
  const { isFitted } = props;

  return {
    flex: isFitted ? 1 : undefined,
    transitionProperty: 'common',
    transitionDuration: 'normal',
    _focusVisible: {
      zIndex: 1,
      boxShadow: 'outline',
    },
    _disabled: {
      cursor: 'not-allowed',
      opacity: 0.4,
    },
  };
});

const baseStyleTablist = defineStyle((props) => {
  const { align = 'start', orientation } = props;

  const alignments: Record<string, string> = {
    end: 'flex-end',
    center: 'center',
    start: 'flex-start',
  };

  return {
    justifyContent: alignments[align],
    flexDirection: orientation === 'vertical' ? 'column' : 'row',
  };
});

const baseStyleTabpanel = defineStyle({
  p: 4,
});

const baseStyle = definePartsStyle((props) => ({
  root: baseStyleRoot(props),
  tab: baseStyleTab(props),
  tablist: baseStyleTablist(props),
  tabpanel: baseStyleTabpanel,
}));

const sizes = {
  sm: definePartsStyle({
    tab: {
      py: 1,
      px: 4,
      fontSize: 'sm',
    },
  }),
  md: definePartsStyle({
    tab: {
      fontSize: 'lg',
      py: 2,
      px: 4,
    },
  }),
  lg: definePartsStyle({
    tab: {
      fontSize: 'lg',
      py: 3,
      px: 4,
    },
  }),
};

const variantLine = definePartsStyle((props) => {
  const { colorScheme: c, orientation } = props;
  const isVertical = orientation === 'vertical';
  const borderProp = isVertical ? 'borderStart' : 'borderBottom';
  const marginProp = isVertical ? 'marginStart' : 'marginBottom';

  return {
    tablist: {
      borderBottom: '1px solid',
      borderColor: 'base.200',
    },
    tabpanel: {
      py: '34px',
      px: '0',
    },
    tab: {
      [borderProp]: '2px solid',
      borderColor: 'transparent',
      [marginProp]: '-2px',
      fontSize: '18px',
      p: '12px 20px',
      _hover: {
        bg: 'base.100',
        [borderProp]: '2px solid',
        borderColor: 'base.200',
      },
      _selected: {
        borderBottom: '2px solid',
        borderColor: 'base.900',
        color: 'base.900',
      },
      _disabled: {
        _active: { bg: 'none' },
      },
      color: 'base.500',
      bg: $bg.reference,
    },
  };
});

const variants = {
  line: variantLine,
};

export const Tabs = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'line',
    colorScheme: 'gray',
  },
});
