import { roundTo2 } from '../utils/number/numberUtils';
import { getDuration } from '../utils/time/timeUtils';

import { Project } from './Project';
import { WorkHour } from './WorkHour';
import { IWorkHour } from './workHours/useWorkHours';

export interface IWorkHourValues {
  projectName: string;
  startTime: string;
  date: Date;
  endTime?: string;
  comment?: string;
}

export function getWorkHourInitialValues(workHour?: WorkHour, projectName?: string): Partial<IWorkHourValues> {
  return {
    projectName,
    startTime: workHour?.startTime,
    endTime: workHour?.endTime,
    date: workHour?._startTime,
    comment: workHour?.comment,
  };
}

export function getMinimumWorkHourDate(project: Project) {
  return project._startDate;
}

export function getMaximumWorkHourDate(project: Project) {
  return project._endDate;
}

export function isAdvanceWorkHour(workHourId: string, p: Project) {
  return Boolean(p.getWorkHourPayment(workHourId)?.isAdvance);
}

export function getWorkHoursDuration(workHours?: WorkHour[]) {
  return workHours?.reduce((acc, curr) => (acc += curr.duration), 0) || 0;
}

export function getWorkHoursDuration2(workHours?: IWorkHour[]) {
  return workHours?.reduce((acc, curr) => (acc += getDuration(curr.startTime, curr.endTime)), 0) || 0;
}

export function getWorkHoursSum(workHours: WorkHour[], rate: number) {
  return roundTo2((getWorkHoursDuration(workHours) / (60 * 60)) * rate);
}
