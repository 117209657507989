import { IPartialWorkHour } from './useWorkHours';
import { IWorkHourApi } from './workHourApi';

export function apiToWorkHours(workHoursApi: IWorkHourApi[]): IPartialWorkHour[] {
  return workHoursApi.map((api) => {
    return {
      _id: api.id.toString(),
      startTime: new Date(api.start_time),
      endTime: api.end_time ? new Date(api.end_time) : null,
      comment: api.comment,
      project: {
        _id: api.project_id?.toString(),
      },
      task_id: api.task_id,
    };
  });
}
