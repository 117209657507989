import { getYear } from 'date-fns';
import { action, computed, makeObservable, observable } from 'mobx';

import { Addition, IAdditionValues } from '../../../models/Addition';
import { CustomerModel } from '../../../models/CustomerModel';
import { Expense, IExpenseValues } from '../../../models/Expense';
import { Payment } from '../../../models/Payment';
import { ProjectsModel } from '../../../models/ProjectsModel';
import { IStore } from '../../../models/RootStore';
import { WorkHour } from '../../../models/WorkHour';
import { IWorkHourApi } from '../../../models/workHours/workHourApi';
import { getWorkHoursDuration, getWorkHoursSum } from '../../../models/workHourUtils';
import { PROJECT_STATUS } from '../../../requests/Client';
import { EditProjectRoute, ProjectsRoute } from '../../../router/routes';
import { RoutingStore } from '../../../router/RoutingStore';
import { logger } from '../../../utils/logger';

export class SingleProjectViewModel implements IStore {
  projectsModel: ProjectsModel;
  customersModel: CustomerModel;
  routingStore: RoutingStore;

  selectedProjectId: string | null;
  _deleteWorkHourState: 'success' | 'error' | null;

  constructor(projectsModel: ProjectsModel, customersModel: CustomerModel, routingStore: RoutingStore) {
    this.projectsModel = projectsModel;
    this.customersModel = customersModel;
    this.routingStore = routingStore;

    this.selectedProjectId = null;
    this._deleteWorkHourState = null;

    makeObservable(this, {
      projectsModel: observable,

      project: computed,
      activate: action,
      customer: computed,
      payments: computed,
      workHours: computed,
      workHoursByYear: computed,

      setProjectStatus: action,

      editWorkHour: action,
      deleteWorkHour: action,
      _deleteWorkHourState: observable,
      deleteWorkHourState: computed,
      setDeleteWorkHourState: action,

      additionsByYear: computed,
      createAddition: action,
      updateAddition: action,
      deleteAddition: action,

      expensesByYear: computed,
      createExpense: action,
      updateExpense: action,
      deleteExpense: action,

      isProjectLoading: computed,
    });
  }

  activate(projectId: string) {
    this.selectedProjectId = projectId;
  }

  get project() {
    return this.selectedProjectId ? this.projectsModel.projects.find((p) => p._id === this.selectedProjectId) : null;
  }

  get isProjectLoading() {
    return this.projectsModel.isLoading;
  }

  redirect = () => {
    this.routingStore.navigate(ProjectsRoute);
  };

  editProject = () => {
    if (this.project) {
      this.routingStore.navigate({ ...EditProjectRoute, params: { projectId: this.project._id } });
    }
  };

  get customer() {
    return this.project ? this.customersModel.getCustomerById(this.project.customer) : null;
  }

  get payments(): Payment[] | null {
    return this.project?.payments || null;
  }

  get workHours(): WorkHour[] | null {
    return this.project?.workHours || null;
  }

  get workHoursByYear(): { [s: string]: WorkHour[] } | null {
    return (
      this.workHours?.reduce<{ [s: string]: WorkHour[] }>((acc, curr) => {
        const year = getYear(curr._startTime);
        if (!acc[year.toString()]) {
          acc[year.toString()] = [];
        }
        acc[year.toString()].push(curr);
        return acc;
      }, {}) || null
    );
  }

  getPaymentTotalWorkHours = (payment: Payment) => {
    const workHours = this.project?.getPaymentWorkHours(payment);
    if (!workHours) {
      return 0;
    }
    return getWorkHoursDuration(workHours);
  };

  getPaymentSum = (payment: Payment) => {
    if (payment.isAdvance) {
      return payment.sum;
    }

    const workHours = this.project?.getPaymentWorkHours(payment);
    if (!workHours || !this.project) {
      return 0;
    }
    return getWorkHoursSum(workHours, this.project?.rate);
  };

  setProjectStatus = async (status: PROJECT_STATUS) => {
    if (!this.project) {
      return;
    }

    await this.projectsModel.updateProjectStatus(this.project._id, status);
    this.project?.setStatus(status);
  };

  editWorkHour = (workHour: WorkHour) => {
    this.project && this.routingStore.setEditWorkHour(workHour, this.project);
  };

  get deleteWorkHourState() {
    return this._deleteWorkHourState;
  }

  setDeleteWorkHourState = (state: 'success' | 'error' | null) => {
    this._deleteWorkHourState = state;
  };

  deleteWorkHour = async (workHour: WorkHour) => {
    try {
      if (this.project && workHour.id) {
        this.setDeleteWorkHourState('success');
        await this.projectsModel.deleteWorkHour(workHour.id, this.project);
      } else {
        this.setDeleteWorkHourState('error');
      }
    } catch (error) {
      logger.error('Error deleting work-hour', { error });
      this.setDeleteWorkHourState('error');
    }
  };

  get additionsByYear() {
    return (
      this.project?._additions
        ?.slice()
        .sort((a, b) => b._date.getTime() - a._date.getTime())
        .reduce<{ [s: string]: Addition[] }>((acc, curr) => {
          const year = getYear(curr._date);
          if (!acc[year.toString()]) {
            acc[year.toString()] = [];
          }
          acc[year.toString()].push(curr);
          return acc;
        }, {}) || null
    );
  }

  createAddition = async (data: IAdditionValues) => {
    if (this.project) {
      await this.projectsModel.createAddition(data, this.project);
    }
  };

  updateAddition = async (data: IAdditionValues, additionId: string) => {
    if (this.project) {
      await this.projectsModel.updateAddition(data, additionId, this.project);
    }
  };

  deleteAddition = async (additionId: string) => {
    if (this.project) {
      await this.projectsModel.deleteAddition(additionId, this.project);
    }
  };

  get expensesByYear() {
    return (
      this.project?._expenses
        ?.slice()
        .sort((a, b) => b._date.getTime() - a._date.getTime())
        .reduce<{ [s: string]: Expense[] }>((acc, curr) => {
          const year = getYear(curr._date);
          if (!acc[year.toString()]) {
            acc[year.toString()] = [];
          }
          acc[year.toString()].push(curr);
          return acc;
        }, {}) || null
    );
  }

  createExpense = async (data: IExpenseValues) => {
    if (this.project) {
      await this.projectsModel.createExpense(data, this.project);
    }
  };

  updateExpense = async (data: IExpenseValues, expenseId: string) => {
    if (this.project) {
      await this.projectsModel.updateExpense(data, expenseId, this.project);
    }
  };

  deleteExpense = async (expenseId: string) => {
    if (this.project) {
      await this.projectsModel.deleteExpense(expenseId, this.project);
    }
  };

  editTask = (id: number) => {
    this.routingStore.setIsCreatingTask(true, id);
  };

  // for timer integration
  saveWorkHour = async (newWorkHour: IWorkHourApi) => {
    if (!newWorkHour.end_time) {
      // return early if saving a partial work hour
      return;
    }

    const workHour = new WorkHour(newWorkHour);
    if (newWorkHour.project_id) {
      await this.projectsModel.createWorkHour(workHour, newWorkHour.project_id.toString());
    }
  };

  openCreateTaskModal = () => {
    this.routingStore.setIsCreatingTask(true);
  };
}
