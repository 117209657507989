import { Bar } from 'react-chartjs-2';

import { useVat } from '../../hooks/useVat/useVat';
import { roundTo2 } from '../../utils/number/numberUtils';

const baseGraphData = {
  labels: [],
  datasets: [
    {
      label: 'Dataset 1',
      yAxisID: 'yAxis',
      xAxisID: 'xAxis',
      data: [],
      backgroundColor: '#2EDEBF',
      hoverBackgroundColor: '#2EDEBF',
      barThickness: 40,
      maxBarThickness: 40,
      minBarLength: 6,
    },
  ],
};

export function PaymentsBarChart({
  data,
  projectPerMonths,
}: {
  data: Array<{ label: string; data: number }>;
  projectPerMonths: { [k: string]: string[] };
}) {
  const { getValueWithVat } = useVat();

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
      },
    },
    elements: {
      bar: {
        borderRadius: 4,
        maxBarThickness: 50,
        barThickness: 50,
      },
    },
    scales: {
      yAxis: {
        ticks: {
          color: '#160825',
          padding: 12,
          stepSize: 2500,
          font: {
            family: 'Ploni',
            size: 16,
          },
        },
        border: {
          display: false,
        },
        grid: {
          color: '#D8DBED',
          drawBorder: false,
          drawTicks: false,
        },
      },
      xAxis: {
        ticks: {
          color: '#160825',
          font: {
            family: 'Ploni',
            size: 16,
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: '#270C43',
        padding: 12,
        rtl: true,
        bodyAlign: 'right',
        callbacks: {
          label: function (ctx: any) {
            return [
              `סה״כ ${roundTo2(ctx.parsed.y).toLocaleString()}`,
              '',
              'פרויקטים:',
              ...projectPerMonths[ctx.label].map((s) => `- ${s}`),
            ];
          },
        },
        titleFont: {
          family: 'Ploni',
          size: 16,
        },
        bodyFont: {
          family: 'Ploni',
          size: 16,
        },
      },
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        labels: {
          font: {
            size: 13,
            family: '"Ploni",Helvetica,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
          },
        },
      },
    },
  };

  const graphData = {
    ...baseGraphData,
    labels: data.map((item) => item.label),
    datasets: [
      {
        ...baseGraphData.datasets[0],
        data: data.map((item) => getValueWithVat(item.data) || null),
      },
    ],
  };

  return <Bar options={options} data={graphData} />;
}
