import { Box, Divider, Flex, Spacer, Stack, Image } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { NavigationOptions } from 'router5';

import { SubscriptionRemainingDays } from '../../pages/subscription/remainingDays/SubscriptionRemainingDays';
import {
  CustomersRoute,
  LinkData,
  ProjectsRoute,
  WorkHoursRoute,
  SettingsRoute,
  InsightsRoute,
  TasksRoute,
  LeadsRoute,
} from '../../router/routes';

import { ProfileLink } from './ProfileLink';
import { SideBarLink } from './SidebarLink';

export const Sidebar = observer(
  ({
    navigate,
    handleLogout,
    openPaymentDialog,
    openHelpDialog,
    isWide,
  }: {
    navigate(linkData: LinkData, options?: NavigationOptions): void;
    handleLogout(): void;
    openPaymentDialog(): void;
    openHelpDialog(): void;
    isWide: boolean;
  }) => {
    const sidebarRoutes = [InsightsRoute, LeadsRoute, CustomersRoute, ProjectsRoute, WorkHoursRoute, TasksRoute];
    const currentPath = window.location.pathname;

    return (
      <Box gridArea="sidebar">
        <Flex
          bg="base.900"
          borderLeftRadius="35px"
          direction="column"
          alignItems="center"
          minH="100%"
          py="40px"
          px="16px"
        >
          <Image
            src={isWide ? '/logo-light.png' : '/logo-light-shape.svg'}
            width={isWide ? '90px' : '30px'}
            mb={isWide ? '48px' : '40px'}
          />
          <Stack w="100%" gap="16px">
            {sidebarRoutes.map((route) => {
              const isSelected = currentPath.includes(route.name);
              return (
                <SideBarLink
                  key={route.name}
                  label={route.displayName}
                  onClick={() => navigate(route.link())}
                  isSelected={isSelected}
                  iconOnly={!isWide}
                />
              );
            })}
          </Stack>
          <Spacer />
          <Box mb="16px">
            <SubscriptionRemainingDays openPaymentDialog={openPaymentDialog} isWide={isWide} />
          </Box>
          <Box w="100%">
            <Divider w="44px" borderColor="#413052" mb="16px" />
            <Box mb="16px">
              <SideBarLink
                iconOnly={!isWide}
                label="הגדרות"
                isSelected={currentPath.includes(SettingsRoute.name)}
                onClick={() => navigate(SettingsRoute.link())}
              />
            </Box>
            <Box mb="16px">
              <SideBarLink iconOnly={!isWide} label="עזרה" onClick={openHelpDialog} />
            </Box>
            <ProfileLink handleLogout={handleLogout} iconOnly={!isWide} />
          </Box>
        </Flex>
      </Box>
    );
  },
);
