import { Icon, IconProps } from '@chakra-ui/react';

export const NoTasksIcon = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 330 330">
      <circle cx="165" cy="164.596" r="127.817" fill="#F1F0FF" />
      <path
        d="M243.405 56.4045C245.32 54.4891 245.32 51.3835 243.405 49.4681C241.489 47.5527 238.384 47.5527 236.468 49.4681C234.553 51.3835 234.553 54.489 236.468 56.4045C238.384 58.3199 241.489 58.3199 243.405 56.4045Z"
        stroke="#270C43"
        strokeWidth="3.09859"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M286.813 227.813C288.988 225.638 288.987 222.112 286.813 219.938C284.638 217.763 281.112 217.763 278.938 219.938C276.763 222.112 276.763 225.638 278.938 227.813C281.112 229.988 284.638 229.988 286.813 227.813Z"
        stroke="#E44ECC"
        strokeWidth="3.09859"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.4046 210.404C44.32 208.489 44.32 205.384 42.4046 203.468C40.4891 201.553 37.3836 201.553 35.4682 203.468C33.5528 205.384 33.5528 208.489 35.4682 210.404C37.3836 212.32 40.4891 212.32 42.4046 210.404Z"
        stroke="#270C43"
        strokeWidth="3.09859"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M148.256 105.572C151.468 104.296 155.124 103.416 159.18 103.008C160.28 102.9 161.08 101.92 160.972 100.82C160.86 99.724 159.88 98.92 158.784 99.028C154.324 99.476 150.308 100.452 146.78 101.856C145.752 102.26 145.252 103.424 145.66 104.452C146.068 105.476 147.232 105.98 148.256 105.572Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.982 102.932C173.71 103.288 178.038 103.364 181.99 103.196C183.094 103.152 183.954 102.22 183.906 101.116C183.862 100.012 182.926 99.1562 181.826 99.2002C178.014 99.3602 173.842 99.2842 169.282 98.9402C168.182 98.8602 167.222 99.6842 167.138 100.784C167.054 101.888 167.882 102.848 168.982 102.932Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M192.79 102.012C197.454 101.136 201.41 99.8403 204.734 98.2443C205.73 97.7643 206.15 96.5683 205.67 95.5763C205.194 94.5803 203.998 94.1603 203.002 94.6363C199.954 96.1003 196.326 97.2803 192.05 98.0843C190.966 98.2883 190.25 99.3323 190.454 100.42C190.658 101.504 191.706 102.216 192.79 102.012Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.886 92.6873C216.47 89.2273 218.574 85.2433 219.522 81.1913C219.774 80.1153 219.102 79.0393 218.03 78.7873C216.954 78.5353 215.878 79.2073 215.626 80.2793C214.842 83.6433 213.082 86.9393 210.11 89.8073C209.314 90.5753 209.29 91.8433 210.058 92.6353C210.826 93.4313 212.09 93.4553 212.886 92.6873Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M218.567 67.4193C216.979 63.2033 214.287 59.6233 211.007 57.3593C210.099 56.7353 208.855 56.9633 208.227 57.8713C207.599 58.7793 207.827 60.0273 208.739 60.6553C211.391 62.4833 213.539 65.4113 214.823 68.8273C215.211 69.8593 216.363 70.3833 217.399 69.9953C218.431 69.6073 218.955 68.4513 218.567 67.4193Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.454 57.3522C168.65 47.0482 177.402 42.0362 183.714 42.0002C185.786 41.9882 187.582 42.4762 188.514 43.7242C189.066 44.4642 189.234 45.4562 189.022 46.6722C188.834 47.7602 189.566 48.7962 190.654 48.9842C191.742 49.1722 192.778 48.4402 192.966 47.3522C193.398 44.8522 192.854 42.8482 191.718 41.3322C190.13 39.2002 187.218 37.9802 183.694 38.0002C176.01 38.0442 165.214 43.9762 162.542 56.5202C162.31 57.6002 162.998 58.6602 164.078 58.8922C165.158 59.1242 166.222 58.4322 166.454 57.3522Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162.558 57.36C165.266 69.84 176.022 75.744 183.686 75.792C187.482 75.812 190.558 74.4 192.062 71.956C192.97 70.484 193.366 68.608 192.942 66.316C192.422 63.496 190.566 59.884 186.682 55.596C185.942 54.776 184.674 54.712 183.858 55.452C183.038 56.196 182.974 57.46 183.718 58.28C186.422 61.268 188.026 63.816 188.73 65.956C189.262 67.588 189.25 68.896 188.658 69.86C188.122 70.728 187.162 71.252 186.006 71.54C184.554 71.9 182.814 71.872 180.978 71.484C175.11 70.244 168.382 65.336 166.47 56.512C166.234 55.432 165.17 54.748 164.09 54.98C163.01 55.216 162.326 56.28 162.558 57.36Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.971 60.9375H190.751C192.959 60.9375 194.751 59.1455 194.751 56.9375C194.751 54.7295 192.959 52.9375 190.751 52.9375H163.971C161.763 52.9375 159.971 54.7295 159.971 56.9375C159.971 59.1455 161.763 60.9375 163.971 60.9375Z"
        fill="#2EDEBF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.9976 217.081V239.517C82.9976 243.933 85.8936 247.825 90.1256 249.093C106.198 253.917 151.406 267.481 162.126 270.693C163.998 271.257 165.998 271.257 167.87 270.693C178.59 267.481 223.798 253.917 239.87 249.093C244.102 247.825 246.998 243.933 246.998 239.517C246.998 228.837 246.998 207.529 246.998 207.529C246.998 206.425 246.102 205.529 244.998 205.529C243.894 205.529 242.998 206.425 242.998 207.529V239.517C242.998 242.165 241.258 244.501 238.722 245.265C222.65 250.085 177.438 263.649 166.722 266.865C165.598 267.201 164.398 267.201 163.274 266.865L91.2736 245.265C88.7376 244.501 86.9976 242.165 86.9976 239.517V217.081C86.9976 215.977 86.1016 215.081 84.9976 215.081C83.8936 215.081 82.9976 215.977 82.9976 217.081Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.3413 171.76C78.3413 171.76 68.2133 186.776 63.0373 194.448C61.9853 196.008 61.7253 197.976 62.3413 199.756C62.9533 201.536 64.3653 202.928 66.1573 203.508L138.137 226.896C140.681 227.72 143.461 226.768 144.961 224.556C150.325 216.636 166.653 192.548 166.653 192.548C167.005 192.028 167.093 191.372 166.889 190.776C166.685 190.18 166.213 189.716 165.613 189.524L85.6133 163.564C84.5653 163.224 83.4373 163.8 83.0933 164.852C82.7533 165.9 83.3293 167.028 84.3813 167.372L161.849 192.508L141.649 222.312C141.149 223.048 140.221 223.368 139.373 223.092L67.3933 199.704C66.7973 199.508 66.3253 199.048 66.1213 198.452C65.9173 197.86 66.0053 197.204 66.3533 196.684L81.6573 173.996C82.2773 173.08 82.0333 171.836 81.1173 171.22C80.2053 170.6 78.9613 170.844 78.3413 171.76Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.003 167.257L164.383 189.525C163.331 189.865 162.755 190.993 163.095 192.045C163.439 193.093 164.567 193.669 165.615 193.329L234.235 171.065C235.287 170.725 235.863 169.593 235.523 168.545C235.179 167.493 234.051 166.917 233.003 167.257Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M169.003 200.905C169.003 200.905 180.607 218.025 185.031 224.557C186.531 226.769 189.311 227.721 191.855 226.897C203.651 223.065 248.639 208.445 263.835 203.509C265.627 202.929 267.039 201.537 267.651 199.757C268.267 197.977 268.007 196.009 266.955 194.449C260.683 185.149 246.655 164.349 246.655 164.349C246.035 163.437 244.791 163.193 243.875 163.809C242.963 164.429 242.719 165.673 243.335 166.589C243.335 166.589 257.367 187.385 263.639 196.685C263.987 197.205 264.075 197.861 263.871 198.453C263.667 199.049 263.195 199.509 262.599 199.705L190.619 223.093C189.771 223.369 188.843 223.049 188.343 222.313L172.315 198.661C171.695 197.745 170.451 197.509 169.535 198.129C168.623 198.745 168.383 199.993 169.003 200.905Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.7665 163.417L165.618 141.329C166.214 141.133 166.687 140.669 166.89 140.073C167.094 139.477 167.006 138.821 166.65 138.301C166.65 138.301 152.283 117.169 147.286 109.821C145.786 107.617 143.01 106.665 140.47 107.489L68.4865 130.877C66.6945 131.461 65.2825 132.849 64.6665 134.633C64.0545 136.413 64.3145 138.377 65.3665 139.941C71.1425 148.505 83.3385 166.589 83.3385 166.589C83.9585 167.501 85.2025 167.745 86.1185 167.125C87.0305 166.509 87.2745 165.265 86.6585 164.349C86.6585 164.349 74.4585 146.269 68.6825 137.705C68.3305 137.181 68.2465 136.529 68.4505 135.933C68.6545 135.341 69.1265 134.877 69.7225 134.681L141.706 111.293C142.554 111.017 143.479 111.337 143.979 112.069L161.846 138.349L96.5305 159.613C95.4785 159.953 94.9065 161.085 95.2465 162.133C95.5905 163.181 96.7185 163.757 97.7665 163.417Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M164.377 141.329L244.377 167.369C245.225 167.645 246.157 167.329 246.657 166.589C246.657 166.589 258.853 148.505 264.629 139.941C265.681 138.377 265.941 136.413 265.329 134.633C264.713 132.849 263.301 131.461 261.509 130.877L189.525 107.489C186.985 106.665 184.209 107.617 182.709 109.821L168.717 130.397C168.097 131.313 168.333 132.557 169.245 133.177C170.161 133.797 171.405 133.561 172.025 132.649L186.017 112.069C186.517 111.337 187.441 111.017 188.289 111.293L260.273 134.681C260.869 134.877 261.341 135.341 261.545 135.933C261.749 136.529 261.665 137.181 261.313 137.705L244.181 163.101L165.617 137.525C164.565 137.181 163.437 137.757 163.097 138.809C162.753 139.857 163.329 140.985 164.377 141.329Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.585 165.461L164.997 185.117L104.409 165.461L164.997 145.737L225.585 165.461Z"
        fill="#2EDEBF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.992 139.239C143.916 137.723 142.992 136.199 142.216 134.675C141.716 133.691 140.512 133.299 139.528 133.803C138.544 134.303 138.152 135.507 138.652 136.491C139.512 138.183 140.536 139.875 141.732 141.559C142.372 142.459 143.62 142.667 144.52 142.031C145.42 141.391 145.632 140.139 144.992 139.239Z"
        fill="#160825"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.442 126.355C139.082 124.075 139.066 121.847 139.382 119.711C139.542 118.619 138.786 117.603 137.694 117.443C136.602 117.283 135.586 118.039 135.422 119.131C135.054 121.651 135.066 124.283 135.49 126.975C135.662 128.067 136.686 128.811 137.778 128.639C138.866 128.467 139.614 127.443 139.442 126.355Z"
        fill="#160825"
      />
    </Icon>
  );
};
