import { makeObservable, observable, computed, action } from 'mobx';

import { Customer } from '../../models/Customer';
import { CustomerModel } from '../../models/CustomerModel';
import { Project } from '../../models/Project';
import { ProjectsModel } from '../../models/ProjectsModel';
import { displayProjectStatuses } from '../../models/ProjectUtils';
import { IStore } from '../../models/RootStore';
import { PROJECT_STATUS } from '../../requests/Client';
import { SingleProjectRoute } from '../../router/routes';
import { RoutingStore } from '../../router/RoutingStore';

import { CreateProjectRoute, EditProjectRoute } from './../../router/routes';

export type viewProjectStatus = {
  key: string;
  value: PROJECT_STATUS | 'all';
};

const projectStatuses: viewProjectStatus[] = [
  {
    key: 'כל הסטטוסים',
    value: 'all',
  },
  {
    key: displayProjectStatuses[PROJECT_STATUS.ACTIVE],
    value: PROJECT_STATUS.ACTIVE,
  },
  {
    key: displayProjectStatuses[PROJECT_STATUS.PAUSED],
    value: PROJECT_STATUS.PAUSED,
  },
  {
    key: displayProjectStatuses[PROJECT_STATUS.CANCELLED],
    value: PROJECT_STATUS.CANCELLED,
  },
  {
    key: displayProjectStatuses[PROJECT_STATUS.COMPLETE],
    value: PROJECT_STATUS.COMPLETE,
  },
];

const SELECTED_STATUS_KEY = 'peko_selected_status';

export type ProjectWithCustomer = { project: Project; customer?: Customer };
export class ProjectsViewModel implements IStore {
  private projectsModel: ProjectsModel;
  private customerModel: CustomerModel;
  private routingStore: RoutingStore;

  _selectedStatus: viewProjectStatus = projectStatuses[0];
  _filter = '';

  constructor(projectsModel: ProjectsModel, customerModel: CustomerModel, routingStore: RoutingStore) {
    this.projectsModel = projectsModel;
    this.customerModel = customerModel;
    this.routingStore = routingStore;

    const preselectedState = sessionStorage.getItem(SELECTED_STATUS_KEY);
    if (preselectedState) {
      this._selectedStatus = JSON.parse(preselectedState);
    }
    makeObservable(this, {
      _selectedStatus: observable,
      selectedStatus: computed,
      setSelectedStatus: action,

      _filter: observable,
      filter: computed,
      setFilter: action,

      projects: computed,
      totalProjectsLength: computed,
      filteredProjectsByStatus: computed,

      areProjectsLoading: computed,
    });
  }

  activate() {}

  get totalProjectsLength(): number {
    return this.projectsModel.projects.length;
  }

  get areProjectsLoading() {
    return this.projectsModel.isLoading;
  }

  get projectByStatus(): Project[] {
    if (this.selectedStatus.value === 'all') {
      return this.projectsModel.projects;
    }

    return this.projectsModel.projects.filter((p) => p.status === this.selectedStatus.value);
  }

  get filteredProjectsByStatus(): Project[] {
    if (this.filter) {
      return this.projectByStatus.filter((p) => p.name.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase()));
    }
    return this.projectByStatus;
  }

  get projects(): Array<ProjectWithCustomer> {
    return this.filteredProjectsByStatus
      .slice()
      .sort((a, b) => b._startDate.getTime() - a._startDate.getTime())
      .map((p) => {
        return {
          id: p._id,
          project: p,
          customer: this.customerModel.getCustomerById(p.customer),
        };
      });
  }

  get selectedStatus() {
    return this._selectedStatus;
  }

  setSelectedStatus(newStatus: viewProjectStatus) {
    sessionStorage.setItem(SELECTED_STATUS_KEY, JSON.stringify(newStatus));
    this._selectedStatus = newStatus;
  }

  get projectStatuses() {
    return projectStatuses;
  }

  get filter() {
    return this._filter;
  }

  setFilter(f: string) {
    this._filter = f;
  }

  onProjectClick(projectId: string) {
    this.routingStore.navigate({
      ...SingleProjectRoute,
      params: { projectId },
    });
  }

  onCreateProject = () => {
    this.routingStore.navigate(CreateProjectRoute);
  };

  async deleteProject(p: Project) {
    return this.projectsModel.deleteProject(p);
  }

  editProject = (p: Project) => {
    this.routingStore.navigate({ ...EditProjectRoute, params: { projectId: p._id } });
  };

  createTask = (projectId: string) => {
    this.routingStore.setIsCreatingTask(true, undefined, projectId);
  };
}
