import { theme as chakraTheme } from '@chakra-ui/react';

import { Button } from './button';
import { Checkbox } from './checkbox';
import { Input } from './input';
import { Link } from './link';
import { Radio } from './radio';
import { Switch } from './switch';
import { Tabs } from './tabs';
import { Textarea } from './textarea';

const { Popover, Table, Badge, FormLabel, Modal, Menu, Tag, Tooltip, Spinner, Select, Progress, Divider } =
  chakraTheme.components;

export const components = {
  Popover,
  Table,
  FormLabel,
  Tooltip,
  Tag,
  Menu,
  Spinner,
  Select,
  Badge: {
    ...Badge,
    baseStyle: {
      px: 6,
      py: 2,
      borderRadius: 'xl',
    },
    defaultProps: {
      colorScheme: 'purple',
    },
  },
  Divider: {
    ...Divider,
    baseStyle: {
      opacity: 1,
      borderColor: '#E6E9F2',
    },
  },
  Progress: {
    ...Progress,
    baseStyle: {
      filledTrack: {
        bg: 'success.100',
      },
      track: {
        bg: '#EEEFF5',
      },
    },
    defaultProps: {
      size: 'lg',
    },
  },
  FormError: {
    baseStyle: {
      text: {
        color: 'accent.100',
      },
    },
  },
  Modal: {
    ...Modal,
    sizes: {
      '2xl': '750px',
    },
  },
  Tabs,
  Checkbox,
  Input,
  Button,
  Switch,
  Textarea,
  Radio,
  Link,
};
