import { makeObservable, observable, computed, action } from 'mobx';

import { SelectItem } from '../../components/form/Select';
import { CustomerStatus } from '../../models/Customer';
import { CustomerModel } from '../../models/CustomerModel';
import { ProjectsModel } from '../../models/ProjectsModel';
import { IStore } from '../../models/RootStore';
import { RoutingStore } from '../../router/RoutingStore';

import { EditLeadRoute, CreateLeadRoute } from './../../router/routes';

export class LeadsViewModel implements IStore {
  customerModel: CustomerModel;
  projectsModel: ProjectsModel;
  routingStore: RoutingStore;

  _filter: string;
  _selectedStatuses: SelectItem[] = [];

  constructor(customerModel: CustomerModel, projectsModel: ProjectsModel, routingStore: RoutingStore) {
    this.customerModel = customerModel;
    this.projectsModel = projectsModel;
    this.routingStore = routingStore;

    this._filter = '';

    makeObservable(this, {
      customerModel: observable,
      projectsModel: observable,
      _filter: observable,
      _selectedStatuses: observable,

      leads: computed,
      leadsExist: computed,
      filter: computed,
      setFilter: action,
      selectedStatuses: computed,
      setSelectedStatuses: action,
      deleteLead: action,
    });
  }

  activate() {}

  get leadsExist() {
    return this.customerModel.leads.length > 0;
  }

  get leads() {
    return this.customerModel.leads
      .filter((c) => c.name.toLowerCase().includes(this.filter.toLowerCase()))
      .filter(
        (c) =>
          (this.selectedStatuses.length === 0 && c.status) ||
          this.selectedStatuses.map((s) => s.value).includes(c.status!),
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get filter() {
    return this._filter;
  }

  setFilter(f: string) {
    this._filter = f;
  }

  get selectedStatuses() {
    return this._selectedStatuses;
  }

  setSelectedStatuses = (s: SelectItem[]) => {
    this._selectedStatuses = s;
  };

  deleteLead = async (leadId: string) => {
    const customerProjects = this.projectsModel.projects.filter((p) => p.customer === leadId);

    await Promise.all(customerProjects.map((p) => this.projectsModel.deleteProject(p)));
    return this.customerModel.deleteCustomer(leadId);
  };

  editLead = async (leadId: string) => {
    this.routingStore.navigate({
      ...EditLeadRoute,
      params: { leadId },
    });
  };

  createLead = async () => {
    this.routingStore.navigate({
      ...CreateLeadRoute,
    });
  };

  changeStatus = async (leadId: string, status: string) => {
    if (status === CustomerStatus.CLOSED) {
      this.routingStore.navigate({ ...EditLeadRoute, params: { leadId, status } });
    } else {
      this.customerModel.changeStatus(leadId, status);
    }
  };
}
