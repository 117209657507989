import { set } from 'date-fns';
import Papa from 'papaparse';

import { IWorkHourBase } from '../models/workHours/workHourApi';
import { IMPORT_TYPE } from '../pages/projects/createProject/UploadWorkHoursModal';

export async function csvToWorkHourApi(file: File, type: IMPORT_TYPE) {
  const dataJSON: Array<string[]> = await new Promise((resolve, reject) => {
    // @ts-ignore
    Papa.parse(file, {
      headers: true,
      skipEmptyLines: true,
      complete: (res: any) => {
        resolve(res.data);
      },
      error: (res: any) => {
        reject(res.error);
      },
    });
  });

  const headers = dataJSON[0];
  const headersMap = {
    description: headers.findIndex((header) => header.toLocaleLowerCase() === 'description'),
    startTime: headers.findIndex((header) => header.toLocaleLowerCase() === 'start time'),
    endTime: headers.findIndex((header) => header.toLocaleLowerCase() === 'end time'),
    startDate: headers.findIndex((header) => header.toLocaleLowerCase() === 'start date'),
    endDate: headers.findIndex((header) => header.toLocaleLowerCase() === 'end date'),
  };

  dataJSON.shift();
  const dataParsed = dataJSON.reduce<
    Array<{
      description: string;
      startTime: string;
      startDate: string;
      endTime: string;
      endDate: string;
    }>
  >((acc, curr) => {
    const res = {
      description: curr[headersMap.description],
      startTime: curr[headersMap.startTime],
      startDate: curr[headersMap.startDate],
      endTime: curr[headersMap.endTime],
      endDate: curr[headersMap.endDate],
    };
    acc.push(res);
    return acc;
  }, []);

  return dataParsed.map((item) =>
    type === IMPORT_TYPE.TOGGL ? togglToWorkHourApi(item) : clockifyToWorkHourApi(item),
  );
}

interface IClockifyExport {
  description: string;
  endDate: string; // dd/mm/yyyy
  endTime: string; // hh:mm:ss
  startDate: string; // dd/mm/yyyy
  startTime: string; // hh:mm:ss
}

export function clockifyToWorkHourApi(data: IClockifyExport): IWorkHourBase {
  const [startH, startM, startS] = data.startTime.split(':');
  const [startD, startMonth, startY] = data.startDate.split('/');
  const startTime = set(new Date(), {
    year: Number(startY),
    month: Number(startMonth) - 1,
    date: Number(startD),
    hours: Number(startH),
    minutes: Number(startM),
    seconds: Number(startS),
    milliseconds: 0,
  });
  const [endH, endM, endS] = data.endTime.split(':');
  const [endD, endMonth, endY] = data.endDate.split('/');
  const endTime = set(new Date(), {
    year: Number(endY),
    month: Number(endMonth) - 1,
    date: Number(endD),
    hours: Number(endH),
    minutes: Number(endM),
    seconds: Number(endS),
    milliseconds: 0,
  });

  return {
    start_time: startTime.toISOString(),
    end_time: endTime.toISOString(),
    comment: data.description,
  };
}
interface ITogglExport {
  description: string;
  endDate: string; // yyyy-mm-dd
  endTime: string; // hh:mm:ss
  startDate: string; // yyyy-mm-dd
  startTime: string; // hh:mm:ss
}

export function togglToWorkHourApi(data: ITogglExport): IWorkHourBase {
  const [startH, startM, startS] = data.startTime.split(':');
  const startTime = set(new Date(data.startDate), {
    hours: Number(startH),
    minutes: Number(startM),
    seconds: Number(startS),
    milliseconds: 0,
  });
  const [endH, endM, endS] = data.endTime.split(':');
  const endTime = set(new Date(data.endDate), {
    hours: Number(endH),
    minutes: Number(endM),
    seconds: Number(endS),
    milliseconds: 0,
  });

  return {
    start_time: startTime.toISOString(),
    end_time: endTime.toISOString(),
    comment: data.description,
  };
}
