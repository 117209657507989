import { startOfMonth, add, format } from 'date-fns';
import { makeObservable, observable, action, computed } from 'mobx';

import { IPaymentApi } from '../requests/Client';

import { PaymentCycleEnum } from './Project';

export class Payment {
  _id: string;
  _sum: number | null;
  _comment?: string;
  _isAdvance: boolean;
  _submitDate: Date;

  _rate: number;
  _paymentCycle: number;

  constructor(rate: number, paymentCycle: PaymentCycleEnum, payment?: IPaymentApi) {
    this._id = payment?.id.toString() || '';
    this._sum = payment?.sum || null;
    this._comment = payment?.comment || undefined;
    this._isAdvance = payment?.is_advance || false;
    this._submitDate = payment?.submit_date ? new Date(payment.submit_date) : new Date();

    this._rate = rate;
    this._paymentCycle = paymentCycle;

    makeObservable(this, {
      _sum: observable,
      sum: computed,
      _comment: observable,
      comment: computed,
      _isAdvance: observable,
      isAdvance: computed,
      _submitDate: observable,
      submitDate: computed,
      displayPaymentDate: computed,

      setId: action,
    });
  }

  get sum() {
    if (this._sum) {
      return this._sum;
    }

    // hourly payment - sum is computed by work-hours
    return null;
  }
  get comment() {
    return this._comment;
  }

  get isAdvance() {
    return this._isAdvance;
  }

  get submitDate(): string {
    return format(this._submitDate, 'yyyy-MM-dd');
  }

  get paymentDate() {
    if (this.isAdvance) {
      return this._submitDate;
    }

    switch (this._paymentCycle) {
      case PaymentCycleEnum.IMMEDIATE:
        return this._submitDate;
      case PaymentCycleEnum.SHOTEF:
        return startOfMonth(add(this._submitDate, { months: 1 }));
      case PaymentCycleEnum.SHOTEF10:
        return add(startOfMonth(add(this._submitDate, { months: 1 })), {
          days: 10,
        });
      case PaymentCycleEnum.SHOTEF15:
        return add(startOfMonth(add(this._submitDate, { months: 1 })), {
          days: 15,
        });
      case PaymentCycleEnum.SHOTEF30:
        return startOfMonth(add(this._submitDate, { months: 2 }));
      case PaymentCycleEnum.SHOTEF45:
        return add(startOfMonth(add(this._submitDate, { months: 2 })), {
          days: 15,
        });
      case PaymentCycleEnum.SHOTEF60:
        return startOfMonth(add(this._submitDate, { months: 3 }));
    }
    return undefined;
  }
  get displayPaymentDate() {
    return this.paymentDate ? format(this.paymentDate, 'dd.MM.yyyy') : undefined;
  }

  setId = (id: string) => {
    this._id = id;
  };
}
