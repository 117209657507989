import { format, isAfter, isSameMonth } from 'date-fns';
import { action, makeObservable, observable, computed } from 'mobx';

import { IProjectApi, PROJECT_STATUS, PROJECT_TYPE } from '../requests/Client';
import { getDisplayPaymentCycle } from '../utils/paymentUtils';
import { HOURS_TO_DURATION } from '../utils/time/timeUtils';

import { Addition } from './Addition';
import { Expense } from './Expense';
import { Payment } from './Payment';
import { WorkHour } from './WorkHour';
import { getWorkHoursDuration, getWorkHoursSum } from './workHourUtils';

export enum AdvancePaymentTypeEnum {
  PERCENTAGE = 'PERCENTAGE',
  EXACT = 'EXACT',
}

export enum PaymentCycleEnum {
  IMMEDIATE,
  SHOTEF,
  SHOTEF10,
  SHOTEF15,
  SHOTEF30,
  SHOTEF45,
  SHOTEF60,
}
export interface IAdvancePayment {
  type: AdvancePaymentTypeEnum;
  value: number;
}

export class Project {
  _id: string;
  _name: string;
  _startDate: Date;
  _endDate: Date | undefined;
  _service: string = '';

  _status: PROJECT_STATUS;
  _type: PROJECT_TYPE;
  _rate: number;
  _value: number;
  _hourTracking: boolean;
  _paymentCycle: PaymentCycleEnum;

  _customer: string | undefined; // id
  _payments: Payment[];
  _workHours: WorkHour[];
  _additions: Addition[];
  _expenses: Expense[];

  constructor(p?: IProjectApi, projectWorkHours?: WorkHour[]) {
    this._id = p?.id.toString() || '';
    this._name = p?.name || '';
    this._startDate = p?.start_date ? new Date(p.start_date) : new Date();
    this._endDate = p?.end_date ? new Date(p.end_date) : undefined;
    this._service = p?.service || '';
    this._customer = p?.customer_id;
    this._status = (p?.status as PROJECT_STATUS) || PROJECT_STATUS.ACTIVE;
    this._type = (p?.type as PROJECT_TYPE) || PROJECT_TYPE.HOURLY;
    this._rate = p?.rate ? parseInt(p.rate) : 0;
    this._value = p?.total_value || 0;
    this._hourTracking = p?.hour_tracking === undefined ? true : p?.hour_tracking;
    this._paymentCycle = p?.payment_cycle || 0;
    this._workHours = projectWorkHours || [];

    this._payments = p?.payments?.map((payment) => new Payment(this._rate, this._paymentCycle, payment)) || [];

    this._additions = p?.additions?.map((addition) => new Addition(addition, this._rate)) || [];

    this._expenses = p?.expenses?.map((expense) => new Expense(expense)) || [];

    makeObservable(this, {
      _name: observable,
      _startDate: observable,
      _endDate: observable,
      _service: observable,
      _status: observable,
      status: computed,
      setStatus: action,
      _type: observable,
      type: computed,
      _rate: observable,
      rate: computed,
      _value: observable,
      totalValue: computed,
      accumulatedValue: computed,
      _hourTracking: observable,
      hourTracking: computed,
      _paymentCycle: observable,
      paymentCycle: computed,
      displayPaymentCycle: computed,
      name: computed,
      startDate: computed,
      endDate: computed,
      service: computed,
      _additions: observable,
      _expenses: observable,

      accumulatedDuration: computed,
      totalDuration: computed,
      remainingValue: computed,
      totalAdditionsValue: computed,
      totalAdditionsHours: computed,
      totalRevenue: computed,
      totalRevenueDuration: computed,
      remainingRevenue: computed,
      remainingRevenueDuration: computed,
      totalExpenses: computed,
      totalProfit: computed,

      _customer: observable,
      customer: computed,
      _payments: observable,
      payments: computed,
      sortedPayments: computed,
      addPayment: action,
      removePayment: action,
      _workHours: observable,
      workHours: computed,
      addWorkHour: action,
      removeWorkHour: action,
      insertAddition: action,
      removeAdditionById: action,
      insertExpense: action,
      removeExpenseById: action,
    });
  }

  get name() {
    return this._name;
  }

  get startDate(): string {
    return format(this._startDate, 'yyyy-MM-dd');
  }

  get endDate() {
    return this._endDate ? format(this._endDate, 'yyyy-MM-dd') : undefined;
  }

  get service() {
    return this._service;
  }

  get status() {
    return this._status;
  }

  setStatus = (status: PROJECT_STATUS) => {
    this._status = status;
  };

  get type() {
    return this._type;
  }

  get displayType() {
    return this.type === PROJECT_TYPE.FIXED ? 'פיקס' : 'שעתי';
  }

  get rate() {
    return this._rate;
  }

  get totalValue() {
    return this._value;
  }

  get accumulatedValue() {
    if (this.type === PROJECT_TYPE.FIXED) {
      const today = new Date();
      return this.payments.reduce((acc, curr) => {
        if (isAfter(curr._submitDate, today)) {
          return acc;
        }
        return (acc += curr.sum || 0);
      }, 0);
    }
    return getWorkHoursSum(this.workHours, this.rate);
  }

  get accumulatedDuration() {
    return getWorkHoursDuration(this.workHours);
  }

  get totalDuration() {
    return (this.totalValue / this.rate) * HOURS_TO_DURATION;
  }

  get remainingValue() {
    return this.totalValue - this.accumulatedValue;
  }

  get totalAdditionsValue() {
    return this._additions.reduce((sum, curr) => {
      sum += curr.value;
      return sum;
    }, 0);
  }

  get totalAdditionsHours() {
    return this.totalAdditionsValue / this.rate;
  }

  get totalRevenue() {
    return this.totalValue + this.totalAdditionsValue;
  }

  get totalRevenueDuration() {
    return this.totalDuration + this.totalAdditionsHours * HOURS_TO_DURATION;
  }

  get remainingRevenueDuration() {
    return Math.max(this.totalRevenueDuration - this.accumulatedDuration, 0);
  }

  get remainingRevenue() {
    return Math.max(this.totalRevenue - this.accumulatedValue, 0);
  }

  get totalExpenses() {
    return this._expenses.reduce((sum, curr) => {
      sum += curr.value;
      return sum;
    }, 0);
  }

  get totalProfit() {
    if (!this.totalValue) {
      // in case of hourly with no hours-bank (no value) display accumulated value
      return this.accumulatedValue;
    }
    return this.totalRevenue - this.totalExpenses;
  }

  get hourTracking() {
    return this._hourTracking;
  }

  get paymentCycle() {
    return this._paymentCycle;
  }

  get displayPaymentCycle() {
    return getDisplayPaymentCycle(this._paymentCycle);
  }

  get customer() {
    return this._customer;
  }

  get payments() {
    return this._payments;
  }

  get sortedPayments() {
    const array = [...this._payments];
    array.sort((a, b) => b._submitDate.getTime() - a._submitDate.getTime());
    return array;
  }

  addPayment(payment: Payment) {
    this._payments.push(payment);
  }

  removePayment(payment: Payment) {
    this._payments = this._payments.filter((p) => p._id !== payment._id);
  }

  get workHours() {
    const array = [...this._workHours];
    array.sort((a, b) => a._startTime.getTime() - b._startTime.getTime());
    return array;
  }

  addWorkHour(workHour: WorkHour) {
    this._workHours.push(workHour);
  }

  removeWorkHour(workHourId: string) {
    this._workHours = this._workHours.filter((wh) => wh._id !== workHourId);
  }

  getWorkHourPayment(workHourId: string) {
    const advanceWorkHours = this.getAdvanceWorkHours();
    const advanceWorkHoursIds = advanceWorkHours.map((wh) => wh.id);
    if (advanceWorkHoursIds.includes(workHourId)) {
      return this.getAdvancePayment();
    }

    const workHour = this.workHours.find((wh) => wh.id === workHourId);
    if (workHour) {
      return this.getPaymentByMonth(workHour._startTime);
    }
    return null;
  }

  getPaymentWorkHours(payment: Payment) {
    const advanceWorkHours = this.getAdvanceWorkHours();
    if (payment.isAdvance) {
      return advanceWorkHours;
    }
    const workHours = this.getWorkHoursByMonth(new Date(payment.submitDate));
    const advanceWorkHoursIds = advanceWorkHours.map((wh) => wh.id);
    return workHours.filter((wh) => !advanceWorkHoursIds.includes(wh._id));
  }

  insertAddition = (addition: Addition) => {
    this._additions.push(addition);
  };

  removeAdditionById = (additionId: string) => {
    this._additions = this._additions.filter((a) => a.id !== additionId);
  };

  insertExpense = (expense: Expense) => {
    this._expenses.push(expense);
  };

  removeExpenseById = (expenseId: string) => {
    this._expenses = this._expenses.filter((e) => e.id !== expenseId);
  };

  getPaymentById = (id: string) => {
    return this.payments.find((p) => p._id === id);
  };

  getAdvancePayment = () => {
    return this.payments.find((p) => p.isAdvance);
  };

  getPaymentByMonth = (date: Date) => {
    return this.sortedPayments.find((p) => isSameMonth(p._submitDate, date));
  };

  getWorkHoursByMonth = (date: Date) => {
    return this.workHours.reduce<WorkHour[]>((workHours, wh) => {
      if (isSameMonth(wh._startTime, date)) {
        workHours.push(wh);
      }
      return workHours;
    }, []);
  };

  getAdvanceWorkHours = () => {
    const advance = this.getAdvancePayment();
    if (!(advance && advance.sum)) {
      return [];
    }
    return this.workHours.reduce<WorkHour[]>((workHours, wh) => {
      const workHoursSum = getWorkHoursSum(workHours, this.rate);
      if (workHoursSum < (advance.sum || 0)) {
        workHours.push(wh);
      }
      return workHours;
    }, []);
  };
}
