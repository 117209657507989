import { isAfter } from 'date-fns';

import { IUserData } from './useUserData';

export function hasSubscriptionEnded(userData: IUserData) {
  const { paymentState, paymentNextDate } = userData;

  if (paymentState === 'PAYING' || paymentState === 'BETA' || !paymentNextDate) {
    return false;
  }

  const now = new Date();
  return isAfter(now, paymentNextDate);
}

export function isPasswordValid(password: string) {
  const conditionsMet = [
    /[0-9]/,
    /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/,
    /[A-Z]/,
    /[a-z]/
  ].filter(condition => condition.test(password)).length >= 3;

  const lengthValid = password.length >= 8;

  return lengthValid && conditionsMet;
}