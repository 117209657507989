import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { unparse } from 'papaparse';
import { useEffect, useState } from 'react';

import { ButtonTabs } from '../../components/ButtonTabs';
import { Dialog } from '../../components/Dialog';
import { durationToMinutesDisplay, getDuration } from '../../utils/time/timeUtils';

import { EnrichedWorkHour } from './WorkHoursViewModel';

const MARGIN = 40;

enum EXPORT_TYPE {
  'PDF' = 'PDF',
  'CSV' = 'CSV',
}

const exportTypeItems = [
  {
    label: 'PDF',
    id: EXPORT_TYPE.PDF,
  },
  {
    label: 'CSV',
    id: EXPORT_TYPE.CSV,
  },
];

export const WorkHoursReportDialog = ({
  onDismiss,
  currentWorkHours,
}: {
  onDismiss(): void;
  currentWorkHours: EnrichedWorkHour[];
}) => {
  const [exportType, setExportType] = useState<EXPORT_TYPE>(EXPORT_TYPE.PDF);
  const [isCreatingReport, setIsCreatingReport] = useState(false);

  const onComplete = () => {
    setIsCreatingReport(false);
    onDismiss();
  };

  const createScreenshotPDF = async () => {
    const content = document.getElementById('export');
    if (!content) {
      return;
    }

    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule('body > div:last-child img { display: inline-block; }');

    const canvas = await html2canvas(content, {
      width: content.scrollWidth,
      height: content.scrollHeight,
      ignoreElements: (el) => el.id === 'ignore-me',
    });
    const img = canvas.toDataURL('image/jpeg'); // 5 is quality scale

    /** To debug html2canvas output uncomment */
    // const link = document.createElement('a');
    // link.download = 'filename.png';
    // link.href = img;
    // link.click();

    const isLandscape = content.scrollWidth > content.scrollHeight;

    const doc = new jsPDF({
      orientation: isLandscape ? 'l' : 'p',
      unit: 'px',
      format: [content.scrollWidth + MARGIN * 2, content.scrollHeight + MARGIN * 2],
      hotfixes: ['px_scaling'],
    });

    // add canvas as an image
    doc.addImage(img, 'JPEG', MARGIN, MARGIN, content.scrollWidth, content.scrollHeight);

    // save the content to screenshot.pdf
    doc.save('screenshot.pdf');
    style.remove();
    onComplete();
  };

  const exportToCsv = () => {
    const csvReadyWorkHours = currentWorkHours.map((workHour) => ({
      'זמן התחלה': format(workHour.startTime, 'HH:mm:ss'),
      'תאריך התחלה': format(workHour.startTime, 'dd.MM.yyyy'),
      'זמן סיום': format(workHour.endTime, 'HH:mm:ss'),
      'תאריך סיום': format(workHour.endTime, 'dd.MM.yyyy'),
      תיאור: workHour.comment,
      'סה״כ': durationToMinutesDisplay(getDuration(workHour.startTime, workHour.endTime)),
      פרויקט: workHour.projectName,
      לקוח: workHour.customerName,
    }));
    const csv = unparse(csvReadyWorkHours);
    const blob = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8' });

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'report.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    onComplete();
  };

  useEffect(() => {
    if (isCreatingReport) {
      if (exportType === EXPORT_TYPE.PDF) {
        createScreenshotPDF();
      } else {
        exportToCsv();
      }
    }
  }, [isCreatingReport]);

  return (
    <Dialog id="ignore-me" title="הורדת דוח שעות" onClose={onDismiss} size="small">
      <Flex direction="column" alignItems="center" maxW="420px" mx="auto">
        <Text fontSize="lg" mb="12px">
          באיזה פורמט לייצא את הדוח?
        </Text>
        <Box maxW="195px" marginInlineStart="16px">
          <ButtonTabs
            items={exportTypeItems}
            selectedId={exportType}
            handleClick={(state: EXPORT_TYPE) => setExportType(state)}
          />
        </Box>
      </Flex>
      <Flex p="32px" justifyContent="space-between">
        <Button onClick={onDismiss} size="sm" variant="secondary">
          ביטול
        </Button>
        <Button onClick={() => setIsCreatingReport(true)} size="sm">
          הורדת הקובץ
        </Button>
      </Flex>
    </Dialog>
  );
};
