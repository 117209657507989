import { Icon, IconProps } from '@chakra-ui/react';

export const TrashIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <g clipPath="url(#clip0_5641_6984)">
        <path
          d="M22.25 4H17.25V2C17.25 1.46957 17.0393 0.960859 16.6642 0.585786C16.2891 0.210714 15.7804 0 15.25 0L9.25 0C8.71957 0 8.21086 0.210714 7.83579 0.585786C7.46071 0.960859 7.25 1.46957 7.25 2V4H2.25V6H4.25V21C4.25 21.7956 4.56607 22.5587 5.12868 23.1213C5.69129 23.6839 6.45435 24 7.25 24H17.25C18.0456 24 18.8087 23.6839 19.3713 23.1213C19.9339 22.5587 20.25 21.7956 20.25 21V6H22.25V4ZM9.25 2H15.25V4H9.25V2ZM18.25 21C18.25 21.2652 18.1446 21.5196 17.9571 21.7071C17.7696 21.8946 17.5152 22 17.25 22H7.25C6.98478 22 6.73043 21.8946 6.54289 21.7071C6.35536 21.5196 6.25 21.2652 6.25 21V6H18.25V21Z"
          fill="currentColor"
        />
        <path d="M11.25 10H9.25V18H11.25V10Z" fill="currentColor" />
        <path d="M15.25 10H13.25V18H15.25V10Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_5641_6984">
          <rect width="24" height="24" fill="white" transform="translate(0.25)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
