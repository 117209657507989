import { action, computed, makeObservable, observable } from 'mobx';

import { Client } from '../requests/Client';
import { UserClient } from '../requests/UserClient';

import { AuthenticationModel } from './AuthenticationModel';
import { CustomerModel } from './CustomerModel';
import { ProjectsModel } from './ProjectsModel';

export interface IStore {
  activate: (params?: any) => void;
  deactivate?: (params?: any) => void;
}

export interface InitializedStore {
  projectsModel: ProjectsModel;
  customersModel: CustomerModel;
}
export class RootStore {
  private client: Client | null;
  private clientInitializer: typeof Client;
  private userClient: UserClient;

  authenticationModel: AuthenticationModel;

  projectsModel: ProjectsModel | null;
  customersModel: CustomerModel | null;

  constructor(userClient: UserClient, clientInitializer: typeof Client) {
    this.userClient = userClient;
    this.clientInitializer = clientInitializer;

    this.authenticationModel = new AuthenticationModel(this.userClient);

    this.client = null;
    this.projectsModel = null;
    this.customersModel = null;

    if (this.authenticationModel.isAuthenticated) {
      this.initialize();
    }

    makeObservable(this, {
      projectsModel: observable,
      customersModel: observable,
      authenticationModel: observable,
      isInitialized: computed,
      getInitializedStore: computed,
      initialize: action,
      deactivate: action,
    });
  }

  initialize = () => {
    this.client = new this.clientInitializer();

    this.projectsModel = new ProjectsModel(this.client);
    this.customersModel = new CustomerModel(this.client);
  };

  deactivate() {
    this.client = null;
    this.projectsModel?.removeListeners();
    this.projectsModel = null;
    this.customersModel?.removeListeners();
    this.customersModel = null;
  }

  logout() {
    this.deactivate();
    this.authenticationModel.logout();
  }

  get isInitialized() {
    return Boolean(this.client);
  }

  get getInitializedStore(): InitializedStore | null {
    if (this.customersModel && this.projectsModel) {
      return {
        customersModel: this.customersModel,
        projectsModel: this.projectsModel,
      };
    }

    return null;
  }
}
