import { useUserData } from '../../models/userData/useUserData';

export const VAT = 1.17;

export function useVat() {
  const { userData } = useUserData();

  const getValueWithVat = (value?: number) => {
    if (!value) {
      return 0;
    }

    return userData?.includeVat ? value * VAT : value;
  };

  return {
    getValueWithVat,
  };
}
