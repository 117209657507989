import { createIcon } from '@chakra-ui/icons';

export const FlagIcon = createIcon({
  displayName: 'FlagIcon',
  viewBox: '0 0 12 12',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M2.29126 1V1.1375L10.2113 4.04155C10.2605 4.05963 10.3029 4.09275 10.3322 4.13622C10.3616 4.17969 10.3766 4.23131 10.375 4.28376C10.3735 4.33621 10.3554 4.38684 10.3235 4.42847C10.2915 4.4701 10.2473 4.50062 10.197 4.5157L2.29126 6.8875V11C2.29126 11.0995 2.25175 11.1948 2.18142 11.2652C2.1111 11.3355 2.01572 11.375 1.91626 11.375C1.8168 11.375 1.72142 11.3355 1.65109 11.2652C1.58077 11.1948 1.54126 11.0995 1.54126 11V1C1.54126 0.900544 1.58077 0.805161 1.65109 0.734835C1.72142 0.664509 1.8168 0.625 1.91626 0.625C2.01572 0.625 2.1111 0.664509 2.18142 0.734835C2.25175 0.805161 2.29126 0.900544 2.29126 1Z"
    />,
  ],
});
