import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Text, Spinner } from '@chakra-ui/react';
import { useSelect } from 'downshift';

import { displayProjectStatuses } from '../../models/ProjectUtils';
import { PROJECT_STATUS } from '../../requests/Client';

export const ProjectStatus = ({
  status,
  onChange,
  loading,
}: {
  status: PROJECT_STATUS;
  onChange: (s: PROJECT_STATUS) => void;
  loading: boolean;
}) => {
  const data = [
    {
      key: displayProjectStatuses[PROJECT_STATUS.ACTIVE],
      value: PROJECT_STATUS.ACTIVE,
    },
    {
      key: displayProjectStatuses[PROJECT_STATUS.PAUSED],
      value: PROJECT_STATUS.PAUSED,
    },
    {
      key: displayProjectStatuses[PROJECT_STATUS.CANCELLED],
      value: PROJECT_STATUS.CANCELLED,
    },
    {
      key: displayProjectStatuses[PROJECT_STATUS.COMPLETE],
      value: PROJECT_STATUS.COMPLETE,
    },
  ];

  const initialSelectedItem = data.find((d) => d.value === status)?.key;
  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: data.map((d) => d.key),
    onSelectedItemChange: ({ selectedItem }) => {
      const item = data.find((d) => d.key === selectedItem);
      item && onChange(item.value);
    },
    initialSelectedItem,
  });

  return (
    <Box position="relative">
      <Box
        type="button"
        {...getToggleButtonProps({})}
        border="1px solid"
        borderColor="brand.600"
        borderRadius="10px"
        w="100%"
        backgroundColor="transparent"
        color="brand.600"
        fontSize="sm"
        py="8px"
        px="11px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        cursor="pointer"
      >
        {selectedItem}
        {loading ? <Spinner color="brand.600" w={5} h={5} /> : <ChevronDownIcon color="brand.600" w={5} h={5} />}
      </Box>
      <Box
        as="ul"
        {...getMenuProps()}
        position="absolute"
        backgroundColor="white"
        boxShadow="0px 0px 15px rgba(39 12 67 / .12)"
        _focus={{
          // override globals
          boxShadow: '0px 0px 15px rgba(39 12 67 / .12) !important',
        }}
        borderRadius="10px"
        listStyleType="none"
        maxH="250px"
        width="100%"
        overflowY="auto"
        display={isOpen ? 'block' : 'none'}
        zIndex="dropdown"
        p="15px"
        _focusVisible={{ outline: 'none' }}
      >
        {isOpen &&
          data.map((item: any, index) => (
            <li key={`${item}${index}`} {...getItemProps({ item, index })}>
              <Text cursor="pointer" color={highlightedIndex === index ? '#42CEB3' : '#685888'}>
                {item.key}
              </Text>
            </li>
          ))}
      </Box>
    </Box>
  );
};
