import { Flex, Box, Divider } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { ButtonTabs } from '../../../components/ButtonTabs';
import { Card } from '../../../components/Card';
import { useVat } from '../../../hooks/useVat/useVat';
import { Project } from '../../../models/Project';
import { useUserData } from '../../../models/userData/useUserData';
import { PROJECT_TYPE } from '../../../requests/Client';
import { roundTo2 } from '../../../utils/number/numberUtils';
import { Stat } from '../../common/Stat';

import { FixedData } from './FixedData';
import { HourlyData } from './HourlyData';

export enum VIEW_STATE {
  HOURS = 'HOURS',
  INCOME = 'INCOME',
}

export const SingleProjectData = observer(({ project }: { project: Project }) => {
  const { userData } = useUserData();
  const { getValueWithVat } = useVat();
  const [viewState, setViewState] = useState<VIEW_STATE>(
    project.type === PROJECT_TYPE.FIXED ? VIEW_STATE.INCOME : VIEW_STATE.HOURS,
  );

  const viewStateItems = [
    {
      label: 'נתוני שעות',
      id: 'HOURS',
    },
    {
      label: 'נתוני הכנסות',
      id: 'INCOME',
    },
  ];

  const getComponentByType = () => {
    if (project.type === PROJECT_TYPE.HOURLY) {
      return <HourlyData project={project} viewState={viewState} />;
    } else {
      return <FixedData project={project} viewState={viewState} baseRate={userData?.baseRate} />;
    }
  };

  return (
    <Card id="ignore-me">
      <Flex justifyContent="space-between" alignItems="center" mb="40px">
        <ButtonTabs
          items={viewStateItems}
          selectedId={viewState}
          handleClick={(state: VIEW_STATE) => setViewState(state)}
        />
      </Flex>
      <Flex alignItems="center">
        {getComponentByType()}
        <Divider orientation="vertical" minHeight="50px" mx="48px" />
        <Box maxW="150px" flex="0 1 auto" marginInlineEnd="56px">
          <Stat value={getValueWithVat(project.totalExpenses).toLocaleString()} label="הוצאות" icon />
        </Box>
        <Box maxW="150px" flex="0 1 auto">
          <Stat
            value={roundTo2(getValueWithVat(project.totalProfit)).toLocaleString()}
            label="רווח צפוי"
            icon
            highlighted
          />
        </Box>
      </Flex>
    </Card>
  );
});
