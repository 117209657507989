import { createIcon } from "@chakra-ui/icons";

export const WarningIcon = createIcon({
  displayName: "WarningIcon",
  viewBox: "0 0 24 24",
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M12 0.625C15.0168 0.625 17.9101 1.82343 20.0433 3.95666C22.1766 6.08989 23.375 8.98316 23.375 12C23.375 15.0168 22.1766 17.9101 20.0433 20.0433C17.9101 22.1766 15.0168 23.375 12 23.375C8.98316 23.375 6.08989 22.1766 3.95666 20.0433C1.82343 17.9101 0.625 15.0168 0.625 12C0.625 8.98316 1.82343 6.08989 3.95666 3.95666C6.08989 1.82343 8.98316 0.625 12 0.625ZM12 5.5C11.7937 5.49982 11.5896 5.54268 11.4008 5.62583C11.212 5.70897 11.0426 5.83059 10.9034 5.9829C10.7643 6.13521 10.6584 6.31487 10.5926 6.5104C10.5268 6.70593 10.5025 6.91304 10.5212 7.1185L11.1144 13.6283C11.1374 13.8471 11.2406 14.0497 11.4041 14.197C11.5677 14.3442 11.7799 14.4257 12 14.4257C12.2201 14.4257 12.4323 14.3442 12.5959 14.197C12.7594 14.0497 12.8626 13.8471 12.8856 13.6283L13.4771 7.1185C13.4959 6.91318 13.4716 6.70621 13.4059 6.51079C13.3402 6.31536 13.2345 6.13578 13.0955 5.9835C12.9565 5.83121 12.7873 5.70957 12.5987 5.62632C12.4101 5.54307 12.2062 5.50005 12 5.5ZM12 18.5C12.3448 18.5 12.6754 18.363 12.9192 18.1192C13.163 17.8754 13.3 17.5448 13.3 17.2C13.3 16.8552 13.163 16.5246 12.9192 16.2808C12.6754 16.037 12.3448 15.9 12 15.9C11.6552 15.9 11.3246 16.037 11.0808 16.2808C10.837 16.5246 10.7 16.8552 10.7 17.2C10.7 17.5448 10.837 17.8754 11.0808 18.1192C11.3246 18.363 11.6552 18.5 12 18.5Z"
    />,
  ],
});
