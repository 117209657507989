import { createIcon } from '@chakra-ui/icons';

export const StarIcon = createIcon({
  displayName: 'StarIcon',
  viewBox: '0 0 18 18',
  path: [
    <path
      key="0"
      fill="currentColor"
      stroke="#currentColor"
      strokeWidth="0.878049"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.9401 7.80627L16.7061 11.5163C16.961 11.7145 16.7902 12.1213 16.4613 12.1002L10.303 11.7088C10.2482 11.7055 10.1934 11.715 10.143 11.7367C10.0926 11.7584 10.048 11.7916 10.0128 11.8337L6.0733 16.5786C5.86206 16.8331 5.44948 16.6771 5.47981 16.3558L6.05186 10.3456C6.05691 10.2927 6.0485 10.2394 6.02741 10.1906C6.00632 10.1418 5.97324 10.0991 5.93124 10.0666L1.16522 6.35651C0.910698 6.1585 1.08094 5.75186 1.4104 5.7728L7.56868 6.16418C7.62343 6.16756 7.67821 6.158 7.72856 6.13627C7.77892 6.11454 7.82344 6.08125 7.85852 6.0391L11.7995 1.29359C12.0104 1.0389 12.4234 1.19512 12.3927 1.51624L11.8211 7.52623C11.8157 7.57915 11.8238 7.63257 11.8446 7.68154C11.8654 7.7305 11.8982 7.77341 11.9401 7.80627Z"
    />,
  ],
});
