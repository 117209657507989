import { Tag, Box, Flex, Text, Button, Divider } from '@chakra-ui/react';
import { useCombobox, useMultipleSelection } from 'downshift';
import { observer } from 'mobx-react';
import { ReactNode, useState } from 'react';

import { ChevronDownIcon, SearchIcon } from '../../pages/common/icons';

import { RequiredIndicator } from './RequiredIndicator';
import { SelectItem, SelectProps } from './Select';
import { StyledInput } from './StyledInput';

export interface MultiselectProps extends Omit<SelectProps, 'selectedItem' | 'onChange'> {
  selectedItems: Array<SelectItem>;
  onChange: (items: Array<SelectItem>) => void;
  icon?: ReactNode;
  placeholder?: string;
  enableAll?: boolean;
  menuWidth?: string;
  action?: {
    label: string;
    onClick: () => void;
    icon?: ReactNode;
  };
  filter?: ReactNode;
  removeSearch?: boolean;
}

export const MultiSelect = observer(
  ({
    data,
    selectedItems,
    onChange,
    placeholder,
    action,
    filter,
    enableAll,
    label,
    isRequired,
    icon,
    menuWidth,
    size = 'lg',
    removeSearch = false,
  }: MultiselectProps) => {
    const [inputItems, setInputItems] = useState(data);
    const {
      getDropdownProps,
      addSelectedItem,
      removeSelectedItem,
      reset: resetMulti,
    } = useMultipleSelection({
      selectedItems,
      stateReducer: (_, actionAndChanges) => {
        const { type, changes } = actionAndChanges;
        switch (type) {
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            return {
              ...changes,
              activeIndex: undefined,
            };
          default:
            return changes;
        }
      },
    });
    const {
      isOpen,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      getLabelProps,
      getItemProps,
      openMenu,
      closeMenu,
      selectItem,
      reset: resetCombo,
    } = useCombobox({
      selectedItem: null,
      items: inputItems,
      onInputValueChange: ({ inputValue }) => {
        setInputItems(data.filter((d) => d.key.toLowerCase().startsWith(inputValue?.toLowerCase() || '')));
      },
      stateReducer: (state, actionAndChanges) => {
        const { changes, type } = actionAndChanges;
        switch (type) {
          case useCombobox.stateChangeTypes.InputBlur:
            return {
              ...changes,
              highlightedIndex: state.highlightedIndex,
              inputValue: '',
            };
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              highlightedIndex: state.highlightedIndex,
              isOpen: true,
              inputValue: '',
            };
          default:
            return changes;
        }
      },
      onStateChange: ({ type, selectedItem }) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            if (selectedItem) {
              if (selectedItems.map((item) => item.value).includes(selectedItem.value)) {
                removeSelectedItem(selectedItem);
                const index = selectedItems.findIndex((item: SelectItem) => selectedItem.value === item.value);
                if (index > 0) {
                  onChange([...selectedItems.slice(0, index), ...selectedItems.slice(index + 1)]);
                } else if (index === 0) {
                  onChange([...selectedItems.slice(1)]);
                }
              } else {
                addSelectedItem(selectedItem);
                onChange([...selectedItems, selectedItem]);
              }
              // @ts-ignore
              selectItem(null);
            }
            break;
          default:
            break;
        }
      },
    });

    const getMenuHeight = () => {
      let height = 280;
      if (action) {
        height += 70;
      }

      if (filter) {
        height += 80;
      }

      if (enableAll) {
        height += 70;
      }

      return height;
    };

    const getMenuScrollHeight = () => {
      let height = 280;

      if (enableAll) {
        height -= 70;
      }

      return height;
    };

    const reset = () => {
      resetCombo();
      resetMulti();
    };

    const toggleAll = () => {
      if (selectedItems.length === data.length) {
        onChange([]);
      } else {
        onChange(data);
      }
    };

    return (
      <>
        {label && (
          <Text textStyle="medium" color="base.600" mb="4px" {...getLabelProps()}>
            {label}
            {isRequired && <RequiredIndicator />}
          </Text>
        )}
        <Box
          position="relative"
          border="1px solid"
          borderColor="base.300"
          borderRadius={size === 'sm' ? '8px' : '12px'}
          background="white"
          _hover={{
            borderColor: isOpen ? 'base.1000' : 'base.600',
          }}
          _focus={{
            borderColor: 'base.1000',
          }}
          _active={{
            borderColor: 'base.1000',
          }}
        >
          <Box {...getComboboxProps()}>
            <Flex
              {...getDropdownProps({
                onClick: isOpen ? closeMenu : openMenu,
              })}
              alignItems="center"
              gap="10px"
              cursor="pointer"
              p={size === 'lg' ? '14px' : '8px'}
            >
              {icon}
              {placeholder && (
                <Text textStyle="large" color="base.1000" flex="1">
                  {placeholder}
                </Text>
              )}
              {selectedItems.length > 0 ? (
                <Tag
                  backgroundColor="base.100"
                  color="base.1000"
                  px="4px"
                  py="2px"
                  fontSize="md"
                  justifyContent="center"
                  alignItems="center"
                >
                  {selectedItems.length}
                </Tag>
              ) : (
                <Box w="24px" h="24px" />
              )}
              <ChevronDownIcon
                cursor="pointer"
                color="base.900"
                w="16px"
                h="16px"
                transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}
              />
            </Flex>
          </Box>
          <Box
            as="ul"
            {...getMenuProps()}
            position="absolute"
            top={size === 'lg' ? '67px' : '50px'}
            border="1px solid"
            borderColor="base.300"
            borderRadius={size === 'sm' ? '8px' : '12px'}
            background="white"
            maxH={`${getMenuHeight()}px`}
            overflow="hidden"
            minWidth="100%"
            w={menuWidth}
            listStyleType="none"
            boxShadow="0px 4px 20px 0px rgba(92, 109, 145, 0.08)"
            _focus={{
              // override globals
              boxShadow: '0px 4px 20px 0px rgba(92, 109, 145, 0.08) !important',
            }}
            display={isOpen ? 'block' : 'none'}
            zIndex="dropdown"
            p="12px"
          >
            {!removeSearch && <StyledInput size="sm" {...getInputProps()} endIcon={<SearchIcon />} />}
            {action && (
              <Button
                variant="ghost"
                mt="8px"
                w="100%"
                size="sm"
                onClick={() => {
                  action.onClick();
                  reset();
                }}
              >
                {action.label}
              </Button>
            )}
            {(!removeSearch || action) && <Divider color="base.200" my="12px" />}
            <Box overflowY="auto" maxH={`${getMenuScrollHeight()}px`}>
              {isOpen &&
                inputItems.map((item, index) => (
                  <Box
                    as="li"
                    key={item.value}
                    {...getItemProps({
                      item,
                      index,
                    })}
                    cursor="pointer"
                    borderRadius={size === 'sm' ? '6px' : '8px'}
                    margin="0"
                    p="8px"
                    textStyle="large"
                    _hover={{
                      background: 'base.25',
                    }}
                  >
                    <FakeCheckbox
                      menuWidth={`calc(${menuWidth} - 80px)`}
                      checked={selectedItems.findIndex((i) => i.value === item.value) !== -1}
                      label={item.key}
                    />
                  </Box>
                ))}
            </Box>
            {(filter || enableAll) && (
              <Box>
                <Divider color="base.200" my="12px" />
                {enableAll && (
                  <Button size="sm" variant="link" onClick={toggleAll}>
                    {selectedItems.length === data.length ? 'איפוס' : 'סימון הכל'}
                  </Button>
                )}
                {filter}
              </Box>
            )}
          </Box>
        </Box>
      </>
    );
  },
);

const FakeCheckbox = ({ checked, label, menuWidth }: { checked: boolean; label: string; menuWidth: string }) => {
  return (
    <Flex alignItems="center" gap="10px">
      <Box
        h="15px"
        w="15px"
        border="1px solid"
        borderColor={checked ? 'base.900' : 'rgba(39, 12, 67, 0.3)'}
        borderRadius="5px"
        backgroundColor={checked ? 'base.900' : '#F8F8FC'}
      >
        {checked && (
          <svg
            viewBox="0 0 12 10"
            style={{
              fill: 'none',
              strokeWidth: 2,
              stroke: 'white',
            }}
          >
            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
          </svg>
        )}
      </Box>
      <Box maxW={menuWidth}>
        <Text color="base.900" fontSize="lg" noOfLines={1} display="block" whiteSpace="nowrap" title={label}>
          {label}
        </Text>
      </Box>
    </Flex>
  );
};
