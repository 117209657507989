import { createIcon } from "@chakra-ui/icons";

export const FacebookIcon = createIcon({
  displayName: "FacebookIcon",
  viewBox: "0 0 8 15",
  path: (
    <path
      key="0"
      fill="currentColor"
      d="M5 8.21429H6.78571L7.5 5.35714H5V3.92857C5 3.19286 5 2.5 6.42857 2.5H7.5V0.1C7.26714 0.0692858 6.38786 0 5.45929 0C3.52 0 2.14286 1.18357 2.14286 3.35714V5.35714H0V8.21429H2.14286V14.2857H5V8.21429Z"
    />
  ),
});
