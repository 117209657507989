import { colors } from '../../theme/colors';

import { TaskPriority, TaskStatus, Task } from './tasksApi';

export function getPriorityColor(priority: TaskPriority) {
  switch (priority) {
    case TaskPriority.HIGH:
      return 'error.200';
    case TaskPriority.MEDIUM:
      return 'primary.200';
    case TaskPriority.LOW:
      return 'success.200';
  }
}

export function getPriorityLabel(priority: TaskPriority) {
  switch (priority) {
    case TaskPriority.HIGH:
      return 'גבוהה';
    case TaskPriority.MEDIUM:
      return 'רגילה';
    case TaskPriority.LOW:
      return 'נמוכה';
  }
}

export function getStatusLabel(status: TaskStatus) {
  switch (status) {
    case TaskStatus.TODO:
      return 'לטיפול';
    case TaskStatus.DOING:
      return 'בתהליך';
    case TaskStatus.DONE:
      return 'הושלם';
  }
}

export const PriorityOptions = [
  {
    key: getPriorityLabel(TaskPriority.HIGH),
    value: TaskPriority.HIGH,
  },
  {
    key: getPriorityLabel(TaskPriority.MEDIUM),
    value: TaskPriority.MEDIUM,
  },
  {
    key: getPriorityLabel(TaskPriority.LOW),
    value: TaskPriority.LOW,
  },
];

export const statusOptions = [
  {
    key: getStatusLabel(TaskStatus.TODO),
    value: TaskStatus.TODO,
  },
  {
    key: getStatusLabel(TaskStatus.DOING),
    value: TaskStatus.DOING,
  },
  {
    key: getStatusLabel(TaskStatus.DONE),
    value: TaskStatus.DONE,
  },
];

export const getInitialTaskValues = (task?: Task, defaultProjectId?: string): Task => {
  return {
    id: task?.id || -1,
    label: task?.label || '',
    is_complete: task?.is_complete || false,
    project_id: task?.project_id || defaultProjectId || null,
    project_name: task?.project_name || '',
    due_date: task?.due_date ? new Date(task.due_date) : null,
    priority: task?.priority || TaskPriority.MEDIUM,
    status: task?.status || TaskStatus.TODO,
    description: task?.description || '',
  };
};
