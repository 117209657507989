import { Divider } from '@chakra-ui/react';
import { Formik, FormikErrors } from 'formik';
import isUndefined from 'lodash/isUndefined';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { IconButton } from '../../../components/IconButton';
import { useCustomToast } from '../../../hooks/toast/useCustomToast';
import { useCreateWorkHour } from '../../../models/workHours/useCreateWorkHour';
import { IWorkHourForm, getInitialFormData, minutesDisplayToDate } from '../../../models/workHours/workHourFormUtils';
import { logger } from '../../../utils/logger';
import { PlusIcon } from '../../common/icons';

import { CreateWorkHourViewModel } from './CreateWorkHourViewModel';
import { ManualWorkHourForm } from './ManualWorkHourForm';
import { TimeTrackerPopover } from './TimeTrackerPopover';
import { TimeTrackerSuccess } from './TimeTrackerSuccess';
import { TrackerProjectAutocomplete } from './TrackerProjectAutocomplete';

export const ManualTimeTracker = observer(({ viewModel }: { viewModel: CreateWorkHourViewModel }) => {
  const { createWorkHour } = useCreateWorkHour(viewModel.saveWorkHour);
  const { triggerToast } = useCustomToast('יצירת הדיווח', false);
  const [isOpen, setIsOpen] = useState(Boolean(viewModel.selectedProject));
  const [success, setSuccess] = useState(false);
  const handleManualSuccess = (values: IWorkHourForm) => {
    const workHour = {
      startTime: minutesDisplayToDate(values.startTime, values.date),
      endTime: minutesDisplayToDate(values.endTime || '', values.date),
      comment: values.comment,
      date: values.date,
      projectId: viewModel.selectedProject?._id,
    };
    createWorkHour(workHour);
    setIsOpen(false);
    setSuccess(true);

    setTimeout(() => {
      setSuccess(false);
      viewModel.setSelectedProject(undefined);
      viewModel.handleToggleState();
      viewModel.handleToggleState();
    }, 1000);
  };

  const handleProjectSelect = (projectId?: string) => {
    setIsOpen(!isUndefined(projectId));
    viewModel.setSelectedProject(projectId);
  };

  const initialValues = getInitialFormData();

  return (
    <>
      <Divider borderColor="#E6E9F2" height="24px" marginInline="15px" orientation="vertical" />
      <TrackerProjectAutocomplete onChange={handleProjectSelect} clearValues={success} data={viewModel.allProjects} />
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: FormikErrors<IWorkHourForm> = {};
          if (!values.startTime) {
            errors.startTime = 'אנחנו צריכים את זה';
          }
          if (!values.endTime) {
            errors.endTime = 'בלי זה אי אפשר להמשיך';
          }
          return errors;
        }}
        onSubmit={async (values, { resetForm }) => {
          try {
            await handleManualSuccess(values as IWorkHourForm);
            resetForm();
          } catch (e) {
            triggerToast('error');
            logger.error(e);
          }
        }}
      >
        {(props) => (
          <TimeTrackerPopover
            isOpen={isOpen}
            offset="140px, 5px"
            minWidth="330px"
            Trigger={<IconButton icon={<PlusIcon />} color="success" onClick={() => setIsOpen((prev) => !prev)} />}
            Body={
              <ManualWorkHourForm
                form={props}
                minimumDate={viewModel.minimumDate}
                maximumDate={viewModel.maximumDate}
              />
            }
            onCancel={() => setIsOpen(false)}
            onSave={props.submitForm}
          />
        )}
      </Formik>
      <TimeTrackerSuccess shouldDisplay={success} />
    </>
  );
});
