import {
  Button,
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
} from '@chakra-ui/react';

interface TimeTrackerPopoverProps {
  isOpen: boolean;
  offset: string;
  Trigger: JSX.Element;
  Body: JSX.Element;
  onSave: () => void;
  onCancel: () => void;
  saveDisabled?: boolean;
  minWidth?: string;
}

export const TimeTrackerPopover = ({
  isOpen,
  offset,
  Trigger,
  Body,
  onSave,
  onCancel,
  saveDisabled = false,
  minWidth = '270px',
}: TimeTrackerPopoverProps) => {
  return (
    <Popover closeOnBlur={false} isOpen={isOpen} isLazy>
      <PopoverTrigger>
        <Box>{Trigger}</Box>
      </PopoverTrigger>
      <PopoverContent
        w="330px"
        minWidth={minWidth}
        transform={`translate(${offset}) !important`}
        boxShadow="0px 0px 15px rgba(39, 12, 67, 0.12)"
        border="none"
        borderRadius="10px"
        p="26px"
        paddingTop="36px"
        _focus={{
          boxShadow: '0px 0px 15px rgba(39, 12, 67, 0.12) !important',
        }}
      >
        <PopoverBody p="0">{Body}</PopoverBody>
        <PopoverFooter p="0" borderBottomRadius="10px" border="none">
          <Flex minW={'100%'} justifyContent="space-between">
            <Button variant="secondary" size="sm" alignSelf="start" onClick={onCancel}>
              ביטול
            </Button>
            <Button size="sm" isDisabled={saveDisabled} onClick={onSave}>
              שמירה
            </Button>
          </Flex>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
