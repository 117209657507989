import { Button, Stack, Flex } from '@chakra-ui/react';
import { Formik, Form, FormikErrors } from 'formik';

import { Dialog } from '../../../components/Dialog';
import { FormDatePicker } from '../../../components/form/FormDatePicker';
import { FormInput } from '../../../components/form/FormInput';
import { Expense, IExpenseValues } from '../../../models/Expense';
import { ShekelIcon } from '../../common/icons';

export const CreateExpense = ({
  initialValues: _initialValues,
  onSubmit,
  onDismiss,
}: {
  initialValues: Expense | null;
  onSubmit: (data: IExpenseValues) => void;
  onDismiss: () => void;
}) => {
  const initialValues: IExpenseValues = {
    date: _initialValues?._date || new Date(),
    value: _initialValues?.value || undefined,
    comment: _initialValues?.comment || '',
    name: _initialValues?.name || '',
  };
  return (
    <Dialog title={_initialValues ? 'עריכת הוצאה' : 'יצירת הוצאה'} onClose={onDismiss}>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: FormikErrors<IExpenseValues> = {};

          if (!values.date) {
            errors.date = 'אנחנו צריכים את זה';
          }
          if (!values.value) {
            errors.value = 'אנחנו צריכים את זה';
          }

          return errors;
        }}
        onSubmit={async (values) => {
          await onSubmit(values);
          onDismiss();
        }}
      >
        {(props) => (
          <Form>
            <Stack gap="16px" my={5} px="32px">
              <FormDatePicker size="sm" name="date" label="תאריך" isRequired />
              <FormInput size="sm" name="name" label="עבור" />
              <FormInput
                size="sm"
                isRequired
                name="value"
                label="סכום"
                type="number"
                step="0.01"
                endIcon={<ShekelIcon w="14px" />}
              />
              <FormInput size="sm" name="comment" label="תיאור ההוצאה" rows={2} type="textarea" />
            </Stack>
            <Flex justifyContent="space-between" alignItems="center" p="32px">
              <Button variant="secondary" alignSelf="start" size="sm" onClick={onDismiss}>
                ביטול
              </Button>
              <Stack>
                <Button isLoading={props.isSubmitting} type="submit" size="sm" isDisabled={!props.isValid}>
                  שמירה
                </Button>
              </Stack>
            </Flex>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
