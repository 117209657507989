import { IStore } from '../../models/RootStore';

import { RoutingStore } from './../../router/RoutingStore';

export enum VIEW_TYPE {
  LOGIN,
  SIGNUP,
  CONFIRM_EMAIL,
  RESET_PASSWORD,
}

export class OnboardingViewModel implements IStore {
  constructor() {}

  activate() {}
}
