import { Text, Button, Stack, Box, Flex, Heading, Spacer } from '@chakra-ui/react';
import axios from 'axios';
import Bowser from 'bowser';
import { Formik, Form, FormikErrors } from 'formik';
import { useState } from 'react';

import { ID_TOKEN } from '../../models/AuthenticationModel';
import { SuccessIcon } from '../../pages/common/icons';
import { logger } from '../../utils/logger';
import { Dialog } from '../Dialog';
import { FormInput } from '../form/FormInput';
import { FormSelect } from '../form/FormSelect';

enum HELP_REQUEST_TYPE {
  FINANCIAL = 'כספי',
  TECHNICAL = 'טכני',
  IMPROVEMENT = 'הצעה לשיפור',
  OTHER = 'אחר',
}

interface IRequestHelpValues {
  type?: HELP_REQUEST_TYPE;
  comment?: string;
}

export const HelpDialog = ({
  userEmail,
  userName,
  onDismiss,
}: {
  userEmail: string;
  userName: string;
  onDismiss: () => void;
}) => {
  const [isSuccessScreen, setIsSuccessScreen] = useState(false);
  const [error, setError] = useState('');
  const initialValues: IRequestHelpValues = {
    type: undefined,
    comment: '',
  };
  const userAgentData = Bowser.parse(window.navigator.userAgent);

  return (
    <Dialog title={isSuccessScreen ? '' : 'במה אנחנו יכולים לעזור?'} onClose={onDismiss}>
      {isSuccessScreen ? (
        <Box>
          <Flex direction="column" alignItems="center" justifyContent="center" textAlign="center" py="74px">
            <Flex
              bg="success.0"
              justifyContent="center"
              alignItems="center"
              h="72px"
              w="72px"
              borderRadius="50%"
              mb="40px"
            >
              <SuccessIcon w="32px" h="32px" color="success.300" />
            </Flex>
            <Heading mb="16px" textStyle="h5">
              קיבלנו את הפניה שלך
            </Heading>
            <Text fontSize="md">הצוות שלנו יחזור אלייך בהקדם.</Text>
          </Flex>
          <Box justifyContent="space-between" alignItems="center" p="32px">
            <Stack>
              <Button onClick={onDismiss}>הבנתי, תודה</Button>
              {error && <Text color="red">{error}</Text>}
            </Stack>
          </Box>
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors: FormikErrors<IRequestHelpValues> = {};
            if (!values.type) {
              errors.type = 'אנחנו צריכים את זה';
            }
            if (!values.comment) {
              errors.comment = 'אנחנו צריכים את זה';
            }

            return errors;
          }}
          onSubmit={async (values) => {
            setError('');
            const token = window.localStorage.getItem(ID_TOKEN);
            try {
              await axios.post(
                `${import.meta.env.REACT_APP_API_URL}/emails/support`,
                {
                  message: values.comment,
                  name: userName,
                  email: userEmail,
                  type: values.type,
                  tags: {
                    page: window.location.pathname,
                    browser: `${userAgentData.browser.name}_${userAgentData.browser.version}`,
                    os: `${userAgentData.os.name}_${userAgentData.os.version}`,
                    platform: `${userAgentData.platform.type}`,
                  },
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                },
              );
              setIsSuccessScreen(true);
            } catch (error) {
              logger.error('Error submitting help form', { error });
              setError('ארעה שגיאה');
            }
          }}
        >
          {(props) => (
            <Form>
              <Stack px="32px">
                <Text fontSize="md" textAlign="center">
                  הצוות שלנו זמין בימים א’-ה’ בין השעות 09:30-17:30. נשתדל לענות בהקדם.
                </Text>
                <FormSelect
                  isRequired
                  name="type"
                  label="סיבת הפניה"
                  size="sm"
                  data={[
                    { key: 'כספי', value: HELP_REQUEST_TYPE.FINANCIAL },
                    { key: 'טכני', value: HELP_REQUEST_TYPE.TECHNICAL },
                    {
                      key: 'הצעה לשיפור',
                      value: HELP_REQUEST_TYPE.IMPROVEMENT,
                    },
                    { key: 'אחר', value: HELP_REQUEST_TYPE.OTHER },
                  ]}
                />
                <FormInput
                  size="sm"
                  name="comment"
                  label="תיאור נוסף"
                  type="textarea"
                  rows={3}
                  placeholder="הזן תיאור כאן"
                />
                <Box alignItems="center" pb="32px">
                  <Stack>
                    {error ? <Text color="error.100">{error}</Text> : <Box h="24px" />}
                    <Button isLoading={props.isSubmitting} type="submit" isDisabled={!props.isValid}>
                      שליחה
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};
