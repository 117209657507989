import { Grid, Flex, Heading, Button, Image, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState } from 'react';

import { Card } from '../../components/Card';
import { Dialog } from '../../components/Dialog';
import { FormDatePicker } from '../../components/form/FormDatePicker';
import { FormInput } from '../../components/form/FormInput';
import { useCustomToast } from '../../hooks/toast/useCustomToast';
import {
  getUserDataInitialValues,
  IValidUserDataValues,
  validateUserDataValues,
} from '../../models/userData/userDataFormUtils';
import { IUserData } from '../../models/userData/useUserData';
import { auth0ResetPassword } from '../../utils/auth0';
import { logger } from '../../utils/logger';

export const BaseDataSettings = ({
  userData,
  onSubmit,
}: {
  userData: IUserData;
  onSubmit: (values: IValidUserDataValues) => Promise<void>;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { triggerToast } = useCustomToast('שינוי הסיסמה', false);
  const handleResetPassword = async () => {
    try {
      await auth0ResetPassword(userData.email);
      setIsDialogOpen(true);
    } catch (e) {
      logger.error('failed to reset password', e);
      triggerToast('error');
    }
  };
  return (
    <>
      <Card>
        <Heading textStyle="h6" mb="65px" mt="10px" marginInlineStart="8px">
          פרטים בסיסיים
        </Heading>
        <Formik
          initialValues={getUserDataInitialValues(userData)}
          validate={(values) => validateUserDataValues(values, 'baseInfo')}
          onSubmit={async (values) => {
            await onSubmit(values as IValidUserDataValues);
          }}
        >
          {(props) => (
            <Form
              onKeyPress={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            >
              <Grid
                columnGap="160px"
                templateColumns="repeat(2, minmax(100px, 50%))"
                paddingInlineEnd="88px"
                marginInlineStart="8px"
              >
                <FormInput isRequired name="username" label="שם מלא" isDisabled />
                <FormInput isRequired name="email" type="email" label="כתובת מייל" isDisabled />
                <FormInput name="companyName" label="שם העסק" />
                <Flex direction="column">
                  <FormInput name="email" label="סיסמה" type="password" isDisabled />
                  <Button variant="link" size="sm" alignSelf="end" mt="-20px" onClick={handleResetPassword}>
                    שינוי סיסמה
                  </Button>
                </Flex>
                <FormInput name="phone" label="טלפון ליצירת קשר" type="tel" />
                <FormDatePicker name="birthDate" label="תאריך לידה" />
                <Button
                  mb="30px"
                  gridColumn="1 / span 2"
                  justifySelf="end"
                  isLoading={props.isSubmitting}
                  type="submit"
                  variant="secondary"
                  isDisabled={!props.isValid}
                >
                  עדכון
                </Button>
              </Grid>
              <Flex marginInlineEnd="275px" justifyContent="end"></Flex>
            </Form>
          )}
        </Formik>
      </Card>
      {isDialogOpen && (
        <Dialog title="בקשת איפוס סיסמה" onClose={() => setIsDialogOpen(false)}>
          <Flex direction="column" alignItems="center">
            <Text textStyle="medium" maxW="200px" textAlign="center" mb="16px">
              שלחנו לך מייל לאיפוס ויצירת סיסמה חדשה
            </Text>
            <Image src="/reset-password.svg" width="56px" />
            <Button m="auto" size="sm" onClick={() => setIsDialogOpen(false)} my="32px">
              מעולה, תודה
            </Button>
          </Flex>
        </Dialog>
      )}
    </>
  );
};
