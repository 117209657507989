import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { createhUiCustomization, getUiCustomization, patchUiCustomization } from './uiCustomizationApi';

const UI_CUSTOMIZATION_GET = 'UI_CUSTOMIZATION_GET';
const UI_CUSTOMIZATION_PATCH = 'UI_CUSTOMIZATION_PATCH';

export const uiCustomizationKeys = {
  TASKS_ORDER: 'TASKS_ORDER',
};

export function useUiCustomizations(key: string) {
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
    queryKey: [UI_CUSTOMIZATION_GET, key],
    queryFn: () => getUiCustomization(key),
  });

  const { mutate: create } = useMutation({
    mutationKey: [UI_CUSTOMIZATION_PATCH],
    mutationFn: (value: string) => createhUiCustomization(key, value),
    onMutate: (value) => {
      queryClient.setQueryData([UI_CUSTOMIZATION_GET, key], () => {
        return { value };
      });
    },
  });

  const { mutate } = useMutation({
    mutationKey: [UI_CUSTOMIZATION_PATCH],
    mutationFn: (value: string) => patchUiCustomization(key, value),
    onMutate: (value) => {
      queryClient.setQueryData([UI_CUSTOMIZATION_GET, key], () => {
        return { value };
      });
    },
  });

  useEffect(() => {
    if (!isLoading && !data) {
      create(JSON.stringify([]));
    }
  }, [data, isLoading]);

  return {
    customization: data && data.value ? JSON.parse(data.value) : null,
    saveCustomization: mutate,
    isLoading,
    error,
  };
}
