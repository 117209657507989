import { Box, Button, Flex, Heading, Text, Image } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';
import { useEffectOnce } from 'usehooks-ts';

import { USER_ID } from '../../models/AuthenticationModel';

export const SubscriptionSuccess = ({
  name,
  email,
  onDismiss,
}: {
  name: string;
  email: string;
  onDismiss: () => void;
}) => {
  useEffectOnce(() => {
    const userId = localStorage.getItem(USER_ID);
    // clear in case there's a previous purchase
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: 'purchase',
        userId,
        user_email: email,
        user_name: name,
        ecommerce: {
          transaction_id: `T_${userId}`,
          value: 33.93,
          tax: 4.93,
          currency: 'ILS',
          items: [
            {
              item_id: 'dcfa61d5-31da-46d0-8b69-e251b7b192cf',
              item_name: 'פרילנס מוביל',
            },
          ],
        },
      },
    });
  });
  return (
    <Box data-testid="SubscriptionSuccess">
      <Flex w="100%" alignItems="center" justifyContent="center" gap="28px" mb="38px" direction="column">
        <Image src="/payment-success.svg" width="137px" />
        <Heading textStyle="h4" textAlign="center" maxW="180px">
          התשלום בוצע בהצלחה!
        </Heading>
      </Flex>
      <Flex
        bg="#fff"
        w="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="26px"
        pt="40px"
        pb="30px"
        borderBottomRadius="10px"
      >
        <Text fontSize="xl" textAlign="center" maxW="310px">
          מתרגשים שהחלטת לשדרג את הסטטוס שלך ובטוחים שמעכשיו יהיה הרבה יותר קל להיות פרילנסר מוביל
        </Text>
        <Button w="194px" onClick={onDismiss}>
          חזרה לחשבון שלך
        </Button>
      </Flex>
    </Box>
  );
};
