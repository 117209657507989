import { format } from 'date-fns';

import { fetcher } from '../../requests/fetcher';

export enum TaskPriority {
  'HIGH' = 'HIGH',
  'MEDIUM' = 'MEDIUM',
  'LOW' = 'LOW',
}

export enum TaskStatus {
  'TODO' = 'TODO',
  'DOING' = 'DOING',
  'DONE' = 'DONE',
}

export interface Task {
  id: number;
  label: string;
  is_complete: boolean;
  project_id: string | null; // id
  project_name: string | null; // id
  due_date: Date | null;
  priority: TaskPriority;
  status: TaskStatus;
  description: string;
}

const baseUrl = import.meta.env.REACT_APP_API_URL;

export async function getTasks(projectId?: string): Promise<Task[]> {
  const params = projectId ? `projectId=${projectId}` : '';
  return fetcher(`${baseUrl}/tasks?${params}`, '', 'GET');
}

export async function getProjectTasks(projectId: string): Promise<Task[]> {
  return fetcher(`${baseUrl}/tasks?projectId=${projectId}`, '', 'GET');
}

export async function createTask(task: Omit<Task, 'id'>) {
  const body = JSON.stringify({
    label: task.label,
    is_complete: task.is_complete,
    priority: task.priority,
    status: task.status,
    due_date: task.due_date ? format(task.due_date, 'yyyy-MM-dd') : null,
    description: task.description,
    project_id: task.project_id,
  });
  return fetcher(`${baseUrl}/tasks`, body, 'POST');
}

export async function updateTask(task: Task) {
  const body = JSON.stringify({
    label: task.label,
    is_complete: task.is_complete,
    priority: task.priority,
    status: task.status,
    due_date: task.due_date ? format(task.due_date, 'yyyy-MM-dd') : null,
    id: task.id,
    project_id: task.project_id,
    description: task.description,
  });
  return fetcher(`${baseUrl}/tasks`, body, 'PATCH');
}

export async function deleteTask(taskId: number) {
  return fetcher(`${baseUrl}/tasks`, JSON.stringify({ id: taskId }), 'DELETE');
}
