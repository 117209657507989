import { createIcon } from '@chakra-ui/icons';

export const GiftIcon = createIcon({
  displayName: 'GiftIcon',
  viewBox: '0 0 18 18',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M15.375 5.25H12.6652C14.0392 4.59075 15 3.48375 15 1.875C15 1.668 14.832 1.5 14.625 1.5C14.418 1.5 14.25 1.668 14.25 1.875C14.25 4.17225 11.7968 5.01675 9.87075 5.2065C10.455 4.48425 11.25 3.3075 11.25 2.25C11.25 1.0095 10.2405 0 9 0C7.7595 0 6.75 1.0095 6.75 2.25C6.75 3.3075 7.54425 4.4835 8.12925 5.2065C6.20325 5.0175 3.75 4.17225 3.75 1.875C3.75 1.668 3.582 1.5 3.375 1.5C3.168 1.5 3 1.668 3 1.875C3 3.48375 3.96075 4.59075 5.33475 5.25H2.625C1.1775 5.25 0 6.4275 0 7.875V8.625C0 9.24525 0.50475 9.75 1.125 9.75H1.5V14.625C1.5 16.4858 3.01425 18 4.875 18H13.125C14.9858 18 16.5 16.4858 16.5 14.625V9.75H16.875C17.4953 9.75 18 9.24525 18 8.625V7.875C18 6.4275 16.8225 5.25 15.375 5.25ZM9 0.75C9.82725 0.75 10.5 1.42275 10.5 2.25C10.5 3.186 9.5775 4.42425 9 5.076C8.4225 4.42425 7.5 3.186 7.5 2.25C7.5 1.42275 8.17275 0.75 9 0.75ZM0.75 8.625V7.875C0.75 6.8415 1.5915 6 2.625 6H8.625V9H1.125C0.918 9 0.75 8.832 0.75 8.625ZM2.25 14.625V9.75H8.625V17.25H4.875C3.4275 17.25 2.25 16.0725 2.25 14.625ZM15.75 14.625C15.75 16.0725 14.5725 17.25 13.125 17.25H9.375V9.75H15.75V14.625ZM17.25 8.625C17.25 8.832 17.082 9 16.875 9H9.375V6H15.375C16.4085 6 17.25 6.8415 17.25 7.875V8.625Z"
    />,
  ],
});
