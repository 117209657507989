import { useEffect, useMemo, useState } from 'react';

import { uiCustomizationKeys, useUiCustomizations } from '../uiCustomizations/useUiCustomizations';

import { Task } from './tasksApi';
import { useTasks } from './useTasks';

export function useOrderedTasks(projectId?: string) {
  const { tasks, isLoading: tasksLoading } = useTasks();
  const {
    customization,
    saveCustomization,
    isLoading: orderLoading,
  } = useUiCustomizations(uiCustomizationKeys.TASKS_ORDER);

  const [order, setOrder] = useState<number[]>([]);

  const isLoading = tasksLoading || orderLoading;

  useEffect(() => {
    if (!isLoading && tasks && customization && order.length === 0) {
      setOrder(orderTasks(customization, tasks).map((t) => t.id));
    }
  }, [tasks?.length, isLoading, customization?.length]);

  useEffect(() => {
    if (!isLoading && tasks && customization && tasks.length !== customization.length) {
      const missingTasks = tasks.filter((t) => !customization.includes(t.id)).map((t) => t.id);
      const taskIds = tasks.map((t) => t.id);
      const withoutDeletedTasks = customization.filter((c: number) => taskIds.includes(c));
      const newOrder = withoutDeletedTasks.concat(missingTasks);
      saveCustomization(JSON.stringify(newOrder));
      setOrder(newOrder);
    }
  }, [tasks, isLoading, customization]);

  const orderTasks = (priority: number[], tasks: Task[]) => {
    return priority && tasks
      ? (priority
          .map((id: number) => tasks.find((t) => t.id === id))
          .filter((t: Task | undefined) => Boolean(t))
          .filter((t: Task | undefined) => (projectId ? t?.project_id?.toString() === projectId : true)) as Task[])
      : [];
  };

  const onOrderChange = async (movedId: number, previousId: number) => {
    const index = customization.findIndex((i: number) => i === previousId);

    const order = customization.filter((id: number) => id !== movedId);
    order.splice(index, 0, movedId);

    setOrder(order);

    saveCustomization(JSON.stringify(order));
  };

  const orderedTasks = useMemo(() => orderTasks(order, tasks!), [order, tasks]);

  return {
    isLoading,
    tasks: orderedTasks,
    onOrderChange,
  };
}
