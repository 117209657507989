import { ChevronRightIcon } from '@chakra-ui/icons';
import { Grid, Box, Image, Button, Flex } from '@chakra-ui/react';
import React from 'react';

import { Wizard } from '../../authentication/components/Wizard';

interface IAuthenticationLayout {
  onBackClick?: () => void;
  wizardStep?: number;
  isMailStep?: boolean;
  children: React.ReactNode;
}

export const AuthenticationLayout = ({ onBackClick, wizardStep, isMailStep, children }: IAuthenticationLayout) => {
  return (
    <Grid
      backgroundColor="white"
      h="100vh"
      templateColumns={{ base: '1fr', md: '6fr 4fr' }}
      templateRows="100vh"
      alignItems="center"
      overflow="hidden"
    >
      <Grid
        gridTemplateRows="50px 50px 1fr"
        alignItems="center"
        py={{ base: '30px', md: '45px' }}
        px={{ base: '35px', md: '60px' }}
        h="100%"
        overflow="auto"
      >
        <Flex justifyContent="space-between">
          <a href="https://peko.co.il" id="logo">
            <Image src="/logo-dark.png" width="110px" alignSelf="start" />
          </a>
          {wizardStep && <Wizard step={wizardStep} />}
        </Flex>
        {onBackClick ? (
          <Box justifySelf="center" maxW="450px" w="100%">
            <Button
              justifySelf="start"
              onClick={onBackClick}
              variant="link"
              leftIcon={<ChevronRightIcon />}
              p="0"
              h="30px"
            >
              חזרה
            </Button>
          </Box>
        ) : (
          <Box mb="40px" h="30px" />
        )}
        <Box justifySelf="center" w={{ base: '100%', md: '450px' }}>
          {children}
        </Box>
      </Grid>
      <Box
        backgroundColor="rgba(123, 115, 234, 0.15)"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        overflow="hidden"
        visibility={{
          base: 'hidden',
          md: 'visible',
        }}
      >
        {isMailStep ? (
          <Image src="/email-sent.svg" maxH="440px" width="70%" zIndex="docked" />
        ) : (
          <Image src="/blissful.svg" maxWidth="600px" width="70%" zIndex="docked" />
        )}
      </Box>
    </Grid>
  );
};
