import { makeObservable, observable, computed, action } from 'mobx';

import { SelectItem } from '../../components/form/Select';
import { CustomerModel } from '../../models/CustomerModel';
import { ProjectsModel } from '../../models/ProjectsModel';
import { IStore } from '../../models/RootStore';
import { WorkHour } from '../../models/WorkHour';
import { IWorkHour } from '../../models/workHours/useWorkHours';
import { RoutingStore } from '../../router/RoutingStore';
import { logger } from '../../utils/logger';

import { CreateProjectRoute } from './../../router/routes';

export type EnrichedWorkHour = IWorkHour & {
  projectName: string;
  projectId: string | undefined;
  customerName: string | undefined;
};

export class WorkHoursViewModel implements IStore {
  projectsModel: ProjectsModel;
  customerModel: CustomerModel;
  routingStore: RoutingStore;

  _deleteWorkHourState: 'success' | 'error' | null;

  constructor(projectsModel: ProjectsModel, customerModel: CustomerModel, routingStore: RoutingStore) {
    this.projectsModel = projectsModel;
    this.customerModel = customerModel;
    this.routingStore = routingStore;

    this._deleteWorkHourState = null;

    makeObservable(this, {
      projectsModel: observable,

      allProjects: computed,
      allCustomers: computed,

      _deleteWorkHourState: observable,
      deleteWorkHourState: computed,
      setDeleteWorkHourState: action,
    });
  }

  activate() {}

  get allProjects(): SelectItem[] {
    const projects = this.projectsModel.projects
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((p) => ({
        value: p._id,
        key: p.name,
      }));

    return [{ value: '', key: 'ללא' }, ...projects];
  }

  get allCustomers(): SelectItem[] {
    return this.customerModel.customers
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((p) => ({
        value: p._id,
        key: p.name,
      }));
  }

  projectById = (projectId?: string) => {
    if (!projectId) {
      return undefined;
    }
    return this.projectsModel.getProjectById(projectId);
  };

  getCustomerNameByProjectName = (projectName: string) => {
    const project = this.projectsModel.getProjectByName(projectName);
    return project?.customer && this.customerModel.getCustomerById(project.customer)?.name;
  };

  get deleteWorkHourState() {
    return this._deleteWorkHourState;
  }

  setDeleteWorkHourState = (state: 'success' | 'error' | null) => {
    this._deleteWorkHourState = state;
  };

  deleteWorkHour = async (workHour: EnrichedWorkHour) => {
    const project = this.projectById(workHour.projectId);
    try {
      if (workHour._id) {
        this.setDeleteWorkHourState('success');
        await this.projectsModel.deleteWorkHour(workHour._id, project);
      } else {
        this.setDeleteWorkHourState('error');
      }
    } catch (error) {
      logger.error('Error deleting work-hour', { error });
      this.setDeleteWorkHourState('error');
    }
  };

  editWorkHour = (workHour: EnrichedWorkHour) => {
    const project = this.projectsModel.getProjectByName(workHour.projectName);

    const wh = new WorkHour({
      id: parseInt(workHour._id),
      start_time: workHour.startTime.toISOString(),
      end_time: workHour.endTime.toISOString(),
      comment: workHour.comment,
    });
    this.routingStore.setEditWorkHour(wh, project);
  };

  onEmptyStateClick = () => {
    this.routingStore.navigate(CreateProjectRoute);
  };
}
