import { action, makeObservable, observable, computed, runInAction } from 'mobx';
import TagManager from 'react-gtm-module';

import { Client, ICustomerApi } from '../requests/Client';

import { Customer, CustomerStatus } from './Customer';
import { ICustomerValues } from './customers/customerUtils';

export class CustomerModel {
  private client: Client;
  private abortController: AbortController;

  _customers: Array<Customer>;

  areCustomersLoading: boolean;

  constructor(client: Client) {
    this.client = client;

    this._customers = [];
    this.areCustomersLoading = false;
    this.abortController = new AbortController();

    this.init();
    this.setupListeners();

    makeObservable(this, {
      _customers: observable,
      areCustomersLoading: observable,
      customers: computed,
      createCustomer: action,
      loadCustomers: action,
      deleteCustomer: action,
      updateCustomer: action,
    });
  }

  private async init() {
    this.areCustomersLoading = true;
    await this.loadCustomers();
    runInAction(() => {
      this.areCustomersLoading = false;
    });
  }

  get customers() {
    return this._customers.filter((c) => c.status === CustomerStatus.CLOSED) || [];
  }

  get leads() {
    return this._customers.filter((c) => c.status !== CustomerStatus.CLOSED) || [];
  }

  getCustomerById = (id?: string) => {
    if (!id) {
      return undefined;
    }
    return this._customers?.find((c) => c._id === id);
  };

  async createCustomer(customer: ICustomerValues) {
    const customerApi = await this.client.createCustomer(customer);
    TagManager.dataLayer({
      dataLayer: {
        event: 'customer_added',
      },
    });
    if (this._customers.length === 0) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'first_customer_added',
        },
      });
    }
    runInAction(() => {
      this._customers.push(new Customer(customerApi));
    });
    return customerApi.id;
  }

  loadCustomers = async () => {
    const res = await this.client.getUserCustomers();
    runInAction(() => {
      this._customers = res.map((customer: ICustomerApi) => new Customer(customer));
    });
  };

  updateCustomer = async (customer: ICustomerValues, customerId: string) => {
    const customerApi = await this.client.updateCustomer(customer, customerId);

    runInAction(() => {
      this._customers = this._customers.filter((c) => c._id !== customerId);
      this._customers.push(new Customer(customerApi));
    });
  };

  deleteCustomer = async (customerId: string) => {
    await this.client.deleteCustomer(customerId);
    runInAction(() => {
      this._customers = this._customers.filter((c) => c._id !== customerId);
    });
  };

  changeStatus = async (customerId: string, status: string) => {
    const customer = this.getCustomerById(customerId);
    if (!customer) {
      return;
    }

    this.updateCustomer({ ...customer.values, status }, customerId);
  };

  removeListeners = () => {
    this.abortController.abort();
  };

  private setupListeners = () => {
    window.addEventListener('focus', this.loadCustomers, { signal: this.abortController.signal });
  };
}
