import { SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { Select } from '../../components/form/Select';
import { StyledInput } from '../../components/form/StyledInput';
import { Loader } from '../../components/Loader/Loader';
import { Page } from '../../components/Page';
import { SquareIconButton } from '../../components/SquareIconButton';
import { StyledTooltip } from '../../components/StyledTooltip';
import { Table } from '../../components/Table';
import { TableActions } from '../../components/TableActions';
import { Project } from '../../models/Project';
import { PROJECT_TYPE } from '../../requests/Client';
import { durationToMinutesDisplay } from '../../utils/time/timeUtils';
import { EmptyBlurb } from '../common/errors/EmptyBlurb';
import { EmptyState } from '../common/errors/EmptyState';
import { NoTasksIcon, TasksIcon } from '../common/icons';

import { DeleteProjectDialog } from './DeleteProjectDialog';
import { ProjectsViewModel, ProjectWithCustomer, viewProjectStatus } from './ProjectsViewModel';

export const Projects = observer(({ viewModel }: { viewModel: ProjectsViewModel }) => {
  const [projectToDelete, setProjectToDelete] = useState<Project | null>(null);

  const columns = [
    {
      key: 'startDate',
      label: 'תאריך התחלה',
      render: ({ project }: ProjectWithCustomer) => format(project._startDate, 'dd.MM.yy'),
    },
    {
      key: 'name',
      label: 'שם',
      render: ({ project }: ProjectWithCustomer) => (
        <Box maxWidth="250px">
          <Text title={project.name} noOfLines={1} display="block" whiteSpace="nowrap">
            {project.name}
          </Text>
        </Box>
      ),
    },
    {
      key: 'customer',
      label: 'לקוח',
      render: ({ customer }: ProjectWithCustomer) => (
        <Box maxWidth="250px">
          <Text title={customer?.name} noOfLines={1} display="block" whiteSpace="nowrap">
            {customer?.name}
          </Text>
        </Box>
      ),
    },
    {
      key: 'service',
      label: 'שירות',
      render: ({ project }: ProjectWithCustomer) => project.service,
    },
    {
      key: 'type',
      label: 'חוזה',
      render: ({ project }: ProjectWithCustomer) => (project.type === PROJECT_TYPE.HOURLY ? 'שעתי' : 'פיקס'),
    },
    {
      key: 'totalHours',
      label: 'סה״כ שעות',
      render: ({ project }: ProjectWithCustomer) =>
        project.type === PROJECT_TYPE.HOURLY || project.hourTracking
          ? durationToMinutesDisplay(project.accumulatedDuration)
          : 'ללא מעקב',
    },
  ];

  if (viewModel.areProjectsLoading) {
    return <Loader />;
  }

  if (viewModel.totalProjectsLength === 0 && !viewModel.areProjectsLoading) {
    return (
      <EmptyState
        title="ניהול פרויקטים"
        body="כאן יופיעו כל הפרויקטים שלך בחלוקה לפי סוג חוזה, לקוח, שירות, מצב
      הכנסות ועוד. מומלץ להוסיף פרויקט חדש כדי לראות איך הכל עובד"
        onClick={viewModel.onCreateProject}
        imageSrc="/no-projects.svg"
        buttonText="+ יצירת פרויקט"
      />
    );
  }

  return (
    <Page title="פרויקטים">
      <Flex mb="16px" alignItems="center">
        <Box w="180px">
          <Select
            onChange={(data) => viewModel.setSelectedStatus(data as viewProjectStatus)}
            selectedItem={viewModel.selectedStatus}
            data={viewModel.projectStatuses}
            menuWidth="230px"
            size="sm"
          />
        </Box>
        <Spacer />
        <StyledInput
          name="search"
          w="314px"
          placeholder="חיפוש פרויקט"
          value={viewModel.filter}
          onChange={(e) => viewModel.setFilter(e.target.value)}
          endIcon={<SearchIcon />}
          size="sm"
        />
      </Flex>
      {viewModel.projects.length > 0 ? (
        <Table
          columns={columns}
          data={viewModel.projects}
          onRowClick={(p) => viewModel.onProjectClick(p.project._id)}
          actions={{
            width: '176px',
            render: (p: any) => (
              <TableActions
                onDelete={() => setProjectToDelete(p.project)}
                onEdit={() => viewModel.editProject(p.project)}
              >
                <StyledTooltip label="יצירת משימה">
                  <SquareIconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      viewModel.createTask(p.project._id);
                    }}
                    variant="ghost"
                    size="sm"
                    icon={<TasksIcon />}
                  />
                </StyledTooltip>
              </TableActions>
            ),
          }}
        />
      ) : (
        <Flex justifyContent="center" alignItems="center" direction="column" textAlign="center" mt="95px">
          <EmptyBlurb
            title="אין לך פרויקט כזה"
            body="כדאי לנסות שם אחר או לשנות את הגדרות הסינון"
            icon={<NoTasksIcon fontSize="72px" mb="16px" />}
            button={{
              label: 'איפוס',
              onClick: () => {
                viewModel.setFilter('');
                viewModel.setSelectedStatus({ key: '', value: 'all' });
              },
            }}
          />
        </Flex>
      )}
      {projectToDelete && (
        <DeleteProjectDialog
          project={projectToDelete}
          onClose={() => setProjectToDelete(null)}
          onSubmit={() => viewModel.deleteProject(projectToDelete)}
        />
      )}
    </Page>
  );
});
