import { createIcon } from '@chakra-ui/icons';

export const DownloadIcon = createIcon({
  displayName: 'DownloadIcon',
  viewBox: '0 0 25 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M10.4612 18.122C10.7398 18.4008 11.0706 18.6219 11.4347 18.7728C11.7988 18.9237 12.1891 19.0014 12.5832 19.0014C12.9774 19.0014 13.3676 18.9237 13.7317 18.7728C14.0958 18.6219 14.4266 18.4008 14.7052 18.122L17.9162 14.911C18.0884 14.7206 18.1807 14.4713 18.1742 14.2147C18.1676 13.958 18.0625 13.7138 17.8808 13.5325C17.6991 13.3512 17.4547 13.2467 17.198 13.2406C16.9414 13.2346 16.6923 13.3274 16.5022 13.5L13.5762 16.427L13.5832 1C13.5832 0.734784 13.4779 0.48043 13.2903 0.292893C13.1028 0.105357 12.8485 0 12.5832 0C12.318 0 12.0637 0.105357 11.8761 0.292893C11.6886 0.48043 11.5832 0.734784 11.5832 1L11.5742 16.408L8.66423 13.5C8.47659 13.3125 8.22215 13.2072 7.95688 13.2073C7.69161 13.2074 7.43724 13.3129 7.24973 13.5005C7.06223 13.6881 6.95694 13.9426 6.95703 14.2079C6.95713 14.4731 7.06259 14.7275 7.25023 14.915L10.4612 18.122Z"
    />,
    <path
      key="1"
      fill="currentColor"
      d="M23.583 16C23.3178 16 23.0634 16.1054 22.8759 16.2929C22.6884 16.4804 22.583 16.7348 22.583 17V21C22.583 21.2652 22.4777 21.5196 22.2901 21.7071C22.1026 21.8946 21.8482 22 21.583 22H3.58301C3.31779 22 3.06344 21.8946 2.8759 21.7071C2.68836 21.5196 2.58301 21.2652 2.58301 21V17C2.58301 16.7348 2.47765 16.4804 2.29011 16.2929C2.10258 16.1054 1.84822 16 1.58301 16C1.31779 16 1.06344 16.1054 0.875901 16.2929C0.688365 16.4804 0.583008 16.7348 0.583008 17L0.583008 21C0.583008 21.7956 0.899078 22.5587 1.46169 23.1213C2.0243 23.6839 2.78736 24 3.58301 24H21.583C22.3787 24 23.1417 23.6839 23.7043 23.1213C24.2669 22.5587 24.583 21.7956 24.583 21V17C24.583 16.7348 24.4777 16.4804 24.2901 16.2929C24.1026 16.1054 23.8482 16 23.583 16Z"
    />,
  ],
});
