import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import type { SystemStyleObject, SystemStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = defineStyle({
  transitionProperty: 'common',
  transitionDuration: 'normal',
  _hover: {
    _disabled: {
      bg: 'initial',
    },
  },
  _disabled: {
    cursor: 'not-allowed',
    color: 'base.600',
    background: 'base.100',
    _hover: {
      color: 'base.600',
      background: 'base.100',
    },
  },
});

const variantGhost: SystemStyleFunction = defineStyle((props) => {
  return {
    color: 'base.1000',
    bg: 'transparent',
    border: '1px solid',
    borderColor: 'transparent',
    _hover: {
      borderColor: 'primary.25',
    },
    _active: {
      borderColor: 'transparent',
      background: 'primary.0',
    },
  };
});

const variantSecondary: SystemStyleFunction = defineStyle((props) => {
  return {
    ...variantGhost(props),
    border: '1px solid',
    borderColor: 'base.600',
    _hover: {
      borderColor: 'primary.25',
      background: 'primary.0',
      _disabled: {
        cursor: 'not-allowed',
        color: 'base.600',
        background: 'base.100',
        borderColor: 'base.600',
      },
    },
    _active: {
      borderColor: 'primary.50',
      background: 'primary.25',
    },
  };
});

const variantPrimary: SystemStyleFunction = defineStyle((props) => {
  return {
    bg: 'primary.100',
    color: 'white',
    _hover: {
      bg: 'primary.200',
    },
    _active: { bg: 'primary.300' },
  };
});

const variantDanger: SystemStyleFunction = defineStyle((props) => {
  return {
    ...variantPrimary(props),
    bg: 'error.100',
    _hover: {
      boxShadow: '0px 0px 0px 1px #B71836, 0px 1px 2px 0px rgba(113, 14, 33, 0.50)',
    },
    _active: {
      bg: 'error.200',
    },
  };
});

const variantLink: SystemStyleFunction = defineStyle((props) => {
  return {
    ...variantGhost(props),
    p: 0,
    height: 'auto',
    lineHeight: 'normal',
    verticalAlign: 'baseline',
    textDecoration: 'underline',
    _hover: {
      color: 'primary.200',
    },
    _active: {
      color: 'primary.300',
    },
  };
});

const variants = {
  primary: variantPrimary,
  secondary: variantSecondary,
  danger: variantDanger,
  ghost: variantGhost,
  link: variantLink,
};

const sizes: Record<string, SystemStyleObject> = {
  lg: defineStyle({
    h: '46px',
    fontSize: 'lg',
    px: '20px',
    py: '13px',
    borderRadius: '12px',
  }),
  sm: defineStyle({
    h: '36px',
    fontSize: 'md',
    px: '16px',
    py: '10px',
    borderRadius: '10px',
  }),
};

export const Button = defineStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'primary',
    size: 'lg',
  },
});
