import { Box, Grid, Text, Tooltip } from '@chakra-ui/react';

import {
  ProjectIcon,
  CustomerIcon,
  WorkHourIcon,
  HelpIcon,
  InsightsIcon,
  TasksIcon,
  LeadIcon,
  SettingsIcon,
} from '../../pages/common/icons';
import {
  CustomersRoute,
  ProjectsRoute,
  WorkHoursRoute,
  InsightsRoute,
  TasksRoute,
  LeadsRoute,
} from '../../router/routes';

export const SideBarLink = ({
  isSelected = false,
  label,
  onClick,
  iconOnly,
}: {
  isSelected?: boolean;
  label: string;
  onClick(): void;
  iconOnly: boolean;
}) => {
  const IconMap = {
    [ProjectsRoute.displayName]: ProjectIcon,
    [InsightsRoute.displayName]: InsightsIcon,
    [CustomersRoute.displayName]: CustomerIcon,
    [WorkHoursRoute.displayName]: WorkHourIcon,
    [TasksRoute.displayName]: TasksIcon,
    [LeadsRoute.displayName]: LeadIcon,
    עזרה: HelpIcon,
    הגדרות: SettingsIcon,
  };
  const IconComponent = IconMap[label] || ProjectIcon;
  return (
    <Tooltip
      hasArrow
      placement="left"
      label={iconOnly ? label : ''}
      bg="white"
      fontSize="lg"
      p="8px"
      color="base.900"
      fontWeight="normal"
      borderRadius="8px"
      gutter={18}
    >
      <Grid
        position="relative"
        templateColumns={iconOnly ? '16px' : '16px 1fr'}
        maxWidth={iconOnly ? '32px' : 'none'}
        gap={iconOnly ? '0' : '12px'}
        marginInlineStart={iconOnly ? '6px !important' : '0'}
        p="8px"
        alignItems="center"
        color={isSelected ? 'white' : '#9286AB'}
        borderRadius="8px"
        _hover={{
          bg: 'base.800',
          textDecoration: 'none',
        }}
        fontSize="xl"
        cursor="pointer"
        onClick={onClick}
      >
        <IconComponent fontSize="md" />
        {!iconOnly && <Text textStyle="extraLarge">{label}</Text>}
        {isSelected && (
          <Box
            position="absolute"
            right={iconOnly ? '-22px' : '-16px'}
            w="6px"
            h="26px"
            borderLeftRadius="30px"
            bg="#11F4CE"
          />
        )}
      </Grid>
    </Tooltip>
  );
};
