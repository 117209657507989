import { Field, FieldProps, getIn } from 'formik';

import { limitToTwoDecimals } from '../../utils/number/numberUtils';

import { StyledInput, StyledInputProps } from './StyledInput';

export const FormInput = ({ name, ...rest }: StyledInputProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<string | number>) => {
        return (
          <StyledInput
            {...field}
            {...rest}
            onChange={(e) => {
              if (rest.type === 'number' && rest.step) {
                const result = limitToTwoDecimals(e.target.value);
                form.setFieldValue(name, result);
              } else {
                field.onChange(e);
              }
            }}
            error={getIn(form.errors, name)}
            touched={getIn(form.touched, name)}
            maintainHeight
          />
        );
      }}
    </Field>
  );
};
