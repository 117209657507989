import { fetcher } from '../../requests/fetcher';

export interface UiCustomization {
  id: number;
  user_id: string;
  key: string;
  value: string;
}

const baseUrl = import.meta.env.REACT_APP_API_URL;

export async function getUiCustomization(key: string): Promise<UiCustomization> {
  return fetcher(`${baseUrl}/ui-cutomizations?key=${key}`, '', 'GET');
}

export async function createhUiCustomization(key: string, value: string): Promise<UiCustomization> {
  return fetcher(`${baseUrl}/ui-cutomizations`, JSON.stringify({ key, value }));
}

export async function patchUiCustomization(key: string, value: string): Promise<UiCustomization> {
  return fetcher(`${baseUrl}/ui-cutomizations`, JSON.stringify({ key, value }), 'PATCH');
}
