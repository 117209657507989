import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Grid,
  Flex,
  Spacer,
  Badge,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  useMediaQuery,
} from '@chakra-ui/react';
import { differenceInCalendarDays } from 'date-fns';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { HelpDialog } from '../../../components/Dialogs/HelpDialog';
import { LeaveCustomerEditDialog } from '../../../components/Dialogs/LeaveCustomerEditDialog';
import { LeaveProjectEditDialog } from '../../../components/Dialogs/LeaveProjectEditDialog';
import { Sidebar } from '../../../components/Sidebar/Sidebar';
import { USER_ID } from '../../../models/AuthenticationModel';
import { PaymentState } from '../../../models/userData/useUserData';
import {
  CreateCustomerRoute,
  CreateLeadRoute,
  CreateProjectRoute,
  CustomersRoute,
  LeadsRoute,
  ProjectsRoute,
  SingleProjectRoute,
  TasksRoute,
} from '../../../router/routes';
import { RoutingStore } from '../../../router/RoutingStore';
import { isAdmin } from '../../affiliateStats/AffiliateStats';
import { CreateCustomerViewModel } from '../../customer/createCustomer/CreateCustomerViewModel';
import { CreateProjectViewModel } from '../../projects/createProject/CreateProjectViewModel';
import { ADDITION_DIALOG } from '../../projects/singleProject/ProjectAdditions';
import { EXPENSE_DIALOG } from '../../projects/singleProject/ProjectExpenses';
import { SubscriptionDialog } from '../../subscription/subscriptionDialog/SubscriptionDialog';
import { SubscriptionFailedWarning } from '../../subscription/subscriptionFailedWarning/SubscriptionFailedWarning';
import { TaskForm } from '../../task/TaskForm';
import { EditWorkHour } from '../../workHour/editWorkHour/EditWorkHour';
import { TimeTracker } from '../../workHour/timeTracker/TimeTracker';
import { AdditionIcon, CustomerIcon, ExpenseIcon, LeadIcon, ProjectIcon, TasksIcon } from '../icons';
import { WatchVideoTeaser } from '../WatchVideoTeaser';

import { Footer } from './Footer';

export const Layout = observer(
  ({
    routingStore,
    userEmail,
    userName,
    paymentState,
    paymentNextDate,
    children,
  }: {
    routingStore: RoutingStore;
    userEmail: string;
    userName: string;
    paymentState: PaymentState;
    paymentNextDate: Date;
    children: JSX.Element | null;
  }) => {
    const [paymentWarningDismissed, setPaymentWarningDismissed] = useSessionStorage('paymentWarningDismissed', false);
    const [showPaymentWarning, setShowPaymentWarning] = useState(false);

    const [_, setIsAdditionDialogOpen] = useSessionStorage(ADDITION_DIALOG, false);
    const [__, setIsExpenseDialogOpen] = useSessionStorage(EXPENSE_DIALOG, false);

    const [isWide] = useMediaQuery('(min-width: 1537px)');

    useEffect(() => {
      if (paymentState === 'PAYMENT_FAILED' && !paymentWarningDismissed) {
        setShowPaymentWarning(true);
      }
    }, [paymentState]);

    const remainingDays = differenceInCalendarDays(paymentNextDate, new Date());

    const handlePaymentWarningDismiss = () => {
      setPaymentWarningDismissed(true);
      setShowPaymentWarning(false);
    };

    const handleUpdatePayment = () => {
      setPaymentWarningDismissed(true);
      setShowPaymentWarning(false);
      routingStore.setIsSubscriptionDialogOpen(true);
    };

    const handleLogout = () => {
      routingStore.logout();
    };

    const userId = window.localStorage.getItem(USER_ID);
    const shouldShowBanner =
      isAdmin(userId!) || paymentState === 'TRIAL' || userId === 'af2101ce-f588-4db7-b244-624b31b7473a';

    const menuItems = [
      {
        label: 'פרויקט',
        icon: ProjectIcon,
        onClick: () => routingStore.navigate(CreateProjectRoute),
      },
      {
        label: 'ליד',
        icon: LeadIcon,
        onClick: () => routingStore.navigate(CreateLeadRoute),
      },
      {
        label: 'לקוח',
        icon: CustomerIcon,
        onClick: () => routingStore.navigate(CreateCustomerRoute),
      },
      {
        label: 'משימה',
        icon: TasksIcon,
        onClick: () => routingStore.setIsCreatingTask(true),
      },
      {
        label: 'תוספת',
        icon: AdditionIcon,
        onClick: () => setIsAdditionDialogOpen(true),
      },
      {
        label: 'הוצאה',
        icon: ExpenseIcon,
        onClick: () => setIsExpenseDialogOpen(true),
      },
    ];

    const getMenuItems = () => {
      switch (routingStore.route.name) {
        case CustomersRoute.name:
          return [menuItems[2]];
        case LeadsRoute.name:
          return [menuItems[1]];
        case ProjectsRoute.name:
          return [menuItems[0]];
        case SingleProjectRoute.name:
          return menuItems.slice(2);
        case TasksRoute.name:
          return [menuItems[3]];
        default:
          return menuItems.slice(0, 4);
      }
    };

    const getMenuLabel = () => {
      switch (routingStore.route.name) {
        case CustomersRoute.name:
          return 'יצירת לקוח';
        case LeadsRoute.name:
          return 'יצירת ליד';
        case ProjectsRoute.name:
          return 'יצירת פרויקט';
        case TasksRoute.name:
          return 'יצירת משימה';
        default:
          return 'יצירת';
      }
    };

    const currentMenuItems = getMenuItems();
    const menuLabel = getMenuLabel();
    return (
      <Grid
        templateAreas={`'sidebar header header' 'sidebar main   main'`}
        templateColumns={isWide ? '175px 1fr' : '76px 1fr'}
        templateRows={'75px 1fr'}
        minW="100%"
        h="100vh"
      >
        <Sidebar
          navigate={routingStore.navigate}
          openPaymentDialog={() => routingStore.setIsSubscriptionDialogOpen(true)}
          openHelpDialog={() => routingStore.setHelpDialogOpen(true)}
          handleLogout={handleLogout}
          isWide={isWide}
        />
        <Flex gridArea="header" borderBottom="1px" borderColor="#E6E9F2" align="center" gap={5} p={2}>
          <TimeTracker viewModel={routingStore.createWorkHourViewModel} />
          <Spacer />
          {import.meta.env.REACT_APP_ENV === 'staging' && (
            <>
              <Badge bg="accent.100" color="white">
                staging
              </Badge>
              <Spacer />
            </>
          )}
          {import.meta.env.REACT_APP_ENV === 'dev' && (
            <>
              <Badge bg="accent.100" color="white">
                dev
              </Badge>
              <Spacer />
            </>
          )}
          {shouldShowBanner && <WatchVideoTeaser />}
          {currentMenuItems.length > 1 ? (
            <Menu>
              <MenuButton
                as={Button}
                aria-label="work-hour settings"
                marginInlineStart="20px"
                marginInlineEnd="40px"
                leftIcon={<AddIcon fontSize="xs" />}
              >
                {menuLabel}
              </MenuButton>
              <Portal>
                <MenuList border="1px solid" borderColor="base.200" borderRadius="8px" p="12px" minW="153px" w="153px">
                  {currentMenuItems.map((item, index) => {
                    const Icon = item.icon;
                    return (
                      <MenuItem
                        key={index}
                        fontSize="md"
                        onClick={item.onClick}
                        display="inline-flex"
                        alignItems="center"
                        gap="8px"
                        borderRadius="6px"
                        _hover={{
                          bg: 'base.25',
                        }}
                      >
                        <Icon h="16px" w="16px" />
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Portal>
            </Menu>
          ) : (
            <Button
              onClick={currentMenuItems[0].onClick}
              marginInlineStart="20px"
              marginInlineEnd="40px"
              leftIcon={<AddIcon fontSize="xs" />}
            >
              {menuLabel}
            </Button>
          )}
        </Flex>
        <Flex
          className="main-content"
          h="100%"
          w="100%"
          overflowY="auto"
          justifyContent="space-between"
          flexDirection="column"
        >
          <Grid templateColumns="minmax(auto, 1380px)" justifyItems="center" mx="auto">
            <Box w="100%" p="40px">
              {children}
            </Box>
          </Grid>
          <Footer />
        </Flex>

        {/* app level modals */}
        {routingStore.isCreatingTask && (
          <TaskForm
            onClose={() => routingStore.setIsCreatingTask(false)}
            taskId={routingStore.editTaskId}
            projects={routingStore.projects || []}
            defaultProjectId={routingStore.defaultTaskProject || undefined}
          />
        )}
        {routingStore.editWorkHourViewModel && <EditWorkHour viewModel={routingStore.editWorkHourViewModel} />}
        {routingStore.isSubscriptionDialogOpen && (
          <SubscriptionDialog onDismiss={() => routingStore.setIsSubscriptionDialogOpen(false)} />
        )}
        {showPaymentWarning && (
          <SubscriptionFailedWarning
            onDismiss={handlePaymentWarningDismiss}
            openPaymentFlow={handleUpdatePayment}
            remainingDays={remainingDays}
          />
        )}
        {routingStore.helpDialogOpen && (
          <HelpDialog
            userName={userName}
            userEmail={userEmail}
            onDismiss={() => routingStore.setHelpDialogOpen(false)}
          />
        )}
        {routingStore.leaveProjectEditDialogTo && (
          <LeaveProjectEditDialog
            onSubmit={() => {
              (routingStore.currentViewModel as CreateProjectViewModel).setIsDirty(false);
              routingStore.navigate(routingStore.leaveProjectEditDialogTo!);
              routingStore.setLeaveProjectEditDialogTo(null);
            }}
            onDismiss={() => routingStore.setLeaveProjectEditDialogTo(null)}
            title={routingStore.route.name === CreateProjectRoute.name ? 'יצירת פרויקט' : 'עריכת פרויקט'}
          />
        )}
        {routingStore.leaveCustomerEditDialogTo && (
          <LeaveCustomerEditDialog
            onSubmit={() => {
              (routingStore.currentViewModel as CreateCustomerViewModel).setIsDirty(false);
              routingStore.navigate(routingStore.leaveCustomerEditDialogTo!);
              routingStore.setLeaveCustomerEditDialogTo(null);
            }}
            onDismiss={() => routingStore.setLeaveCustomerEditDialogTo(null)}
            title={routingStore.route.name === CreateCustomerRoute.name ? 'יצירת לקוח' : 'עריכת לקוח'}
          />
        )}
      </Grid>
    );
  },
);
