import { Flex, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';

import { Card } from '../../../components/Card';
import { Customer } from '../../../models/Customer';
import { DataPoint } from '../../common/DataPoint';

export const SingleCustomerMainTab = observer(({ customer }: { customer: Customer }) => {
  return (
    <Flex direction="column" gap="24px">
      <Card>
        <Text textStyle="extraLarge" color="base.500" mb="40px">
          הערות מיוחדות
        </Text>
        <Text textStyle="large" color="base.1000">
          {customer.comment ||
            'כאן אפשר להוסיף רקע על החברה או על אחד מאנשי הקשר (כל דבר שחשוב לדעת ולזכור בהתנהלות השוטפת מול הלקוח)'}
        </Text>
      </Card>
      <Card>
        <Text textStyle="extraLarge" color="base.500">
          פרטי הארגון
        </Text>
        <Flex gap="100px" pt="26px" pb="20px" paddingInlineStart="8px" paddingInlineEnd="26px">
          <DataPoint value={customer.createdAt} label="תאריך יצירה" />
          <DataPoint value={customer.vat_number} label="מס׳ עוסק" />
          <DataPoint value={customer.industry} label="תחום" />
          <DataPoint value={customer.address} label="כתובת" />
        </Flex>
      </Card>
      <Card>
        <Text textStyle="extraLarge" color="base.500">
          פרטי איש הקשר
        </Text>
        <Flex gap="100px" pt="26px" pb="20px" paddingInlineStart="8px" paddingInlineEnd="26px">
          <DataPoint value={customer.contact_name} label="שם" />
          <DataPoint value={customer.contact_role} label="תפקיד" />
          <DataPoint value={customer.email} label="מייל" />
          <DataPoint value={customer.phone} label="טלפון" />
          <DataPoint value={customer.contact_birth_date} label="תאריך לידה" />
        </Flex>
      </Card>
    </Flex>
  );
});
