import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';

export const EmptyBlurb = ({
  title,
  body,
  icon,
  button,
}: {
  title: string;
  body: string;
  icon?: React.ReactNode;
  button?: { label: string; onClick: () => void };
}) => {
  return (
    <Box background="url(/blurb.svg) center no-repeat" h="380px" w="450px">
      <Flex justifyContent="center" alignItems="center" direction="column" textAlign="center" h="100%">
        {icon}
        <Heading textStyle="h5" mb="8px">
          {title}
        </Heading>
        <Text textStyle="large" w="224px">
          {body}
        </Text>
        {button && (
          <Button variant="link" fontSize="sm" my="12px" onClick={button.onClick}>
            {button.label}
          </Button>
        )}
      </Flex>
    </Box>
  );
};
