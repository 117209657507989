import { createIcon } from '@chakra-ui/icons';

export const HelpIcon = createIcon({
  displayName: 'HelpIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M8 10.6667C8.552 10.6667 9 11.1147 9 11.6667C9 12.2187 8.552 12.6667 8 12.6667C7.448 12.6667 7 12.2187 7 11.6667C7 11.1147 7.448 10.6667 8 10.6667ZM8.66667 9.33333C8.66667 8.95933 8.93867 8.51667 9.28533 8.32533C10.2853 7.774 10.8233 6.64333 10.6247 5.51067C10.436 4.43467 9.55267 3.552 8.47733 3.364C7.692 3.22733 6.89133 3.43733 6.286 3.946C5.68067 4.45467 5.33333 5.19867 5.33333 5.98867C5.33333 6.35667 5.632 6.65533 6 6.65533C6.368 6.65533 6.66667 6.35667 6.66667 5.98867C6.66667 5.594 6.84067 5.22133 7.14333 4.96667C7.45067 4.708 7.84267 4.60533 8.24733 4.67667C8.772 4.76867 9.21933 5.216 9.31133 5.74067C9.41333 6.32067 9.15067 6.87667 8.642 7.15733C7.87133 7.582 7.33333 8.47667 7.33333 9.33333C7.33333 9.702 7.632 10 8 10C8.368 10 8.66667 9.702 8.66667 9.33333ZM16 12.6667V3.33333C16 1.49533 14.5047 0 12.6667 0H3.33333C1.49533 0 0 1.49533 0 3.33333V12.6667C0 14.5047 1.49533 16 3.33333 16H12.6667C14.5047 16 16 14.5047 16 12.6667ZM12.6667 1.33333C13.7693 1.33333 14.6667 2.23067 14.6667 3.33333V12.6667C14.6667 13.7693 13.7693 14.6667 12.6667 14.6667H3.33333C2.23067 14.6667 1.33333 13.7693 1.33333 12.6667V3.33333C1.33333 2.23067 2.23067 1.33333 3.33333 1.33333H12.6667Z"
    />,
  ],
});
