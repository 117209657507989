import { Text, Button, Flex } from '@chakra-ui/react';

import { WarningIcon } from '../../pages/common/icons';
import { Dialog } from '../Dialog';

export const LeaveProjectEditDialog = ({
  title,
  onSubmit,
  onDismiss,
}: {
  title: string;
  onSubmit: () => void;
  onDismiss: () => void;
}) => {
  return (
    <Dialog title={title} onClose={onDismiss}>
      <Flex
        position="relative"
        p="16px"
        mx="53px"
        marginTop="26px"
        marginBottom="40px"
        background="#FBC5F24D"
        borderRadius="5px"
        textAlign="center"
      >
        <WarningIcon
          color="accent.100"
          position="absolute"
          top="-12px"
          left="50%"
          transform="translateX(-50%)"
          w="24px"
          h="24px"
        />
        <Text fontSize="lg">לא ניתן לשחזר את פרטי הפרויקט לאחר היציאה. לצאת בכל זאת?</Text>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" p="32px">
        <Button variant="secondary" alignSelf="start" size="sm" onClick={onDismiss}>
          ביטול
        </Button>
        <Button size="sm" onClick={onSubmit}>
          אישור
        </Button>
      </Flex>
    </Dialog>
  );
};
