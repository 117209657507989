import { Field, FieldProps, getIn } from 'formik';

import { Autocomplete, AutocompleteProps } from './Autocomplete';
import { SelectItem } from './Select';

interface FormAutocompleteProps extends Omit<AutocompleteProps, 'onChange'> {
  name: string;
  onChange?: (data: SelectItem) => void;
}

export const FormAutocomplete = ({ name, onChange, ...rest }: FormAutocompleteProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<SelectItem>) => (
        <Autocomplete
          {...field}
          {...rest}
          selectedItem={field.value}
          error={getIn(form.errors, name)}
          touched={getIn(form.touched, name)}
          onChange={(data: SelectItem) => {
            form.setFieldValue(name, data.value);
            onChange?.(data);
          }}
          setTouched={() => form.setFieldTouched(name, true)}
          maintainHeight
        />
      )}
    </Field>
  );
};
