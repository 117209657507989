const headingFont = '"Matritsa",Helvetica,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';
const bodyFont = '"Ploni",Helvetica,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

export const fonts = {
  heading: headingFont,
  body: bodyFont,
};

export const fontSizes = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem', // 18
  xl: '1.25rem', // 20
  '2xl': '1.5rem', // 24
  '3xl': '1.75rem', // 28
  '4xl': '2.25rem', // 36
  '5xl': '3rem', // 48
};

export const textStyles = {
  h1: {
    fontSize: '5xl',
    fontFamily: headingFont,
    fontWeight: 'bold',
    lineHeight: '120%',
  },
  h2: {
    fontSize: '4xl',
    fontFamily: headingFont,
    fontWeight: 'bold',
    lineHeight: '120%',
  },
  h3: {
    fontSize: '3xl',
    fontFamily: headingFont,
    fontWeight: 'bold',
    lineHeight: '120%',
  },
  h4: {
    fontSize: '2xl',
    fontFamily: headingFont,
    fontWeight: 'bold',
    lineHeight: '120%',
  },
  h5: {
    fontSize: 'xl',
    fontFamily: headingFont,
    fontWeight: 'bold',
    lineHeight: '120%',
  },
  h6: {
    fontSize: 'lg',
    fontFamily: headingFont,
    fontWeight: 'bold',
    lineHeight: '120%',
  },
  doubleLarge: {
    fontSize: '2xl',
    lineHeight: '1.75rem',
  },
  extraLarge: {
    fontSize: 'xl',
    lineHeight: '1.5rem',
  },
  large: {
    fontSize: 'lg',
    lineHeight: '1.375rem',
  },
  medium: {
    fontSize: 'md',
    lineHeight: '1.25rem',
  },
  small: {
    fontSize: 'sm',
    lineHeight: '1.125rem',
  },
  caption: {
    fontSize: 'xs',
    lineHeight: '1rem',
  },
};
