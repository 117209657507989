import { Flex } from '@chakra-ui/layout';

import { useUserData } from '../../models/userData/useUserData';

export const InitialsAvatar = ({ isSelected }: { isSelected?: boolean }) => {
  const { userData } = useUserData();
  const initial = userData?.username.charAt(0);

  return (
    <Flex
      borderRadius="full"
      h="28px"
      w="28px"
      bg="primary.100"
      border="1px solid"
      borderColor={isSelected ? 'white' : 'primary.100'}
      color="white"
      justifyContent="center"
      alignItems="center"
    >
      {initial}
    </Flex>
  );
};
