import { Badge, Box, Flex, Stack, Text } from '@chakra-ui/react';
import { format, getYear, isBefore } from 'date-fns';

import { LabeledSeparator } from '../../../components/LabeledSeparator';
import { Table } from '../../../components/Table';
import { TableActions } from '../../../components/TableActions';
import { Project } from '../../../models/Project';
import { getWorkHoursDuration2, isAdvanceWorkHour } from '../../../models/workHourUtils';
import { durationToMinutesDisplay, getDateString, getDuration } from '../../../utils/time/timeUtils';
import { SummaryPill } from '../SummaryPill';
import { EnrichedWorkHour } from '../WorkHoursViewModel';

export const DetailedWorkHours = ({
  workHours,
  getProjectById,
  deleteWorkHour,
  editWorkHour,
}: {
  workHours: EnrichedWorkHour[];
  getProjectById: (id?: string) => Project | undefined;
  deleteWorkHour: (wh: EnrichedWorkHour) => void;
  editWorkHour: (wh: EnrichedWorkHour) => void;
}) => {
  const columns = [
    {
      key: 'projectName',
      label: 'פרויקט',
      render: (wh: EnrichedWorkHour) => (
        <Box maxWidth="150px">
          <Text noOfLines={1} display="block" whiteSpace="nowrap" title={wh.projectName}>
            {wh.projectName}
          </Text>
        </Box>
      ),
      w: '200px',
    },
    {
      key: 'comment',
      label: 'תאור',
      render: (wh: EnrichedWorkHour) => (
        <Box maxWidth="210px">
          <Text noOfLines={1} display="block" whiteSpace="nowrap" title={wh.comment || ''}>
            {wh.comment || ''}
          </Text>
        </Box>
      ),
      w: '250px',
    },
    {
      key: 'customer',
      label: 'לקוח',
      render: (wh: EnrichedWorkHour) => (
        <Box maxWidth="140px">
          <Text noOfLines={1} display="block" whiteSpace="nowrap" title={wh.customerName}>
            {wh.customerName}
          </Text>
        </Box>
      ),
      w: '170px',
    },
    {
      key: '',
      label: '',
      render: (wh: EnrichedWorkHour) => {
        const p = getProjectById(wh.projectId);
        if (p && isAdvanceWorkHour(wh._id, p)) {
          return (
            <Badge color="brand.600" bg="purple.300">
              על חשבון מקדמה
            </Badge>
          );
        }
        return <Box w="170px" />;
      },
      w: '220px',
    },
    {
      key: 'range',
      label: 'טווח',
      render: (wh: EnrichedWorkHour) => `${format(wh.startTime, 'HH:mm')}-${format(wh.endTime, 'HH:mm')}`,
      w: '125px',
    },
    {
      key: 'total',
      label: 'סה״כ',
      render: (wh: EnrichedWorkHour) => `${durationToMinutesDisplay(getDuration(wh.startTime, wh.endTime))}`,
      w: '120px',
    },
  ];

  const filteredWorkHoursByYearByDate = workHours.reduce<{ [s: string]: { [s: string]: EnrichedWorkHour[] } }>(
    (acc, curr) => {
      const date = format(curr.startTime, 'yyyy-MM-dd');
      const year = getYear(new Date(date));
      if (!acc[year]) {
        acc[year] = {};
      }
      const yearKey = acc[year];
      if (!yearKey[date]) {
        yearKey[date] = [];
      }
      yearKey[date].push(curr);
      return acc;
    },
    {},
  );

  return (
    <Stack>
      {filteredWorkHoursByYearByDate &&
        Object.keys(filteredWorkHoursByYearByDate)
          .sort((a, b) => (isBefore(new Date(a), new Date(b)) ? 1 : -1))
          .map((year) => (
            <Flex direction="column" gap="40px" key={year}>
              <LabeledSeparator label={year} />
              {filteredWorkHoursByYearByDate?.[year] &&
                Object.keys(filteredWorkHoursByYearByDate[year])
                  .sort((a, b) => (isBefore(new Date(a), new Date(b)) ? 1 : -1))
                  .map(
                    (date: string) =>
                      filteredWorkHoursByYearByDate[year][date] && (
                        <Stack key={date} mb="50px" gap="10px">
                          <Table
                            title={getDateString(date)}
                            columns={columns}
                            data={filteredWorkHoursByYearByDate[year][date].sort(
                              (a, b) => b.startTime.getTime() - a.startTime.getTime(),
                            )}
                            actions={{
                              width: '140px',
                              render: (wh: EnrichedWorkHour) => (
                                <TableActions onDelete={() => deleteWorkHour(wh)} onEdit={() => editWorkHour(wh)} />
                              ),
                            }}
                          />
                          <Flex direction="row-reverse">
                            <SummaryPill
                              value={durationToMinutesDisplay(
                                getWorkHoursDuration2(filteredWorkHoursByYearByDate[year][date]),
                              )}
                              label="סה״כ"
                            />
                          </Flex>
                        </Stack>
                      ),
                  )}
            </Flex>
          ))}
    </Stack>
  );
};
