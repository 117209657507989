import { Box, Text, Button, Input, UnorderedList, ListItem, Flex } from '@chakra-ui/react';
import { useState, useRef, ChangeEvent } from 'react';

import { ButtonTabs } from '../../../components/ButtonTabs';
import { Dialog } from '../../../components/Dialog';
import { IWorkHourBase } from '../../../models/workHours/workHourApi';
import { csvToWorkHourApi } from '../../../utils/csv';
import { ExclamationLightIcon } from '../../common/icons';

export enum IMPORT_TYPE {
  TOGGL = 'Toggl',
  CLOCKIFY = 'Clockify',
}

const importTypeItems = [
  {
    label: 'Toggl',
    id: IMPORT_TYPE.TOGGL,
  },
  { label: 'Clockify', id: IMPORT_TYPE.CLOCKIFY },
];

const exportExplanation = {
  [IMPORT_TYPE.TOGGL]: [
    'התחברות לחשבון שלכם',
    'בסרגל הניווט השמאלי, לחיצה על Reports',
    'בראש המסך, לחיצה על Detailed (כדי להציג דוח מפורט)',
    'בחירת הפרויקט הרלוונטי באמצעות שורת הסינון העליונה (Filters)',
    'בחירת תקופת הזמן הרצויה ',
    'בפינה ימנית עליונה, לחיצה על Export ',
    'בחירה באפשרות Download CSV',
  ],
  [IMPORT_TYPE.CLOCKIFY]: [
    'התחברות לחשבון שלכם',
    'בסרגל ההניווט השמאלי, לחיצה על Reports',
    'בראש המסך, לחיצה על Detailed (כדי להציג דוח מפורט)',
    'בפינה הימנית העליונה, בחירת תקופת הזמן הרצויה',
    'בחירת הפרויקט הרלוונטי באמצעות שורת הסינון העליונה (Filters)',
    'לחיצה על הכפתור Apply Filter מימין',
    'בראש הטבלה מימין, לחיצה על כפתור Export ',
    'בחירה באפשרות Save as CSV',
  ],
};

export const UploadWorkHoursModal = ({
  onDismiss,
  onSuccess,
}: {
  onDismiss: () => void;
  onSuccess: (fileName: string, workHours: IWorkHourBase[]) => void;
}) => {
  const [importType, setImportType] = useState<IMPORT_TYPE>(IMPORT_TYPE.TOGGL);
  const [error, setError] = useState(false);
  const fileUpload = useRef<HTMLInputElement>(null);

  const handleCsvUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    if (!file) {
      return;
    }

    try {
      const parsedWorkHours = await csvToWorkHourApi(file, importType);
      onSuccess(file.name, parsedWorkHours);
      onDismiss();
    } catch (e) {
      setError(true);
    }
  };

  return (
    <Dialog title="ייבוא שעות" onClose={onDismiss} size="medium">
      <Flex direction="column" maxW="420px" mx="auto">
        <Box maxW="195px" alignSelf="center" mb="50px">
          <ButtonTabs
            items={importTypeItems}
            selectedId={importType}
            handleClick={(state: IMPORT_TYPE) => setImportType(state)}
          />
        </Box>
        <Text fontSize="lg" mb="27px">
          צעדים ליבוא קובץ שעות מתוך מערכת{' '}
          <Box as="span" fontWeight="bold">
            {importType}
          </Box>
        </Text>
        <UnorderedList mb="44px">
          {exportExplanation[importType].map((explanation) => (
            <ListItem key={explanation}>{explanation}</ListItem>
          ))}
        </UnorderedList>
      </Flex>
      {error && (
        <Flex px="42px" gap="8px" color="accent.100" fontSize="md" mb="34px">
          <ExclamationLightIcon mt="5px" />
          <Text>הקובץ שניסית להעלות לא תקין. חשוב לוודא שסוג הקובץ הוא CSV ושהוא מכיל דיווחי שעות</Text>
        </Flex>
      )}
      <Input ref={fileUpload} display="none" onChange={handleCsvUpload} type="file" />
      <Flex p="32px" justifyContent="space-between">
        <Button onClick={onDismiss} size="sm" variant="secondary">
          ביטול
        </Button>
        <Button onClick={() => fileUpload.current?.click()} size="sm">
          העלאת קובץ
        </Button>
      </Flex>
    </Dialog>
  );
};
