import { createIcon } from '@chakra-ui/icons';

export const AdditionIcon = createIcon({
  displayName: 'AdditionIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M17 12C17 12.553 16.552 13 16 13H13V16C13 16.553 12.552 17 12 17C11.448 17 11 16.553 11 16V13H8C7.448 13 7 12.553 7 12C7 11.447 7.448 11 8 11H11V8C11 7.447 11.448 7 12 7C12.552 7 13 7.447 13 8V11H16C16.552 11 17 11.447 17 12ZM24 5V19C24 21.757 21.757 24 19 24H5C2.243 24 0 21.757 0 19V5C0 2.243 2.243 0 5 0H19C21.757 0 24 2.243 24 5ZM22 5C22 3.346 20.654 2 19 2H5C3.346 2 2 3.346 2 5V19C2 20.654 3.346 22 5 22H19C20.654 22 22 20.654 22 19V5Z"
    />,
  ],
});
