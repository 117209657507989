import axios from 'axios';

import { fetcher } from '../../requests/fetcher';

const baseUrl = import.meta.env.REACT_APP_API_URL;

export interface IAffiliateLinkApi {
  id: string;
  additional_months: number;
  description?: string;
  affiliate_user_id?: string;
}

export interface IAffiliateStatsApi {
  id: string;
  additional_months: number;
  description?: string;
  affiliate_user_id?: string;
  affiliate_user_name?: string;
  affiliate_email?: string;
  users_signed_up: number;
  users_paid: number;
  affiliate_created_at: string;
  is_active: boolean;
}

export interface UserStatsApi {
  source: string;
  count: number;
}

export type UserStats = UserStatsApi & { purchases: number };

export async function getAffiliateLink(id?: string): Promise<IAffiliateLinkApi | null> {
  if (!id) {
    return null;
  }
  const res = await axios.get(`${baseUrl}/affiliate?afid=${id}`);
  return res.data;
}

export async function getAffiliateStats(): Promise<IAffiliateStatsApi[]> {
  return fetcher(`${baseUrl}/affiliate/stats`, '', 'GET');
}

export async function getUserStats(startTime: string | null, endTime: string | null) {
  if (!startTime || !endTime) {
    return null;
  }
  return fetcher(`${baseUrl}/users/user-by-source?startTime=${startTime}&endTime=${endTime}`, '', 'GET');
}

export async function getUserPurchases(startTime: string | null, endTime: string | null) {
  if (!startTime || !endTime) {
    return null;
  }
  return fetcher(`${baseUrl}/users/purchase-by-source?startTime=${startTime}&endTime=${endTime}`, '', 'GET');
}
