import { createIcon } from '@chakra-ui/icons';

export const ChevronDownIcon = createIcon({
  displayName: 'ChevronDownIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M12.4733 5.47332C12.4113 5.41084 12.3376 5.36124 12.2563 5.32739C12.1751 5.29355 12.088 5.27612 12 5.27612C11.912 5.27612 11.8248 5.29355 11.7436 5.32739C11.6623 5.36124 11.5886 5.41084 11.5266 5.47332L8.47329 8.52665C8.41132 8.58914 8.33758 8.63874 8.25634 8.67258C8.1751 8.70643 8.08797 8.72385 7.99996 8.72385C7.91195 8.72385 7.82481 8.70643 7.74357 8.67258C7.66233 8.63874 7.5886 8.58914 7.52663 8.52665L4.47329 5.47332C4.41132 5.41084 4.33758 5.36124 4.25634 5.32739C4.1751 5.29355 4.08797 5.27612 3.99996 5.27612C3.91195 5.27612 3.82481 5.29355 3.74357 5.32739C3.66233 5.36124 3.5886 5.41084 3.52663 5.47332C3.40246 5.59823 3.33276 5.7672 3.33276 5.94332C3.33276 6.11945 3.40246 6.28841 3.52663 6.41332L6.58663 9.47332C6.96163 9.84786 7.46996 10.0582 7.99996 10.0582C8.52996 10.0582 9.03829 9.84786 9.41329 9.47332L12.4733 6.41332C12.5975 6.28841 12.6672 6.11945 12.6672 5.94332C12.6672 5.7672 12.5975 5.59823 12.4733 5.47332Z"
    />,
  ],
});
