import { format } from 'date-fns';
import { makeObservable, observable, computed } from 'mobx';

import { ICustomerApi } from '../requests/Client';
import { getDisplayPaymentCycle } from '../utils/paymentUtils';

import { ICustomerValues } from './customers/customerUtils';
import { PaymentCycleEnum } from './Project';

export enum CustomerStatus {
  NEW_LEAD = 'ליד חדש',
  PENDING_PROPOSAL = 'ממתין להצעה',
  PROPOSAL_SENT = 'נשלחה הצעה',
  NEGOTIATION = 'משא ומתן',
  CLOSED = 'נסגר',
  DIDNT_CLOSE = 'לא נסגר',
}

export const CustomerStatuses = [
  {
    key: 'ליד חדש',
    value: CustomerStatus.NEW_LEAD,
  },
  {
    key: 'ממתין להצעה',
    value: CustomerStatus.PENDING_PROPOSAL,
  },
  {
    key: 'נשלחה הצעה',
    value: CustomerStatus.PROPOSAL_SENT,
  },
  {
    key: 'משא ומתן',
    value: CustomerStatus.NEGOTIATION,
  },
  {
    key: 'נסגר',
    value: CustomerStatus.CLOSED,
  },
  {
    key: 'לא נסגר',
    value: CustomerStatus.DIDNT_CLOSE,
  },
];

export class Customer {
  _id: string;
  _name: string;
  _phone: string;
  _email: string;
  _source: string;
  _createdAt: Date;
  _status?: string;
  _rate?: number;
  _payment_cycle: PaymentCycleEnum;
  _vat_number?: string;
  _industry?: string;
  _address?: string;
  _contact_name?: string;
  _contact_role?: string;
  _contact_birth_date?: Date;
  _comment?: string;
  _isActive: boolean;

  constructor(customer?: ICustomerApi) {
    this._id = customer?.id || '';
    this._name = customer?.name || '';
    this._phone = !customer?.phone || [undefined, 'undefined'].includes(customer?.phone) ? '' : customer?.phone;
    this._email = customer?.email || '';
    this._source = customer?.source || '';
    this._createdAt = customer ? new Date(customer.created_at) : new Date();
    this._status = customer?.status;
    this._rate = customer?.rate;
    this._payment_cycle = customer?.payment_cycle || 0;
    this._vat_number = customer?.vat_number;
    this._industry = customer?.industry;
    this._address = customer?.address;
    this._contact_name = customer?.contact_name;
    this._contact_role = customer?.contact_role;
    this._contact_birth_date = customer?.contact_birth_date ? new Date(customer.contact_birth_date) : undefined;
    this._comment = customer?.comment;
    this._isActive = customer?.is_active || true;

    makeObservable(this, {
      _name: observable,
      name: computed,
      _phone: observable,
      phone: computed,
      _email: observable,
      email: computed,
      _source: observable,
      source: computed,
      _createdAt: observable,
      createdAt: computed,
      _status: observable,
      status: computed,
      displayStatus: computed,
      _rate: observable,
      rate: computed,
      _payment_cycle: observable,
      payment_cycle: computed,
      _vat_number: observable,
      vat_number: computed,
      _industry: observable,
      industry: computed,
      _address: observable,
      address: computed,
      _contact_name: observable,
      contact_name: computed,
      _contact_role: observable,
      contact_role: computed,
      _contact_birth_date: observable,
      contact_birth_date: computed,
      _comment: observable,
      comment: computed,
      _isActive: observable,
      isActive: computed,

      values: computed,
    });
  }

  get name() {
    return this._name;
  }

  get phone() {
    return this._phone;
  }

  get email() {
    return this._email;
  }

  get source() {
    return this._source;
  }

  get createdAt() {
    return format(this._createdAt, 'dd.MM.yyyy');
  }

  get status() {
    return this._status;
  }

  get displayStatus() {
    return CustomerStatuses.find((s) => s.value === this._status)?.key;
  }

  get rate() {
    return this._rate;
  }

  get payment_cycle() {
    return this._payment_cycle;
  }

  get displayPaymentCycle() {
    return getDisplayPaymentCycle(this._payment_cycle);
  }

  get vat_number() {
    return this._vat_number;
  }

  get industry() {
    return this._industry;
  }

  get address() {
    return this._address;
  }

  get contact_name() {
    return this._contact_name;
  }

  get contact_role() {
    return this._contact_role;
  }

  get contact_birth_date() {
    return this._contact_birth_date ? format(this._contact_birth_date, 'dd.MM.yyyy') : undefined;
  }

  get comment() {
    return this._comment;
  }

  get isActive() {
    return this._isActive;
  }

  get values(): ICustomerValues {
    return {
      name: this._name,
      phone: this._phone,
      email: this._email,
      source: this._source,
      status: this._status,
      rate: this._rate,
      payment_cycle: this._payment_cycle,
      vat_number: this._vat_number,
      industry: this._industry,
      address: this._address,
      contact_name: this._contact_name,
      contact_role: this._contact_role,
      contact_birth_date: this._contact_birth_date,
      comment: this._comment,
      is_active: this._isActive,
    };
  }
}
