import * as Sentry from '@sentry/react';

export const logger = {
  log: (message: string) => {
    if (import.meta.env.REACT_APP_ENV === 'production') {
      Sentry.captureMessage(message);
    } else {
      console.log(message);
    }
  },
  error: (err: any, extra?: any) => {
    if (import.meta.env.REACT_APP_ENV === 'production') {
      Sentry.captureException(err, { extra });
    } else {
      console.error(err);
    }
  },
  setUserContext: (user: any) => {
    if (import.meta.env.REACT_APP_ENV === 'production') {
      Sentry.setUser(user);
    } else {
      console.log(user);
    }
  },
};
