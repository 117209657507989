import { createIcon } from "@chakra-ui/icons";

export const ExclamationIcon = createIcon({
  displayName: "ExclamationIcon",
  viewBox: "0 0 15 15",
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M7.5 0.9375C9.24048 0.9375 10.9097 1.6289 12.1404 2.85961C13.3711 4.09032 14.0625 5.75952 14.0625 7.5C14.0625 9.24048 13.3711 10.9097 12.1404 12.1404C10.9097 13.3711 9.24048 14.0625 7.5 14.0625C5.75952 14.0625 4.09032 13.3711 2.85961 12.1404C1.6289 10.9097 0.9375 9.24048 0.9375 7.5C0.9375 5.75952 1.6289 4.09032 2.85961 2.85961C4.09032 1.6289 5.75952 0.9375 7.5 0.9375ZM7.5 3.75C7.38097 3.7499 7.26324 3.77462 7.15431 3.82259C7.04538 3.87056 6.94765 3.94072 6.86736 4.02859C6.78708 4.11647 6.726 4.22012 6.68804 4.33292C6.65007 4.44573 6.63605 4.56522 6.64688 4.68375L6.98906 8.43937C7.00233 8.56564 7.06188 8.68252 7.15623 8.76748C7.25058 8.85243 7.37304 8.89944 7.5 8.89944C7.62696 8.89944 7.74942 8.85243 7.84377 8.76748C7.93812 8.68252 7.99767 8.56564 8.01094 8.43937L8.35219 4.68375C8.363 4.5653 8.34901 4.44589 8.3111 4.33315C8.27319 4.2204 8.2122 4.1168 8.13202 4.02894C8.05183 3.94108 7.95422 3.8709 7.8454 3.82288C7.73658 3.77485 7.61895 3.75003 7.5 3.75ZM7.5 11.25C7.69891 11.25 7.88968 11.171 8.03033 11.0303C8.17098 10.8897 8.25 10.6989 8.25 10.5C8.25 10.3011 8.17098 10.1103 8.03033 9.96967C7.88968 9.82902 7.69891 9.75 7.5 9.75C7.30109 9.75 7.11032 9.82902 6.96967 9.96967C6.82902 10.1103 6.75 10.3011 6.75 10.5C6.75 10.6989 6.82902 10.8897 6.96967 11.0303C7.11032 11.171 7.30109 11.25 7.5 11.25Z"
    />,
  ],
});
