import { makeObservable, observable, computed } from 'mobx';

import { CustomerModel } from '../../../models/CustomerModel';
import { Project } from '../../../models/Project';
import { ProjectsModel } from '../../../models/ProjectsModel';
import { IStore } from '../../../models/RootStore';
import { WorkHour } from '../../../models/WorkHour';
import { getMaximumWorkHourDate, getMinimumWorkHourDate, IWorkHourValues } from '../../../models/workHourUtils';

export class EditWorkHourViewModel implements IStore {
  projectsModel: ProjectsModel;
  customerModel: CustomerModel;

  project?: Project;
  workHour: WorkHour;

  onClose: () => void;

  constructor(
    workHour: WorkHour,
    projectsModel: ProjectsModel,
    customerModel: CustomerModel,
    onClose: () => void,
    project?: Project,
  ) {
    this.projectsModel = projectsModel;
    this.customerModel = customerModel;

    this.project = project;
    this.workHour = workHour;

    this.onClose = onClose;

    makeObservable(this, {
      projectsModel: observable,

      allProjects: computed,
    });
  }

  activate() {}

  get allProjects() {
    const projects = this.projectsModel.projects
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((p) => ({
        value: p.name,
        key: p.name,
      }));

    return [{ value: '', key: 'ללא' }, ...projects];
  }

  getCustomerNameByProjectName = (projectName: string) => {
    const project = this.projectsModel.getProjectByName(projectName);
    return project?.customer && this.customerModel.getCustomerById(project.customer)?.name;
  };

  updateWorkHour = async (updatedValues: IWorkHourValues) => {
    await this.projectsModel.updateWorkHour(this.workHour, updatedValues, this.project);
  };

  getMinimumDate = (values: Partial<IWorkHourValues>) => {
    const project = this.projectsModel.getProjectByName(values.projectName || '');

    return project && getMinimumWorkHourDate(project);
  };

  getMaximumDate = (values: Partial<IWorkHourValues>) => {
    const project = this.projectsModel.getProjectByName(values.projectName || '');

    return project && getMaximumWorkHourDate(project);
  };
}
