import { useToast } from "@chakra-ui/react";
import { Toast } from "./Toast";

export function useCustomToast(action = "המחיקה", isFemale = true) {
  const onToastSuccess = useToast({
    position: "bottom-left",
    render: () => (
      <Toast state="success" text={`${action} בוצע${isFemale ? "ה" : ""}`} />
    ),
  });

  const onToastError = useToast({
    position: "bottom-left",
    render: () => (
      <Toast
        state="error"
        text={`${action} לא בוצע${isFemale ? "ה" : ""} - כדאי לנסות שוב`}
      />
    ),
  });

  const triggerToast = (state: "success" | "error") => {
    if (state === "success") {
      onToastSuccess();
    } else {
      onToastError();
    }
  };

  return {
    triggerToast,
  };
}
