import { Box, Divider, Flex } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { Card } from '../../../components/Card';
import { Page } from '../../../components/Page';
import { TabGroup } from '../../../components/TabGroup';
import { useVat } from '../../../hooks/useVat/useVat';
import { durationToMinutesDisplay } from '../../../utils/time/timeUtils';
import { EditIcon } from '../../common/icons/EditIcon';
import { Stat } from '../../common/Stat';

import { SingleCustomerMainTab } from './SingleCustomerMainTab';
import { SingleCustomerPayments } from './SingleCustomerPayments';
import { SingleCustomerProjects } from './SingleCustomerProjects';
import { SingleCustomerViewModel } from './SingleCustomerViewModel';

export const SingleCustomer = observer(({ viewModel }: { viewModel: SingleCustomerViewModel }) => {
  const customer = viewModel.customer;
  const [currentTab, setCurrentTab] = useState(0);
  const { getValueWithVat } = useVat();

  if (!customer) {
    return null;
  }

  const tabs = {
    ראשי: <SingleCustomerMainTab customer={customer} />,
    פרויקטים: (
      <SingleCustomerProjects projectsByYear={viewModel.projectsByYear} createProject={viewModel.createProject} />
    ),
    'צפי הכנסות': <SingleCustomerPayments paymentsByYear={viewModel.paymentsByYear} />,
  };
  return (
    <Page
      title={customer.name}
      component={
        <EditIcon onClick={viewModel.editCustomer} color="base.500" cursor="pointer" _hover={{ color: 'base.600' }} />
      }
    >
      <Box mb="24px">
        <Card>
          <Flex justifyContent="space-between" pt="26px" pb="20px" paddingInlineStart="8px" paddingInlineEnd="26px">
            <Stat value={customer.contact_name} label="איש קשר" />
            <Stat value={customer.source} label="מקור" />
            <Stat value={viewModel.customerTotalProjects.toLocaleString()} label="פרויקטים" />
            <Stat value={customer.rate ? customer.rate.toLocaleString() : '-'} label="עלות לשעה" />
            <Stat value={customer.displayPaymentCycle} label="תנאי תשלום" />
            <Divider orientation="vertical" minHeight="50px" marginInlineStart="100px" />
            <Stat value={durationToMinutesDisplay(viewModel.totalWorkHours)} label="סה״כ שעות" />
            <Stat
              value={getValueWithVat(viewModel.totalIncome).toLocaleString()}
              label="סה״כ הכנסות"
              icon
              highlighted
            />
          </Flex>
        </Card>
      </Box>
      <TabGroup index={currentTab} onChange={setCurrentTab} items={tabs} />
    </Page>
  );
});
