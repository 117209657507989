import { Image } from '@chakra-ui/image';
import { Box, Grid, Text, Flex } from '@chakra-ui/layout';

import { WORK_HOUR_PRESETS } from '../InsightsViewModel';

import { WorkHourDonutChart } from './WorkHourDonutChart';

export const WorkHoursBreakdown = ({
  selectedWorkHourPreset,
  setSelectedWorkHourPreset,
  totalHoursPerPreset,
  workHourDonutData,
}: {
  selectedWorkHourPreset: WORK_HOUR_PRESETS;
  setSelectedWorkHourPreset: (s: WORK_HOUR_PRESETS) => void;
  totalHoursPerPreset: { [key in WORK_HOUR_PRESETS]: string };
  workHourDonutData: Array<{ label: string; data: number }>;
}) => {
  const presetLabels = {
    [WORK_HOUR_PRESETS.YESTERDAY]: 'אתמול',
    [WORK_HOUR_PRESETS.TODAY]: 'היום',
    [WORK_HOUR_PRESETS.WEEK]: 'השבוע',
    [WORK_HOUR_PRESETS.MONTH]: 'החודש',
  };

  const isEmpty = !workHourDonutData.length;

  return (
    <Grid templateColumns="1fr 1fr" gap="32px">
      <Box>
        <Text fontSize="xl" mb="22px">
          סה״כ שעות שדיווחת
        </Text>
        <Grid h="220px" templateColumns="1fr 1fr" templateRows="1fr 1fr" gap="16px">
          {Object.values(WORK_HOUR_PRESETS).map((preset) => (
            <Box
              key={preset}
              bg={selectedWorkHourPreset === preset ? '#E6E9F2' : 'white'}
              p="20px"
              border="1px solid #E6E9F2"
              borderRadius="8px"
              cursor={selectedWorkHourPreset === preset ? 'normal' : 'pointer'}
              _hover={{
                borderColor: selectedWorkHourPreset === preset ? '#E6E9F2' : 'base.1000',
              }}
              onClick={() => setSelectedWorkHourPreset(preset)}
            >
              <Text color="#685888" fontSize="lg" mb="13px">
                {presetLabels[preset]}
              </Text>
              <Text color="base.900" fontSize="xl">
                {totalHoursPerPreset[preset]}
              </Text>
            </Box>
          ))}
        </Grid>
      </Box>
      <Box>
        <Text fontSize="xl" mb="22px">
          התפלגות לפי פרויקט
        </Text>
        <Box w="378px" h="220px">
          {isEmpty ? (
            <Flex direction="column" alignItems="center" justifyContent="center" h="100%">
              <Image src="/empty-donut.svg" mb="8px" />
              <Text fontSize="xl" fontWeight="500">
                אין נתונים להצגה
              </Text>
              <Text fontSize="lg" color="#685888">
                נראה שלא דיווחת שעות בטווח הזה
              </Text>
            </Flex>
          ) : (
            <WorkHourDonutChart data={workHourDonutData} />
          )}
        </Box>
      </Box>
    </Grid>
  );
};
