import { useQuery } from '@tanstack/react-query';

import { getWorkHours } from './workHourApi';
import { apiToWorkHours } from './workHourMapper';

export enum NUM_CO_WORKERS {
  ALONE = 'ALONE',
  GROUP = 'GROUP',
}

export enum BUSINESS_TYPE {
  OSEK = 'OSEK',
  MURSHE = 'MURSHE',
  COMPANY = 'COMPANY',
}

export interface IWorkHour {
  _id: string;

  startTime: Date;
  endTime: Date;
  comment: string | null;
  project: {
    _id?: string;
  };
  task_id?: number;
}

export type IPartialWorkHour = Omit<IWorkHour, 'endTime'> & {
  endTime: Date | null;
};

export const WORK_HOURS_QUERY_KEY = 'workHours';

export function useWorkHours() {
  const { isLoading, error, data } = useQuery({
    queryKey: [WORK_HOURS_QUERY_KEY],
    queryFn: getWorkHours,
  });

  const parsedWorkHours = data && apiToWorkHours(data);

  const workHours: IWorkHour[] | undefined = parsedWorkHours?.filter((wh) => Boolean(wh.endTime)) as IWorkHour[];
  const partialWorkHour: IPartialWorkHour | undefined = parsedWorkHours?.find((wh) => !wh.endTime); // shouldn't be more than one

  const getWorkHoursByTask = (taskId: number) => {
    return workHours.filter((w) => w.task_id === taskId);
  };

  return {
    isLoading,
    error,
    workHours,
    partialWorkHour,
    getWorkHoursByTask,
  };
}
