import { Text, Button, Stack, Box, Flex, Grid, Radio, RadioGroup } from '@chakra-ui/react';
import { getMonth, isBefore } from 'date-fns';
import { Formik, Form, Field, FieldAttributes, FormikErrors } from 'formik';
import { useState } from 'react';

import { ButtonTabs } from '../../../components/ButtonTabs';
import { Dialog } from '../../../components/Dialog';
import { FormDatePicker } from '../../../components/form/FormDatePicker';
import { FormInput } from '../../../components/form/FormInput';
import { Addition, IAdditionValues } from '../../../models/Addition';
import { Payment } from '../../../models/Payment';
import { MONTHS } from '../../../utils/time/timeUtils';
import { DataPoint } from '../../common/DataPoint';
import { ExclamationLightIcon } from '../../common/icons';

type ValueType = 'ABSOLUTE_VALUE' | 'HOURLY_VALUE';

export const CreateAddition = ({
  initialValues: _initialValues,
  onSubmit,
  onDismiss,
  rate,
  payments,
  isFixedProject,
  editProject,
}: {
  initialValues: Addition | null;
  onSubmit: (data: IAdditionValues) => void;
  onDismiss: () => void;
  editProject: () => void;
  rate: number;
  payments: Array<Payment>;
  isFixedProject: boolean;
}) => {
  const [isAbsoluteValue, setIsAbsoluteValue] = useState<ValueType>('ABSOLUTE_VALUE');
  const valueTypeItems = [
    {
      label: 'סכום',
      id: 'ABSOLUTE_VALUE',
    },
    {
      label: 'שעות',
      id: 'HOURLY_VALUE',
    },
  ];

  const allPaymentsInPast = payments.every((p) => isBefore(p._submitDate, new Date()));

  const initialValues: IAdditionValues = {
    date: _initialValues?._date || new Date(),
    value: _initialValues?.value || undefined,
    comment: _initialValues?.comment || '',
    approvedBy: _initialValues?.approvedBy || '',
    payment: _initialValues?.payment === '0' ? undefined : _initialValues?.payment,
  };
  return (
    <Dialog
      title={_initialValues ? 'עריכת תוספת' : 'יצירת תוספת'}
      onClose={onDismiss}
      size={isFixedProject ? 'large' : 'small'}
    >
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: FormikErrors<IAdditionValues> = {};

          if (allPaymentsInPast && isFixedProject) {
            return errors;
          }

          if (!values.date) {
            errors.date = 'אנחנו צריכים את זה';
          }
          if (!values.value) {
            errors.value = 'אנחנו צריכים את זה';
          }
          if (isFixedProject && !values.payment) {
            errors.payment = 'שכחת לבחור תשלום';
          }

          return errors;
        }}
        onSubmit={async (values) => {
          if (isFixedProject && allPaymentsInPast) {
            editProject();
          }
          if (isAbsoluteValue === 'HOURLY_VALUE' && values.value) {
            values.value = values.value * rate;
          }
          await onSubmit(values);
          onDismiss();
        }}
      >
        {(props) => (
          <Form>
            <Grid
              templateColumns={isFixedProject ? '1fr 1fr' : '1fr'}
              templateRows="90px 90px"
              columnGap="30px"
              rowGap="16px"
              px="32px"
              alignItems="end"
            >
              <FormDatePicker size="sm" name="date" label="תאריך" isRequired />
              <FormInput size="sm" name="approvedBy" label="אושר ע״י" />
              <FormInput
                size="sm"
                name="value"
                type="number"
                label="גובה התוספת"
                isRequired
                step="0.01"
                endIcon={
                  !isFixedProject && (
                    <ButtonTabs
                      items={valueTypeItems}
                      selectedId={isAbsoluteValue}
                      handleClick={(state: ValueType) => setIsAbsoluteValue(state)}
                      size="sm"
                    />
                  )
                }
              />
              <FormInput size="sm" name="comment" label="תיאור" type="textarea" rows={2} />
            </Grid>
            {isFixedProject && (
              <>
                <Box py="25px" px="48px" borderTop="1px solid" borderColor="#F4EFEF" position="relative">
                  <Text>לאיזה מהתשלומים בפרויקט לשייך את התוספת?</Text>
                  {props.errors.payment && (
                    <Text fontSize="xs" color="accent.100" position="absolute" bottom=" 3px" left="51px">
                      {props.errors.payment}
                    </Text>
                  )}
                  {allPaymentsInPast && (
                    <Flex
                      alignItems="center"
                      fontSize="xs"
                      color="accent.100"
                      gap="8px"
                      position="absolute"
                      bottom="6px"
                    >
                      <ExclamationLightIcon />
                      <Text>לא נותרו תשלומים עתידיים לשיוך התוספת</Text>
                    </Flex>
                  )}
                </Box>
                <Box mx="48px" borderTop="1px solid" borderColor="#F4EFEF" maxH="220px" overflowY="auto">
                  <Field name="payment">
                    {({ field, form }: FieldAttributes<any>) => (
                      <RadioGroup
                        value={field.value}
                        onChange={(paymentId) => form.setFieldValue(field.name, paymentId)}
                      >
                        {payments
                          .sort((a, b) => a._submitDate.getTime() - b._submitDate.getTime())
                          .map((p) => {
                            const isDisabled = isBefore(p._submitDate, new Date());
                            return (
                              <Grid
                                key={p._id}
                                templateColumns="40px 120px 120px 120px"
                                py="14px"
                                borderBottom="1px solid"
                                borderColor="#F4EFEF"
                                marginInlineEnd="30px"
                                opacity={isDisabled ? '.4' : '1'}
                              >
                                <Radio
                                  alignSelf="center"
                                  h="15px"
                                  paddingInlineStart="5px"
                                  value={p._id}
                                  isDisabled={isDisabled}
                                />
                                <DataPoint label="חודש" value={MONTHS[getMonth(p.paymentDate || p._submitDate)]} />
                                <DataPoint label="סכום" value={`${p.sum?.toLocaleString()} ₪`} />
                                <DataPoint label="מועד צפוי לתשלום" value={p.displayPaymentDate || ''} />
                              </Grid>
                            );
                          })}
                      </RadioGroup>
                    )}
                  </Field>
                </Box>
              </>
            )}
            <Flex justifyContent="space-between" alignItems="center" p="32px">
              <Button variant="secondary" alignSelf="start" size="sm" onClick={onDismiss}>
                ביטול
              </Button>
              <Stack>
                <Button isLoading={props.isSubmitting} type="submit" size="sm" isDisabled={!props.isValid}>
                  {isFixedProject && allPaymentsInPast ? 'המשך לעריכת הפרויקט ויצירת תשלום חדש' : 'שמירה'}
                </Button>
              </Stack>
            </Flex>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
