import { createIcon } from '@chakra-ui/icons';

export const ProjectIcon = createIcon({
  displayName: 'ProjectIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      key="0"
      fill="currentColor"
      d="M12.6667 2.00008H8.31467C8.21156 2.00076 8.10965 1.97796 8.01667 1.93341L5.91267 0.877415C5.63493 0.739111 5.32893 0.667006 5.01867 0.666748H3.33333C2.4496 0.667807 1.60237 1.01934 0.97748 1.64423C0.352588 2.26912 0.00105857 3.11635 0 4.00008L0 12.0001C0.00105857 12.8838 0.352588 13.731 0.97748 14.3559C1.60237 14.9808 2.4496 15.3324 3.33333 15.3334H12.6667C13.5504 15.3324 14.3976 14.9808 15.0225 14.3559C15.6474 13.731 15.9989 12.8838 16 12.0001V5.33342C15.9989 4.44968 15.6474 3.60245 15.0225 2.97756C14.3976 2.35267 13.5504 2.00114 12.6667 2.00008ZM3.33333 2.00008H5.01867C5.12177 1.9994 5.22368 2.0222 5.31667 2.06675L7.42067 3.11941C7.69813 3.25887 8.00413 3.33212 8.31467 3.33341H12.6667C13.0654 3.33407 13.4548 3.45386 13.7849 3.67742C14.115 3.90098 14.3708 4.21811 14.5193 4.58808L1.33333 4.66275V4.00008C1.33333 3.46965 1.54405 2.96094 1.91912 2.58587C2.29419 2.2108 2.8029 2.00008 3.33333 2.00008ZM12.6667 14.0001H3.33333C2.8029 14.0001 2.29419 13.7894 1.91912 13.4143C1.54405 13.0392 1.33333 12.5305 1.33333 12.0001V5.99608L14.6667 5.92075V12.0001C14.6667 12.5305 14.456 13.0392 14.0809 13.4143C13.7058 13.7894 13.1971 14.0001 12.6667 14.0001Z"
    />
  ),
});
