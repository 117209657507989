import { Box, Button, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import { Field, FieldAttributes, Form, Formik, FormikErrors } from 'formik';
import { useState } from 'react';

import { Dialog } from '../../components/Dialog';
import { FormAutocomplete } from '../../components/form/FormAutocomplete';
import { FormDatePicker } from '../../components/form/FormDatePicker';
import { FormInput } from '../../components/form/FormInput';
import { FormSelect } from '../../components/form/FormSelect';
import { StatusDropDown } from '../../components/StatusDropDown';
import { useCustomToast } from '../../hooks/toast/useCustomToast';
import { Task } from '../../models/tasks/tasksApi';
import { PriorityOptions, getInitialTaskValues, getPriorityColor } from '../../models/tasks/taskUtils';
import { useCreateTask } from '../../models/tasks/useCreateTask';
import { useTasks } from '../../models/tasks/useTasks';
import { useUpdateTask } from '../../models/tasks/useUpdateTask';
import { IWorkHour, useWorkHours } from '../../models/workHours/useWorkHours';
import { durationToMinutesDisplay, getDuration } from '../../utils/time/timeUtils';
import { FlagIcon } from '../common/icons';

export const TaskForm = ({
  onClose,
  taskId,
  projects,
  defaultProjectId,
}: {
  onClose(): void;
  taskId?: number | null;
  projects: Array<{ key: string; value: string }>;
  defaultProjectId?: string;
}) => {
  const { getWorkHoursByTask } = useWorkHours();

  const { tasks } = useTasks();
  const { createTask } = useCreateTask();
  const { updateTask } = useUpdateTask();
  const [error, setError] = useState('');

  const title = taskId ? 'עריכת משימה' : 'יצירת משימה';
  const { triggerToast } = useCustomToast(taskId ? 'עריכת המשימה' : 'יצירת המשימה');

  const task = tasks?.find((t) => t.id === taskId);

  const getTasksDuration = () => {
    if (!taskId) {
      return 0;
    }

    return getWorkHoursByTask(taskId).reduce<number>((duration: number, curr: IWorkHour) => {
      duration += getDuration(curr.startTime, curr.endTime);
      return duration;
    }, 0);
  };
  const totalTaskDuration = durationToMinutesDisplay(getTasksDuration());

  return (
    <Dialog title={title} onClose={onClose} size="medium">
      <Formik
        initialValues={getInitialTaskValues(task, defaultProjectId)}
        validate={(values: Task): FormikErrors<Task> => {
          const errors: FormikErrors<Task> = {};
          if (!values.label) {
            errors.label = 'אנחנו צריכים את זה';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          if (values) {
            taskId ? await updateTask(values) : await createTask(values);
            triggerToast('success');
            onClose();
          } else {
            setError('ארעה שגיאה');
          }
        }}
      >
        {(props) => (
          <Form>
            <Box px="32px" overflowY="scroll">
              <Box mb="16px">
                <FormInput type="textarea" name="label" label="משימה" size="sm" isRequired rows={2} />
              </Box>
              <Grid templateColumns="repeat(2, minmax(100px, 50%))" gridColumnGap="40px">
                <FormAutocomplete size="sm" name="project_id" data={projects} label="פרויקט" menuWidth="300px" />
                <FormDatePicker name="due_date" size="sm" label="תאריך יעד" isClearable />
                <FormSelect
                  size="sm"
                  name="priority"
                  label="חשיבות"
                  data={PriorityOptions.map((options) => ({
                    key: options.key,
                    value: options.value,
                    icon: <FlagIcon color={getPriorityColor(options.value)} />,
                  }))}
                />
                <Box>
                  <Field name="status">
                    {({ field, form }: FieldAttributes<any>) => {
                      return (
                        <Box>
                          <Text textStyle="medium" color="base.600" mb="4px">
                            סטטוס
                          </Text>
                          <StatusDropDown
                            value={field.value}
                            onChange={(status) => form.setFieldValue(field.name, status)}
                          />
                        </Box>
                      );
                    }}
                  </Field>
                </Box>
              </Grid>
              <FormInput size="sm" name="description" label="תיאור" type="textarea" rows={3} />
              <Text textStyle="medium" color="base.600" mb="4px">
                מעקב שעות
              </Text>
              <Flex>
                <Box borderRadius="8px" bg="success.0" px="12px" py="8px">
                  {totalTaskDuration}
                </Box>
              </Flex>
            </Box>
            <Flex justifyContent="space-between" alignItems="center" p="32px">
              <Button alignSelf="start" size="sm" onClick={onClose} variant="secondary">
                ביטול
              </Button>
              <Stack>
                <Button isLoading={props.isSubmitting} size="sm" type="submit" isDisabled={!props.isValid}>
                  שמירה
                </Button>
                {error && <Text color="red">{error}</Text>}
              </Stack>
            </Flex>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
