import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';

import { IAffiliateLinkApi, getAffiliateLink } from './affiliateLinkApi';

const AFFILIATE_LINK_KEY = 'AFFILIATE_LINK';
export const AFFILIATE_LINK_COOKIE_NAME = 'afid';

export const useAffiliateLink = () => {
  const affiliateLinkId = Cookies.get(AFFILIATE_LINK_COOKIE_NAME);

  const { isLoading, error, data } = useQuery<IAffiliateLinkApi | null>({
    queryKey: [AFFILIATE_LINK_KEY],
    enabled: Boolean(affiliateLinkId) && affiliateLinkId !== 'null', // specific case of Cookies package
    queryFn: () => getAffiliateLink(affiliateLinkId),
  });

  return {
    isLoading,
    error,
    affiliateLinkId,
    additionalMonths: data?.additional_months || null,
  };
};
