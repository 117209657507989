import { FormikErrors } from 'formik';

import { IUserData } from '../userData/useUserData';

export type IUserDataValues = Partial<Omit<IUserData, 'includeVat'> & { includeVat: 'true' | 'false' }>;
export type IValidUserDataValues = IUserDataValues & {
  baseRate: number;
  services: string[];
  companyName: string;
  industry: string;
  yearlyGoal: number;
};

export type USER_DATA_VALIDATION_TYPE = 'onboardingFirstStep' | 'onboardingSecondStep' | 'settings' | 'baseInfo';

export const industryOptions = [
  { key: 'עיצוב / אפיון', value: 'עיצוב / אפיון' },
  { key: 'מוצר', value: 'מוצר' },
  { key: 'תוכן', value: 'תוכן' },
  { key: 'פיתוח', value: 'פיתוח' },
  { key: 'שיווק', value: 'שיווק' },
  { key: 'פיננסים', value: 'פיננסים' },
  { key: 'ביטוח', value: 'ביטוח' },
  { key: 'חינוך', value: 'חינוך' },
  { key: 'עריכת דין', value: 'עריכת דין' },
  { key: 'נדלן ובינוי', value: 'נדלן ובינוי' },
  { key: 'בריאות ורווחה', value: 'בריאות ורווחה' },
  { key: 'אחר', value: 'אחר' },
];

export function getUserDataInitialValues(userData?: IUserData): IUserDataValues {
  return (
    {
      ...userData,
      includeVat: userData?.includeVat ? 'true' : 'false',
      yearlyGoal: userData?.yearlyGoal || undefined,
    } || {}
  );
}

export function validateUserDataValues(
  values: IUserDataValues,
  type: USER_DATA_VALIDATION_TYPE,
): FormikErrors<IUserDataValues> {
  // first step
  const errors: FormikErrors<IUserDataValues> = {};
  if (type === 'onboardingFirstStep') {
    if (!values.companyName) {
      errors.companyName = 'נראה ששכחת למלא את זה';
    }
    if (!values.industry) {
      errors.industry = 'אופס... שכחת את זה ';
    }
    if (!values.numberOfYears) {
      errors.numberOfYears = 'שכחת למלא את זה';
    }
  }

  if (type === 'onboardingSecondStep') {
    if (!values.baseRate) {
      errors.baseRate = 'נראה ששכחת למלא את זה';
    }
    if (values.baseRate && values.baseRate.toString().includes('.')) {
      errors.baseRate = 'אפשר להזין רק מספרים שלמים';
    }
    if (!values.services || values.services.length === 0) {
      errors.services = 'אופס... שכחת את זה';
    }
    if (values.services && values.services.length === 1) {
      errors.services = 'יש למלא לפחות 2 שירותים';
    }
    if (values.yearlyGoal && values.yearlyGoal.toString().includes('.')) {
      errors.yearlyGoal = 'אפשר להזין רק מספרים שלמים';
    }
  }
  if (type === 'settings') {
    if (!values.industry) {
      errors.industry = 'אופס... שכחת את זה ';
    }
    if (!values.baseRate) {
      errors.baseRate = 'נראה ששכחת למלא את זה';
    }
    if (values.baseRate && values.baseRate.toString().includes('.')) {
      errors.baseRate = 'אפשר להזין רק מספרים שלמים';
    }
    if (values.yearlyGoal && values.yearlyGoal.toString().includes('.')) {
      errors.yearlyGoal = 'אפשר להזין רק מספרים שלמים';
    }
    if (values.services?.length === 0) {
      errors.services = 'אופס... שכחת את זה ';
    }
  }
  if (type === 'baseInfo') {
    if (!values.username) {
      errors.username = 'נראה ששכחת למלא את זה';
    }
    if (values.phone && values.phone.length < 9) {
      errors.phone = "יש להכניס מס' תקין בעל 9 ספרות לפחות";
    }
  }
  return errors;
}
