import { Box, Flex, Text } from '@chakra-ui/react';
import { useSelect } from 'downshift';
import { useEffect } from 'react';

import { ChevronDownIcon } from '../../pages/common/icons';
import { DropListItem } from '../DropListItem';

import { RequiredIndicator } from './RequiredIndicator';

export interface SelectItem {
  key: string;
  value: string;
  icon?: React.ReactNode;
}

export interface SelectProps {
  data: Array<SelectItem>;
  onChange: (data: SelectItem) => void;
  selectedItem?: SelectItem;
  label?: string;
  helperText?: string;
  error?: string;
  touched?: boolean;
  isRequired?: boolean;
  maintainHeight?: boolean;
  setTouched?: () => void;
  size?: 'sm' | 'lg';
  placeholder?: string;
  menuWidth?: string;
}

export const Select = ({
  label,
  data,
  helperText,
  isRequired,
  onChange,
  touched,
  error,
  maintainHeight = false,
  selectedItem: initialSelectedItem,
  setTouched,
  size = 'lg',
  placeholder = 'בחירה',
  menuWidth = '100%',
}: SelectProps) => {
  const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, getItemProps, selectItem } =
    useSelect({
      items: data,
      itemToString: (item) => {
        return item?.key || '';
      },
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem) {
          onChange(selectedItem);
        }
      },
      initialSelectedItem: data.find((d) => d.value == initialSelectedItem?.value) || null,
      onIsOpenChange: (changes) => {
        if (!changes.isOpen && setTouched) {
          setTouched();
        }
      },
    });

  useEffect(() => {
    if (initialSelectedItem && initialSelectedItem.value !== selectedItem?.value) {
      selectItem(initialSelectedItem);
    }
  }, [initialSelectedItem]);

  const getTopOffset = () => {
    if (label) {
      return size === 'lg' ? '80px' : '72px';
    }

    return size === 'lg' ? '58px' : '46px';
  };

  return (
    <Box position="relative">
      {label && (
        <Text textStyle="medium" color="base.600" mb="4px" {...getLabelProps()}>
          {label}
          {isRequired && <RequiredIndicator />}
        </Text>
      )}
      <Box
        type="button"
        {...getToggleButtonProps()}
        border="1px solid"
        borderColor={touched && error ? 'error.100' : isOpen ? 'base.1000' : 'base.300'}
        borderRadius={size === 'sm' ? '8px' : '12px'}
        w="100%"
        minH={size === 'sm' ? '38px' : '50px'}
        bg="white"
        color={selectedItem?.key ? 'base.1000' : 'base.600'}
        textStyle="large"
        p={size === 'sm' ? '8px' : '14px'}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        cursor="pointer"
        tabIndex="0"
        _hover={{
          borderColor: touched && error ? 'error.100' : isOpen ? 'base.1000' : 'base.600',
        }}
        _focus={{
          borderColor: touched && error ? 'error.100' : 'base.1000',
        }}
        _active={{
          borderColor: touched && error ? 'error.100' : 'base.1000',
        }}
      >
        <Flex gap="4px" alignItems="center">
          {!selectedItem?.key && placeholder}
          {selectedItem?.icon && selectedItem?.icon}
          {selectedItem?.key || <Box />}
        </Flex>
        <ChevronDownIcon
          color="base.900"
          w="16px"
          h="16px"
          marginInlineEnd="0px"
          transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}
        />
      </Box>
      <Box
        as="ul"
        {...getMenuProps()}
        position="absolute"
        top={getTopOffset()}
        backgroundColor="white"
        boxShadow="0px 4px 20px 0px rgba(92, 109, 145, 0.08)"
        _focus={{
          // override globals
          boxShadow: '0px 4px 20px 0px rgba(92, 109, 145, 0.08) !important',
        }}
        borderRadius={size === 'sm' ? '8px' : '12px'}
        border="1px solid"
        borderColor="base.300"
        listStyleType="none"
        maxH="250px"
        width={menuWidth}
        overflowY="auto"
        display={isOpen ? 'block' : 'none'}
        zIndex="dropdown"
        p="12px"
        _focusVisible={{ outline: 'none' }}
      >
        {isOpen &&
          data.map((item: any, index) => (
            <Box as="li" key={`${item}${index}`} {...getItemProps({ item, index })}>
              <DropListItem
                label={
                  <Flex gap="4px" alignItems="center">
                    {item.icon && item.icon}
                    <Text color="base.1000">{item.key}</Text>
                  </Flex>
                }
                size={size}
                isSelected={selectedItem?.value === item.value}
              />
            </Box>
          ))}
      </Box>
      {error && touched && (
        <Text textStyle="small" color="error.100" mt="8px">
          {error}
        </Text>
      )}
      {helperText && (!error || !touched) && (
        <Text textStyle="small" color="base.600" mt="8px">
          {helperText}
        </Text>
      )}
      {maintainHeight && !helperText && (!error || !touched) && (
        <Box textStyle="small" color="base.600" mt="8px" h="18px" />
      )}
    </Box>
  );
};
