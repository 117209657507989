import { Button, Flex, Text, UnorderedList, ListItem } from '@chakra-ui/react';

import { Dialog } from '../../../components/Dialog';

export const CancelSubscriptionModal = ({
  endDate,
  onCancel,
  onClose,
  isLoading,
}: {
  endDate: string;
  onCancel: () => void;
  onClose: () => void;
  isLoading: boolean;
}) => {
  return (
    <Dialog title="ביטול מנוי" onClose={onClose} size="medium">
      <Flex direction="column" alignItems="center" px="60px" gap="35px" mb="45px">
        <Text fontSize="lg">מודים שאנחנו קצת עצובים אבל מכבדים את ההחלטה שלך לבטל את המינוי. מה זה אומר עכשיו?</Text>
        <UnorderedList
          listStyleType="none"
          fontSize="lg"
          paddingInlineStart="5px"
          sx={{
            '& > li': {
              position: 'relative',
            },
            '& > li:before': {
              content: "''",
              display: 'block',
              position: 'absolute',
              right: '-20px',
              top: '8px',
              opacity: 1,
              borderRadius: '50%',
              width: '10px',
              height: '10px',
              backgroundColor: 'accent.100',
            },
          }}
        >
          <ListItem>החשבון שלך ימשיך להיות פעיל עד לתאריך {endDate}</ListItem>
          <ListItem>התחרטת? תמיד אפשר לחדש את המינוי</ListItem>
          <ListItem>לאחר אישור הביטול, המידע שלך יישמר בחשבון לשלושה חודשים נוספים, ולאחר מכן כל המידע יימחק</ListItem>
        </UnorderedList>
      </Flex>
      <Flex justifyContent="center">
        <Button mb="32px" px="20px" onClick={onCancel} isLoading={isLoading}>
          הבנתי ואני מאשר/ת
        </Button>
      </Flex>
    </Dialog>
  );
};
