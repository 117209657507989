import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Flex, Divider, Button, Input, Text } from '@chakra-ui/react';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, sub, startOfDay, endOfDay, isSameDay } from 'date-fns';
import { he } from 'date-fns/locale';
import { ReactNode, forwardRef, useRef } from 'react';
import DatePicker, {
  registerLocale,
  CalendarContainer,
  CalendarContainerProps,
  ReactDatePickerCustomHeaderProps,
} from 'react-datepicker';

import { CalendarIcon } from '../pages/common/icons';

import { DropListItem } from './DropListItem';
import { StyledInput } from './form/StyledInput';
import { IconButton } from './IconButton';

registerLocale('he', he);

export enum TIME_PRESETS_NAMES {
  TODAY = 'היום',
  YESTERDAY = 'אתמול',
  THIS_WEEK = 'שבוע נוכחי',
  LAST_WEEK = 'שבוע שעבר',
  THIS_MONTH = 'חודש נוכחי',
  LAST_MONTH = 'חודש שעבר',
}

export const TIME_PRESETS = {
  [TIME_PRESETS_NAMES.TODAY]: {
    start: (today: Date) => startOfDay(today),
    end: (today: Date) => endOfDay(today),
  },
  [TIME_PRESETS_NAMES.YESTERDAY]: {
    start: (today: Date) => startOfDay(sub(today, { days: 1 })),
    end: (today: Date) => endOfDay(sub(today, { days: 1 })),
  },
  [TIME_PRESETS_NAMES.THIS_WEEK]: {
    start: (today: Date) => startOfWeek(today),
    end: (today: Date) => endOfWeek(today),
  },
  [TIME_PRESETS_NAMES.LAST_WEEK]: {
    start: (today: Date) => startOfWeek(sub(startOfWeek(today), { weeks: 1 })),
    end: (today: Date) => endOfWeek(sub(today, { weeks: 1 })),
  },
  [TIME_PRESETS_NAMES.THIS_MONTH]: {
    start: (today: Date) => startOfMonth(today),
    end: (today: Date) => endOfMonth(today),
  },
  [TIME_PRESETS_NAMES.LAST_MONTH]: {
    start: (today: Date) => startOfMonth(sub(today, { months: 1 })),
    end: (today: Date) => endOfMonth(sub(today, { months: 1 })),
  },
};

interface IDateRangePickerProps {
  selectedStartTime: Date | null;
  selectedEndTime: Date | null;
  allowEmpty?: boolean;
  onDateSelect(s: Date | null, e: Date | null): void;
  size?: 'sm' | 'lg';
}

export const DateRangePicker = ({
  selectedStartTime,
  selectedEndTime,
  onDateSelect,
  allowEmpty,
  size = 'lg',
}: IDateRangePickerProps) => {
  let datePickerRef = useRef<DatePicker<never, true>>(null);

  const handlePresetSelect = (preset: TIME_PRESETS_NAMES) => {
    const today = startOfDay(new Date());

    const start = TIME_PRESETS[preset].start(today);
    const end = TIME_PRESETS[preset].end(today);
    onDateSelect(start, end);
    datePickerRef.current?.setOpen(false);
  };

  const getSelectionPreset = () => {
    const today = startOfDay(new Date());

    const preset = Object.entries(TIME_PRESETS).find(
      ([_, preset]) =>
        selectedStartTime &&
        isSameDay(preset.start(today), selectedStartTime) &&
        selectedEndTime &&
        isSameDay(preset.end(today), selectedEndTime),
    );
    return preset?.[0] || '';
  };

  const clearSelection = () => {
    onDateSelect(null, null);
    datePickerRef.current?.setOpen(false);
  };

  const DateRangeInput = forwardRef(
    (
      { value, onClick, onChange, error, touched, isRequired, maintainHeight, label, setTouched, size, ...rest }: any,
      ref: any,
    ) => {
      return (
        <StyledInput
          startIcon={<CalendarIcon color="base.500" />}
          name="date-picker"
          w="200px"
          onClick={onClick}
          label={label}
          error={error}
          touched={touched}
          maintainHeight={maintainHeight}
          onChange={onChange}
          isRequired={isRequired}
          ref={ref}
          size={size}
          value={getSelectionPreset() || value}
          {...rest}
          onBlur={setTouched}
          placeholder="תקופת תאריכים"
        />
      );
    },
  );

  return (
    <DatePicker
      ref={datePickerRef}
      renderCustomHeader={(props) => <MyHeader {...props} />}
      selected={selectedStartTime}
      onChange={(dates) => {
        const [start, end] = dates;
        onDateSelect(start, end);
      }}
      startDate={selectedStartTime}
      endDate={selectedEndTime}
      dateFormat="dd.MM.yyyy"
      locale="he"
      selectsRange
      customInput={<DateRangeInput size={size} />}
      monthsShown={2}
      calendarContainer={(props: any) => (
        <MyContainer
          {...props}
          selectedPreset={getSelectionPreset()}
          onPresetSelect={handlePresetSelect}
          allowEmpty={allowEmpty}
          clearSelection={clearSelection}
        />
      )}
    />
  );
};

interface MyContainerProps extends CalendarContainerProps {
  className: string;
  children: ReactNode;
  onPresetSelect(preset: TIME_PRESETS_NAMES): void;
  selectedPreset: TIME_PRESETS_NAMES;
  allowEmpty: boolean;
  clearSelection(): void;
}

const MyContainer = ({
  className,
  children,
  onPresetSelect,
  selectedPreset,
  allowEmpty,
  clearSelection,
}: MyContainerProps) => {
  return (
    <Flex
      boxShadow="0px 0px 15px rgba(39, 12, 67, 0.12)"
      bg="white"
      border="1px solid"
      borderColor="#e6e9f2"
      borderRadius="14px"
      minH="386px"
      p="24px"
    >
      <Flex direction="column" w="190px" alignItems="flex-start" gap="12px" marginInlineEnd="5px">
        {Object.keys(TIME_PRESETS).map((preset: any) => (
          <DropListItem
            key={preset}
            label={preset}
            isSelected={preset === selectedPreset}
            onClick={() => onPresetSelect(preset)}
          />
        ))}
        {allowEmpty && (
          <Button variant="secondary" size="sm" w="100%" onClick={clearSelection}>
            ניקוי בחירה
          </Button>
        )}
      </Flex>
      <Divider orientation="vertical" borderColor="base.200" minH="338px" marginInline="24px" />
      <CalendarContainer className={className}>{children}</CalendarContainer>
    </Flex>
  );
};

const MyHeader = ({ monthDate, customHeaderCount, decreaseMonth, increaseMonth }: ReactDatePickerCustomHeaderProps) => (
  <Flex justifyContent="space-between" alignItems="center" mb="24px">
    <IconButton
      size="sm"
      color="success"
      aria-label="Previous Month"
      onClick={decreaseMonth}
      visibility={customHeaderCount === 0 ? 'visible' : 'hidden'}
      icon={<ChevronRightIcon />}
    />
    <Text textStyle="extraLarge">
      {monthDate.toLocaleString('he', {
        month: 'long',
        year: 'numeric',
      })}
    </Text>
    <IconButton
      size="sm"
      color="success"
      aria-label="Next Month"
      onClick={increaseMonth}
      visibility={customHeaderCount === 1 ? 'visible' : 'hidden'}
      icon={<ChevronLeftIcon />}
    />
  </Flex>
);
