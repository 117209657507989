import { createIcon } from "@chakra-ui/icons";

export const UserIcon = createIcon({
  displayName: "UserIcon",
  viewBox: "0 0 18 16",
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M9.25 0.5C7.17893 0.5 5.5 2.17893 5.5 4.25C5.5 6.32107 7.17893 8 9.25 8C11.3211 8 13 6.32107 13 4.25C13 2.17893 11.3211 0.5 9.25 0.5Z"
    />,
    <path
      key="1"
      fill="currentColor"
      d="M7.75 9.5C5.67893 9.5 4 11.1789 4 13.25C4 14.4926 5.00736 15.5 6.25 15.5H12.25C13.4926 15.5 14.5 14.4926 14.5 13.25C14.5 11.1789 12.8211 9.5 10.75 9.5H7.75Z"
    />,
  ],
});
