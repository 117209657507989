import { action, computed, makeObservable, observable, autorun } from 'mobx';
import { NavigationOptions, Router, State } from 'router5';

import { Project } from '../models/Project';
import { RootStore, IStore } from '../models/RootStore';
import { WorkHour } from '../models/WorkHour';
import { AuthenticationViewModel } from '../pages/authentication/AuthenticationViewModel';
import { CreateCustomerViewModel } from '../pages/customer/createCustomer/CreateCustomerViewModel';
import { EditWorkHourViewModel } from '../pages/workHour/editWorkHour/EditWorkHourViewModel';
import { CreateWorkHourViewModel } from '../pages/workHour/timeTracker/CreateWorkHourViewModel';

import { CreateProjectViewModel } from './../pages/projects/createProject/CreateProjectViewModel';
import { makeMobxRouter } from './Router';
import {
  IRoute,
  Routes,
  LinkData,
  LoginRoute,
  InsightsRoute,
  CreateProjectRoute,
  EditProjectRoute,
  SingleProjectRoute,
  CreateCustomerRoute,
  EditCustomerRoute,
} from './routes';

export class RoutingStore {
  store: RootStore;
  router: Router;
  route: IRoute;
  currentViewModel: IStore | null;

  routes: Routes;

  _createWorkHourViewModel: CreateWorkHourViewModel | undefined;
  _editWorkHourViewModel: EditWorkHourViewModel | undefined;
  isCreatingTask: boolean;
  editTaskId: number | null;
  defaultTaskProject: string | null;
  isSubscriptionDialogOpen: boolean;
  helpDialogOpen: boolean;
  leaveProjectEditDialogTo: State | null;
  leaveCustomerEditDialogTo: State | null;

  constructor(store: RootStore, routes: Routes) {
    this.route = InsightsRoute;
    this.routes = routes;
    this.store = store;
    this.currentViewModel = null;
    this.isCreatingTask = false;
    this.editTaskId = null;
    this.defaultTaskProject = null;
    this.isSubscriptionDialogOpen = false;
    this.helpDialogOpen = false;
    this.leaveProjectEditDialogTo = null;
    this.leaveCustomerEditDialogTo = null;

    this.router = makeMobxRouter(this.routes, store, this);
    this.router.start();
    this.syncUrl();
    this.setupMiddleware();

    makeObservable(this, {
      store: observable,
      route: observable,
      _createWorkHourViewModel: observable,
      _editWorkHourViewModel: observable,
      currentViewModel: observable,

      setCurrentViewModel: action,
      setEditWorkHour: action,
      closeEditWorkHour: action,

      isCreatingTask: observable,
      setIsCreatingTask: action,
      projects: computed,
      setDefaultTaskProject: action,
      defaultTaskProject: observable,

      renderScreen: computed,
      createWorkHourViewModel: computed,

      isSubscriptionDialogOpen: observable,
      setIsSubscriptionDialogOpen: action,

      leaveProjectEditDialogTo: observable,
      setLeaveProjectEditDialogTo: action,

      leaveCustomerEditDialogTo: observable,
      setLeaveCustomerEditDialogTo: action,

      helpDialogOpen: observable,
      setHelpDialogOpen: action,
    });
  }

  get renderScreen() {
    if (!this.currentViewModel) {
      return null;
    }
    return this.routes[this.route.name].component(this.currentViewModel);
  }

  navigate = (linkData: LinkData, options?: NavigationOptions) => {
    const { name, params } = linkData;
    this.router.navigate(name, params, options || {});
  };

  setCurrentViewModel(viewModel: IStore) {
    this.currentViewModel = viewModel;
  }

  setEditWorkHour(workHour: WorkHour, project?: Project) {
    if (this.store.getInitializedStore) {
      this._editWorkHourViewModel = new EditWorkHourViewModel(
        workHour,
        this.store.getInitializedStore.projectsModel,
        this.store.getInitializedStore.customersModel,
        this.closeEditWorkHour,
        project,
      );
    }
  }

  closeEditWorkHour = () => {
    this._editWorkHourViewModel = undefined;
  };
  get editWorkHourViewModel() {
    return this._editWorkHourViewModel;
  }

  get authenticationViewModel() {
    return new AuthenticationViewModel(this.store.initialize, this.store.authenticationModel, this);
  }

  get createWorkHourViewModel() {
    if (this.store.getInitializedStore) {
      return new CreateWorkHourViewModel(this.store.getInitializedStore.projectsModel);
    }
    return undefined;
  }

  setIsCreatingTask = (state: boolean, id?: number, projectId?: string) => {
    this.isCreatingTask = state;
    this.editTaskId = id || null;
    this.setDefaultTaskProject(projectId);
  };

  setDefaultTaskProject = (projectId?: string) => {
    if (projectId) {
      this.defaultTaskProject = projectId;
    } else if (this.route.name === SingleProjectRoute.name) {
      this.defaultTaskProject = window.location.pathname.split('/').pop() || null;
    } else {
      this.defaultTaskProject = null;
    }
  };

  get projects() {
    return this.store.getInitializedStore?.projectsModel.projects.map((p) => ({
      key: p.name,
      value: p._id,
    }));
  }

  setIsSubscriptionDialogOpen = (state: boolean) => {
    this.isSubscriptionDialogOpen = state;
  };

  setHelpDialogOpen = (state: boolean) => {
    this.helpDialogOpen = state;
  };

  setLeaveProjectEditDialogTo = (navigateTo: State | null) => {
    this.leaveProjectEditDialogTo = navigateTo;
  };

  setLeaveCustomerEditDialogTo = (navigateTo: State | null) => {
    this.leaveCustomerEditDialogTo = navigateTo;
  };

  logout = () => {
    this.store.logout();
    this.navigate(LoginRoute);
  };

  createEditProjectMiddleware = () => (toState: State, fromState: State) => {
    const isCreateEditRoute = fromState?.name === CreateProjectRoute.name || fromState?.name === EditProjectRoute.name;

    if (toState && isCreateEditRoute && (this.currentViewModel as CreateProjectViewModel).isDirty) {
      this.setLeaveProjectEditDialogTo(toState);
      return false;
    }
    return true;
  };

  createEditCustomerMiddleware = () => (toState: State, fromState: State) => {
    const isCreateEditRoute =
      fromState?.name === CreateCustomerRoute.name || fromState?.name === EditCustomerRoute.name;

    if (toState && isCreateEditRoute && (this.currentViewModel as CreateCustomerViewModel).isDirty) {
      this.setLeaveCustomerEditDialogTo(toState);
      return false;
    }
    return true;
  };

  setupMiddleware = () => {
    this.router.useMiddleware(this.createEditProjectMiddleware);
    this.router.useMiddleware(this.createEditCustomerMiddleware);
  };

  syncUrl = () => {
    autorun(() => {
      if (!this.router.getState()) {
        this.navigate(this.route);
      }
    });
  };
}
