import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { ReactNode, useState, useEffect } from 'react';

interface TabGroupProps {
  items: {
    [s: string]: ReactNode | null;
  };
  index?: number;
  onChange?: (i: number) => void;
}
export const TabGroup = ({ items, index, onChange }: TabGroupProps) => {
  const [selectedTab, setSelectedTab] = useState(index || 0);

  useEffect(() => {
    if (index !== undefined) {
      setSelectedTab(index);
    }
  }, [index]);

  const handleChange = (index: number) => {
    if (onChange) {
      onChange(index);
    }
    setSelectedTab(index);
  };
  return (
    <Tabs index={selectedTab} onChange={handleChange} colorScheme="gray">
      <TabList id="ignore-me">
        {Object.keys(items).map((label) => (
          <Tab key={label} isDisabled={!items[label]}>
            {label}
          </Tab>
        ))}
      </TabList>

      <TabPanels>
        {Object.values(items).map((comp, i) => (
          <TabPanel key={i}>{comp}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
