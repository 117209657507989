import { Bar } from 'react-chartjs-2';

import { formatToCompactNotation } from '../../../utils/number/numberUtils';

export const options: any = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      top: 30,
    },
  },
  elements: {
    bar: {
      borderRadius: 4,
      maxBarThickness: 30,
      barThickness: 30,
    },
  },
  scales: {
    yAxis: {
      display: false,
    },
    xAxis: {
      ticks: {
        color: '#160825',
        font: {
          family: 'Ploni',
          size: 16,
        },
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
    datalabels: {
      color: '#160825',
      anchor: 'end',
      align: 'end',
      textAlign: 'center',
      font: {
        family: 'Ploni',
        size: 18,
      },
      formatter: function (value: string) {
        const num = parseInt(value, 10);
        return formatToCompactNotation(num);
      },
    },
    legend: {
      display: false,
    },
  },
};

function colorize(activeIndex: number) {
  return (ctx: any) => (ctx.dataIndex === activeIndex ? '#2EDEBF' : '#EEEFF5');
}

const baseGraphData = {
  labels: [],
  datasets: [
    {
      label: 'Dataset 1',
      yAxisID: 'yAxis',
      xAxisID: 'xAxis',
      data: [],
      backgroundColor: colorize(0),
      hoverBackgroundColor: colorize(0),
      barThickness: 40,
      maxBarThickness: 40,
    },
  ],
};

export function GoalBarChart({ data }: { data: Array<{ label: string; data: number }>; includeGrid?: boolean }) {
  const graphData = {
    ...baseGraphData,
    labels: data.map((item) => item.label),
    datasets: [
      {
        ...baseGraphData.datasets[0],
        data: data.map((item) => item.data),
      },
    ],
  };

  return <Bar options={options} data={graphData} />;
}
