import { Box } from '@chakra-ui/react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useRef, useEffect } from 'react';

import logoLoader from './logo_loader.json';

export const Loader = () => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(45);
    }
  }, [lottieRef.current]);

  return (
    <Box h="100vh" display="flex" justifyContent="center" alignItems="center" data-testid="Loader">
      <Box h="150px" w="150px" transform="rotate(-90deg)">
        <Lottie animationData={logoLoader} loop={true} lottieRef={lottieRef} />
      </Box>
    </Box>
  );
};
