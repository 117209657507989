import {
  Box,
  Flex,
  Text,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
  Tooltip,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import { useUserData } from '../../models/userData/useUserData';
import { LogoutIcon } from '../../pages/common/icons';

import { InitialsAvatar } from './InitialsAvatar';

export const ProfileLink = ({ handleLogout, iconOnly }: { handleLogout: () => void; iconOnly: boolean }) => {
  const initialFocusRef = useRef(null);
  const openedMenuRef = useRef(null);
  const { userData } = useUserData();
  const { isOpen, onToggle, onClose } = useDisclosure();
  useOnClickOutside(openedMenuRef, onClose);

  return (
    <Popover isOpen={isOpen} initialFocusRef={initialFocusRef} placement="left-end" offset={[0, 12]}>
      <Tooltip
        hasArrow
        placement="left"
        label={iconOnly && !isOpen ? 'פרופיל' : ''}
        bg="white"
        fontSize="lg"
        p="8px"
        color="base.900"
        fontWeight="normal"
        borderRadius="8px"
        gutter={10}
      >
        <Box>
          <PopoverTrigger>
            <Flex
              p="8px"
              gap="12px"
              onClick={onToggle}
              alignItems="cetner"
              cursor="pointer"
              borderRadius="8px"
              _hover={{
                bg: 'base.800',
              }}
            >
              <InitialsAvatar isSelected={isOpen} />
              {!iconOnly && (
                <Text fontSize="xl" color={isOpen ? 'white' : '#9286AB'}>
                  פרופיל
                </Text>
              )}
            </Flex>
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <Portal>
        <PopoverContent
          border="none"
          w="206px"
          p="10px"
          borderRadius="8px"
          boxShadow="0px 4px 12px 0px rgba(22, 8, 37, 0.20)"
        >
          <PopoverArrow />
          <PopoverBody p="0">
            <Flex px="8px" gap="8px" pb="16px" borderBottom="1px solid" borderColor="#DEE0EF" ref={openedMenuRef}>
              <Box w="28px" mt="5px">
                <InitialsAvatar />
              </Box>
              <Flex direction="column" maxW="140px">
                <Text noOfLines={1} display="block" whiteSpace="nowrap" title={userData?.email} fontSize="xl">
                  {userData?.username}
                </Text>
                <Text fontSize="xs" noOfLines={1} display="block" whiteSpace="nowrap" title={userData?.email}>
                  {userData?.email}
                </Text>
              </Flex>
            </Flex>
            <Flex
              ref={initialFocusRef}
              p="8px"
              gap="12px"
              my="8px"
              alignItems="center"
              fontSize="xl"
              onClick={handleLogout}
              cursor="pointer"
              borderRadius="8px"
              _hover={{
                bg: 'base.50',
              }}
              transition=".2s"
            >
              <LogoutIcon fontSize="md" />
              <Text fontSize="xl">התנתקות</Text>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
