import { Flex, Heading, Button, Text, Box } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { FormInput } from '../../../components/form/FormInput';
import { auth0Login } from '../../../utils/auth0';
import { ExclamationLightIcon } from '../../common/icons';
import { AuthenticationViewModel, ILoginFormValues, VIEW_TYPE } from '../AuthenticationViewModel';

export const Login = observer(({ viewModel }: { viewModel: AuthenticationViewModel }) => {
  const [error, setError] = useState('');
  const initialValues: ILoginFormValues = {
    email: '',
    password: '',
  };

  const isRegularLogin = viewModel.currentView === VIEW_TYPE.LOGIN;
  const title = isRegularLogin ? 'כיף לראות אותך' : 'נהדר, בוא נמשיך';

  return (
    <>
      <Heading textStyle={{ base: 'h3', md: 'h2' }} mb="65px">
        {title}
      </Heading>
      <Formik
        initialValues={initialValues}
        validate={(values) => viewModel.validateLogin(values)}
        onSubmit={async (values) => {
          try {
            await auth0Login(values.email, values.password);
          } catch (err) {
            // @ts-ignore
            if (err?.description === 'Wrong email or password.') {
              setError('אחד מפרטי הכניסה שלך לא נכונים');
            } else {
              setError('ארעה שגיאה');
            }
          }
        }}
      >
        {(props) => (
          <Form>
            <Flex direction="column" justifyContent="center">
              <Box mb="24px">
                <FormInput isRequired name="email" label="מייל" type="email" />
              </Box>
              <Box display="flex" flexDirection="column" mb="24px">
                <FormInput isRequired name="password" label="סיסמא" type="password" />
                <Button
                  variant="link"
                  alignSelf="end"
                  onClick={() => viewModel.setCurrentView(VIEW_TYPE.RESET_PASSWORD)}
                  fontSize="md"
                >
                  שכחת סיסמא?
                </Button>
              </Box>
              <Button isLoading={props.isSubmitting} type="submit" w="100%" isDisabled={!props.isValid} mb="22px">
                כניסה
              </Button>
              {error ? (
                <Flex textStyle="small" mb="40px" color="error.100" alignItems="center">
                  <ExclamationLightIcon marginInlineEnd="10px" mb="2px" />
                  <Text>{error}</Text>
                </Flex>
              ) : (
                <Box h="20px" />
              )}
              {isRegularLogin && (
                <Button variant="link" whiteSpace="normal" onClick={() => viewModel.setCurrentView(VIEW_TYPE.SIGNUP)}>
                  עדיין לא הצטרפת? מתחילים כאן עם 30 יום ניסיון חינם
                </Button>
              )}
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
});
