import { ID_TOKEN } from '../models/AuthenticationModel';
import { logger } from '../utils/logger';

type IMethod = 'POST' | 'GET' | 'PATCH' | 'DELETE';

export async function fetcher(url: string, body: string, method: IMethod = 'POST') {
  const token = window.localStorage.getItem(ID_TOKEN);
  if (!token) {
    logger.log('missing token!!!');
    // handleLogout();
    return;
  }

  return fetch(url, {
    method,
    body: method !== 'GET' ? body : undefined,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then(async (res) => {
      if (!res.ok) {
        if (res.status === 401) {
          logger.log('token expired');
          handleLogout();
          return;
        }

        try {
          const content = await res.json();
          throw new Error(JSON.stringify(content));
        } catch (e) {
          logger.error('failed to parse json response', e);
          throw e;
        }
      }

      if (res.headers.get('content-type')?.includes('application/json')) {
        return res.json();
      }
      return res.text();
    })
    .catch((e) => {
      logger.error('odd error occurred:', e);
      throw e;
    });
}

function handleLogout() {
  window.localStorage.removeItem(ID_TOKEN);
  window.location.reload();
}

// old token for testing purposes:
// eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImliMDlEMGNwQ19Fc2psUUl1VXNwRiJ9.eyJuaWNrbmFtZSI6Imd1eS53YXJidXJnK2FsemUwIiwibmFtZSI6IteS15nXkCDXlSIsInBpY3R1cmUiOiJodHRwczovL3MuZ3JhdmF0YXIuY29tL2F2YXRhci82MjIyNDljNWI5MGRlNGEzNDY5NzNjZTUxNzgxMjczZD9zPTQ4MCZyPXBnJmQ9aHR0cHMlM0ElMkYlMkZjZG4uYXV0aDAuY29tJTJGYXZhdGFycyUyRmd1LnBuZyIsInVwZGF0ZWRfYXQiOiIyMDIyLTEyLTAyVDIxOjAxOjI5Ljc4NloiLCJlbWFpbCI6Imd1eS53YXJidXJnK2FsemUwQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJpc3MiOiJodHRwczovL2F1dGguYWx6ZS5tZS8iLCJzdWIiOiJhdXRoMHw2MzRjMzlhOTExZjY5ZmE4OTQ2YmY5Y2UiLCJhdWQiOiJGd0xhQ0FhY01xcXBYTVJKbnBaVmozZkFyU2Nob0swTyIsImlhdCI6MTY3MDAxNDg5MCwiZXhwIjoxNjcwNjE5NjkwLCJhdF9oYXNoIjoiZUMyWXlKbzRmRVVsejRrZEU4dkxiUSIsIm5vbmNlIjoiazZodE9EQkJ4TlFOQ0RWSEdGLmtPN0NuRlRBcXJxNVkifQ.X3ydzFxFEKCL1b-yO6FhJW7AxGJof0tT-FuDbVshekKIpedeT79NpV5jt3hRoYriFvk7ckuzjFzDasuscRti-NK4vc94tTTp6pZGE0ypVpqGhkcRjklIAA4QZe1m_xtyKnz_S8lFt9h0tRA0CdrPrRbsDYEA_XgAVYVCbhMgsWJovNrsMDHUy_obus-OdT3A8IWfbu8QTP3pXpBUp031jav2hd3sJoVxQCRcfEeurCnztAbDDgJfTawfV5s_nGaWOSrOZEiRA7T_nkQa4phHLLN3OXRpuZo_0LgIXutczc88iQejxn5m3OXrdms7q8gXj_soEGtsAQ5fI9m_2Ir1zg
