import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar, defineStyle } from '@chakra-ui/styled-system';
import { getColor, mode } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  field: {
    width: '100%',
    minWidth: 0,
    outline: 'none',
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
  },
});

const size = {
  lg: defineStyle({
    fontSize: 'lg',
    px: 4,
    h: 12,
    borderRadius: 'md',
  }),

  md: defineStyle({
    fontSize: '18px',
    px: 4,
    h: 10,
    borderRadius: 'md',
  }),

  sm: defineStyle({
    fontSize: 'sm',
    px: 3,
    h: 8,
    borderRadius: 'sm',
  }),

  xs: defineStyle({
    fontSize: 'xs',
    px: 2,
    h: 6,
    borderRadius: 'sm',
  }),
};

const sizes = {
  lg: definePartsStyle({
    field: size.lg,
    addon: size.lg,
  }),
  md: definePartsStyle({
    field: size.md,
    addon: size.md,
  }),
  sm: definePartsStyle({
    field: size.sm,
    addon: size.sm,
  }),
  xs: definePartsStyle({
    field: size.xs,
    addon: size.xs,
  }),
};

function getDefaults(props: Record<string, any>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode('brand.600', 'brand.600')(props),
    errorBorderColor: ec || mode('accent.100', 'accent.100')(props),
  };
}

const variantOutline = definePartsStyle((props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      border: '1px solid',
      borderColor: 'base.300',
      bg: 'white',
      boxShadow: 'none',
      _hover: {
        borderColor: 'base.1000',
        boxShadow: 'none',
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        boxShadow: 'none',
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: 'none',
        outline: 'none',
      },
      _focus: {
        border: '2px solid',
        zIndex: 1,
        borderColor: 'base.1000',
        boxShadow: 'none',
      },
    },
  };
});

const variantFilled = definePartsStyle((props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      border: '2px solid',
      borderColor: 'transparent',
      bg: mode('gray.100', 'whiteAlpha.50')(props),
      _hover: {
        bg: mode('gray.200', 'whiteAlpha.100')(props),
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _invalid: {
        borderColor: getColor(theme, ec),
      },
      _focus: {
        bg: 'transparent',
        borderColor: getColor(theme, fc),
      },
    },
    addon: {
      border: '2px solid',
      borderColor: 'transparent',
      bg: mode('gray.100', 'whiteAlpha.50')(props),
    },
  };
});

const variantFlushed = definePartsStyle((props) => {
  return {
    field: {
      borderBottom: '1px solid',
      borderColor: 'base.300',
      borderRadius: 0,
      px: 0,
      bg: 'transparent',
      outline: 'none',
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all',
      },
      _hover: {
        borderColor: 'base.900',
      },
      _focus: {
        borderColor: 'base.1000',
        borderBottom: '2px solid',
        boxShadow: 'none !important',
      },
      _invalid: {
        borderColor: 'accent.100 !important',
        boxShadow: 'none !important',
      },
    },
    addon: {
      borderBottom: '2px solid',
      color: 'blue !important',
      borderColor: 'inherit',
      borderRadius: 0,
      px: 0,
      bg: 'transparent',
    },
  };
});

const variantUnstyled = definePartsStyle({
  field: {
    bg: 'transparent',
    px: 0,
    height: 'auto',
  },
  addon: {
    bg: 'transparent',
    px: 0,
    height: 'auto',
  },
});

const variants = {
  outline: variantOutline,
  filled: variantFilled,
  flushed: variantFlushed,
  unstyled: variantUnstyled,
};

export const Input = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'flushed',
  },
});
