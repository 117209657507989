import { Field, FieldProps, getIn } from 'formik';

import { Select, SelectProps, SelectItem } from './Select';

interface FormSelectProps extends Omit<SelectProps, 'onChange'> {
  name: string;
  onChange?: (data: SelectItem) => void;
}

export const FormSelect = ({
  name,
  label,
  data,
  helperText,
  size,
  isRequired,
  onChange,
  placeholder,
}: FormSelectProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<string>) => {
        const selectedItem = data.find((item) => item.value == field.value);
        return (
          <Select
            label={label}
            data={data}
            isRequired={Boolean(isRequired)}
            helperText={helperText}
            error={getIn(form.errors, name)}
            touched={getIn(form.touched, name)}
            onChange={(data) => {
              form.setFieldValue(name, data.value);
              onChange?.(data);
            }}
            setTouched={() => form.setFieldTouched(name, true)}
            selectedItem={selectedItem}
            maintainHeight
            placeholder={placeholder}
            size={size}
          />
        );
      }}
    </Field>
  );
};
