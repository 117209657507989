import { Stack, Divider, Grid, Flex } from '@chakra-ui/react';
import { format } from 'date-fns';

import { StackedCards } from '../../../components/StackedCards';
import { useVat } from '../../../hooks/useVat/useVat';
import { Project } from '../../../models/Project';
import { displayProjectStatuses } from '../../../models/ProjectUtils';
import { PROJECT_TYPE } from '../../../requests/Client';
import { DataPoint } from '../../common/DataPoint';
import { EmptyBlurb } from '../../common/errors/EmptyBlurb';
import { NoTasksIcon } from '../../common/icons';

export const SingleCustomerProjects = ({
  projectsByYear,
  createProject,
}: {
  projectsByYear: { [year: string]: Project[] };
  createProject(): void;
}) => {
  const { getValueWithVat } = useVat();

  if (Object.keys(projectsByYear).length === 0) {
    return (
      <Flex justifyContent="center" alignItems="center" direction="column" textAlign="center" mt="95px">
        <EmptyBlurb
          title="אין לך פרויקטים עדיין"
          body="מיד לאחר יצירת פרויקט עם הלקוח הוא יופיע כאן"
          icon={<NoTasksIcon fontSize="72px" mb="16px" />}
          button={{
            label: 'יצירת פרויקט',
            onClick: createProject,
          }}
        />
      </Flex>
    );
  }

  return (
    <Stack>
      {Object.keys(projectsByYear)
        .sort((a, b) => Number(b) - Number(a))
        .map((year) => (
          <StackedCards
            key={year}
            title={year}
            items={projectsByYear[year].map((project) => (
              <Grid key={project._id} templateColumns={`100px 150px 80px 80px 150px 3px 80px`} gap="45px">
                <DataPoint label="ת.התחלה" value={format(project._startDate, 'dd.MM.yyyy')} />
                <DataPoint label="שם הפרויקט" value={project.name} />
                <DataPoint label="סוג השירות" value={project.service} />
                <DataPoint label="סוג החוזה" value={project.type === PROJECT_TYPE.FIXED ? 'פיקס' : 'שעתי'} />
                <DataPoint label="סה״כ הכנסות" value={getValueWithVat(project.totalValue).toLocaleString()} />
                <Divider h="100%" orientation="vertical" />
                <DataPoint label="סטטוס" value={displayProjectStatuses[project.status]} />
              </Grid>
            ))}
          />
        ))}
    </Stack>
  );
};
