import { Box, Spacer } from '@chakra-ui/react';
import Select, { StylesConfig } from 'react-select';

import { CustomerStatus, CustomerStatuses } from '../models/Customer';
import { TaskStatus } from '../models/tasks/tasksApi';
import { statusOptions } from '../models/tasks/taskUtils';
import { CheckIcon, ChevronDownIcon } from '../pages/common/icons';
import { colors } from '../theme/colors';

function getStatusColor(status: string) {
  switch (status) {
    case TaskStatus.TODO:
      return {
        dot: colors.accent[100],
        hover: colors.accent[25],
        bg: colors.accent[0],
      };
    case TaskStatus.DOING:
      return {
        dot: colors.primary[100],
        hover: colors.primary[25],
        bg: colors.primary[0],
      };
    case TaskStatus.DONE:
      return {
        dot: colors.success[100],
        hover: colors.success[25],
        bg: colors.success[0],
      };
    case CustomerStatus.NEW_LEAD:
      return {
        dot: colors.primary[100],
        hover: colors.primary[25],
        bg: colors.primary[0],
      };
    case CustomerStatus.PENDING_PROPOSAL:
      return {
        dot: colors.accent[100],
        hover: colors.accent[25],
        bg: colors.accent[0],
      };
    case CustomerStatus.PROPOSAL_SENT:
      return {
        dot: colors.success[100],
        hover: colors.success[25],
        bg: colors.success[0],
      };
    case CustomerStatus.NEGOTIATION:
      return {
        dot: colors.base[600],
        hover: colors.base[300],
        bg: colors.base[200],
      };
    case CustomerStatus.CLOSED:
      return {
        dot: colors.success[300],
        hover: colors.success[50],
        bg: colors.success[25],
      };
    case CustomerStatus.DIDNT_CLOSE:
      return {
        dot: colors.error[100],
        hover: colors.error[50],
        bg: colors.error[25],
      };
    default:
      // implement project
      return {
        dot: colors.success[100],
        hover: colors.success[25],
        bg: colors.success[0],
      };
  }
}

const CustomOption = ({ children, isSelected, innerProps, getStyles, ...rest }: any) => {
  const style = getStyles('option', rest);
  return (
    <Box
      {...innerProps}
      sx={{
        ...style,
      }}
    >
      {children}
      {isSelected && (
        <>
          <Spacer />
          <CheckIcon />
        </>
      )}
    </Box>
  );
};

const CustomDropdownIndicator = ({ menuIsOpen }: any) => {
  return (
    <ChevronDownIcon
      mt="4px"
      fontSize="16px"
      color="base.1000"
      transform={menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
    />
  );
};

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: '50%',
    content: '" "',
    display: 'block',
    marginInlineEnd: '8px',
    height: '8px',
    width: '8px',
  },
});

const colourStyles: StylesConfig<StatusOption> = {
  // @ts-ignore
  control: (styles, data) => {
    const color = getStatusColor(data.getValue()[0].value);
    return {
      ...styles,
      border: '1px solid',
      borderColor: `${data.selectProps.menuIsOpen ? colors.base[1000] : color.bg} !important`,
      borderRadius: '8px',
      padding: '2px 8px',
      backgroundColor: color.bg,
      cursor: 'pointer',
      boxShadow: 'none !important',
      transition: 'none',
      ':hover': {
        backgroundColor: color.hover,
        '*': {
          backgroundColor: color.hover,
        },
      },
      ':active': {
        borderColor: `${colors.base[1000]} !important`,
      },
      ':focus': {
        borderColor: `${colors.base[1000]} !important`,
      },
    };
  },
  valueContainer: (styles) => {
    return { ...styles, transition: 'none', padding: 0 };
  },
  input: (styles, data) => ({
    ...styles,
    ...dot(),
    transition: 'none',
  }),
  placeholder: (styles) => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...dot(getStatusColor(data.value).dot),
    backgroundColor: getStatusColor(data.value).bg,
    transition: 'none',
  }),
  indicatorSeparator: () => {
    return { display: 'none' };
  },
  dropdownIndicator: (styles, data) => {
    return {
      ...styles,
      color: `${colors.base[1000]} !important`,
      transform: `${data.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'}`,
    };
  },
  menu: (styles) => {
    return { ...styles, padding: '12px', minWidth: '150px' };
  },
  option: (styles, { data, isDisabled }) => {
    const color = getStatusColor(data.value);
    return {
      ...styles,
      ...dot(color.dot),
      height: '38px',
      cursor: isDisabled ? 'not-allowed' : 'default',
      color: colors.base[1000],
      borderRadius: '8px',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: !isDisabled ? color.hover : undefined,
      },
      ':active': {
        ...styles[':active'],
        borderColor: colors.base[1000],
        backgroundColor: !isDisabled ? color.bg : undefined,
      },
      backgroundColor: color.bg,
      ':not(:last-child)': {
        marginBottom: '4px',
      },
    };
  },
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export enum StatusTypes {
  TASK = 'TASK',
  CUSTOMER = 'CUSTOMER',
  PROJECT = 'PROJECT',
}

type StatusOption = {
  key: string;
  value: string;
};

const dataMap = {
  [StatusTypes.TASK]: statusOptions,
  [StatusTypes.CUSTOMER]: CustomerStatuses,
  [StatusTypes.PROJECT]: statusOptions,
};

export const StatusDropDown = ({
  value,
  onChange,
  type = StatusTypes.TASK,
}: {
  value: string;
  onChange(v: string): void;
  type?: StatusTypes;
}) => {
  const handleChange = (option: StatusOption | null) => {
    if (option?.value) {
      onChange(option.value);
    }
  };

  const data = dataMap[type];

  const selectedValue = data.find((opt) => opt.value === value);

  return (
    <Select
      isRtl
      // @ts-ignore
      value={{ ...selectedValue, label: selectedValue?.key }}
      options={data.map((opt) => ({ ...opt, label: opt.key }))}
      isSearchable={false}
      isMulti={false}
      onChange={handleChange}
      styles={colourStyles}
      components={{
        Option: CustomOption,
        DropdownIndicator: CustomDropdownIndicator,
      }}
      menuPortalTarget={document.body}
    />
  );
};
