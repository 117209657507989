import { Icon, IconProps } from '@chakra-ui/react';

export const PencilIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <g clipPath="url(#clip0_5136_9660)">
        <path
          d="M23.436 1.14795C22.7562 0.469151 21.8347 0.0878906 20.874 0.0878906C19.9133 0.0878906 18.9919 0.469151 18.312 1.14795L2.04802 17.412C1.58227 17.8751 1.21299 18.426 0.961521 19.0327C0.710057 19.6395 0.58141 20.2901 0.583023 20.947V23C0.583023 23.2652 0.68838 23.5195 0.875916 23.7071C1.06345 23.8946 1.31781 24 1.58302 24H3.63602C4.29279 24.0018 4.94339 23.8734 5.55018 23.6221C6.15697 23.3708 6.70788 23.0016 7.17102 22.536L23.436 6.27095C24.1145 5.59115 24.4956 4.66992 24.4956 3.70945C24.4956 2.74899 24.1145 1.82776 23.436 1.14795ZM5.75702 21.122C5.19303 21.6822 4.43099 21.9977 3.63602 22H2.58302V20.947C2.58201 20.5529 2.65918 20.1625 2.81006 19.7984C2.96094 19.4343 3.18254 19.1038 3.46202 18.826L15.805 6.48295L18.105 8.78295L5.75702 21.122ZM22.021 4.85695L19.515 7.36395L17.215 5.06895L19.722 2.56195C19.873 2.41126 20.0523 2.29179 20.2495 2.21036C20.4466 2.12893 20.6579 2.08714 20.8713 2.08738C21.0846 2.08761 21.2958 2.12986 21.4929 2.21172C21.6899 2.29357 21.8688 2.41343 22.0195 2.56445C22.1702 2.71547 22.2897 2.8947 22.3711 3.09189C22.4525 3.28908 22.4943 3.50038 22.4941 3.71372C22.4939 3.92706 22.4516 4.13827 22.3698 4.33529C22.2879 4.5323 22.168 4.71126 22.017 4.86195L22.021 4.85695Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5136_9660">
          <rect width="24" height="24" fill="white" transform="translate(0.583008)" />
        </clipPath>
      </defs>
    </Icon>
  );
};
