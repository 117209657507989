import { Stack, Heading, Button, Checkbox, Text, Flex, Link, Box } from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { isMobile } from '../../../App';
import { FormInput } from '../../../components/form/FormInput';
import { RequiredIndicator } from '../../../components/form/RequiredIndicator';
import { useAffiliateLink } from '../../../models/affiliateLink/useAffiliateLink';
import { auth0Signup } from '../../../utils/auth0';
import { GiftIcon } from '../../common/icons';
import { AuthenticationViewModel, VIEW_TYPE, ISignupFormValues } from '../AuthenticationViewModel';

const BASE_FREE_DAYS = 30;
const MAOF_AFID = '5fea0ea1-2a09-474a-a23d-2990bda65be5';

export const Signup = observer(({ viewModel }: { viewModel: AuthenticationViewModel }) => {
  const [error, setError] = useState('');
  const { additionalMonths, affiliateLinkId } = useAffiliateLink();

  const initialValues: ISignupFormValues = {
    name: '',
    email: '',
    password: '',
    approvedTermsOfService: false,
  };

  const freeDays = additionalMonths ? additionalMonths * 30 + BASE_FREE_DAYS : BASE_FREE_DAYS;
  const hasGift = freeDays !== BASE_FREE_DAYS;
  const isMaof = affiliateLinkId === MAOF_AFID;
  const giftPosition = isMobile() || isMaof ? { mt: '16px' } : { position: 'absolute', top: '-4px', left: '-30px' };

  return (
    <>
      <Heading textStyle={{ base: 'h3', md: 'h2' }} mb="18px">
        הגיע הזמן להיות
        <br />
        פרילנס מוביל
      </Heading>
      <Flex wrap="wrap" position="relative" mb={{ base: '43px', md: '68px' }}>
        <Text color="accent.100" fontSize={{ base: 'lg', md: 'xl' }}>
          {hasGift && !isMaof && <Box as="s">{BASE_FREE_DAYS}</Box>} {freeDays} יום ניסיון חינם. ללא צורך באשראי.
        </Text>
        {hasGift && (
          // @ts-ignore
          <Flex
            {...giftPosition}
            alignItems="center"
            justifyContent="center"
            bg="primary.0"
            color="base.1000"
            borderRadius="16px"
            h="36px"
            px="12px"
            paddingInlineStart="8px"
            minW="150px"
            gap="8px"
            textStyle={{ base: 'small', md: 'medium' }}
          >
            <GiftIcon mb="2px" />
            <Text>הטבה מיוחדת{isMaof && ' ללקוחות לוח ההטבות BIZנס'}</Text>
          </Flex>
        )}
      </Flex>
      <Formik
        initialValues={initialValues}
        validate={(values) => viewModel.validateSignup(values)}
        onSubmit={async (values) => {
          try {
            await auth0Signup(values.email, values.password, values.name);
          } catch (err) {
            // @ts-ignore
            if (err.description === 'Invalid sign up') {
              setError('מייל קיים במערכת');
            } else {
              setError('ארעה שגיאה');
            }
          }
        }}
      >
        {(props) => (
          <Form>
            <Stack gap="24px" mb="6px">
              <FormInput isRequired name="name" label="שם מלא בעברית" />
              <FormInput
                isRequired
                name="email"
                type="email"
                label="כתובת מייל"
                helperText="לשם נשלח לך קישור לאימות והפעלת החשבון"
              />
              <FormInput
                isRequired
                name="password"
                type="password"
                label="בחירת סיסמא"
                helperText="לפחות 8 תווים הכוללים תו מיוחד, מספר, אות קטנה ואות גדולה"
              />
              <Field name="approvedTermsOfService">
                {({ field }: any) => (
                  <Box alignSelf="center">
                    <Checkbox
                      size="sm"
                      mb="16px"
                      isInvalid={props.errors.approvedTermsOfService && props.touched.approvedTermsOfService}
                      {...field}
                    >
                      <Flex wrap="wrap" h="54px" gap="4px" textStyle="medium" alignItems="center">
                        קראתי ואישרתי את
                        <Link href="https://peko.co.il/terms/" target="_blank" fontSize="md">
                          תנאי השימוש
                        </Link>
                        ואת
                        <Link href="https://peko.co.il/privacy/" target="_blank" fontSize="md">
                          מדיניות הפרטיות
                        </Link>
                        <RequiredIndicator />
                      </Flex>
                    </Checkbox>
                    {props.errors.approvedTermsOfService && props.touched.approvedTermsOfService ? (
                      <Text textStyle="small" color="error.100" mt="8px">
                        {props.errors.approvedTermsOfService}
                      </Text>
                    ) : (
                      <Box h="18px" mt="8px" />
                    )}
                  </Box>
                )}
              </Field>
              <Button isLoading={props.isSubmitting} type="submit" w="100%" isDisabled={!props.isValid}>
                המשך
              </Button>
              {error ? (
                <Text textStyle="small" color="error.100">
                  {error}
                </Text>
              ) : (
                <Box h="18px" />
              )}
            </Stack>
          </Form>
        )}
      </Formik>
      {isMaof && (
        <Flex alignItems="center" mb="32px" direction={{ base: 'column', md: 'row' }}>
          <Text fontWeight="300" fontSize="lg" marginInlineEnd="5px">
            לפרטים נוספים, אנחנו מזמינים אותך
          </Text>
          <Link target="_blank" href="https://peko.co.il">
            לבקר באתר שלנו
          </Link>
        </Flex>
      )}
      <Button w="100%" textAlign="center" variant="link" onClick={() => viewModel.setCurrentView(VIEW_TYPE.LOGIN)}>
        כבר הצטרפת? נכנסים לחשבון מכאן
      </Button>
    </>
  );
});
