import { Box } from '@chakra-ui/react';
import { Field, FieldProps, getIn } from 'formik';

import { DatePicker, DatePickerProps } from './DatePicker';

interface FormDatePickerProps extends Omit<DatePickerProps, 'onDateSelect' | 'selectedDate'> {
  name: string;
}

export const FormDatePicker = ({ name, ...rest }: FormDatePickerProps) => {
  return (
    <Box>
      <Field name={name}>
        {({ field, form }: FieldProps<Date | null>) => {
          return (
            <DatePicker
              {...field}
              {...rest}
              setTouched={() => {
                form.setFieldTouched(field.name, true);
              }}
              error={getIn(form.errors, name)}
              touched={getIn(form.touched, name)}
              selectedDate={field.value}
              onDateSelect={(date) => form.setFieldValue(field.name, date)}
              maintainHeight
            />
          );
        }}
      </Field>
    </Box>
  );
};
