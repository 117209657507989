import { Box, Flex } from '@chakra-ui/react';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { Loader } from '../../../components/Loader/Loader';
import { Page } from '../../../components/Page';
import { TabGroup } from '../../../components/TabGroup';
import { useCustomToast } from '../../../hooks/toast/useCustomToast';
import { PROJECT_TYPE } from '../../../requests/Client';
import { EmptyBlurb } from '../../common/errors/EmptyBlurb';

import { ProjectAdditions } from './ProjectAdditions';
import { ProjectExpenses } from './ProjectExpenses';
import { ProjectMainTab } from './ProjectMainTab';
import { ProjectPayments } from './ProjectPayments';
import { ProjectWorkHours } from './ProjectWorkHours';
import { SingleProjectData } from './SingleProjectData';
import { SingleProjectHeader } from './SingleProjectHeader';
import { SingleProjectViewModel } from './SingleProjectViewModel';

export const SingleProject = observer(({ viewModel }: { viewModel: SingleProjectViewModel }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { triggerToast } = useCustomToast();

  if (viewModel.deleteWorkHourState) {
    triggerToast(viewModel.deleteWorkHourState);
    viewModel.setDeleteWorkHourState(null);
  }

  if (viewModel.isProjectLoading) {
    return <Loader />;
  }

  if (!viewModel.project || !viewModel.customer) {
    viewModel.redirect();
    return null;
  }

  const tabs = {
    משימות: (
      <ProjectMainTab
        projectId={viewModel.project._id}
        editTask={viewModel.editTask}
        saveWorkHour={viewModel.saveWorkHour}
        createTask={viewModel.openCreateTaskModal}
      />
    ),
    'צפי הכנסות': (
      <ProjectPayments
        payments={viewModel.payments || []}
        getPaymentTotalWorkHours={viewModel.getPaymentTotalWorkHours}
        getPaymentSum={viewModel.getPaymentSum}
        rate={viewModel.project.rate}
        isHourlyProject={viewModel.project.type === PROJECT_TYPE.HOURLY}
        navigateToHoursTab={() => setCurrentTab(2)}
        navigateToAdditions={() => setCurrentTab(3)}
        projectTotalValue={viewModel.project.totalValue}
        additions={viewModel.project._additions}
      />
    ),
    'מעקב שעות': (
      <>
        {(!viewModel.workHoursByYear || !Object.entries(viewModel.workHoursByYear).length) && (
          <Flex justifyContent="center" alignItems="center" direction="column" textAlign="center" mt="50px">
            <EmptyBlurb title="אין מעקב שעות" body="נראה שעדיין לא דיווחת שעות בפרויקט הזה" />
          </Flex>
        )}
        {Object.entries(viewModel.workHoursByYear || {})
          .sort((a, b) => Number(b[0]) - Number(a[0]))
          .map(([year, workHours]) => (
            <ProjectWorkHours
              key={year}
              project={viewModel.project}
              workHours={workHours}
              year={year}
              onEdit={viewModel.editWorkHour}
              onDelete={viewModel.deleteWorkHour}
              customerName={viewModel.customer?.name || ''}
            />
          ))}
      </>
    ),
    תוספות: (
      <ProjectAdditions
        additions={viewModel.additionsByYear}
        createAddition={viewModel.createAddition}
        updateAddition={viewModel.updateAddition}
        deleteAddition={viewModel.deleteAddition}
        payments={viewModel.payments || []}
        rate={viewModel.project.rate}
        isFixedProject={viewModel.project.type === PROJECT_TYPE.FIXED}
        navigateToPayments={() => setCurrentTab(1)}
        editProject={viewModel.editProject}
      />
    ),
    הוצאות: (
      <ProjectExpenses
        expensesByYear={viewModel.expensesByYear}
        createExpense={viewModel.createExpense}
        updateExpense={viewModel.updateExpense}
        deleteExpense={viewModel.deleteExpense}
      />
    ),
  };

  return (
    <Page title={viewModel.project.name} id="export">
      <SingleProjectHeader
        startDate={format(viewModel.project._startDate, 'dd.MM.yyyy')}
        customerName={viewModel.customer.name}
        customerSource={viewModel.customer.source}
        projectType={viewModel.project.type}
        serviceType={viewModel.project.service}
        status={viewModel.project.status}
        setStatus={viewModel.setProjectStatus}
        editProject={viewModel.editProject}
      />
      <SingleProjectData project={viewModel.project} />
      <Box p="3px" mt="40px">
        <TabGroup index={currentTab} onChange={setCurrentTab} items={tabs} />
      </Box>
    </Page>
  );
});
