import { Box, Button, Flex, Heading, Text, Image } from '@chakra-ui/react';
import TagManager from 'react-gtm-module';
import { useEffectOnce } from 'usehooks-ts';

export const SubscriptionFailure = ({ onRetry }: { onRetry: () => void }) => {
  useEffectOnce(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'payment_failure',
      },
    });
  });
  return (
    <Box data-testid="SubscriptionFailure">
      <Flex w="100%" alignItems="center" justifyContent="center" gap="28px" mb="38px" direction="column">
        <Image src="/payment-failure.svg" width="137px" />
        <Heading textStyle="h4" textAlign="center">
          התשלום נכשל...
        </Heading>
      </Flex>
      <Flex
        bg="#fff"
        w="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
        gap="26px"
        pt="40px"
        pb="30px"
        borderBottomRadius="10px"
      >
        <Text fontSize="xl" textAlign="center" maxW="310px">
          כדאי לבדוק את פרטי הכרטיס ולנסות שוב. עדיין לא עובר? נשמח לעזור info@alze.com
        </Text>
        <Button w="194px" onClick={onRetry}>
          ניסיון נוסף
        </Button>
      </Flex>
    </Box>
  );
};
