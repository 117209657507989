import { Icon, IconProps } from '@chakra-ui/react';

export const LeadIcon = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_9383_489)">
        <path
          d="M8 4.66667C6.162 4.66667 4.66667 6.162 4.66667 8C4.66667 9.838 6.162 11.3333 8 11.3333C9.838 11.3333 11.3333 9.838 11.3333 8C11.3333 6.162 9.838 4.66667 8 4.66667ZM8 10C6.89733 10 6 9.10267 6 8C6 6.89733 6.89733 6 8 6C9.10267 6 10 6.89733 10 8C10 9.10267 9.10267 10 8 10ZM16 7.33333H14.6333C14.3193 4.18867 11.8113 1.68 8.66667 1.36667V0H7.33333V1.36667C4.18867 1.68 1.68 4.18867 1.36667 7.33333H0V8.66667H1.36667C1.68067 11.8113 4.18867 14.32 7.33333 14.6333V16H8.66667V14.6333C11.8113 14.3193 14.32 11.8113 14.6333 8.66667H16V7.33333ZM8 13.3333C5.05933 13.3333 2.66667 10.9407 2.66667 8C2.66667 5.05933 5.05933 2.66667 8 2.66667C10.9407 2.66667 13.3333 5.05933 13.3333 8C13.3333 10.9407 10.9407 13.3333 8 13.3333Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9383_489">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </Icon>
  );
};
