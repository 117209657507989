import { Payment } from './Payment';

export interface IPaymentValues {
  id?: string;
  sum: number | null;
  isAdvance: boolean;
  submitDate: Date;
  comment?: string;
}

export function getInitialPaymentValues(p: Payment): Partial<IPaymentValues> {
  return {
    id: p._id || undefined,
    sum: p.sum || undefined,
    isAdvance: p.isAdvance,
    submitDate: p?.submitDate ? new Date(p.submitDate) : undefined,
    comment: p.comment,
  };
}

export function getPaymentInitialValues(submitDate?: Date): Partial<IPaymentValues> {
  return {
    isAdvance: false,
    submitDate: submitDate,
  };
}

export function getAdvancePaymentValues(): Partial<IPaymentValues> {
  return {
    isAdvance: true,
    submitDate: undefined,
  };
}
