import { Field, FieldProps, getIn } from 'formik';
import isNaN from 'lodash/isNaN';

import { limitToTwoDecimals, roundTo2 } from '../../utils/number/numberUtils';

import { StyledInput, StyledInputProps } from './StyledInput';

export const FormBigNumberInput = ({ name, ...rest }: StyledInputProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<string | number>) => {
        return (
          <StyledInput
            {...field}
            {...rest}
            onChange={(e) => {
              const result = limitToTwoDecimals(e.target.value.replaceAll(',', ''));
              form.setFieldValue(name, result);
            }}
            value={field.value?.toLocaleString()}
            error={getIn(form.errors, name)}
            touched={getIn(form.touched, name)}
            maintainHeight
          />
        );
      }}
    </Field>
  );
};
