import { Box, Flex, Text } from '@chakra-ui/react';
import { Field, FieldInputProps, FieldProps, FormikProps } from 'formik';
import { useEffect } from 'react';

import { ButtonTabs } from '../../components/ButtonTabs';

import { RequiredIndicator } from './RequiredIndicator';

interface RadioGroupProps {
  options: Array<{ label: string; value: string }>;
  label?: string;
  field: FieldInputProps<string | number>;
  form: FormikProps<any>;
  helperText?: string;
  alignment?: 'row' | 'column';
  isRequired?: boolean;
}

const RadioGroup = ({ options, label, field, form, helperText, isRequired, alignment = 'row' }: RadioGroupProps) => {
  const onChange = (newValue: string) => {
    form.setFieldValue(field.name, newValue);
    form.setFieldTouched(field.name, true);
  };

  useEffect(() => {
    if (!field.value) {
      onChange(options[0].value);
    }
  }, [field.value]);

  return (
    <Flex direction="column">
      <Flex justifyContent="space-between" direction={alignment} alignItems={alignment === 'row' ? 'center' : 'start'}>
        {label && (
          <Flex mb={alignment === 'column' ? '4px' : '0'}>
            <Text textStyle="medium" color="base.600">
              {label}
            </Text>
            {isRequired && <RequiredIndicator />}
          </Flex>
        )}
        <ButtonTabs
          items={options.map((option) => ({ label: option.label, id: option.value }))}
          selectedId={field.value?.toString()}
          handleClick={onChange}
        />
      </Flex>
      {helperText ? (
        <Text textStyle="small" color="base.600" mt="8px">
          {helperText}
        </Text>
      ) : (
        <Box textStyle="small" color="base.600" mt="8px" h="18px" />
      )}
    </Flex>
  );
};

interface IFormikField {
  name: string;
  options: Array<{ label: string; value: string }>;
  label?: string;
  helperText?: string;
  alignment?: 'row' | 'column';
  isRequired?: boolean;
}

export const FormButtonTabs = ({ name, options, label, helperText, isRequired, alignment }: IFormikField) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps<string>) => (
        <RadioGroup
          field={field}
          form={form}
          label={label}
          options={options}
          helperText={helperText}
          alignment={alignment}
          isRequired={isRequired}
        />
      )}
    </Field>
  );
};
