import { Flex, Stack, Text } from '@chakra-ui/react';

export const Stat = ({
  value,
  label,
  icon = false,
  highlighted = false,
}: {
  value?: string;
  label: string;
  icon?: boolean;
  highlighted?: boolean;
}) => {
  return (
    <Stack>
      <Flex alignItems="end">
        <Text color={highlighted ? 'success.100' : 'base.1000'} textStyle="doubleLarge">
          {value || '-'}
        </Text>
        {icon && (
          <Text as="span" color={highlighted ? 'success.100' : 'base.1000'} fontSize="md" marginInlineStart="6px">
            ₪
          </Text>
        )}
      </Flex>
      <Text textStyle="medium" color="base.600">
        {label}
      </Text>
    </Stack>
  );
};
