import { AddIcon } from '@chakra-ui/icons';
import { Text, Stack, Box, Flex, Grid, Button } from '@chakra-ui/react';
import { useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { SquareIconButton } from '../../../components/SquareIconButton';
import { TableActions } from '../../../components/TableActions';
import { useVat } from '../../../hooks/useVat/useVat';
import { Addition, IAdditionValues } from '../../../models/Addition';
import { Payment } from '../../../models/Payment';
import { DataPoint } from '../../common/DataPoint';
import { EmptyBlurb } from '../../common/errors/EmptyBlurb';
import { NoTasksIcon } from '../../common/icons';

import { CreateAddition } from './CreateProjectAddition';

export const ADDITION_DIALOG = 'isAdditionDialogOpen';

export const ProjectAdditions = ({
  additions,
  createAddition,
  updateAddition,
  deleteAddition,
  rate,
  payments,
  isFixedProject,
  navigateToPayments,
  editProject,
}: {
  additions: { [year: string]: Addition[] } | null;
  createAddition: (data: IAdditionValues) => void;
  updateAddition: (data: IAdditionValues, id: string) => void;
  deleteAddition: (id: string) => void;
  payments: Array<Payment>;
  rate: number;
  isFixedProject: boolean;
  navigateToPayments: () => void;
  editProject: () => void;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useSessionStorage(ADDITION_DIALOG, false);
  const [editAddition, setEditAddition] = useState<Addition | null>(null);
  const { getValueWithVat } = useVat();

  const years = additions ? Object.keys(additions) : null;

  return (
    <Box>
      <Flex w="100%" direction="row-reverse" alignItems="center">
        <SquareIconButton
          onClick={() => setIsDialogOpen((prevState) => !prevState)}
          aria-label="create addition"
          variant="ghost"
          icon={<AddIcon />}
        />
      </Flex>
      <Stack>
        {!years?.length && (
          <Flex justifyContent="center" alignItems="center" direction="column" textAlign="center" mt="50px">
            <EmptyBlurb
              title="עוד לא הוגדרו תוספות"
              body="תוספת היא עלות עבור תכולה נוספת שהגיעה מצד הלקוח אחרי שהפרויקט התחיל"
              icon={<NoTasksIcon fontSize="72px" mb="16px" />}
              button={{
                label: 'יצירת תוספת חדשה',
                onClick: () => setIsDialogOpen(true),
              }}
            />
          </Flex>
        )}
        {years &&
          years.map((year) => (
            <Box
              position="relative"
              _before={{
                content: "''",
                display: 'block',
                position: 'absolute',
                right: '28px',
                borderLeft: '2px solid',
                borderColor: 'base.200',
                height: 'calc(100% - 30px)',
                top: '32px',
                width: '1px',
                zIndex: '0',
              }}
            >
              <Flex my={3} alignItems="center">
                <Box
                  marginInlineStart="24px"
                  height="12px"
                  width="12px"
                  border="2px solid"
                  borderColor="base.200"
                  borderRadius="full"
                />
                <Text marginInlineStart={5} fontSize="xl">
                  {year}
                </Text>
              </Flex>
              <Stack gap={5}>
                {additions &&
                  additions[year].map((a) => {
                    const payment = payments.find((p) => p._id === a.payment);
                    return (
                      <Grid
                        key={a._id}
                        templateColumns="100px 80px 1fr 120px 120px 120px 84px"
                        border="1px solid"
                        borderColor="base.200"
                        borderRadius="2xl"
                        py={5}
                        px={6}
                        bg="white"
                        gap={10}
                        zIndex={1}
                        sx={{
                          '&:hover .menu': {
                            display: 'block',
                          },
                        }}
                      >
                        <DataPoint label="תאריך" value={a.displayDate} />
                        <DataPoint label="אושר ע״י" value={a.approvedBy} />
                        <Box maxW="380px">
                          <DataPoint label="תיאור" value={a.comment || ''} />
                        </Box>
                        {isFixedProject ? <Box /> : <DataPoint label="תוספת בשעות" value={`${a.valueInHours}`} />}
                        <DataPoint label="סכום" value={`${getValueWithVat(a.value).toLocaleString()} ₪`} />
                        {payment ? (
                          <DataPoint
                            label="משויך לתשלום"
                            component={
                              <Button variant="link" onClick={navigateToPayments} justifyContent="start">
                                {payment.displayPaymentDate}
                              </Button>
                            }
                          />
                        ) : (
                          <Box />
                        )}
                        <Box className="menu" display="none" alignSelf="center">
                          <TableActions
                            onDelete={() => deleteAddition(a.id)}
                            onEdit={() => {
                              setEditAddition(a);
                              setIsDialogOpen(true);
                            }}
                          />
                        </Box>
                      </Grid>
                    );
                  })}
              </Stack>
            </Box>
          ))}
      </Stack>
      {isDialogOpen && (
        <CreateAddition
          initialValues={editAddition}
          onSubmit={(data) => {
            if (editAddition) {
              updateAddition(data, editAddition.id);
            } else {
              createAddition(data);
            }
          }}
          payments={payments}
          rate={rate}
          isFixedProject={isFixedProject}
          onDismiss={() => {
            if (editAddition) {
              setEditAddition(null);
            }
            setIsDialogOpen(false);
          }}
          editProject={editProject}
        />
      )}
    </Box>
  );
};
