import { createIcon } from '@chakra-ui/icons';

export const SuccessIcon = createIcon({
  displayName: 'SuccessIcon',
  viewBox: '0 0 52 42',
  path: [
    <path
      key="0"
      stroke="currentColor"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      d="M48.3913 3L17.1848 39L3 22.6364"
    />,
  ],
});
