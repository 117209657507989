import { getDate, getMonth, getDay, add, isSameDay, isSameMonth, differenceInMilliseconds } from 'date-fns';

import { roundTo2 } from '../number/numberUtils';

export const MONTHS = [
  'ינואר',
  'פברואר',
  'מרץ',
  'אפריל',
  'מאי',
  'יוני',
  'יולי',
  'אוגוסט',
  'ספטמבר',
  'אוקטובר',
  'נובמבר',
  'דצמבר',
];

export const DAYS = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];

export const HOURS_TO_DURATION = 60 * 60; // hours -> minutes -> seconds

export const getDateString = (date: string) => {
  const dateObj = new Date(date);
  const day = DAYS[getDay(dateObj)];
  const dayOfMonth = getDate(dateObj);
  const month = MONTHS[getMonth(dateObj)];

  return `${day}, ${dayOfMonth} ${month}`;
};

export const durationToSecondsDisplay = (duration: number): string => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = duration - hours * 3600 - minutes * 60;

  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export const durationToMinutesDisplay = (duration: number): string => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);

  return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

export function secondsToDecimalHours(sec: number): number {
  const value = sec / (60 * 60);
  return roundTo2(value);
}

export function decimalHoursToDuration(decimalHours: number): number {
  return decimalHours * 60 * 60;
}

export function decimalHoursToMinutesDisplay(decimalHours: number) {
  return durationToMinutesDisplay(decimalHours * 60 * 60);
}

export function getDaysInRange(startDate: Date, endDate: Date) {
  const days = [startDate];

  if (startDate.getDate() === endDate.getDate()) {
    return days;
  }

  let day = add(startDate, { days: 1 });
  while (true) {
    days.push(day);
    if (isSameDay(day, endDate)) {
      break;
    }
    day = add(day, { days: 1 });
  }

  return days;
}

export function getMonthsInRange(startDate: Date, endDate: Date) {
  const months = [startDate];

  if (startDate.getMonth() === endDate.getMonth() && startDate.getFullYear() === endDate.getFullYear()) {
    return months;
  }

  let month = add(startDate, { months: 1 });
  while (true) {
    months.push(month);
    if (isSameMonth(month, endDate)) {
      break;
    }
    month = add(month, { months: 1 });
  }

  return months;
}

export function getDuration(startTime: Date, endTime: Date) {
  const diff = differenceInMilliseconds(endTime, startTime);
  return Math.floor(diff / 1000);
}

export function roundToNextMinute(seconds: number) {
  if (seconds < 0) {
    return 0;
  }

  const remainder = seconds % 60;
  if (remainder === 0) {
    return seconds;
  }

  return seconds - remainder + 60;
}

export function parseIsraeliDate(dateString: string) {
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}-${month}-${day}`);
}
