import { useMutation, useQueryClient } from '@tanstack/react-query';

import { logger } from '../../utils/logger';

import { WORK_HOURS_QUERY_KEY } from './useWorkHours';
import { createWorkHour, IWorkHourApi } from './workHourApi';
import { IValidWorkHourValues, roundTime } from './workHourFormUtils';

export function useCreateWorkHour(onSuccess?: (values: IWorkHourApi) => void, onError?: () => void) {
  const queryClient = useQueryClient();

  const { mutate } = useMutation<IWorkHourApi, unknown, IValidWorkHourValues, { previousWorkHours: IWorkHourApi[] }>({
    mutationFn: async (workHour: IValidWorkHourValues) => {
      return createWorkHour(workHour.endTime ? roundTime(workHour) : workHour);
    },
    onMutate: async (_newWorkHour) => {
      await queryClient.cancelQueries({
        queryKey: [WORK_HOURS_QUERY_KEY],
      });

      const previousWorkHours = queryClient.getQueryData<IWorkHourApi[]>([WORK_HOURS_QUERY_KEY]) || [];

      const newWorkHour = roundTime(_newWorkHour);
      queryClient.setQueryData<IWorkHourApi[]>([WORK_HOURS_QUERY_KEY], (old) => {
        const wh: IWorkHourApi = {
          id: 0,
          start_time: newWorkHour.startTime.toISOString(),
          end_time: newWorkHour.endTime ? newWorkHour.endTime.toISOString() : null,
          comment: newWorkHour.comment || null,
          project_id: newWorkHour.projectId ? parseInt(newWorkHour.projectId) : undefined,
        };
        if (old) {
          return [...old, wh];
        }
        return [wh];
      });

      return { previousWorkHours };
    },
    onSuccess: (res) => {
      if (onSuccess) {
        onSuccess(res);
      }
    },
    onError: (e, _, context) => {
      if (context?.previousWorkHours) {
        queryClient.setQueryData([WORK_HOURS_QUERY_KEY], context.previousWorkHours);
      }
      logger.error('Failed to create partial work hour', e);
      if (onError) {
        onError();
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [WORK_HOURS_QUERY_KEY],
      });
    },
  });

  return { createWorkHour: mutate };
}
