import { PlacementWithLogical } from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import { ReactNode } from 'react';

export const StyledTooltip = ({
  children,
  label,
  placement = 'top-start',
}: {
  placement?: PlacementWithLogical;
  label: string;
  children: ReactNode;
}) => {
  return (
    <Tooltip
      placement={placement}
      label={label}
      aria-label={label}
      fontWeight="normal"
      borderRadius="4px"
      bg="base.900"
      color="white"
      py="4px"
      px="8px"
      openDelay={100}
      hasArrow
      maxW="160px"
    >
      {children}
    </Tooltip>
  );
};
