import { computed, makeObservable } from 'mobx';

import { ProjectsModel } from '../../models/ProjectsModel';
import { IStore } from '../../models/RootStore';
import { WorkHour } from '../../models/WorkHour';
import { IWorkHourApi } from '../../models/workHours/workHourApi';
import { RoutingStore } from '../../router/RoutingStore';

import { SingleProjectRoute } from './../../router/routes';

export class TasksViewModel implements IStore {
  private projectsModel: ProjectsModel;
  private routingStore: RoutingStore;

  constructor(projectsModel: ProjectsModel, routingStore: RoutingStore) {
    this.projectsModel = projectsModel;
    this.routingStore = routingStore;

    makeObservable(this, {
      projects: computed,
    });
  }

  activate() {}

  get projects() {
    return this.projectsModel.projects;
  }

  editTask = (id: number) => {
    this.routingStore.setIsCreatingTask(true, id);
  };

  // for timer integration
  saveWorkHour = async (newWorkHour: IWorkHourApi) => {
    if (!newWorkHour.end_time) {
      // return early if saving a partial work hour
      return;
    }

    const workHour = new WorkHour(newWorkHour);
    if (newWorkHour.project_id) {
      await this.projectsModel.createWorkHour(workHour, newWorkHour.project_id.toString());
    }
  };

  onProjectClick = (projectId: string) => {
    this.routingStore.navigate({
      ...SingleProjectRoute,
      params: { projectId },
    });
  };

  createTask = () => {
    this.routingStore.setIsCreatingTask(true);
  };
}
