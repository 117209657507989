import { observer } from 'mobx-react';
import { useEffect } from 'react';

import { Loader } from '../../components/Loader/Loader';
import { ID_TOKEN } from '../../models/AuthenticationModel';
import { useUserData } from '../../models/userData/useUserData';
import { AuthenticationLayout } from '../common/Layout/AuthenticationLayout';

import { AuthenticationViewModel, VIEW_TYPE } from './AuthenticationViewModel';
import { ConfirmEmail } from './components/ConfirmEmail';
import { Login } from './components/Login';
import { ResetPassword } from './components/ResetPassword';
import { Signup } from './components/Signup';

export const Authentication = observer(({ viewModel }: { viewModel: AuthenticationViewModel }) => {
  const userId = localStorage.getItem(ID_TOKEN);
  const { userData } = useUserData(Boolean(userId));
  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash;
      viewModel.parseHash(hash);
      // remove hash
      window.history.pushState('', document.title, window.location.pathname + window.location.search);
    } else {
      viewModel.setLoading(false);
    }
  }, [viewModel.currentView]);

  useEffect(() => {
    if (userData && !userData.emailVerified) {
      viewModel.setCurrentView(VIEW_TYPE.CONFIRM_EMAIL);
    }
  }, [userData]);

  if (viewModel.isLoading) {
    return <Loader />;
  }

  const getCurrentComponent = () => {
    switch (viewModel.currentView) {
      case VIEW_TYPE.CONFIRM_EMAIL:
        return (
          <ConfirmEmail
            email={viewModel.email || ''}
            goToLogin={() => viewModel.setCurrentView(VIEW_TYPE.LOGIN)}
            onOtpVerified={viewModel.onInitComplete}
          />
        );
      case VIEW_TYPE.SIGNUP:
        return <Signup viewModel={viewModel} />;
      case VIEW_TYPE.RESET_PASSWORD:
        return <ResetPassword viewModel={viewModel} />;
      case VIEW_TYPE.LOGIN:
      case VIEW_TYPE.EMAIL_VERIFIED:
        return <Login viewModel={viewModel} />;
      default:
        return <Loader />;
    }
  };

  const getWizardStep = () => {
    switch (viewModel.currentView) {
      case VIEW_TYPE.SIGNUP:
        return 1;
      case VIEW_TYPE.CONFIRM_EMAIL:
      case VIEW_TYPE.EMAIL_VERIFIED:
        return 2;
      case VIEW_TYPE.RESET_PASSWORD:
      case VIEW_TYPE.LOGIN:
      default:
        return undefined;
    }
  };

  return (
    <AuthenticationLayout
      isMailStep={
        viewModel.currentView === VIEW_TYPE.CONFIRM_EMAIL || viewModel.currentView === VIEW_TYPE.RESET_PASSWORD
      }
      wizardStep={getWizardStep()}
    >
      {getCurrentComponent()}
    </AuthenticationLayout>
  );
});
