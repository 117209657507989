import { Divider, Flex, Grid, Stack, Text } from '@chakra-ui/react';

import { StackedAccordionCards } from '../../../components/StackedAccordionCards';
import { useVat } from '../../../hooks/useVat/useVat';
import { durationToMinutesDisplay } from '../../../utils/time/timeUtils';
import { DataPoint } from '../../common/DataPoint';
import { EmptyBlurb } from '../../common/errors/EmptyBlurb';
import { NoTasksIcon } from '../../common/icons';

import { PaymentSummaryByYearByMonth } from './SingleCustomerViewModel';

export const SingleCustomerPayments = ({ paymentsByYear }: { paymentsByYear: PaymentSummaryByYearByMonth }) => {
  const { getValueWithVat } = useVat();
  if (Object.keys(paymentsByYear).length === 0) {
    return (
      <Flex justifyContent="center" alignItems="center" direction="column" textAlign="center" mt="95px">
        <EmptyBlurb
          title="לא קיים צפי הכנסות"
          body="ברגע שידווחו שעות או תשלומים בפרויקט, הם יופיעו כאן"
          icon={<NoTasksIcon fontSize="72px" mb="16px" />}
        />
      </Flex>
    );
  }

  return (
    <Stack>
      {Object.keys(paymentsByYear)
        .sort((a, b) => Number(b) - Number(a))
        .map((year) => (
          <StackedAccordionCards
            key={year}
            title={year}
            items={Object.keys(paymentsByYear[year]).map((month) => ({
              mainComponent: (
                <Grid key={month} templateColumns={`100px 150px 200px 3px 150px`} gap="45px">
                  <DataPoint label="חודש" value={month} />
                  <DataPoint label="סכום" value={getValueWithVat(paymentsByYear[year][month].total).toLocaleString()} />
                  <DataPoint label="מועד צפוי לתשלום" value={paymentsByYear[year][month].displayPaymentCycle} />
                  <Divider h="100%" orientation="vertical" />
                  <DataPoint label="פרויקט" value={paymentsByYear[year][month].payments.length.toString()} />
                </Grid>
              ),
              subComponents: paymentsByYear[year][month].payments.map((payment) => (
                <Grid key={month} templateColumns={`100px 150px 100px 100px 3px 300px`} gap="45px">
                  <DataPoint label="שם הפרויקט" value={payment.projectName} />
                  <DataPoint
                    label="סכום"
                    value={`${getValueWithVat(payment.totalValue + payment.additionValue).toLocaleString()} ₪ ${payment.additionValue ? '*' : ''}`}
                  />
                  <DataPoint label="סוג השירות" value={payment.service} />
                  <DataPoint label="סוג החוזה" value={payment.projectType} />
                  <Divider h="100%" orientation="vertical" />
                  {(payment.workHourDuration || 0) !== 0 && (
                    <DataPoint
                      label="מבוסס על"
                      value={`${durationToMinutesDisplay(payment.workHourDuration!)} שעות שדווחו בחודש ${month}`}
                    />
                  )}
                  {payment.additionValue !== 0 && (
                    <Text alignSelf="end">
                      * התשלום בחודש זה כולל תוספות בסך {payment.additionValue.toLocaleString()} ₪
                    </Text>
                  )}
                </Grid>
              )),
            }))}
          />
        ))}
    </Stack>
  );
};
