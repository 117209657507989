import { Flex, Text } from '@chakra-ui/react';

export const HorizontalBullets = ({ labels }: { labels: string[] }) => {
  return (
    <Flex gap="43px">
      {labels.map((label) => (
        <Text
          key={label}
          fontSize="md"
          textAlign="center"
          opacity="0.7"
          position="relative"
          _before={{
            content: "''",
            display: 'block',
            position: 'absolute',
            right: '-15px',
            top: '50%',
            opacity: 1,
            transform: 'translateY(-50%)',
            borderRadius: '50%',
            width: '10px',
            height: '10px',
            backgroundColor: '#42CEB3',
          }}
        >
          {label}
        </Text>
      ))}
    </Flex>
  );
};
