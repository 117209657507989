import { makeObservable, observable, computed, action } from 'mobx';

import { CustomerModel } from '../../models/CustomerModel';
import { ProjectsModel } from '../../models/ProjectsModel';
import { IStore } from '../../models/RootStore';
import { RoutingStore } from '../../router/RoutingStore';

import { SingleCustomerRoute, EditCustomerRoute, CreateCustomerRoute } from './../../router/routes';

export class CustomersViewModel implements IStore {
  customerModel: CustomerModel;
  projectsModel: ProjectsModel;
  routingStore: RoutingStore;

  _filter: string;

  constructor(customerModel: CustomerModel, projectsModel: ProjectsModel, routingStore: RoutingStore) {
    this.customerModel = customerModel;
    this.projectsModel = projectsModel;
    this.routingStore = routingStore;

    this._filter = '';

    makeObservable(this, {
      customerModel: observable,
      projectsModel: observable,
      _filter: observable,

      customers: computed,
      customersExist: computed,
      filter: computed,
      setFilter: action,
      deleteCustomer: action,
    });
  }

  activate() {}

  get customersExist() {
    return this.customerModel.customers.length > 0;
  }

  get customers() {
    return this.customerModel.customers
      .filter((c) => c.name.toLowerCase().includes(this.filter.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  get filter() {
    return this._filter;
  }

  setFilter(f: string) {
    this._filter = f;
  }

  customerTotalProjects(customerId: string) {
    return this.projectsModel.projects.reduce((total, project) => {
      return project.customer === customerId ? total + 1 : total;
    }, 0);
  }

  customerTotalValue(customerId: string) {
    return this.projectsModel.projects.reduce((total, project) => {
      return project.customer === customerId ? total + project.totalValue : total;
    }, 0);
  }

  customerProjects(customerId: string) {
    return this.projectsModel.projects.filter((project) => {
      return project.customer === customerId;
    });
  }

  deleteCustomer = async (customerId: string) => {
    const customerProjects = this.projectsModel.projects.filter((p) => p.customer === customerId);

    await Promise.all(customerProjects.map((p) => this.projectsModel.deleteProject(p)));
    return this.customerModel.deleteCustomer(customerId);
  };

  editCustomer = async (customerId: string) => {
    this.routingStore.navigate({
      ...EditCustomerRoute,
      params: { customerId },
    });
  };

  createCustomer = async () => {
    this.routingStore.navigate({
      ...CreateCustomerRoute,
    });
  };

  onCustomerClick = (customerId: string) => {
    this.routingStore.navigate({
      ...SingleCustomerRoute,
      params: { customerId },
    });
  };
}
