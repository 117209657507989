import {
  Box,
  Button,
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Portal,
} from '@chakra-ui/react';
import { differenceInCalendarDays } from 'date-fns';
import { useRef } from 'react';

import { useUserData } from '../../../models/userData/useUserData';
import { FailedCreditCardIcon, StarIcon } from '../../common/icons';

export const SubscriptionRemainingDays = ({
  openPaymentDialog,
  isWide,
}: {
  openPaymentDialog(): void;
  isWide: boolean;
}) => {
  const initialFocusRef = useRef(null);
  const { userData } = useUserData();

  if (!userData?.paymentNextDate || userData.paymentState === 'BETA' || userData.paymentState === 'PAYING') {
    return null;
  }

  const remiaingDays = differenceInCalendarDays(userData?.paymentNextDate, new Date());
  const { paymentState } = userData;

  if (remiaingDays > 7 && paymentState === 'TRIAL') {
    return null;
  }

  const getTexts = (): { body: string; button: string } => {
    switch (paymentState) {
      case 'TRIAL':
        return {
          body: 'לתקופת הניסיון',
          button: 'לרכישת מינוי',
        };
      case 'PAYMENT_FAILED':
        return {
          body: 'לא הצלחנו לחייב את הכרטיס שלך',
          button: 'לעדכון הכרטיס',
        };
      case 'CANCELLED':
        return {
          body: 'עד שהמנוי יבוטל',
          button: 'כאן מתחרטים',
        };
    }
  };

  const text = getTexts();

  if (!isWide) {
    return (
      <Popover placement="left-end" gutter={18} initialFocusRef={initialFocusRef}>
        {({ isOpen }) => (
          <>
            <PopoverTrigger>
              {paymentState === 'PAYMENT_FAILED' ? (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  w="30px"
                  h="30px"
                  borderRadius="full"
                  border="2px solid"
                  borderColor={isOpen ? 'white' : 'base.800'}
                  bg="accent.100"
                  color="white"
                  data-testid="remaining-days_number"
                  cursor="pointer"
                  _hover={{
                    borderColor: isOpen ? 'white' : 'base.300',
                  }}
                >
                  <FailedCreditCardIcon />
                </Flex>
              ) : (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  w="30px"
                  h="30px"
                  borderRadius="full"
                  border="2px solid"
                  borderColor={isOpen ? 'white' : '#3c2556'}
                  bg="base.1000"
                  color="accent.100"
                  data-testid="remaining-days_number"
                  cursor="pointer"
                  _hover={{
                    borderColor: isOpen ? 'white' : 'base.300',
                  }}
                >
                  {remiaingDays}
                </Flex>
              )}
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                border="none"
                w="140px"
                p="10px"
                borderRadius="8px"
                boxShadow="0px 4px 12px 0px rgba(22, 8, 37, 0.20)"
              >
                <PopoverArrow />
                <PopoverBody p="0">
                  <Text textAlign="center" mb="10px" fontSize="lg">
                    {paymentState !== 'PAYMENT_FAILED' && (
                      <>
                        נותרו לך
                        <Box as="span" px="5px" color="accent.100" data-testid="remaining-days_number">
                          {remiaingDays}
                        </Box>
                        ימים
                      </>
                    )}{' '}
                    {text.body}
                  </Text>
                  <Button
                    ref={initialFocusRef}
                    data-testid="haloo"
                    bg="base.1000"
                    color="white"
                    _hover={{
                      backgroundColor: 'base.1000',
                    }}
                    w="120px"
                    onClick={openPaymentDialog}
                  >
                    {text.button}
                  </Button>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    );
  }

  return (
    <Flex
      data-testid="remaining-days"
      p="12px"
      color="white"
      bg="#3c2556"
      borderRadius="10px"
      fontSize="lg"
      flexDirection="column"
      alignItems="center"
      position="relative"
    >
      {paymentState === 'PAYMENT_FAILED' && (
        <Flex
          bg="accent.100"
          color="white"
          borderRadius="full"
          justifyContent="center"
          alignItems="center"
          w="32px"
          h="32px"
          position="absolute"
          top="-22px"
          left="50%"
          transform="translateX(-50%)"
          fontSize="md"
        >
          <FailedCreditCardIcon />
        </Flex>
      )}
      {paymentState === 'TRIAL' && (
        <>
          <StarIcon
            color="accent.100"
            w="16px"
            h="16px"
            position="absolute"
            top="6px"
            right="4px"
            transform="rotate(22deg);"
          />
          <StarIcon
            color="accent.100"
            w="8px"
            h="8px"
            position="absolute"
            top="26px"
            right="3px"
            transform="rotate(-53deg);"
          />
        </>
      )}
      <Text textAlign="center" mb="10px">
        {paymentState !== 'PAYMENT_FAILED' && (
          <>
            נותרו לך
            <Box as="span" px="5px" color="accent.100" data-testid="remaining-days_number">
              {remiaingDays}
            </Box>
            ימים{' '}
          </>
        )}
        {text.body}
      </Text>
      <Button
        data-testid="haloo"
        bg="base.1000"
        color="white"
        _hover={{
          backgroundColor: 'base.1000',
        }}
        w="118px"
        onClick={openPaymentDialog}
      >
        {text.button}
      </Button>
    </Flex>
  );
};
