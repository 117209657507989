import { Flex } from '@chakra-ui/react';

import { Loader } from '../../components/Loader/Loader';
import { useCustomToast } from '../../hooks/toast/useCustomToast';
import { useUpdateUserData } from '../../models/userData/useUpdateUserData';
import { useUserData } from '../../models/userData/useUserData';

import { BaseDataSettings } from './BaseDataSettings';
import { ProfessionalDataSettings } from './ProfessionalDataSettings';

export const UserSettings = () => {
  const { triggerToast } = useCustomToast('העדכון', false);
  const { isLoading, userData } = useUserData();
  const { updateUserData } = useUpdateUserData(
    () => triggerToast('success'),
    () => triggerToast('error'),
  );

  if (isLoading || !userData) {
    return <Loader />;
  }
  return (
    <Flex flexDirection="column" rowGap="24px">
      <BaseDataSettings userData={userData} onSubmit={updateUserData} />
      <ProfessionalDataSettings userData={userData} onSubmit={updateUserData} />
    </Flex>
  );
};
