import { CheckIcon } from '@chakra-ui/icons';
import { Flex, Spacer, Box, Divider, Text } from '@chakra-ui/react';
import { observer } from 'mobx-react';

import { ProgressBar } from '../../../components/ProgressBar';
import { useVat } from '../../../hooks/useVat/useVat';
import { Project } from '../../../models/Project';
import { durationToMinutesDisplay, HOURS_TO_DURATION } from '../../../utils/time/timeUtils';
import { Stat } from '../../common/Stat';

import { VIEW_STATE } from './SingleProjectData';

export const FixedData = observer(
  ({ project, viewState, baseRate }: { project: Project; viewState: VIEW_STATE; baseRate?: number }) => {
    const { getValueWithVat } = useVat();
    const totalDuration = baseRate
      ? ((project.totalValue + project.totalAdditionsValue) / baseRate) * HOURS_TO_DURATION
      : undefined;

    const getProgressStats = () => {
      if (viewState === 'HOURS') {
        return {
          current: `${durationToMinutesDisplay(project.accumulatedDuration)}`,
          total: totalDuration
            ? `${durationToMinutesDisplay(Math.max(totalDuration - project.accumulatedDuration, 0))}`
            : 'לא הוגדר תעריף שעתי',
          currentIcon: 'ש׳',
          totalIcon: totalDuration ? 'ש׳' : '',
        };
      }
      return {
        current: `${getValueWithVat(project.accumulatedValue).toLocaleString()}`,
        total: `${getValueWithVat(project.remainingRevenue).toLocaleString()}`,
        currentIcon: '₪',
        totalIcon: '₪',
      };
    };

    const getProgressValue = () => {
      if (viewState === 'HOURS') {
        return totalDuration && project.accumulatedDuration
          ? Math.max((project.accumulatedDuration / totalDuration) * 100, 5)
          : 0;
      }

      return project.totalRevenue && project.accumulatedValue
        ? Math.max((project.accumulatedValue / project.totalRevenue) * 100, 5)
        : 0;
    };

    const stats = [
      {
        label: 'הכנסה צפויה',
        value: project.totalValue ? `${getValueWithVat(project.totalValue).toLocaleString()}` : '-',
        icon: project.totalValue ? '₪' : '',
      },
      {
        label: 'תוספות',
        value: project.totalAdditionsValue ? `${getValueWithVat(project.totalAdditionsValue).toLocaleString()}` : '-',
        icon: project.totalAdditionsValue ? '₪' : '',
      },
      {
        label: 'הכנסה סופית',
        value: project.totalRevenue ? `${getValueWithVat(project.totalRevenue).toLocaleString()}` : '-',
        icon: project.totalRevenue ? '₪' : '',
      },
      {
        label: 'תנאי תשלום',
        value: project.displayPaymentCycle,
      },
    ];

    return (
      <>
        {viewState === VIEW_STATE.INCOME ? (
          <>
            <Flex gap="56px">
              {stats.map((stat) => (
                <Stat key={stat.label} value={stat.value} label={stat.label} icon={Boolean(stat.icon)} />
              ))}
            </Flex>
            <Divider orientation="vertical" minHeight="50px" mx="48px" />
          </>
        ) : (
          <>
            <Flex>
              {project.hourTracking ? (
                <CheckIcon
                  borderRadius="50%"
                  p="4px"
                  mt="3px"
                  h="18px"
                  w="18px"
                  backgroundColor="base.900"
                  color="white"
                />
              ) : (
                <Box h="18px" w="18px" />
              )}
              <Flex direction="column" marginInlineStart="10px" marginInlineEnd="92px">
                <Text fontStyle="medium" color="base.600">
                  מעקב שעות
                  {project.hourTracking ? ' ' : ' לא '}
                  פעיל
                </Text>
                <Text fontStyle="extraLarge" maxW="383px" color="base.1000">
                  {project.hourTracking && totalDuration
                    ? `על פי עלות הפרויקט ועל בסיס התעריף השעתי הרגיל שלך, מומלץ לא להשקיע יותר מ-${durationToMinutesDisplay(
                        totalDuration,
                      )} שעות כדי שהוא יהיה רווחי`
                    : 'בפרויקט הזה בחרת לא לבצע מעקב שעות'}
                </Text>
              </Flex>
            </Flex>
            {!project.hourTracking && <Spacer />}
          </>
        )}
        {(viewState === VIEW_STATE.INCOME || project.hourTracking) && (
          <>
            <Box flex="1">
              <ProgressBar
                current={{ label: getProgressStats().current, icon: getProgressStats().currentIcon }}
                total={{ label: getProgressStats().total, icon: getProgressStats().totalIcon }}
                value={getProgressValue()}
              />
            </Box>
          </>
        )}
      </>
    );
  },
);
