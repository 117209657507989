import { Flex, Text, Button } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { Dialog } from '../../components/Dialog';
import { Project } from '../../models/Project';
import { WarningIcon } from '../common/icons/WarningIcon';

export const DeleteProjectDialog = observer(
  ({ project, onClose, onSubmit }: { project: Project; onClose: () => void; onSubmit: () => Promise<void> }) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
      setLoading(true);
      await onSubmit();
      onClose();
    };

    return (
      <Dialog title="מחיקת פרויקט" onClose={onClose}>
        <Flex marginBottom="26px" width="100%" flexDirection="column" alignItems="center">
          <Text textStyle="medium" color="base.600" mb="4px">
            האם למחוק את:
          </Text>
          <Text textStyle="medium" color="base.1000">
            '{project.name}'?
          </Text>
        </Flex>
        <Flex
          position="relative"
          p="24px"
          pt="32px"
          mx="32px"
          background="base.25"
          borderRadius="12px"
          textAlign="center"
          gap="6px"
        >
          <WarningIcon
            color="error.100"
            position="absolute"
            top="-12px"
            left="50%"
            transform="translateX(-50%)"
            w="26px"
            h="26px"
          />
          <Text textStyle="medium" color="base.1000">
            <Text as="span" textDecoration="underline">
              חשוב:
            </Text>{' '}
            לא ניתן לשחזר את הפרויקט לאחר המחיקה. בנוסף, כל הנתונים המשויכים לפרויקט (לדוג’ שעות שדווחו) ימחקו גם.
          </Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" p="32px">
          <Button variant="secondary" alignSelf="start" size="sm" onClick={onClose}>
            ביטול
          </Button>
          <Button isLoading={loading} size="sm" onClick={handleSubmit}>
            מחיקה
          </Button>
        </Flex>
      </Dialog>
    );
  },
);
