import { FormikErrors } from 'formik';

import { ICustomerApi } from '../../requests/Client';
import { Customer, CustomerStatus } from '../Customer';

export type ICustomerValues = Partial<
  Omit<ICustomerApi, '_id' | 'created_at' | 'contact_birth_date'> & { contact_birth_date: Date }
>;

export function getInitialCustomerValues(
  customer?: Customer,
  isLead: boolean = false,
  initialStatus?: CustomerStatus,
): Partial<ICustomerValues> {
  return {
    name: customer?.name || '',
    phone: customer?.phone || '',
    email: customer?.email || '',
    source: customer?.source || '',
    status: initialStatus || customer?.status || (isLead ? CustomerStatus.NEW_LEAD : CustomerStatus.CLOSED),
    rate: customer?.rate || undefined,
    payment_cycle: customer?.payment_cycle || undefined,
    vat_number: customer?.vat_number || '',
    industry: customer?.industry || '',
    address: customer?.address || '',
    contact_name: customer?.contact_name || '',
    contact_role: customer?.contact_role || '',
    contact_birth_date: customer?._contact_birth_date,
    is_active: customer?.isActive || true,
    comment: customer?.comment || '',
  };
}

export function validateCustomerValues(
  values: Partial<ICustomerValues>,
  existingCustomerNames: string[],
  baseName?: string,
  isShortForm?: boolean,
): FormikErrors<ICustomerValues> {
  const errors: FormikErrors<ICustomerValues> = {};
  if (!values.name) {
    errors.name = 'אנחנו צריכים את זה';
  }

  if (values.name && existingCustomerNames.includes(values.name)) {
    // if isEditMode ignore base name of the edited project
    if (values.name !== baseName) {
      errors.name = 'כבר יש לקוח עם השם הזה';
    }
  }

  if (values.phone && values.phone.length < 9) {
    errors.phone = "יש להכניס מס' תקין בעל 9 ספרות לפחות";
  }
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'צריך להקפיד על המבנה הזה: name@email.com';
  }

  if (!isShortForm && !values.contact_name) {
    errors.contact_name = 'אנחנו צריכים את זה';
  }

  return errors;
}
