import { endOfMonth } from 'date-fns';
import { makeObservable, action, computed } from 'mobx';

import { CustomerModel } from '../../../models/CustomerModel';
import { ICustomerValues } from '../../../models/customers/customerUtils';
import { Project } from '../../../models/Project';
import { ProjectsModel } from '../../../models/ProjectsModel';
import { IProjectValues } from '../../../models/ProjectUtils';
import { IWorkHourBase } from '../../../models/workHours/workHourApi';
import { ProjectsRoute, SingleProjectRoute } from '../../../router/routes';

import { IStore } from './../../../models/RootStore';
import { RoutingStore } from './../../../router/RoutingStore';

export const CREATE_NEW_CUSTOMER_ID = 'CREATE_NEW_CUSTOMER_ID';

export enum PAYMENT_TYPE {
  PERCENTAGE = 'PERCENTAGE',
  EXACT = 'EXACT',
}

export class CreateProjectViewModel implements IStore {
  private projectsModel: ProjectsModel;
  private customersModel: CustomerModel;
  private routingStore: RoutingStore;

  baseProject: Project | undefined;
  isDirty = false;

  constructor(
    projectsModel: ProjectsModel,
    customersModel: CustomerModel,
    routingStore: RoutingStore,
    projectId?: string,
  ) {
    this.projectsModel = projectsModel;
    this.customersModel = customersModel;
    this.routingStore = routingStore;

    const project = projectId ? this.projectsModel.getProjectById(projectId) : undefined;
    this.baseProject = project;

    this.isDirty = false;

    makeObservable(this, {
      allCustomers: computed,
      customerForSelection: computed,
      existingProjectNames: computed,
      existingCustomerNames: computed,
      createProject: action,
      isEditMode: computed,
    });
  }

  activate() {}

  get allCustomers() {
    return this.customersModel.customers.map((customer) => ({
      key: customer.name,
      value: customer._id,
    }));
  }

  get customerForSelection() {
    return this.allCustomers.sort((a, b) => a.key.localeCompare(b.key));
  }

  getCustomerById = (id: string) => {
    return this.customersModel.getCustomerById(id);
  };

  get existingProjectNames() {
    return this.projectsModel.projects.map((p) => p.name);
  }

  get existingCustomerNames() {
    return this.customersModel.customers.map((c) => c.name);
  }

  createProject = async (values: IProjectValues, importedWorkHours: IWorkHourBase[]) => {
    if (values.customerId === CREATE_NEW_CUSTOMER_ID && values.customer) {
      const customerId = await this.customersModel.createCustomer(values.customer as ICustomerValues);
      values.customerId = customerId;
    }

    if (this.isEditMode) {
      await this.projectsModel.updateProject(values, this.baseProject!);
    } else {
      if (values.payments.length === 0) {
        values.payments = [
          {
            sum: 0,
            isAdvance: false,
            submitDate: endOfMonth(new Date()),
          },
        ];
      }
      await this.projectsModel.createProject(values, importedWorkHours);
    }
    this.setIsDirty(false);
    const nextRoute = this.isEditMode
      ? {
          ...SingleProjectRoute,
          params: { projectId: this.baseProject?._id },
        }
      : ProjectsRoute;
    this.routingStore.navigate(nextRoute);
  };

  onCancel = () => {
    window.history.go(-1);
  };
  get isEditMode() {
    return Boolean(this.baseProject);
  }

  setIsDirty = (state: boolean) => {
    this.isDirty = state;
  };
}
