import { format, add, set, isBefore, sub } from 'date-fns';
import { makeObservable, observable, action, computed } from 'mobx';

import { getDuration } from '../utils/time/timeUtils';

import { IWorkHourApi } from './workHours/workHourApi';

type incrementTimeObj = {
  hours?: number;
  minutes?: number;
  seconds?: number;
};

const SECONDS_IN_DAY = 24 * 60 * 60;
export class WorkHour {
  _id?: string;

  _startTime: Date;
  _endTime?: Date;
  _comment?: string;

  constructor(workHour?: IWorkHourApi) {
    this._id = workHour?.id.toString() || '';
    this._startTime = workHour?.start_time
      ? new Date(workHour?.start_time)
      : set(new Date(), { seconds: 0, milliseconds: 0 });
    this._endTime = workHour?.end_time ? new Date(workHour?.end_time) : undefined;
    this._comment = workHour?.comment || undefined;

    this.setDate(workHour?.start_time ? new Date(workHour?.start_time) : new Date());
    makeObservable(this, {
      _id: observable,
      id: computed,
      setId: action,
      date: computed,
      displayDate: computed,
      setDate: action,
      _startTime: observable,
      startTime: computed,
      setStartTime: action,
      _endTime: observable,
      endTime: computed,
      setEndTime: action,
      autoUpdateEndDate: action,
      _comment: observable,
      comment: computed,
      setComment: action,

      incrementTime: action,
      duration: computed,
    });
  }

  get id() {
    return this._id;
  }

  setId = (id: string) => {
    this._id = id;
  };

  get date(): string {
    return format(this._startTime, 'yyyy-MM-dd');
  }

  get displayDate(): string {
    return format(this._startTime, 'dd.MM');
  }

  setDate = (newDate: Date) => {
    this._startTime = set(this._startTime, {
      year: newDate.getFullYear(),
      month: newDate.getMonth(),
      date: newDate.getDate(),
      seconds: 0,
      milliseconds: 0,
    });
    this._endTime = set(this._endTime ? this._endTime : new Date(), {
      year: newDate.getFullYear(),
      month: newDate.getMonth(),
      date: newDate.getDate(),
      seconds: 0,
      milliseconds: 0,
    });
    this.autoUpdateEndDate();
  };

  get startTime(): string {
    return format(this._startTime, 'HH:mm');
  }

  setStartTime = (time: string) => {
    const [hours, minutes, seconds] = time.split(':');
    this._startTime = set(this._startTime, {
      hours: Number(hours),
      minutes: Number(minutes),
      seconds: Number(seconds) || 0,
      milliseconds: 0,
    });
  };

  get endTime(): string | undefined {
    return this._endTime ? format(this._endTime, 'HH:mm') : undefined;
  }

  setEndTime = (time: string) => {
    if (!this._endTime) {
      this._endTime = this._startTime;
    }
    const [hours, minutes, seconds] = time.split(':');
    this._endTime = set(this._endTime, {
      hours: Number(hours),
      minutes: Number(minutes),
      seconds: Number(seconds) || 0,
      milliseconds: 0,
    });

    this.autoUpdateEndDate();
  };

  autoUpdateEndDate = () => {
    if (!this._endTime) {
      return;
    }
    if (isBefore(this._endTime, this._startTime)) {
      this._endTime = add(this._endTime, {
        days: 1,
      });
    }
    if (this.duration >= SECONDS_IN_DAY) {
      this._endTime = sub(this._endTime, {
        days: 1,
      });
    }
  };

  get comment() {
    return this._comment;
  }

  setComment = (comment: string) => {
    this._comment = comment;
  };

  // in seconds
  get duration() {
    if (this._startTime && this._endTime) {
      return getDuration(this._startTime, this._endTime);
    }
    return 0;
  }

  incrementTime = (timeToAdd: incrementTimeObj) => {
    this._endTime = add(this._endTime || new Date(), timeToAdd);
    this.autoUpdateEndDate();
  };
}
