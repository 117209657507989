import { CheckIcon } from '@chakra-ui/icons';
import { Box, Flex, Fade, Text } from '@chakra-ui/react';

export const TimeTrackerSuccess = ({ shouldDisplay }: { shouldDisplay: boolean }) => {
  return (
    <Fade in={shouldDisplay} unmountOnExit>
      <Flex
        color="white"
        bgColor="primary.100"
        borderRadius="14px"
        alignItems="center"
        justifyContent="center"
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Text fontStyle="large">מעולה, שמרנו את זה!</Text>
        <Box
          h="56px"
          w="56px"
          borderRadius="50%"
          bg="white"
          boxShadow="0px 0px 6px 2px rgba(104, 88, 136, 0.20)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          left="-10px"
        >
          <CheckIcon color="base.1000" h="16px" />
        </Box>
      </Flex>
    </Fade>
  );
};
