import { Button } from '@chakra-ui/button';
import { Box, Flex, Text } from '@chakra-ui/layout';

import { Dialog } from '../../../components/Dialog';

export const SubscriptionFailedWarning = ({
  onDismiss,
  openPaymentFlow,
  remainingDays,
}: {
  remainingDays: number;
  onDismiss: () => void;
  openPaymentFlow: () => void;
}) => {
  return (
    <Dialog size="medium" title="לא הצלחנו לחייב את האשראי שלך" onClose={onDismiss}>
      <Flex justifyContent="center" maxW="420px" mx="auto" marginTop="20px" mb="36px">
        <Text textAlign="center">
          אנחנו מבינים כמה חשוב להמשיך לנהל את היומיום ולכן נאפשר לך להשתמש במערכת למשך{' '}
          <Box as="span" fontWeight="bold">
            {remainingDays}
          </Box>{' '}
          ימים נוספים.
          <br /> חשוב לעדכן את פרטי הכרטיס בהקדם
        </Text>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" py="16px" px="30px">
        <Button variant="secondary" alignSelf="start" onClick={onDismiss}>
          הבנתי, תודה
        </Button>
        <Button onClick={openPaymentFlow} w="94px">
          עדכן
        </Button>
      </Flex>
    </Dialog>
  );
};
