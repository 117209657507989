import { useMutation, useQueryClient } from '@tanstack/react-query';

import { logger } from '../../utils/logger';

import { Task, updateTask } from './tasksApi';
import { TASKS_QUERY_KEY } from './useTasks';

export function useUpdateTask() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (task: Task) => updateTask(task),
    onMutate: (task: Task) => {
      queryClient.setQueryData([TASKS_QUERY_KEY], (old: Task[]) => {
        return old.map((t) => (t.id === task.id ? task : t));
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TASKS_QUERY_KEY],
      });
    },
    onError: (e) => {
      logger.error('Failed to create userData', e);
    },
  });

  return {
    updateTask: mutateAsync,
  };
}
