import { Text, Flex, Heading, Box, Button, Stack } from '@chakra-ui/react';
import { Formik, FormikErrors, Form } from 'formik';
import { useState } from 'react';

import { FormInput } from '../../../components/form/FormInput';
import { auth0ResetPassword } from '../../../utils/auth0';
import { AuthenticationViewModel, IResetPasswordFormValues, VIEW_TYPE } from '../AuthenticationViewModel';

export const ResetPassword = ({ viewModel }: { viewModel: AuthenticationViewModel }) => {
  const [isSuccessScreen, setSuccessScreen] = useState(false);
  const [email, setEmail] = useState('');
  const [isResending, setIsResending] = useState(false);

  const handleEmailResend = async () => {
    setIsResending(true);
    await auth0ResetPassword(email);
    setIsResending(false);
  };
  const initialValues: IResetPasswordFormValues = {
    email: '',
  };

  if (isSuccessScreen) {
    return (
      <>
        <Heading textStyle={{ base: 'h3', md: 'h2' }} mb="30px">
          יש לך מייל מאיתנו
        </Heading>
        <Text fontSize={{ base: 'lg', md: 'xl' }} mb="64px" fontWeight="300">
          כדי ליצור סיסמה חדשה, צריך ללחוץ על הקישור ששלחנו לך למייל:{' '}
          <Box as="span" fontWeight="bold">
            {email}
          </Box>
        </Text>
        <Box borderRadius="10px" bg="rgba(251, 197, 242, 0.30)" py="20px" px="40px" mb="50px">
          <Text fontWeight="bold" fontSize="lg">
            לא קיבלת את המייל?
          </Text>
          <Text fontWeight="300" fontSize="lg" mb="18px">
            כדאי לבדוק את תיקיית הספאם.
          </Text>
          <Button variant="link" isLoading={isResending} onClick={handleEmailResend}>
            לא קיבלתי, שלחו לי שוב
          </Button>
        </Box>
        <Flex alignItems="center" mt="60px">
          <Text fontWeight="300" fontSize="lg" marginInlineEnd="5px">
            עדיין לא הצטרפת?
          </Text>
          <Button variant="link" onClick={() => viewModel.setCurrentView(VIEW_TYPE.SIGNUP)}>
            מתחילים כאן עם 30 יום ניסיון חינם
          </Button>
        </Flex>
      </>
    );
  }

  return (
    <>
      <Heading textStyle={{ base: 'h3', md: 'h2' }} mb="38px">
        איפוס סיסמא
      </Heading>
      <Text fontSize={{ base: 'lg', md: 'xl' }} mb="64px" fontWeight="300">
        מכניסים את המייל שאיתו נרשמת ונשלח לך
        <br />
        קישור לאיפוס ויצירת סיסמה חדשה.
      </Text>

      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: FormikErrors<IResetPasswordFormValues> = {};
          if (!values.email) {
            errors.email = 'אנחנו צריכים את זה';
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'צריך להקפיד על המבנה הזה: name@email.com';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          setEmail(values.email);
          await auth0ResetPassword(values.email);
          setSuccessScreen(true);
        }}
      >
        {(props) => (
          <Form>
            <Stack>
              <FormInput isRequired name="email" label="כתובת מייל" type="email" />
              <Button my="24px" isLoading={props.isSubmitting} type="submit" w="100%" isDisabled={!props.isValid}>
                שליחה
              </Button>
              <Button variant="link" onClick={() => viewModel.setCurrentView(VIEW_TYPE.LOGIN)}>
                חזרה למסך הכניסה
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};
