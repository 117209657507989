import { Flex } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { CheckIcon } from '../pages/common/icons';

export const DropListItem = ({
  label,
  isSelected,
  onClick,
  size = 'sm',
}: {
  label: string | ReactNode;
  isSelected: boolean;
  onClick?: () => void;
  size?: 'sm' | 'lg';
}) => {
  return (
    <Flex
      p={size === 'sm' ? '8px' : '12px'}
      w="100%"
      borderRadius="8px"
      color="base.1000"
      alignItems="center"
      justifyContent="space-between"
      cursor="pointer"
      textStyle="large"
      onClick={onClick}
      _hover={{
        bg: 'base.25',
      }}
    >
      {label}
      {isSelected && <CheckIcon />}
    </Flex>
  );
};
