import { FormikErrors } from 'formik';

import { CREATE_NEW_CUSTOMER_ID } from '../pages/projects/createProject/CreateProjectViewModel';
import { PROJECT_STATUS, PROJECT_TYPE } from '../requests/Client';

import { ICustomerValues, getInitialCustomerValues, validateCustomerValues } from './customers/customerUtils';
import { getInitialPaymentValues, IPaymentValues } from './PaymentUtils';
import { PaymentCycleEnum, Project } from './Project';

export interface IProjectValues {
  name: string;
  startDate: Date;
  endDate?: Date;
  service: string;
  type?: PROJECT_TYPE;
  rate?: number;
  totalValue?: number;
  includeTotalHours?: boolean;
  totalHours?: number;
  paymentCycle: PaymentCycleEnum;
  includeAdvance: boolean;
  customerId: string;
  customer: Partial<ICustomerValues>;
  payments: Array<Partial<IPaymentValues>>;
}

export function getProjectInitialValues(p?: Project, baseRate?: number) {
  let payments: Array<Partial<IPaymentValues>> = [];
  let includeAdvance = false;
  if (p?.payments && p?._payments.length > 0) {
    const advancePayment = p.getAdvancePayment();
    if (p.type === PROJECT_TYPE.HOURLY && advancePayment) {
      payments.push(getInitialPaymentValues(advancePayment));
      includeAdvance = true;
    } else if (p.type === PROJECT_TYPE.FIXED) {
      payments = p._payments.map(getInitialPaymentValues);
    }
  }

  let totalHours = undefined;
  if (p && p.totalValue && p.rate) {
    totalHours = p.totalValue / p.rate;
  }

  return {
    customer: getInitialCustomerValues(),
    name: p?.name || '',
    customerId: p?.customer,
    startDate: p?._startDate || new Date(),
    endDate: p?._endDate,
    service: p?.service || '',
    type: p?.type,
    rate: p?.rate || baseRate,
    totalValue: p?.totalValue,
    totalHours,
    includeTotalHours: p ? Boolean(p?.totalValue) : true,
    paymentCycle: p?.paymentCycle || PaymentCycleEnum.IMMEDIATE,
    includeAdvance,
    payments,
  };
}

export function getProjectErrors(
  values: Partial<IProjectValues>,
  existingProjectName: string[],
  existingCustomerNames: string[],
  baseName?: string,
): FormikErrors<IProjectValues> {
  const errors: FormikErrors<IProjectValues & { paymentsTotal: string }> = {};
  if (!values.name) {
    errors.name = 'בלי זה אי אפשר להמשיך';
  }

  if (values.name && existingProjectName.includes(values.name)) {
    // if isEditMode ignore base name of the edited project
    if (values.name !== baseName) {
      errors.name = 'כבר יש פרויקט עם השם הזה';
    }
  }

  if (values.customerId === CREATE_NEW_CUSTOMER_ID) {
    const customerErrors = validateCustomerValues(values.customer || {}, existingCustomerNames, undefined, true);
    if (Object.keys(customerErrors).length > 0) {
      errors.customer = customerErrors;
    }
  }
  if (!values.customerId) {
    errors.customerId = 'בלי זה אי אפשר להמשיך';
  }
  if (!values.startDate) {
    errors.startDate = 'אנחנו צריכים את זה';
  }
  if (!values.service) {
    errors.service = 'בלי זה אי אפשר להמשיך';
  }
  if (!values.type) {
    errors.type = 'אנחנו צריכים את זה';
  }

  if (!values.type) {
    return errors;
  }

  if (values.type === PROJECT_TYPE.HOURLY) {
    if (!values.paymentCycle) {
      errors.paymentCycle = 'אנחנו צריכים את זה';
    }
    if (!values.rate) {
      errors.rate = 'אנחנו צריכים את זה';
    }
    if (values.payments && values.payments.length > 0) {
      const paymentErrors: { sum?: string; submitDate?: string } = {};
      if (!values.payments[0].sum) {
        paymentErrors.sum = 'אי אפשר להשאיר את זה ריק';
      }
      if (!values.payments[0].submitDate) {
        paymentErrors.submitDate = 'אנחנו צריכים את זה';
      }
      if (Object.keys(paymentErrors).length > 0) {
        if (!Array.isArray(errors.payments)) {
          errors.payments = [];
        }
        errors.payments[0] = paymentErrors;
      }
    }
  } else if (values.type === PROJECT_TYPE.FIXED) {
    if (!values.totalValue) {
      errors.totalValue = 'אי אפשר להשאיר את זה ריק';
    }
    if (values.payments && values.payments.length > 0) {
      const remainingValue = getRemainingValue(values);

      if (remainingValue !== 0) {
        errors.paymentsTotal = 'סך התשלומים אינו זהה לעלות הכוללת';
      }
      values.payments.forEach((p, i) => {
        const paymentErrors: { sum?: string; submitDate?: string } = {};
        if (!p.sum) {
          paymentErrors.sum = 'אי אפשר להשאיר את זה ריק';
        }
        if (!p.submitDate) {
          paymentErrors.submitDate = 'אנחנו צריכים את זה';
        }
        if (Object.keys(paymentErrors).length > 0) {
          if (!Array.isArray(errors.payments)) {
            errors.payments = [];
          }
          errors.payments[i] = paymentErrors;
        }
      });
    }
  }
  return errors;
}

export function getRemainingValue(values: Partial<IProjectValues>): number | null {
  if (!values.totalValue) {
    return null;
  }
  if (!values.payments || values.payments.length < 0) {
    return null;
  }
  return values.totalValue - values.payments.reduce((acc, curr) => acc + (curr.sum || 0), 0);
}

export const displayProjectStatuses = {
  [PROJECT_STATUS.ACTIVE]: 'פעיל',
  [PROJECT_STATUS.COMPLETE]: 'הושלם',
  [PROJECT_STATUS.CANCELLED]: 'לא פעיל',
  [PROJECT_STATUS.PAUSED]: 'מושהה',
};
