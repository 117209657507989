import { PaymentCycleEnum } from '../models/Project';

export const getDisplayPaymentCycle = (paymentCycle: PaymentCycleEnum): string => {
  switch (paymentCycle) {
    case PaymentCycleEnum.IMMEDIATE:
      return 'מיידי';
    case PaymentCycleEnum.SHOTEF:
      return 'שוטף';
    case PaymentCycleEnum.SHOTEF10:
      return 'שוטף 10';
    case PaymentCycleEnum.SHOTEF15:
      return 'שוטף 15';
    case PaymentCycleEnum.SHOTEF30:
      return 'שוטף 30';
    case PaymentCycleEnum.SHOTEF45:
      return 'שוטף 45';
    case PaymentCycleEnum.SHOTEF60:
      return 'שוטף 60';
    default:
      return '';
  }
};
