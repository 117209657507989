import { createIcon } from '@chakra-ui/icons';

export const StopIcon = createIcon({
  displayName: 'StopIcon',
  viewBox: '0 0 25 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M18.7501 2H7.08341C5.97875 2.00132 4.91971 2.44073 4.1386 3.22185C3.35748 4.00296 2.91807 5.062 2.91675 6.16667L2.91675 17.8333C2.91807 18.938 3.35748 19.997 4.1386 20.7782C4.91971 21.5593 5.97875 21.9987 7.08341 22H18.7501C19.8547 21.9987 20.9138 21.5593 21.6949 20.7782C22.476 19.997 22.9154 18.938 22.9167 17.8333V6.16667C22.9154 5.062 22.476 4.00296 21.6949 3.22185C20.9138 2.44073 19.8547 2.00132 18.7501 2ZM21.2501 17.8333C21.2501 18.4964 20.9867 19.1323 20.5178 19.6011C20.049 20.0699 19.4131 20.3333 18.7501 20.3333H7.08341C6.42037 20.3333 5.78449 20.0699 5.31565 19.6011C4.84681 19.1323 4.58341 18.4964 4.58341 17.8333V6.16667C4.58341 5.50363 4.84681 4.86774 5.31565 4.3989C5.78449 3.93006 6.42037 3.66667 7.08341 3.66667H18.7501C19.4131 3.66667 20.049 3.93006 20.5178 4.3989C20.9867 4.86774 21.2501 5.50363 21.2501 6.16667V17.8333Z"
    />,
  ],
});
