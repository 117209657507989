import { createIcon } from '@chakra-ui/icons';

export const ShekelIcon = createIcon({
  displayName: 'ShekelIcon',
  viewBox: '0 0 21 16',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M6.66135 16C6.45032 16 6.28725 15.944 6.17214 15.8319C6.05704 15.7011 5.99948 15.5423 5.99948 15.3555V5.35201C5.99948 5.16521 6.05704 5.01576 6.17214 4.90368C6.28725 4.79159 6.45032 4.73555 6.66135 4.73555H7.95632C8.14816 4.73555 8.30164 4.79159 8.41675 4.90368C8.55104 5.01576 8.61819 5.16521 8.61819 5.35201V13.7583H12.906C14.6134 13.7583 15.8508 13.3473 16.6182 12.5254C17.3856 11.6848 17.7693 10.5172 17.7693 9.02277V0.644483C17.7693 0.457676 17.8268 0.308231 17.9419 0.196147C18.0762 0.065382 18.2393 0 18.4311 0H19.7549C19.9467 0 20.1002 0.065382 20.2153 0.196147C20.3496 0.308231 20.4167 0.457676 20.4167 0.644483V9.10683C20.4167 10.62 20.1482 11.8903 19.611 12.9177C19.0738 13.9451 18.2585 14.7204 17.1649 15.2434C16.0906 15.7478 14.7189 16 13.0498 16H6.66135ZM1.04984 16C0.857996 16 0.704518 15.944 0.58941 15.8319C0.474302 15.7011 0.416748 15.5423 0.416748 15.3555V0.644483C0.416748 0.457676 0.474302 0.308231 0.58941 0.196147C0.704518 0.065382 0.857996 0 1.04984 0H7.46711C9.13617 0 10.5079 0.261529 11.5822 0.784588C12.6757 1.28897 13.4815 2.05487 13.9995 3.08231C14.5367 4.09107 14.8052 5.36135 14.8052 6.89317V10.62C14.8052 10.8068 14.7477 10.9656 14.6326 11.0963C14.5175 11.2084 14.364 11.2644 14.1721 11.2644H12.8484C12.6566 11.2644 12.4935 11.2084 12.3592 11.0963C12.2441 10.9656 12.1865 10.8068 12.1865 10.62V6.97723C12.1865 5.48278 11.8028 4.32458 11.0355 3.50263C10.2681 2.662 9.03066 2.24168 7.32322 2.24168H3.03545V15.3555C3.03545 15.5423 2.96831 15.7011 2.83401 15.8319C2.71891 15.944 2.56543 16 2.37358 16H1.04984Z"
    />,
  ],
});
