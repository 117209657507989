import { Stack, Text } from '@chakra-ui/react';

export const DataPoint = ({ label, value, component }: { label: string; value?: string; component?: JSX.Element }) => {
  return (
    <Stack>
      <Text textStyle="md" color="base.600">
        {label}
      </Text>
      {component ? (
        component
      ) : (
        <Text fontSize="lg" noOfLines={1} display="block" whiteSpace="nowrap">
          {value || '-'}
        </Text>
      )}
    </Stack>
  );
};
