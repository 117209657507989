import { createIcon } from '@chakra-ui/icons';

export const PlayIcon = createIcon({
  displayName: 'PlayIcon',
  viewBox: '0 0 25 24',
  path: [
    <path
      key="0"
      fill="currentColor"
      d="M18.5801 8.63908L10.6292 2.80503C10.0082 2.35029 9.27334 2.07643 8.5062 2.01381C7.73905 1.9512 6.96956 2.10227 6.28303 2.45028C5.5965 2.79829 5.01977 3.32964 4.61679 3.98541C4.21381 4.64118 4.00032 5.39575 4 6.16544V17.8336C4.00014 18.6036 4.21364 19.3585 4.61681 20.0146C5.01997 20.6707 5.59704 21.2022 6.28397 21.5502C6.97089 21.8982 7.74081 22.049 8.50827 21.986C9.27572 21.923 10.0107 21.6485 10.6317 21.1931L18.5826 15.3591C19.1104 14.972 19.5396 14.4661 19.8355 13.8823C20.1313 13.2985 20.2855 12.6532 20.2855 11.9987C20.2855 11.3442 20.1313 10.6989 19.8355 10.1151C19.5396 9.53123 19.1104 9.0253 18.5826 8.63825L18.5801 8.63908ZM17.5934 14.0148L9.64237 19.8488C9.26983 20.121 8.82924 20.2849 8.36935 20.3222C7.90947 20.3595 7.44822 20.2688 7.03668 20.0602C6.62514 19.8516 6.27935 19.5331 6.03759 19.1402C5.79583 18.7472 5.66752 18.295 5.66687 17.8336V6.16544C5.66225 5.70318 5.78813 5.24898 6.03007 4.85505C6.272 4.46112 6.62016 4.14343 7.03454 3.9385C7.387 3.75922 7.77675 3.66557 8.17218 3.66513C8.70216 3.66716 9.2175 3.83923 9.64237 4.15603L17.5934 9.99008C17.9096 10.2224 18.1668 10.5258 18.3441 10.8759C18.5213 11.226 18.6137 11.6129 18.6137 12.0053C18.6137 12.3977 18.5213 12.7847 18.3441 13.1348C18.1668 13.4848 17.9096 13.7883 17.5934 14.0206V14.0148Z"
    />,
  ],
});
