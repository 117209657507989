import { useMutation, useQueryClient } from '@tanstack/react-query';

import { logger } from '../../utils/logger';

import { Task, createTask } from './tasksApi';
import { TASKS_QUERY_KEY } from './useTasks';

export function useCreateTask() {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (task: Omit<Task, 'id'>) => createTask(task),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [TASKS_QUERY_KEY],
      });
    },
    onError: (e) => {
      logger.error('Failed to create userData', e);
    },
  });

  return {
    createTask: mutateAsync,
  };
}
