import { Box } from '@chakra-ui/react';
import { useState } from 'react';

import { Page } from '../../components/Page';
import { TabGroup } from '../../components/TabGroup';

import { SettingsViewModel } from './SettingsViewModel';
import { SubscriptionSettings } from './subscriptionSettings/SubscriptionSettings';
import { UserSettings } from './UserSettings';

export const Settings = ({ viewModel }: { viewModel: SettingsViewModel }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const tabs = {
    'פרטי העסק': <UserSettings />,
    'פרטי המנוי': <SubscriptionSettings openSubscriptionDialog={viewModel.openSubscriptionDialog} />,
    'התראות ועדכונים (בקרוב)': null,
  };

  return (
    <Page title="הגדרות">
      <Box mt="40px">
        <TabGroup index={currentTab} onChange={setCurrentTab} items={tabs} />
      </Box>
    </Page>
  );
};
