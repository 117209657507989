import { useState } from 'react';
import { useInterval, useLocalStorage, useSessionStorage } from 'usehooks-ts';

import { durationToSecondsDisplay, getDuration } from '../../utils/time/timeUtils';

import { useCreateWorkHour } from './useCreateWorkHour';
import { useUpdateWorkHour } from './useUpdateWorkHour';
import { useWorkHours } from './useWorkHours';
import { IWorkHourApi } from './workHourApi';
import { IValidWorkHourValues } from './workHourFormUtils';

const PAUED_WORK_HOUR_KEY = 'pausedWorkHour';
const WORK_HOUR_SUCCESS = 'workHourSuccess';

export type PausedWorkHour = {
  projectId?: string;
  comment?: string;
  duration: number;
  task_id?: number;
};

export const useTimeTracking = ({ saveWorkHour }: { saveWorkHour: (newWorkHour: IWorkHourApi) => Promise<void> }) => {
  const { createWorkHour } = useCreateWorkHour();
  const { updateWorkHour } = useUpdateWorkHour();
  const { partialWorkHour } = useWorkHours();
  const [pausedWorkHour, setPausedWorkHour] = useLocalStorage<PausedWorkHour | null>(PAUED_WORK_HOUR_KEY, null);
  const [now, setNow] = useState(new Date());
  const [success, setSuccess] = useSessionStorage(WORK_HOUR_SUCCESS, false);

  useInterval(
    () => {
      setNow(new Date());
    },
    partialWorkHour ? 1000 : null,
  );

  const startTimer = (projectId?: string, comment?: string, taskId?: number) => {
    createWorkHour({
      startTime: new Date(),
      comment,
      projectId,
      task_id: taskId,
    });
  };

  const completeWorkHour = async (wasStopped = false) => {
    if (!partialWorkHour) {
      if (wasStopped) {
        setPausedWorkHour(null);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 1000);
      }
      return;
    }

    if (!wasStopped) {
      setPausedWorkHour({
        projectId: partialWorkHour.project._id,
        comment: partialWorkHour.comment || undefined,
        duration: getDuration(partialWorkHour.startTime, now) + (pausedWorkHour?.duration || 0),
        task_id: partialWorkHour.task_id,
      });
    }

    await updateWorkHour({
      ...partialWorkHour,
      endTime: now,
      projectId: partialWorkHour.project._id,
    } as IValidWorkHourValues);

    const wh: IWorkHourApi = {
      start_time: partialWorkHour.startTime.toISOString(),
      end_time: now.toISOString(),
      comment: partialWorkHour.comment,
      id: parseInt(partialWorkHour._id),
      project_id: partialWorkHour.project._id ? parseInt(partialWorkHour.project._id) : undefined,
    };

    saveWorkHour(wh);
    if (wasStopped) {
      setPausedWorkHour(null);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    }
  };

  const handleCommentUpdate = (newComment: string) => {
    if (!partialWorkHour && !pausedWorkHour) {
      throw new Error('no partial work hour');
    }

    if (pausedWorkHour) {
      setPausedWorkHour({ ...pausedWorkHour, comment: newComment });
    } else if (partialWorkHour) {
      updateWorkHour({
        ...partialWorkHour,
        comment: newComment,
        projectId: partialWorkHour.project._id,
      } as IValidWorkHourValues);
    }
  };

  const getRunningDuration = () => {
    const activeRecordingDuration = partialWorkHour ? getDuration(partialWorkHour?.startTime!, now) : 0;

    return durationToSecondsDisplay(Math.max((pausedWorkHour?.duration || 0) + activeRecordingDuration, 0));
  };

  return {
    startTimer,
    completeWorkHour,
    handleCommentUpdate,
    runningDuration: getRunningDuration(),
    isRecording: Boolean(partialWorkHour),
    isPaused: pausedWorkHour && !partialWorkHour,
    currentWorkHour: partialWorkHour || pausedWorkHour,
    success,
  };
};
