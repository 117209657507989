const baseUrl = import.meta.env.REACT_APP_API_URL;
export class UserClient {
  async createUser(idToken: string, affiliateLinkId?: string, source?: string) {
    const params = {
      afid: affiliateLinkId || '',
      source: source || '',
    };
    const urlParams = new URLSearchParams(params);
    const url = `${baseUrl}/users?${urlParams.toString()}`;
    return fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => res.id);
  }

  async getUserByToken(idToken: string) {
    return fetch(`${baseUrl}/users`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
      .then((res) => res.json())
      .then((res) => res.id);
  }
}
