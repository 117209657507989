import { Box, Flex, Input, InputProps, Text, Textarea } from '@chakra-ui/react';
import { forwardRef, ReactNode, useRef } from 'react';

import { ExclamationLightIcon } from '../../pages/common/icons';
import { StyledTooltip } from '../StyledTooltip';

import { RequiredIndicator } from './RequiredIndicator';

export interface StyledInputProps extends InputProps {
  name: string;
  label?: string;
  error?: string;
  touched?: boolean;
  helperText?: string;
  tooltipText?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  rows?: number;
  size?: 'lg' | 'sm';
  maintainHeight?: boolean;
}

export const StyledInput = forwardRef(
  (
    {
      name,
      onChange,
      onBlur,
      value = '',
      type = 'text',
      label,
      error = '',
      isRequired = false,
      touched = false,
      helperText,
      placeholder,
      isDisabled,
      tooltipText,
      rows = 1,
      startIcon,
      endIcon,
      size = 'lg',
      maintainHeight = false,
      ...rest
    }: StyledInputProps,
    ref,
  ) => {
    const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement | null>(null);

    const setFocus = () => {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    };
    const isLarge = size === 'lg';
    return (
      <Box onClick={setFocus}>
        {label && (
          <Flex justifyContent="space-between" alignItems="center" mb="4px">
            <Flex>
              <Text textStyle="medium" color="base.600">
                {label}
              </Text>
              {isRequired && <RequiredIndicator />}
            </Flex>
            {tooltipText && (
              <StyledTooltip label={tooltipText}>
                <ExclamationLightIcon color="base.300" />
              </StyledTooltip>
            )}
          </Flex>
        )}
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap="8px"
          p={isLarge ? '14px' : '8px'}
          color={value ? 'base.1000' : 'base.500'}
          textStyle="large"
          border={isDisabled ? 'none' : '1px solid'}
          borderColor={error && touched ? 'error.100 !important' : 'base.300'}
          borderRadius={isLarge ? '12px' : '8px'}
          _hover={{
            borderColor: 'base.600',
          }}
          _focusWithin={{
            borderColor: 'base.1000',
          }}
          cursor={isDisabled ? 'not-allowed' : 'text'}
          bg={isDisabled ? 'base.100' : 'white'}
        >
          {startIcon}
          {type === 'textarea' ? (
            <Textarea
              name={name}
              ref={(node) => {
                inputRef.current = node;
                if (typeof ref === 'function') {
                  ref(node);
                } else if (ref) {
                  ref.current = node;
                }
              }}
              // @ts-ignore
              onChange={onChange}
              // @ts-ignore
              onBlur={onBlur}
              isDisabled={isDisabled}
              type={type}
              placeholder={placeholder}
              cursor={isDisabled ? 'not-allowed' : 'text'}
              variant="unstyled"
              flex="1"
              value={value}
              {...rest}
              resize="vertical"
              rows={rows}
              p="0"
            />
          ) : (
            <Input
              name={name}
              ref={(node) => {
                inputRef.current = node;
                if (typeof ref === 'function') {
                  ref(node);
                } else if (ref) {
                  ref.current = node;
                }
              }}
              onChange={onChange}
              onBlur={onBlur}
              isDisabled={isDisabled}
              type={type}
              placeholder={placeholder}
              cursor={isDisabled ? 'not-allowed' : 'text'}
              variant="unstyled"
              flex="1"
              value={value}
              {...rest}
            />
          )}
          {endIcon}
        </Flex>
        {error && touched && (
          <Text textStyle="small" color="error.100" mt="8px">
            {error}
          </Text>
        )}
        {helperText && (!error || !touched) && (
          <Text textStyle="small" color="base.600" mt="8px">
            {helperText}
          </Text>
        )}
        {maintainHeight && !helperText && (!error || !touched) && (
          <Box textStyle="small" color="base.600" mt="8px" h="18px" />
        )}
      </Box>
    );
  },
);
