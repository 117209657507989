import { Box } from '@chakra-ui/react';

import { FormBigNumberInput } from '../../components/form/FormBigNumberInput';
import { FormButtonTabs } from '../../components/form/FormButtonTabs';
import { FormInput } from '../../components/form/FormInput';
import { FormMultiValueInput } from '../../components/form/MultiValueInput';
import { BUSINESS_TYPE } from '../../models/userData/useUserData';
import { ShekelIcon } from '../common/icons';

export const OnboardingSecondStep = () => {
  return (
    <>
      <Box mb="10px">
        <FormButtonTabs
          name="businessType"
          label="סוג העסק שלך?"
          options={[
            { label: 'פטור', value: BUSINESS_TYPE.OSEK },
            { label: 'מורשה', value: BUSINESS_TYPE.MURSHE },
            { label: 'חברה', value: BUSINESS_TYPE.COMPANY },
          ]}
        />
      </Box>
      <FormInput
        isRequired
        name="baseRate"
        label="מה התעריף השעתי שלך?"
        type="number"
        step="0.01"
        endIcon={<ShekelIcon color="base.500" w="14px" />}
        helperText="כדי שנוכל לחשב לך את מידת הרווחיות בכל פרויקט"
      />
      <Box maxW="450px">
        <FormMultiValueInput
          isRequired
          name="services"
          label="איזה סוגי שירותים את/ה מציע/ה?"
          helperText="לפחות שניים, מופרדים בפסיק"
          tooltipText="כאלו שמוצעים בדר״כ ללקוחות. לדוג׳: עיצוב לוגו, פיתוח אתר, הכנת מסמך אפיון"
        />
      </Box>
      <FormBigNumberInput
        name="yearlyGoal"
        label="כמה את/ה רוצה להרוויח השנה?"
        tooltipText="לדוג׳ 120,000. אפשר לעדכן את זה גם בהמשך"
      />
    </>
  );
};
