import { Stack, Text, Flex, Box } from '@chakra-ui/react';
import { useState } from 'react';

import { ChevronDownIcon } from '../pages/common/icons';

import { Card } from './Card';

interface StackedAccordionCard {
  mainComponent: React.ReactNode;
  subComponents: React.ReactNode[];
}

export interface StackedAccordionCards {
  title: string;
  items: StackedAccordionCard[];
}

export const StackedAccordionCards = ({ title, items }: StackedAccordionCards) => {
  return (
    <Box
      position="relative"
      _before={{
        content: "''",
        display: 'block',
        position: 'absolute',
        right: '28px',
        borderLeft: '2px solid',
        borderColor: 'base.200',
        height: 'calc(100% - 30px)',
        top: '30px',
        width: '1px',
        zIndex: '0',
      }}
    >
      <Flex my={3} alignItems="center">
        <Box
          marginInlineStart="24px"
          height="12px"
          width="12px"
          border="2px solid"
          borderColor="base.200"
          borderRadius="full"
        />
        <Text marginInlineStart={5} textStyle="doubleLarge">
          {title}
        </Text>
      </Flex>
      <Stack gap="24px">
        {items.map((item, i) => {
          return <AccordionCard key={i} {...item} />;
        })}
      </Stack>
    </Box>
  );
};

const AccordionCard = ({ mainComponent, subComponents }: StackedAccordionCard) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box position="relative">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        p="24px"
        bg="white"
        border="1px solid"
        borderColor="base.200"
        borderRadius={isOpen ? '16px 16px 0 0' : '16px'}
      >
        {mainComponent}
        <ChevronDownIcon
          cursor="pointer"
          fontSize="24px"
          onClick={() => setIsOpen((prev) => !prev)}
          transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg'}
          transition=".2s"
        />
      </Flex>
      {isOpen && (
        <Stack
          gap="16px"
          bg="base.25"
          p="24px"
          border="1px solid"
          borderColor="base.200"
          borderRadius="0 0 16px 16px"
          borderTop="none"
        >
          {subComponents.map((subComponent, i) => {
            return <Card key={i}>{subComponent}</Card>;
          })}
        </Stack>
      )}
    </Box>
  );
};
