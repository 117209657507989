import { Stack, Text, Flex, Box } from '@chakra-ui/react';

import { Card } from './Card';

export interface StackedCardProps {
  title: string;
  items: React.ReactNode[];
}

export const StackedCards = ({ title, items }: StackedCardProps) => {
  return (
    <Box
      position="relative"
      _before={{
        content: "''",
        display: 'block',
        position: 'absolute',
        right: '28px',
        borderLeft: '2px solid',
        borderColor: 'base.200',
        height: 'calc(100% - 30px)',
        top: '30px',
        width: '1px',
        zIndex: '0',
      }}
    >
      <Flex my={3} alignItems="center">
        <Box
          marginInlineStart="24px"
          height="12px"
          width="12px"
          border="2px solid"
          borderColor="base.200"
          borderRadius="full"
        />
        <Text marginInlineStart={5} textStyle="doubleLarge">
          {title}
        </Text>
      </Flex>
      <Stack gap="24px">
        {items.map((item, i) => {
          return <Card key={i}>{item}</Card>;
        })}
      </Stack>
    </Box>
  );
};
