import { Box, Button, Heading, Image, Flex, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useState } from 'react';

import { Card } from '../../../components/Card';
import { Loader } from '../../../components/Loader/Loader';
import { IUserData, PaymentState, useUserData } from '../../../models/userData/useUserData';
import { DataPoint } from '../../common/DataPoint';
import { RocketIcon } from '../../common/icons';

import { CancelSubscriptionModal } from './CancelSubscriptionModal';

function getPaymentName(paymentState: PaymentState) {
  switch (paymentState) {
    case 'BETA':
      return 'פרילנס VIP';
    case 'PAYING':
    case 'TRIAL':
      return 'פרילנס מוביל';
    default:
      return '';
  }
}

function getPaymentPeriod(userData: IUserData) {
  if (userData.paymentState === 'BETA') {
    return `ללא`;
  }
  return `${format(userData.paymentStartDate!, 'dd.MM.yy')} - ${format(userData.paymentNextDate!, 'dd.MM.yy')}`;
}

function getPaymentType(paymentState: PaymentState) {
  if (paymentState === 'BETA') {
    return 'ללא';
  }
  if (paymentState === 'CANCELLED') {
    return 'מבוטל';
  }

  return 'חודשי מתחדש';
}

export const SubscriptionSettings = ({ openSubscriptionDialog }: { openSubscriptionDialog: () => void }) => {
  const { userData, isLoading, cancelSubscription } = useUserData();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false);

  const handleCancelSubscription = async () => {
    setCancelSubscriptionLoading(true);
    await cancelSubscription();
    setCancelSubscriptionLoading(false);
    setCancelModalOpen(false);
  };

  if (isLoading || !userData) {
    return <Loader />;
  }

  const headerData = [
    { title: 'שם', value: getPaymentName(userData.paymentState) },
    {
      title: 'תקופת החיוב',
      value: getPaymentPeriod(userData),
    },
    {
      title: 'מחיר',
      value: userData.paymentState === 'PAYING' || userData.paymentState === 'PAYMENT_FAILED' ? '33.93 ₪' : '0 ₪',
    },
    { title: 'סוג חיוב', value: getPaymentType(userData.paymentState) },
    {
      title: 'אמצעי תשלום',
      value:
        userData.paymentState === 'PAYING' && userData.payment4Digits ? `${userData.payment4Digits}*********` : '-',
    },
  ];
  return (
    <Flex flexDirection="column" rowGap="25px">
      <Card>
        <Heading textStyle="h6" mb="32px">
          המסלול שלך
        </Heading>
        <Box
          position="absolute"
          top="0"
          left="24px"
          display="flex"
          gap="12px"
          alignItems="center"
          borderRadius="0 0 8px 8px"
          py="8px"
          px="24px"
          bg="primary.0"
        >
          <Text fontSize="lg" color="base.900">
            מסלולים חדשים בקרוב!
          </Text>
          <RocketIcon color="accent.100" />
        </Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex columnGap="70px" mb="22px">
            {headerData.map((item, index) => (
              <Box key={index}>
                <DataPoint label={item.title} value={item.value} />
              </Box>
            ))}
          </Flex>
          {userData.paymentState === 'PAYING' && (
            <Flex justifyContent="space-between" gap="16px">
              <Button isDisabled variant="secondary" onClick={openSubscriptionDialog}>
                עדכון הכרטיס
              </Button>
              <Button variant="secondary" onClick={() => setCancelModalOpen(true)}>
                ביטול המנוי
              </Button>
            </Flex>
          )}
          {userData.paymentState === 'TRIAL' && (
            <Flex>
              <Button onClick={openSubscriptionDialog}>רכישת מנוי</Button>
            </Flex>
          )}
        </Flex>
      </Card>
      <Card testid="SubscriptionSettings.invoices">
        <Heading textStyle="h6" mt="10px">
          החשבוניות שלך
        </Heading>
        <Flex mb="70px" mt="50px" flexDirection="column" justifyContent="center" alignItems="center">
          <Image src="/subscription-illustration.svg" mb="22px" />
          <Text textStyle="large" textAlign="center" color="base.900">
            {userData.paymentState === 'BETA' ? (
              <>
                במקרה שלך, אין חיוב ולכן גם אין חשבוניות.
                <br />
                משתלם להיות VIP!
              </>
            ) : (
              <>
                עובדים על זה!
                <br />
                בקרוב יהיה אפשר להוריד אותן מכאן
              </>
            )}
          </Text>
        </Flex>
      </Card>
      {cancelModalOpen && (
        <CancelSubscriptionModal
          endDate={userData.paymentNextDate ? format(userData.paymentNextDate, 'dd.MM.yyyy') : ''}
          onCancel={handleCancelSubscription}
          onClose={() => setCancelModalOpen(false)}
          isLoading={cancelSubscriptionLoading}
        />
      )}
    </Flex>
  );
};
