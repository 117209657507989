import { Button, Flex, Stack, Text, Heading, Grid, Box } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import isEqual from 'lodash/isEqual';
import { observer } from 'mobx-react';
import { useState } from 'react';

import { FormAutocomplete } from '../../../components/form/FormAutocomplete';
import { FormDatePicker } from '../../../components/form/FormDatePicker';
import { FormInput } from '../../../components/form/FormInput';
import { FormSelect } from '../../../components/form/FormSelect';
import { SelectItem } from '../../../components/form/Select';
import { getPaymentInitialValues } from '../../../models/PaymentUtils';
import { PaymentCycleEnum } from '../../../models/Project';
import { getProjectInitialValues, getProjectErrors, IProjectValues } from '../../../models/ProjectUtils';
import { useUserData } from '../../../models/userData/useUserData';
import { IWorkHourBase } from '../../../models/workHours/workHourApi';
import { PROJECT_TYPE } from '../../../requests/Client';
import { CustomerFormInner } from '../../customer/CustomerFormInner';

import { CreateFixedContractForm } from './CreateFixedContractForm';
import { CreateHourlyContractForm } from './CreateHourlyContractForm';
import { CreateProjectViewModel } from './CreateProjectViewModel';
import { CREATE_NEW_CUSTOMER_ID } from './CreateProjectViewModel';

export const CreateProject = observer(({ viewModel }: { viewModel: CreateProjectViewModel }) => {
  const { userData } = useUserData();
  const [error, setError] = useState('');
  const [importedWorkHours, setImportedWorkHours] = useState<IWorkHourBase[]>([]);

  const services = userData?.services.map((s) => ({ key: s, value: s })) || [{ key: 'טוען...', value: '' }];

  const handleSubmit = async (values: IProjectValues) => {
    await viewModel.createProject(values, importedWorkHours);
  };

  // TODO - type form values, or pass only necessary values
  const handleProjectTypeChange = (value: string, props: any) => {
    value === PROJECT_TYPE.FIXED
      ? props.setFieldValue('payments[0]', getPaymentInitialValues(), false)
      : props.setFieldValue('payments', [], true);

    if (props.values.type && value !== props.values.type) {
      // clear all form data
      props.setFieldValue('totalValue', undefined, false);
      props.setFieldValue('totalHours', undefined, false);
      props.setFieldValue('includeTotalHours', true, false);
      props.setFieldValue('hourTracking', true, false);

      handleCustomerChange({ value: props.values.customerId, key: '' }, props, value === PROJECT_TYPE.FIXED);
    }
    // need to assign again for some reason
    props.setFieldValue('type', value);
  };

  const initialValues = getProjectInitialValues(viewModel.baseProject, userData?.baseRate);
  const markAsDitry = (values: any) => {
    viewModel.setIsDirty(!isEqual(values, initialValues));
  };

  const handleCustomerChange = (data: SelectItem, props: any, isFixed: boolean) => {
    const customer = viewModel.getCustomerById(data.value);

    if (customer && !isFixed) {
      props.setFieldValue('rate', customer.rate || userData?.baseRate || 0, false);
      props.setFieldValue('paymentCycle', customer.payment_cycle || PaymentCycleEnum.IMMEDIATE, false);
    } else {
      props.setFieldValue('paymentCycle', PaymentCycleEnum.IMMEDIATE, false);
    }
  };

  return (
    <>
      <Heading textStyle="h3" mb="20px">
        {viewModel.baseProject ? 'עריכת פרויקט' : 'יצירת פרויקט'}
      </Heading>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          markAsDitry(values);
          return getProjectErrors(
            values,
            viewModel.existingProjectNames,
            viewModel.existingCustomerNames,
            viewModel.baseProject?.name,
          );
        }}
        onSubmit={async (values, actions) => {
          try {
            // @ts-ignore
            await handleSubmit(values, actions);
          } catch (_) {
            setError('ארעה שגיאה');
          }
        }}
      >
        {(props) => (
          <Form>
            <Grid
              columnGap="160px"
              templateColumns="repeat(2, minmax(100px, 50%))"
              p="55px"
              paddingBottom="25px"
              paddingInlineEnd="112px"
              bg="white"
              border="1px solid"
              borderColor="base.200"
              borderRadius="18px"
              mb="38px"
            >
              <FormInput isRequired name="name" label="שם הפרויקט" />
              <FormAutocomplete
                size="lg"
                name="customerId"
                data={viewModel.customerForSelection}
                label="שם הלקוח"
                action={{
                  label: '+ הוספת לקוח חדש',
                  onClick: () => props.setFieldValue('customerId', CREATE_NEW_CUSTOMER_ID),
                }}
                onChange={(data) => handleCustomerChange(data, props, props.values.type === PROJECT_TYPE.FIXED)}
                isRequired
                menuWidth="450px"
              />
            </Grid>
            {props.values.customerId === CREATE_NEW_CUSTOMER_ID && (
              <Box
                p="55px"
                paddingBottom="20px"
                paddingInlineEnd="112px"
                bg="white"
                border="1px solid"
                borderColor="base.200"
                borderRadius="18px"
                mb="38px"
              >
                <Heading textStyle="h4" mb="36px">
                  הוספת לקוח
                </Heading>
                <CustomerFormInner prefix="customer" />
                {/* <Text fontSize="15px" color="#685888" marginTop="30px" marginBottom="20px">
                  <ExclamationLightIcon color="#634B7B" w="15px" marginInlineEnd="8px" />
                  השדות המוצגים מהווים פרטים התחלתיים ליצירת הפרויקט. ניתן להזין פרטים נוספים בהמשך מתוך מסך לקוח
                </Text> */}
              </Box>
            )}
            <Grid
              columnGap="160px"
              templateColumns="1fr 1fr"
              p="55px"
              paddingBottom="25px"
              paddingInlineEnd="112px"
              bg="white"
              border="1px solid"
              borderColor="base.200"
              borderRadius="18px"
              mb="38px"
            >
              <FormDatePicker name="startDate" label="תאריך התחלה" isRequired />
              <FormDatePicker name="endDate" label="תאריך סיום" isClearable minimumDate={props.values.startDate} />
              <FormSelect
                name="service"
                label="סוג השירות"
                data={services}
                isRequired
                helperText="אפשר להוסיף סוגי שירותים באזור ההגדרות"
              />
              <FormSelect
                name="type"
                label="סוג החוזה"
                isRequired
                data={[
                  { key: 'שעתי', value: PROJECT_TYPE.HOURLY },
                  { key: 'פיקס (פרויקטלי)', value: PROJECT_TYPE.FIXED },
                ]}
                onChange={(e) => handleProjectTypeChange(e.value, props)}
              />
            </Grid>
            {props.values.type &&
              (props.values.type === PROJECT_TYPE.FIXED ? (
                <CreateFixedContractForm
                  values={props.values}
                  additions={viewModel.baseProject?._additions}
                  isEditMode={viewModel.isEditMode}
                  setImportedWorkHours={setImportedWorkHours}
                />
              ) : (
                <CreateHourlyContractForm
                  values={props.values}
                  isEditMode={viewModel.isEditMode}
                  setImportedWorkHours={setImportedWorkHours}
                />
              ))}
            <Flex
              justifyContent="space-between"
              alignItems="center"
              py={props.values.type === PROJECT_TYPE.FIXED ? '76px' : '16px'}
            >
              <Button onClick={viewModel.onCancel} variant="secondary">
                ביטול
              </Button>
              <Stack>
                <Button type="submit" isDisabled={!props.isValid} isLoading={props.isSubmitting}>
                  {viewModel.isEditMode ? 'שמירה' : 'יצירת הפרויקט'}
                </Button>
                {error && <Text color="red">{error}</Text>}
              </Stack>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
});
