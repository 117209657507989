import auth0, { Auth0DecodedHash } from 'auth0-js';

export function initWebAuth() {
  return new auth0.WebAuth({
    domain: import.meta.env.REACT_APP_AUTH_DOMAIN,
    clientID: import.meta.env.REACT_APP_AUTH_CLIENT,
    responseType: 'token id_token',
    audience: import.meta.env.REACT_APP_AUTH_AUDIENCE,
    redirectUri: window.location.href,
    scope: 'openid profile email user_metadata',
  });
}

export async function auth0Login(email: string, password: string) {
  const webAuth = initWebAuth();
  return new Promise((resolve, reject) => {
    webAuth.login(
      {
        realm: 'Username-Password-Authentication',
        email,
        password,
      },
      function (err) {
        if (err) {
          reject(err);
        }
        resolve(true);
      },
    );
  });
}

export async function auth0Signup(email: string, password: string, name: string) {
  const webAuth = initWebAuth();
  return new Promise((resolve, reject) => {
    webAuth.redirect.signupAndLogin(
      {
        connection: 'Username-Password-Authentication',
        email,
        password,
        userMetadata: {
          name,
        },
      },
      function (err) {
        if (err) {
          reject(err);
        }
        resolve(true);
      },
    );
  });
}

export async function auth0ResetPassword(email: string) {
  const webAuth = initWebAuth();
  return new Promise((resolve, reject) => {
    webAuth.changePassword(
      {
        connection: 'Username-Password-Authentication',
        email,
      },
      function (err) {
        if (err) {
          reject(err);
        }
        resolve(true);
      },
    );
  });
}

export async function auth0RenewAuth(): Promise<Auth0DecodedHash> {
  const webAuth = initWebAuth();
  return await new Promise((resolve, reject) => {
    webAuth.renewAuth(
      {
        redirectUri: `${window.location.origin}/.netlify/functions/renew-auth`,
        usePostMessage: true,
      },
      (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      },
    );
  });
}
