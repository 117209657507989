import { createIcon } from '@chakra-ui/icons';

export const PartyIcon = createIcon({
  displayName: 'PartyIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      fill="#D613B6"
      d="M7.4155 6.88944C7.05491 6.5288 6.43971 6.66065 6.26005 7.13969L0.294166 23.0487C0.0828196 23.6122 0.634739 24.1657 1.19936 23.9539C1.99839 23.6542 16.4672 18.2284 17.1083 17.988C17.5851 17.8092 17.721 17.195 17.3586 16.8326L7.4155 6.88944ZM7.19323 8.65581L10.0825 11.5451C10.2824 13.9478 11.1725 16.2576 12.6239 18.1678L9.61624 19.2957C7.36657 16.9556 6.21848 13.6838 6.52427 10.4397L7.19323 8.65581ZM5.29763 20.9152C4.60406 20.1274 4.02721 19.2495 3.57765 18.2974L5.27555 13.7696C5.69726 16.0855 6.7374 18.1572 8.1963 19.8281L5.29763 20.9152ZM2.92201 20.0458C3.21618 20.532 3.54288 20.997 3.90042 21.4392L2.15391 22.0941L2.92201 20.0458ZM14.0059 17.6496C12.9514 16.3667 12.1946 14.8577 11.7885 13.2511L15.5922 17.0547L14.0059 17.6496Z"
    />,
    <path
      key="1"
      fill="#3D1E5C"
      d="M11.1161 7.66337C11.3906 7.93795 11.8358 7.93799 12.1104 7.66337C15.3632 4.41058 14.3151 1.11669 14.2689 0.978039C14.1461 0.60967 13.7479 0.410511 13.3795 0.533363C13.0112 0.656168 12.8121 1.05435 12.9349 1.42271C12.9434 1.44845 13.7612 4.02403 11.1161 6.66912C10.8415 6.94365 10.8415 7.38884 11.1161 7.66337Z"
    />,
    <path
      key="2"
      fill="#5B50F3"
      d="M13.6014 9.15477C13.3269 9.42934 13.3269 9.87449 13.6014 10.1491C13.8735 10.4212 14.3155 10.4247 14.5916 10.1532C14.9548 9.97226 16.0821 10.6511 16.7087 10.0248C17.3285 9.40492 16.67 8.30047 16.8344 7.9134C17.222 7.74851 18.3261 8.40734 18.9459 7.7876C19.5658 7.16776 18.9072 6.06331 19.0717 5.6762C19.4599 5.51125 20.5633 6.17018 21.1831 5.55039C21.803 4.93055 21.1444 3.8261 21.3089 3.43899C21.6948 3.27489 22.8009 3.93232 23.4204 3.31313C24.0466 2.68678 23.3691 1.5574 23.5487 1.19606C23.8192 0.921202 23.8177 0.479151 23.5446 0.205933C23.27 -0.0686443 22.8249 -0.0686443 22.5503 0.205933C21.9961 0.760102 22.1045 1.54245 22.2089 2.10177C21.6496 1.99739 20.8673 1.88897 20.3131 2.44309C19.7589 2.99726 19.8674 3.77961 19.9717 4.33893C19.4124 4.2346 18.63 4.12609 18.0759 4.6803C17.5217 5.23447 17.6302 6.01682 17.7346 6.57614C17.1752 6.47176 16.3929 6.36329 15.8387 6.91746C15.2846 7.47163 15.393 8.25398 15.4974 8.8133C14.938 8.70901 14.1555 8.6006 13.6014 9.15477Z"
    />,
    <path
      key="3"
      fill="#5B50F3"
      d="M17.5791 3.18851C17.8537 2.91394 17.8537 2.46879 17.5791 2.19421C17.3046 1.91964 16.8594 1.91964 16.5848 2.19421C16.3103 2.46879 16.3103 2.91394 16.5848 3.18851C16.8594 3.46309 17.3046 3.46309 17.5791 3.18851Z"
    />,
    <path
      key="4"
      fill="#5B50F3"
      d="M22.0537 8.36613C22.442 8.36613 22.7568 8.05135 22.7568 7.66304C22.7568 7.27474 22.442 6.95996 22.0537 6.95996C21.6654 6.95996 21.3506 7.27474 21.3506 7.66304C21.3506 8.05135 21.6654 8.36613 22.0537 8.36613Z"
    />,
    <path
      key="5"
      fill="#5B50F3"
      d="M20.5624 14.1259C20.2878 14.4004 20.2878 14.8456 20.5624 15.1202C20.837 15.3947 21.2821 15.3947 21.5567 15.1202C21.8313 14.8456 21.8313 14.4004 21.5567 14.1259C21.2821 13.8513 20.837 13.8513 20.5624 14.1259Z"
    />,
    <path
      key="6"
      fill="#5B50F3"
      d="M10.6192 4.18363C10.8938 3.90905 10.8938 3.46391 10.6192 3.18933C10.3446 2.91475 9.89946 2.91475 9.62488 3.18933C9.3503 3.46386 9.3503 3.90905 9.62488 4.18363C9.89946 4.45821 10.3446 4.45821 10.6192 4.18363Z"
    />,
  ],
});
