import { Button } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import { Box, Flex, Grid, Text } from '@chakra-ui/layout';
import { observer } from 'mobx-react';

import { Loader } from '../../components/Loader/Loader';
import { Page } from '../../components/Page';
import { useVat } from '../../hooks/useVat/useVat';
import { useUserData } from '../../models/userData/useUserData';

import { GoalCard } from './components/GoalCard';
import { TopServices } from './components/TopServices';
import { WorkHoursBreakdown } from './components/WorkHoursBreakdown';
import { InsightsViewModel } from './InsightsViewModel';
import { PaymentsBarChart } from './PaymentsBarChart';

export const Insights = observer(({ viewModel }: { viewModel: InsightsViewModel }) => {
  const { isLoading, userData } = useUserData();
  const { getValueWithVat } = useVat();

  if (isLoading || viewModel.isLoading) {
    return <Loader />;
  }

  // TODO - change overall layout to grid for better responsive
  // make first row a full width cell with inner flex

  // generate custom dash using https://kovart.github.io/dashed-border-generator/
  const dashedBg = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23270C43FF' stroke-width='2' stroke-dasharray='5%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`;
  return (
    <Page title="תובנות">
      <Flex mb="16px" w="1295px" direction="row-reverse">
        {viewModel.isEmpty ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            sx={{
              'background-image': dashedBg,
            }}
            flex="1"
            borderRadius="10px"
          >
            <Text fontSize="lg">
              הנתונים שמוצגים הם לצורך הדגמה בלבד. עם תחילת העבודה שלך, יופיעו כאן נתונים אמיתיים{' '}
            </Text>
          </Flex>
        ) : (
          <Text textStyle="large">
            יש לך פידבק על המסך הזה?
            <Button variant="link" onClick={viewModel.openHelpDialog} marginInlineStart="4px">
              נשמח לשמוע
            </Button>
          </Text>
        )}
      </Flex>
      <Grid templateColumns="1fr" templateRows="380px 335px" gap="24px">
        <Flex gap="24px">
          {viewModel.isEmpty ? (
            <Image src="/insights_empty-goals.svg" />
          ) : (
            <Box w="245px" borderRadius="10px" bg="white" border="1px solid" borderColor="#E6E9F2" p="24px">
              <GoalCard
                totalIncomeInYear={getValueWithVat(viewModel.totalIncomeInYear)}
                yearlyGoal={userData?.yearlyGoal ? getValueWithVat(userData?.yearlyGoal) : undefined}
              />
            </Box>
          )}
          {viewModel.isEmpty ? (
            <Image src="/insights_empty-payments.svg" />
          ) : (
            <Box flex="1" borderRadius="10px" bg="white" border="1px solid" borderColor="#E6E9F2" p="24px">
              <Text fontSize="xl">צפי הכנסות לפי חודשים</Text>
              <Box h="300px" mt="10px">
                <PaymentsBarChart
                  data={viewModel.expectedIncomePerMonth}
                  projectPerMonths={viewModel.projectsPerExpectedIncomePerMonth}
                />
              </Box>
            </Box>
          )}
        </Flex>
        <Flex gap="24px">
          {viewModel.isEmpty ? (
            <Image src="/insights_empty-services.svg" />
          ) : (
            <Box w="418px" borderRadius="10px" bg="white" border="1px solid" borderColor="#E6E9F2" p="24px">
              <TopServices topServices={viewModel.topServices} />
            </Box>
          )}
          {viewModel.isEmpty ? (
            <Image src="/insights_empty-hours.svg" />
          ) : (
            <Box flex="1" borderRadius="10px" bg="white" border="1px solid" borderColor="#E6E9F2" p="24px">
              <WorkHoursBreakdown
                selectedWorkHourPreset={viewModel.selectedWorkHourPreset}
                setSelectedWorkHourPreset={viewModel.setSelectedWorkHourPreset}
                totalHoursPerPreset={viewModel.totalHoursPerPreset}
                workHourDonutData={viewModel.workHourDonutData}
              />
            </Box>
          )}
        </Flex>
      </Grid>
    </Page>
  );
});
