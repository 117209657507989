import { Box, Flex, Heading } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const Page = ({
  title,
  component,
  children,
  id = '',
}: {
  title: string;
  component?: ReactNode;
  children: ReactNode;
  id?: string;
}) => {
  return (
    <Box id={id}>
      <Flex alignItems="center" mb="16px">
        <Heading textStyle="h3">{title}</Heading>
        {component && <Box marginInlineStart="16px">{component}</Box>}
      </Flex>
      {children}
    </Box>
  );
};
