import { Flex, Text, Divider } from '@chakra-ui/react';

export const SummaryPill = ({ label, value }: { label: string; value: string }) => {
  return (
    <Flex
      border="1px solid"
      borderColor="base.200"
      borderRadius="14px"
      alignItems="center"
      bg="white"
      px="16px"
      h="44px"
      gap="10px"
      textStyle="large"
    >
      <Text>{label}</Text>
      <Divider orientation="vertical" borderColor="base.200" h="24px" />
      <Text>{value}</Text>
    </Flex>
  );
};
